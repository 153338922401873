import BaseView from './base_view';
import template from '../templates/comments_pagination.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.CommentsPagination
export default BaseView.extend({
  template: template,

  initialize: function (options) {
    this.options = this.options || options || {};

    bindAll(this, "handleShowMoreClick");
    this.model.get('comments').on("all", this.render, this);
    this.registerEvents();
    this.render();
  },

  render: function(){
    this.$el.html(this.template());
  },

  hasMorePages: function(){
    return (
      this.model.get("comments").length <
      this.model.get("comments_count")
    );
  },

  registerEvents: function(){
    this.$el.on("click", ".link", this.handleShowMoreClick);
  },

  handleShowMoreClick: function(){
    this.model.fetchNext(this);
  }
});
