export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<img src=\'' +
((__t = ( App.Assets.imagePath('logos/bd-logo-2019-reversed.svg') )) == null ? '' : __t) +
'\' alt="Brad\'s Deals" class=\'bd-logo\' />\n<h2 class=\'modal-title\'>' +
((__t = ( this.modalTitle() )) == null ? '' : __t) +
'</h2>\n<h3 class=\'modal-subtitle\'>' +
((__t = ( this.modalSubTitle() )) == null ? '' : __t) +
'</h3>\n<form action=\'/users\' method=\'POST\' data-remote=\'true\' class=\'sign-up-form\' data-type=\'json\'>\n  ';
 if ( !this.hideEmail() ) { ;
__p += '\n    <label for=\'email\'>Please enter your email address:</label>\n    <ul class="errors reset"></ul>\n    <input type=\'email\' id=\'email\' name=\'email\' placeholder=\'Email Address\' />\n    <input type=\'submit\' value=\'Submit\' class=\'rounded-button secondary\' style=\'' +
((__t = ( this.buttonStyling() )) == null ? '' : __t) +
'\' data-disable-with=\'Submitting...\'>\n  ';
 } ;
__p += '\n  ';
 if ( !this.hideFacebook() ) { ;
__p += '\n    <span class="rounded-button facebook-login-button signup">\n      <img alt="Facebook logo" src="' +
((__t = ( App.Assets.imagePath("svgs/official_facebook_white.svg") )) == null ? '' : __t) +
'">\n      Sign Up with Facebook\n    </span>\n  ';
 } ;
__p += '\n</form>\n<p class="centered small">\n  The Brad\'s Deals <a href=\'/privacy\'>Privacy Policy</a> is this:\n  <br />\n  We don\'t share your email address with anyone, and we never will.\n</p>\n';
return __p
}