import BaseCollection from './base_collection';
import Deal from './deal';
import { bindAll, clone } from 'underscore';

// App.Collections.Deals
export default BaseCollection.extend({
  url: '/deals.json',

  model: Deal,

  initialize: function (models, options) {
    if (options) {
      this.limit = options.limit;
      this.total = options.total;
      this.query = options.query;
    }

    bindAll(this, 'parseAndMerge');
  },

  parse: function (data) {
    if (data.deals) {
      this.pageCount = data.count;
      this.total = data.total;
      return data.deals;
    }

    return data;
  },

  parseAndMerge: function (data) {
    if (data.deals) {
      this.total = data.total;
      this.add(data.deals);
    }

    this.trigger('sync');
  },

  fetchNext: function () {
    var params = clone(this.query);
    params.page = parseInt(this.length / this.limit) + 1;
    if (this.limit) params.limit = this.limit;
    this.trigger('request');

    return $.ajax({
      url: this.url,
      type: 'GET',
      data: params,
      dataType: 'json'
    }).done(this.parseAndMerge);
  }
});
