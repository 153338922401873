import BaseView from './base_view';

// App.Views.CollectionItemView
export default BaseView.extend({
  inheritsFromCollectionItemView: true,

  initialize: function(options) {
    this.options = this.options || options || {};
    this.index = this.options.index;
  }
});
