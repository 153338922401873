export default function(data) {
var __t, __p = '';
__p += '<a href="' +
((__t = (this.model.get("go_link_href"))) == null ? '' : __t) +
'" class="go-link ' +
((__t = ( this.goLinkStylingClass() )) == null ? '' : __t) +
'">\n  ' +
((__t = ( this.model.get('go_link_text') )) == null ? '' : __t) +
'\n  at ' +
((__t = ( this.model.get("merchant").name )) == null ? '' : __t) +
'\n</a>\n';
return __p
}