// App.Lib.Deferred
export default function () {
  var _this = this;

  this.promise = new Promise(function (resolve, reject) {
    _this.resolve = resolve;
    _this.reject = reject;
  }).catch(function (e) {
    console.error(e);
  });
};
