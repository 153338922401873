import EmailGate from '../views/email_gate';
import EmailPreference from '../models/email_preference';
import EmailPreferences from '../views/users/email_preferences';
import NewsletterTypes from '../models/newsletter_types';
import UnsubscribeModal from '../views/modals/unsubscribe';
import { bindAll } from 'underscore';
import user from '@legacy-js/user-data/user';

// App.Controllers.EmailPreferencesController
export default {
  initialize: function () {
    bindAll(this, 'lookupEmailPreferences', 'revealEmailPreferences', 'revealEmailGate',
                    'knownEmail', 'initShow');
    App.Dispatcher.on('emailPreferencesControllerGateShow', this.initShow);
    this.options = {};
  },

  initShow: function (options) {
    // Provide default in case trigger provides no options
    options                       = options || {};
    this.options.email            = options.email || this.options.email;
    this.options.unsubscribe      = options.unsubscribe || this.options.unsubscribe || false;
    this.options.newsletterTypes  = options.newsletterTypes || this.options.newsletterTypes;

    if (this.getMD5QueryParam() || this.knownEmail()) {
      this.lookupEmailPreferences();
    } else {
      App.Dispatcher.on('bdUserReady', this.initShow, this);
      this.revealEmailGate();
    }
  },

  lookupEmailPreferences: function () {
    var emailPreferences = new EmailPreference({ md5: this.md5() });
    emailPreferences.fetch({
      success:  this.revealEmailPreferences,
      error:    this.revealEmailGate
    });
  },

  revealEmailPreferences: function (model, data) {
    if (model.get('user_id') !== user.get('id')) {
      user.init(data)
    }

    if (this.emailGateView) this.emailGateView.sever();

    this.emailPreferencesView = new EmailPreferences({
      el: '#email-preferences',
      model: model,
      user: user,
      newsletterTypes: new NewsletterTypes(this.options.newsletterTypes)
    });

    // Load unsubscribe modal if the url is /unsubscribe
    if (this.options.unsubscribe) {
      this.revealUnsubscribe(model);
    }
  },

  revealEmailGate: function () {
    if (this.emailGateView) this.emailGateView.sever();

    this.emailGateView = new EmailGate({
      email: this.knownEmail(),
      el: '#email-preferences'
    });
  },

  revealUnsubscribe: function (model) {
    App.Dispatcher.trigger('showModal', {
      modalClass: UnsubscribeModal,
      model: model
    });
  },

  knownEmail: function () {
    return this.options.email ||
           this.getFillEmail() ||
           this.getEmailQueryParam() ||
           user.get('email');
  },

  md5: function () {
    return (
      this.getMD5QueryParam() ||
      CryptoJS.MD5(
        this.knownEmail()
          .toLowerCase()
          .trim()
        ).toString()
    );
  },

  getEmailQueryParam: function () {
    return App.Data.currentUrlParameters().email ?
      unescape(App.Data.currentUrlParameters().email) : null;
  },

  getMD5QueryParam: function () {
    return App.Data.currentUrlParameters().md5_hash;
  },

  getFillEmail: function () {
    // Base64 decode param fill if param fill exists
    if (App.Data.currentUrlParameters().fill) {
      return CryptoJS.enc.Base64.parse(
        App.Data.currentUrlParameters().fill
      ).toString(
        CryptoJS.enc.Utf8
      );
    }

    return null;
  }
};
