<template src="./my_deals_wrapper.html" />
<style src="./my_deals_wrapper.scss" lang="scss" scoped></style>

<script>
import { get, post } from '../../../utils/api-service';
import InfoLinks from '../../info_links/info_links.vue';
import MyDealsInterests from '../my_deals_interests/my_deals_interests.vue';
import MyDealsSignUp from '../my_deals_sign_up/my_deals_sign_up.vue';
import MyDealsConfirmation from '../my_deals_confirmation/my_deals_confirmation.vue';
import MyDealsCompleteProfile from '../my_deals_complete_profile/my_deals_complete_profile.vue';
import MyDealsPassword from '../my_deals_password/my_deals_password.vue';
import MyDealsEmailConfirm from '../my_deals_email_confirm/my_deals_email_confirm.vue';

const interestSlugs = [
  'home-furnishings-and-decor',
  'cooking-and-food',
  'technology-and-gadgets',
  'outdoors',
  'womens-apparel-and-accessories',
  'mens-apparel-and-accessories',
  'womens-shoes',
  'mens-shoes',
  'gifting',
  'travel',
];

export default {
  name: 'MyDealsWrapper',
  components: {
    'info-links': InfoLinks,
    'my-deals-interests': MyDealsInterests,
    'my-deals-sign-up': MyDealsSignUp,
    'my-deals-confirmation': MyDealsConfirmation,
    'my-deals-complete-profile': MyDealsCompleteProfile,
    'my-deals-password': MyDealsPassword,
    'my-deals-email-confirm': MyDealsEmailConfirm,
  },

  props: {
    completeProfile: {
      type: Boolean,
      required: false,
    },

    emailUser: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      currentStep: 1,
      email: '',
      interests: [],
      open: true,
      selectedInterests: [],
    };
  },

  computed: {
    drawerClass() {
      return this.open ? 'open' : 'closed';
    },
  },

  created() {
    const parsedInterests = this.parseUsersInterestsLocal();

    if (this.completeProfile && parsedInterests.length > 0) {
      this.currentStep = 4;
      this.selectedInterests = parsedInterests;
    }
  },

  mounted() {
    this.getInterests();
    this.$dispatcher.on('openMyDealsDrawer', this.openDrawer);
  },

  beforeDestroy() {
    this.$dispatcher.off('openMyDealsDrawer', this.openDrawer);
  },

  methods: {
    closeDrawer() {
      this.open = false;
    },

    openDrawer() {
      this.open = true;
    },

    getInterests() {
      get('/api/interests').then(
        this.handleGetInterestsSuccess,
        this.handleGetInterestsError,
      ).catch((e) => { console.error(e); });
    },

    handleGetInterestsSuccess(response) {
      const { body: { interests } } = response;

      this.interests = interests.filter((interest) => interestSlugs.includes(interest.slug));
    },

    handleGetInterestsError(response) {
      console.error(response);
    },

    handleSetUserEmail(email) {
      this.email = email;
    },

    setSelectedInterests(interests) {
      this.selectedInterests = interests;
    },

    goToStep(step) {
      this.currentStep = step;
    },

    broadCastUserInterestChanges() {
      const userInterests = this.selectedInterests;

      this.$dispatcher.trigger('sync:userInterests', { userInterests, source: 'my-deals' });
    },

    saveUsersInterests() {
      // check for local storage first, fall back on selected interests
      // const interests = local storage or this.selectedInterests
      const requestOptions = { body: { users_interests: { interests: this.selectedInterests } } };

      return post('/api/users/interests', requestOptions)
        .then(this.broadCastUserInterestChanges)
        .catch(this.handleSetInterestsError);
    },

    saveUsersInterestsLocal() {
      localStorage.setItem('interests', this.selectedInterests);
    },

    parseUsersInterestsLocal() {
      const interestsString = localStorage.getItem('interests');
      return (interestsString && interestsString.split(',')) || [];
    },

    handleSetInterestsError() {
      console.error('Something went wrong, please try again.');
    },
  },
};
</script>
