import Search from '../models/search';
import SearchPage from '../views/search_page';
import SubscriptionModule from '../views/subscription_module';
import { clone } from 'underscore';
import user from '@legacy-js/user-data/user';

// App.Controllers.SearchesController
export default {
  initialize: function () {
    App.Dispatcher.on('searchesControllerShow', this.initShow, this);
    App.Dispatcher.on('initSubscriptionModule', this.initSubscriptionModule, this);
    App.Dispatcher.on('initBrandSubscriptionModule', this.initBrandSubscriptionModule, this);
    App.Dispatcher.on('route', this.handleRouteChange, this);
  },

  initShow: function (searchJSON) {
    this.initSearch(searchJSON);
    this.initSubscriptionModule();
    this.initBrandSubscriptionModule();
    this.recordSearchEvent(searchJSON.query.search_string);
  },

  initSearch: function (searchJSON) {
    this.search = new Search(searchJSON);
    this.searchPage = new SearchPage({ model: this.search });

    var searchAttributes = clone(this.search.attributes);

    App.Dispatcher.trigger('search:update', searchAttributes);
  },

  initBrandSubscriptionModule: function () {
    if (!this.search || this.brandSubscriptionModule) return;

    var brand = this.search.brands().first();

    if (!brand) return;

    this.brandSubscriptionModule = new SubscriptionModule({
      el: '.brand',
      trigger: 'deal-alert',
      label: 'Brand',
      brands: [brand.get('slug')],
      name: brand.get('name'),
      user: user
    });
  },

  initSubscriptionModule: function () {
    if (!this.search || this.subscriptionModule) return;

    this.subscriptionModule = new SubscriptionModule({
      el: '.keyword',
      trigger: 'deal-alert',
      searchString: this.search.get('query').search_string,
      user: user
    });
  },

  handleRouteChange: function (path, params) {
    var data = clone(params);

    delete data.type;

    if (!this.search || this.search.isEqualToParams(data)) return;

    this.search.fetch({ data: this.search.queryToApiParams(data) }).done(function (searchJSON) {
      App.Dispatcher.trigger('search:update', searchJSON);
    });
  },

  recordSearchEvent: function (searchString) {
    if (!searchString) return;

    $.ajax({
      url: '/api/search_events',
      type: 'POST',
      data: {
        raw_search_string: searchString
      },
      dataType: 'json'
    });
  }
};
