import CollectionView from './collection_view';
import DealsPageItem from './deals_page_item';

var _super = CollectionView.prototype;

// App.Views.SearchPopularDeals
export default CollectionView.extend({
  modelName: 'Deal',
  modelView: DealsPageItem,

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.collection.off('add reset remove sync', this.render);
    this.collection.once('sync', this.render, this);
    this.getCollection();
  },

  getCollection: function () {
    this.collection.fetch({ data: { limit: 20, search: true }});
  },

  renderListItemView: function (modelView) {
    this.$el.append(modelView.render());
  },
});
