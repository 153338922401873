<template>
  <div :class="['bottom', backgroundColor]">
    <div class="info-links">
      <span>Check out our</span>
      <a href="/terms-of-use" target="_blank">Terms of Service</a>
      <span>and</span>
      <a href="/privacy" target="_blank">Privacy Policy</a>
    </div>
  </div>
</template>
<style src="./info_links.scss" lang="scss" scoped></style>

<script>
export default {
  name: 'InfoLinks',
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  computed: {
    backgroundColor() {
      return this.step === 1 ? 'gray' : '';
    }
  },
}
</script>
