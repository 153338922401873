import BaseView from './base_view';
import template from '../templates/single_use_coupon.jst.ejs';

// App.Views.SingleUseCoupon
export default BaseView.extend({
  template: template,

  events: {
    'submit form': 'getCode'
  },

  initialize: function (options) {
    this.options = this.options || options || {};

    // Fetch Events
    this.model.on('sync', this.success, this);
    this.model.on('error', this.error, this);

    this.render();

    // Setup click to copy instance
    this.clipboard = new Clipboard(this.$('a')[0]);
    this.clipboard.on('success', this.clipboardSuccess, this);
    this.clipboard.on('error', this.clipboardError, this);
  },

  render: function () {
    return this.$el.html(this.template());
  },

  getCode: function (event) {
    event.preventDefault();
    this.model.fetch();
  },

  error: function (xhr, status, error) {

    // Emit success event
    App.Dispatcher.trigger('SingleUseCouponError', error);

    this.clipboard.destroy();

    this.$('a')
      .text(this.options.errorLinkText)
      .attr('href', this.options.errorLink);

    this.complete();
  },

  success: function (event, data, status, xhr) {

    // Emit success event
    App.Dispatcher.trigger('SingleUseCouponSuccess', data);

    this.$('form code')
      .html(data.code)
      .removeClass('hide');

    this.complete();
  },

  complete: function () {
    this.$('input').remove();
    this.$('a')
      .attr('href', this.linkUrl())
      .removeClass('hide');
  },

  clipboardSuccess: function (e) {
    // Prevents hightlighting the coupon code after
    e.clearSelection();

    // Turn the button teal and change the text
    $(e.trigger).text(this.options.copySuccessText).addClass('teal');
  },

  clipboardError: function (e) {
    console.error('Error:', this.options.copyErrorText);

    // This assumes that clipboard.js will not work for this client
    this.clipboard.destroy();
  },

  linkUrl: function () {
    if (this.options.goLink) return this.options.goLink;

    // This assumes that the codeLink is in a format like this: https://example.com?code=
    // The model's code will be appended to this link.
    if (this.options.codeLink) return this.options.codeLink + this.model.get('code');
  },
});
