import BaseView from '../base_view';
import Deals from '../../models/deals';
import GridPagination from '../grid_pagination';
import Pagination from '../pagination';

// App.Views.HomePageTab
export default BaseView.extend({
  initialize: function (options) {
    this.options = options;
    this.slug = this.options.slug;
    this.collection = new Deals();
    this.collection.url = this.options.url;
    this.$tab = $('#' + this.slug + '-tab');

    App.Data.PageItems.Deals.registerCollection(this.collection);
    this.collectionView = new this.options.collectionView({
      el: '#' + this.slug + '-tab ul',
      collection: this.collection
    });

    var paginationView = this.options.dealsLayout === 'grid' ? GridPagination : Pagination;

    this.paginationView = new paginationView({
      el: '#' + this.slug + '-pagination',
      pathHelper: this.options.pathHelper,
      currentPageNumber: 1
    });

    this.collection.on('sync', this.updatePagination, this);
  },

  hide: function () {
    this.collectionView.hide();
    this.$tab.addClass('hide');
    this.paginationView.$el.addClass('hide');
  },

  show: function () {
    this.collectionView.show();
    this.$tab.removeClass('hide');
    this.paginationView.$el.removeClass('hide');
  },

  updatePagination: function (collection) {
    this.paginationView.maxPageNumber = collection.pageCount;
    this.paginationView.render();
  }
});
