import Cookies from 'js-cookie';

import sst from '@vendor-js/sst';

// App.UId
export default {
  trackUser: function (userJSON) {
    this.updateUIdCookie(userJSON);
    this.updateUIdTrackers();
  },

  updateUIdCookie: function (userJSON) {
    userJSON = userJSON || {};
    var params = App.Data.currentUrlParameters();
    var rawUId = userJSON.id || params.u_id || params.emailid;
    var newUId = parseInt(rawUId);

    // We only want to set the cookie if we
    // can verify that it's an integer,
    // rawUId might be a string or an int so we want
    // the coercion provided by == rather than ===
    if (rawUId == newUId) {
      sst.id.u_id.set(newUId);
    }
  },

  updateUIdTrackers: function () {
    var uId = parseInt(sst.id.u_id.get()) || null;
    App.Dispatcher.trigger('UIdReady', uId);

    if (!uId) return;

    this.updateIdService(uId);

  },

  updateIdService: function (uId) {
    var currentSession = sst.id.s_id.get();
    var uHash = Cookies.get('u_hash');

    // Try to parse value if value is present
    if (uHash) uHash = JSON.parse(uHash);
    var lastSession = uHash && uHash[uId];

    var sdata = {
      s_data: {
        user_id: uId
      }
    };

    var ddata = {
      d_data: {
        user_id: uId
      }
    };

    var update = function (scope, data) {
      sst.id.update(scope, data);
    };

    if (!lastSession) {
      update('session', sdata);
      update('device', ddata);
    } else if (lastSession != currentSession) {
      update('session', sdata);
    }

    uHash = {};
    uHash[uId] = currentSession;
    Cookies.set('u_hash', JSON.stringify(uHash));
  },
};
