import BaseView from '../base_view';
import CreateProfileModal from '../modals/create_profile';
import Forms from '../../lib/forms/forms';
import template from '../../templates/users/reset_password.jst.ejs';
import UserHelpers from '../../lib/user_helpers';
import successTemplate from '../../templates/users/reset_password_success.jst.ejs';

// App.Views.User.ResetPassword
export default BaseView.extend({
  template: template,
  successTemplate: successTemplate,

  events: {
    'ajax:success .password-reset': 'success'
  },

  render: function () {
    this.$el.html(this.template());
    this.validator = this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      showErrors: Forms.Validations.showErrors.inline,
      rules: {
        email: Forms.Validations.rules.email
      },
      messages: {
        email: Forms.Validations.messages.email
      }
    });
  },

  renderSuccess: function (message) {
    App.Dispatcher.trigger('showModal', {
      html: this.successTemplate({ message: message })
    });
  },

  success: function (event, data, status, xhr) {
    if (data.flash.error) {
      this.showError(data.flash.error);
    } else if (data.user.subscriber) {
      UserHelpers.logIn(data.user.email).done(this.showCreateProfileModal);
    } else {
      this.renderSuccess(data.flash.success);
    }
  },

  showError: function (message) {
    this.validator.showErrors({
      'email': message
    });
  },

  showCreateProfileModal: function () {
    App.Dispatcher.trigger('showModal', {
      modalClass: CreateProfileModal,
      to: 2
    });
  },
});
