import HomePage from '../views/home_page/home_page';

// App.Controllers.HomePageController
export default {
  initialize: function () {
    App.Dispatcher.once('initHomePage', this.initHomePage, this);
    App.Dispatcher.once('bdUserReady', this.initMyDealsComplete, this);
  },

  initHomePage: function (flags) {
    this.homePageView = new HomePage({
      el: '.body-content',
      flags: flags || {}
    });
  },

  initMyDealsComplete: function () {
    // Initialize my deals complete profile flow
    var params = App.Data.currentUrlParameters();

    if (params.m === 'my-deals-complete') {
      App.Dispatcher.trigger('initMyDeals', { el: '#vue-app-root', completeProfile: true });
    }
  },
};
