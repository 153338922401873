import Forms from '../../lib/forms/forms';
import Modal from '../modal';
import MessagePreviewView from '../message_preview_view';
import template from '../../templates/modals/email_share.jst.ejs';

// App.Views.EmailShareModal
export default Modal.extend({
  template: template,
  events: {
    'ajax:before form': 'validate',
    'ajax:error form': 'ajaxError',
    'ajax:success form': 'hideModal'
  },

  afterRender: function () {
    this.createViews();
    this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      rules: {
        'share[to]': {
          required: true,
          email: true
        },
        'share[from]': {
          required: true,
          email: true
        }
      },
      messages: {
        'share[to]': 'Please enter your <em>friend\'s</em> email address.',
        'share[from]': 'Please enter <em>your</em> email address.'
      },
      errorPlacement: Forms.Validations.errorPlacement.before
    });
  },

  beforeSever: function () {
    if (this.messagePreviewView) {
      this.messagePreviewView.remove();
    }
  },

  validate: function () {
    return this.$('form').valid();
  },

  createViews: function () {
    this.messagePreviewView = new MessagePreviewView({
      el: this.$('.message-preview-area'),
      model: this.model
    });
  },

  title: function () {
    if (this.model.get('item').modelClass === 'Merchant') {
      return 'store';
    } else {
      return this.model.get('item').modelClass.toLowerCase();
    }
  },

  shareUrl: function () {
    return this.model.get('item').shareURL();
  },

  headline: function () {
    return this.model.get('item').shareName();
  },

  description: function () {
    return this.model.get('item').shareDescription();
  }
});
