import BaseView from './base_view';
import template from '../templates/grid_pagination.jst.ejs';

// App.Views.GridPagination
export default BaseView.extend({
  template: template,

  initialize: function (options) {
    this.options = this.options || options || {};
    this.pathHelper = this.options.pathHelper;
    this.render();
  },

  render: function () {
    return this.$el.html(this.template());
  }
});
