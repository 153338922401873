import CouponsPageItem from '../coupons_page_item';

// App.Views.MerchantCouponsPageItem
export default CouponsPageItem.extend({
  events: {
    'click .more-details': 'toggleCouponInfo'
  },

  toggleCouponInfo: function() {
    var description = this.$('.coupon-description');
    var content;

    if (description.is(':visible')){
      content = 'More Details';
    } else {
      content = 'Less Details';
    }
    this.$('.more-details').text(content);
    description.slideToggle();
  }
});
