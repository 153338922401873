<template src="./interests-panel.html" />
<style src="./interests-panel.scss" lang="scss" scoped></style>

<script>
import { post } from '../../../utils/api-service';

export default {
  name: 'InterestsPanel',
  props: {
    close: {
      type: Function,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    interests: {
      type: Array,
      required: true,
    },
    initInterests: {
      type: Array,
      required: true,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    setCurrentStep: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      selectedInterests: [],
    };
  },
  mounted() {
    // Initialize interests
    this.selectedInterests = this.initInterests;
  },
  methods: {
    onSubmit() {
      const { selectedInterests } = this;

      // Short Circuit and move to next step if the user hasn't selected interests
      if (selectedInterests.length === 0) return this.handleOnSubmitSuccess();

      return post('/api/users/interests', { body: { users_interests: { interests: selectedInterests } } })
        .then(this.triggerSubmitEvents, this.handleOnSubmitError)
        .then(this.handleOnSubmitSuccess);
    },
    triggerSubmitEvents() {
      if (this.$dataLayer) this.$dataLayer.push({ event: 'onBoardingIMSuccess' });
    },
    handleOnSubmitSuccess() {
      this.$emit('finished');
      this.setCurrentStep(this.currentStep + 1);
    },
    handleOnSubmitError() {
      this.error = 'Something went wrong, please try again.';
    },
    checked(interestSlug) {
      return this.selectedInterests.indexOf(interestSlug) !== -1;
    },
    updateInterests() {
      this.$emit('updateInterests', this.selectedInterests);
    },
  },
};
</script>
