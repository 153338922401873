import Forms from '../../lib/forms/forms';
import Modal from '../modal';
import template from '../../templates/modals/unsubscribe.jst.ejs';
import { queryStringFromHash } from '../../lib/view_helpers';
import { bindAll } from 'underscore';

// App.Views.UnsubscribeModal
export default Modal.extend({
  template: template,

  events: {
    'submit form': 'handleFormSubmit',
    'click .cancel-button': 'hideModal',
    'click .clear-user a': 'clearUser'
  },

  modalName: 'unsubscribe',

  afterRender: function () {
    bindAll(this, 'handleFormError', 'handleFormSuccess', 'handleUnsubscribeLink');

    this.validator = this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      rules: {
        email: Forms.Validations.rules.email
      },
      messages: {
        email: Forms.Validations.messages.email
      },
      showErrors: Forms.Validations.showErrors.inline
    });
    return this.validator;
  },

  validate: function () {
    return this.validator.checkForm();
  },

  handleFormSubmit: function (e) {
    e.preventDefault();
    this.model.set('subscribe', false);
    this.model.save()
      .then(this.handleFormSuccess)
      .then(this.handleUnsubscribeLink)
      .catch(this.handleFormError);
  },

  handleFormSuccess: function () {
    this.hideModal();
    App.Dispatcher.trigger('userUnsubscribe');
    App.Dispatcher.trigger('addSuccess', "Success! You have unsubscribed from all Brad's Deals emails.");
  },

  handleFormError: function (error) {
    this.model.set('subscribe', true);
    this.validator.showErrors({
      email: 'Sorry, the server could not complete your request at this time, please try again later.'
    });
    button = this.$('.rounded-button[data-disable-with]');

    // Known bug in UJS disable_with, doesn't support inputs in this version. Latest release does.
    // @TODO: Update UJS
    button.val(button.data('ujs:enable-with'));
    $.rails.enableFormElements(button.parent('form'));
  },

  email: function () {
    return this.options.model.get('email');
  },

  handleUnsubscribeLink: function () {
    var unsubLink = App.Data.currentUrlParameters().unsub_link;

    if (unsubLink) {
      // appboy/braze - The email unsubscribe links
      var urlValid = unsubLink.match(/^https:\/\/dashboard(?:-\d+)?.(?:appboy|braze).com.+/) || unsubLink.match(/^https:\/\/\d+.emailinboundprocessing.com/);

      if (urlValid) {
        $.ajax({
          type: 'POST',
          url:  '/appboy/unsubscribe',
          data: { unsub_link: unsubLink }
        });
      }
    }
  },

  clearUser: function () {
    var params = App.Data.currentUrlParameters();

    delete params.email
    delete params.md5_hash
    delete params.fill

    var queryString = queryStringFromHash(params);
    var newLocation = window.location.pathname

    if (queryString.length) newLocation += ('?' + queryString);

    window.history.replaceState(null, '', newLocation);
  }
});
