<template src="./wrapper.html" />

<script>
import Cookies from 'js-cookie';

// Generic Modules
import user from '@legacy-js/user-data/user';
import { get } from '../../../utils/api-service';
import NavigationDots from '../../navigation-dots/navigation-dots.vue';

// Onboarding Specific
import InterestsPanel from '../interests-panel/interests-panel.vue';
import SignupPanel from '../signup-panel/signup-panel.vue';
import SuccessPanel from '../success-panel/success-panel.vue';

const COOKIE_NAME = 'bd-onboarding';
const SOURCE = 'onboarding';

export default {
  components: {
    'interests-panel': InterestsPanel,
    'navigation-dots': NavigationDots,
    'signup-panel': SignupPanel,
    'success-panel': SuccessPanel,
  },
  props: {
    startOpened: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    const cookie = Cookies.get(COOKIE_NAME) ? JSON.parse(Cookies.get(COOKIE_NAME)) : {};

    return {
      currentStep: cookie.currentStep || 1,
      loggedIn: false,
      interests: [],
      finished: false,
      opened: cookie.opened || false,
      selectedInterests: [],
      subscribedViaOnboarding: cookie.subscribedViaOnboarding || false,
      steps: [1, 2, 3],
    };
  },
  computed: {
    drawerClass() {
      return this.opened ? 'open' : 'closed';
    },
    requiredProps() {
      return {
        close: this.close,
        currentStep: this.$data.currentStep,
        opened: this.$data.opened,
        setCurrentStep: this.setCurrentStep,
      };
    },
  },
  created() {
    // Find or initialize the cookie
    if (Cookies.get(COOKIE_NAME)) {
      if (this.opened || this.startOpened) setTimeout(this.open, 100);
    } else {
      this.$dispatcher.trigger('onboardingInit');
      this.setCookie();
      setTimeout(this.open, 100);
    }
  },
  mounted() {
    this.$dispatcher.on('initUserApplication', this.setLoggedIn);
    this.setLoggedIn();
    this.getInterests();
    this.$dispatcher.on('openOnboardingDrawer', this.open);
    this.$dispatcher.on('sync:userInterests', this.finish);
  },
  beforeDestroy() {
    this.$dispatcher.off('initUserApplication', this.setLoggedIn);
    this.$dispatcher.off('openOnboardingDrawer', this.open);
    this.$dispatcher.off('sync:userInterests', this.finish);
  },
  methods: {
    getInterests() {
      get('/api/interests').then(
        this.handleGetInterestsSuccess,
        this.handleGetInterestsError,
      ).catch((e) => { console.error(e); });
    },
    handleGetInterestsSuccess(response) {
      const { body: { interests } } = response;

      this.interests = interests;
    },
    handleGetInterestsError(response) {
      console.error(response);
    },
    open() {
      this.opened = true;
      this.setCookie();
      window.freestar.queue.push(() => {
        window.freestar.deleteStickyFooter();
      });
    },
    close() {
      this.opened = false;
      this.setCookie();

      if (this.finished) {
        setTimeout(this.remove, 1000);
      }
    },
    setCurrentStep(step) {
      this.$el.scrollTop = 0;
      this.currentStep = step;
      this.setCookie();
    },
    setLoggedIn() {
      this.loggedIn = user.loggedIn();
      if (!this.loggedIn && this.currentStep !== 1) {
        this.setCurrentStep(1);
      }
      if (this.loggedIn && !this.subscribedViaOnboarding) {
        this.remove();
      }
    },
    setSubscribed(userJSON) {
      this.subscribedViaOnboarding = true;
      this.setCookie();
      return userJSON;
    },
    setCookie() {
      const cookieParams = {
        currentStep: this.currentStep,
        opened: this.opened,
        subscribedViaOnboarding: this.subscribedViaOnboarding,
      };

      // Sets a session cookie for the current domain and if the request is secure, it secures the cookie as well
      Cookies.set(COOKIE_NAME, JSON.stringify(cookieParams), {
        domain: window.location.hostname,
        secure: window.location.protocol === 'https:',
      });
    },
    stepDisabled(step) {
      switch (step) {
        case 1:
          return !this.loggedIn;
        case 2:
          return !this.loggedIn;
        case 3:
          return !this.loggedIn || !this.finished;
        default:
          return true;
      }
    },
    remove() {
      this.$el.remove();
      this.$destroy();
      window.freestar.queue.push(() => {
        window.freestar.newStickyFooter();
      });
    },
    setSelectedInterests(selectedInterests) {
      this.selectedInterests = selectedInterests;
    },
    finish(obj) {
      this.finished = true;
      this.$dispatcher.trigger('onboardingComplete');

      // Close if updated from somwhere else
      if (obj && obj.source !== SOURCE) this.close();
    },
    broadCastUserInterestChanges() {
      const userInterests = this.selectedInterests;
      this.$dispatcher.trigger('sync:userInterests', { userInterests, source: SOURCE });
    },
  },
};
</script>

<style src="./wrapper.scss" lang="scss" scoped></style>
