import BaseView from './base_view';
import CommentForm from './comment_form';
import Comments from './comments';
import CommentsPagination from './comments_pagination';
import template from '../templates/comments_area.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.CommentsArea
export default BaseView.extend({
  template: template,

  events: {
    'click .expand-comments': 'toggleComments'
  },

  initialize: function (options) {
    this.options = this.options || options || {};
    bindAll(this, 'showDiscussion', 'renderCommentButton');
    App.Dispatcher.on('hashChange:comments', this.showDiscussion);
    this.listenTo(this.model, 'change:comments_count', this.renderCommentButton);
  },

  render: function () {
    this.$el.html(this.template());
    this.createSubViews();
    this.$expandComments = this.$('.expand-comments');
    this.$commentToggle = this.$('.comment-toggle');
    this.renderCommentButton();
  },

  createSubViews: function () {
    this.commentForm = new CommentForm({
      el: '#comment-form',
      model: this.model
    });

    this.commentsView = new Comments({
      el: '#discussion-comments',
      collection: this.model.get('comments')
    });

    this.commentsPagination = new CommentsPagination({
      el: '#comments-pagination',
      model: this.model
    });
  },

  getSlideState: function () {
    return this.options.discussionOpen ? '' : 'display:none;';
  },

  showDiscussion: function () {
    this.options.discussionOpen = true;
    this.renderCommentButton();
    this.$commentToggle.show();
  },

  toggleComments: function () {
    this.options.discussionOpen = !this.options.discussionOpen;
    this.renderCommentButton();
    this.$commentToggle.slideToggle();
  },

  renderCommentButton: function () {
    var content;
    if (this.options.discussionOpen) {
      content = 'Hide Comments (' + this.model.get('comments_count') + ')';
    } else {
      content = 'View Comments (' + this.model.get('comments_count') + ')';
    }

    this.$expandComments.text(content);
  }
});
