import CommentsArea from '../views/comments_area';
import { detectMobileDevice } from '../lib/view_helpers';

// App.Controllers.DiscussionController
export default {
  initialize: function () {
    App.Dispatcher.on('initDiscussion', this.initDiscussion, this);
  },

  initDiscussion: function (topic, options) {
    this.commentsArea = new CommentsArea({
      el: '#comments-area',
      model: topic,
      discussionOpen: this.determineDiscussionState(options)
    });

    this.commentsArea.render();
  },

  determineDiscussionState: function (options) {
    var show = options && options.discussionOpen;
    if (show === undefined) show = true;
    return detectMobileDevice() ? false : show;
  },
};
