import BaseModel from './base_model';
import { getRoute } from '../../../javascript/src/utils/route-service'

// App.Models.Subscription
export default BaseModel.extend({
  urlRoot: getRoute('subscription-route'),

  initialize: function () {
    this.setQuery(this.attributes.query);
  },

  getName: function () {
    return (this.get('name') || this.get('query').name);
  },

  getSearchString: function () {
    return this.get('query').search_string;
  },

  getQuery: function () {
    return this.get('query');
  },

  setQuery: function (query) {
    var compactedQuery = {};

    // Build a new compacted query without any keys with null/undefined values
    for (var key in query) {
      var value = query[key];

      if (value !== null && value !== undefined) compactedQuery[key] = value;
    }

    this.set({ query: compactedQuery });

    if (compactedQuery.search_string) this.setSearchString(compactedQuery.search_string);

    return compactedQuery;
  },

  setSearchString: function (searchString) {
    var query = this.get('query');

    query.search_string = searchString
      .toLowerCase()
      .trim()
      .replace(/\s{2,}/, ' ');

    return query.search_string;
  },
});
