export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="coupon-modal-container large-7 medium-10 small-12">\n  <button class="close-coupon-modal centered-container hide-for-small modal-close-button reset-button" tabindex="0">\n    <i class="material-icons">close</i>\n  </button>\n  <button class="reset-button close-coupon-modal link close-link" tabindex="0">\n    <span class="back-arrow">&lsaquo; </span>\n    Back\n  </button>\n  <div class="centered-container coupon-info">\n    <img src="' +
((__t = ( this.model.get('merchant').logo.large )) == null ? '' : __t) +
'" alt="" class="merchant-logo"/>\n    <h2>\n      <a class="go-link title" href="' +
((__t = ( this.model.goLink() )) == null ? '' : __t) +
'" target="_blank" tabindex="0">\n        ' +
((__t = ( this.model.get('name') )) == null ? '' : __t) +
'\n      </a>\n    </h2>\n    ';
 if (this.model.get('code')) { ;
__p += '\n      <div class="step-block code-block large-7 medium-6 small-12">\n        <div class="step">\n          1\n        </div>\n        <div class="large-11 medium-11 small-11 step-details">\n          <p>Copy the following coupon code.</p>\n          <div class="large-12 medium-12 small-12 coupon-button-container">\n            <span class="coupon-code">' +
((__t = ( this.model.get('code') )) == null ? '' : __t) +
'</span>\n            <input type="submit" class="rounded-button copy-and-go" value="Copy" data-clipboard-text="' +
((__t = ( this.model.get('code') )) == null ? '' : __t) +
'">\n          </div>\n          <p class="clipboard-message success hide">Code has been copied to clipboard</p>\n          <p class="clipboard-message error hide">Something went wrong, try again.</p>\n        </div>\n      </div>\n      <div class="step-block code-bottom-block large-7 medium-6 small-12">\n        <div class="step">\n          2\n        </div>\n        <div class="large-11 medium-11 small-11 step-details">\n          <p>Paste code at checkout</p>\n          <a class="rounded-button secondary large-12 medium-12 small-12" href="' +
((__t = ( this.model.goLink() )) == null ? '' : __t) +
'" target="_blank" tabindex="0">\n            Go to ' +
((__t = ( this.model.get('merchant').name )) == null ? '' : __t) +
'\n          </a>\n        </div>\n      </div>\n    ';
 } else { ;
__p += '\n      <div class="step-block sale-block large-7 medium-6 small-12">\n        <div class="step">\n          1\n        </div>\n        <div class="large-11 medium-11 small-11 step-details">\n          <p>Go to ' +
((__t = ( this.model.get('merchant').name )) == null ? '' : __t) +
' and Shop!</p>\n          <a class="rounded-button secondary large-12 medium-12 small-12" href="' +
((__t = ( this.model.goLink() )) == null ? '' : __t) +
'" target="_blank">\n            Go to ' +
((__t = ( this.model.get('merchant').name )) == null ? '' : __t) +
'\n          </a>\n        </div>\n      </div>\n    ';
 } ;
__p += '\n    ';
 if (this.model.get('merchant').merchant_rules.alternate_disclaimer) { ;
__p += '\n      <p>' +
((__t = ( this.model.get('merchant').merchant_rules.alternate_disclaimer )) == null ? '' : __t) +
'</p>\n    ';
 } ;
__p += '\n  </div>\n  <div class="coupon-details-container">\n    <h4>Details</h4>\n    <div class="row expiration-info">\n      <span>Expires ' +
((__t = ( this.model.get('expiration') )) == null ? '' : __t) +
'</span>\n     </div>\n     <span class="coupon-description">\n      ' +
((__t = ( this.model.get("description") )) == null ? '' : __t) +
'\n      ' +
((__t = ( this.model.get("instructions") )) == null ? '' : __t) +
'\n    </span>\n  </div>\n</div>\n';
return __p
}