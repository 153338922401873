export default function(data) {
var __t, __p = '';
__p += '<a href="/deals/' +
((__t = ( this.model.get('param') )) == null ? '' : __t) +
'" class="tile">\n  ' +
((__t = ( this.model.get('image') )) == null ? '' : __t) +
'\n  ' +
((__t = ( this.model.get('third_party_tracking_pixel') )) == null ? '' : __t) +
'\n  <span class="deal-title">\n    ' +
((__t = ( this.model.get('title') )) == null ? '' : __t) +
'\n    <span class="advertiser-disclosure-link" hidden>*</span>\n  </span>\n</a>\n';
return __p
}