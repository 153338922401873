import Cookies from 'js-cookie';

const Experiment = () => {
  const bdFeature = Cookies.get('bd_feature');
  if (!bdFeature || typeof bdFeature !== 'string') {
    return undefined;
  }

  const [feature, bucket, split] = bdFeature.split(':');

  if (!feature || !bucket || !split) {
    return undefined;
  }

  return {
    campaign_name: feature,
    experiment_name: `${feature}-${split}`,
    variation_name: bucket,
  };
};

export default Experiment;
