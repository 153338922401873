import EngagementModule from './engagement_module';
import CommentLinkModule from './engagements/comment_link_module';
import template from '../templates/item_engagement_module.jst.ejs';

var _super = EngagementModule.prototype;

// App.Views.ItemEngagementModule
export default EngagementModule.extend({
  template: template,
  signUpModalTitleOption: "3",

  initialize: function (options) {
    this.createViews();
    _super.initialize.apply(this);
  },

  render: function(){
    _super.render.apply(this);
    this.commentLinkModule
      .setElement(this.$el.find(".chat-pack"))
      .render();
    return this.$el;
  },

  createViews: function(){
    this.commentLinkModule = new CommentLinkModule({
      el: this.$el.find(".chat-pack"),
      model: this.model
    });
  }
});
