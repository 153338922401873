import Password from './password';
import Profile from './profile';
import Validations from './validations';

// App.Forms
export default {
  initialize: function () {
    Password.initialize();
    $(document).on('change', '#show_password', this.togglePassword);
  },

  Password: Password,

  Profile: Profile,

  togglePassword: function (event) {
    var $checkBox = $(event.target);
    $checkBox.closest('form').find('input[name="password"]').prop({
      type: ($checkBox.is(':checked') ? 'text' : 'password')
    });
  },

  Validations: Validations
};
