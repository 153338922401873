import Modal from '../modal';
import template from '../../templates/modals/email_preference.jst.ejs';

// App.Views.EmailPreferenceModal
export default Modal.extend({
  template: template,

  modalText: function () {
    var params = App.Data.currentUrlParameters();

    switch (+params.to) {
      case 0:
        return {
          title: 'Success! You have changed your Newsletter Emails frequency to weekly.'
        };
      case 1:
        return {
          title: 'Success! You have changed your Newsletter Emails frequency to two days a week.'
        };
      case 2:
        return {
          title: 'Success! You have unsubscribed from Newsletter Emails.'
        };
      case 3:
        return {
          title: 'Success! You have unsubscribed from Special Emails.'
        };
      case 4:
        return {
          title: 'You are already unsubscribed from Newsletter Emails. ',
          subtitle: 'If you would like to change your email preferences, updates can be made on this page.'
        };
      case 5:
        return {
          title: 'You are already unsubscribed from Special Emails.',
          subtitle: 'If you would like to change your email preferences, updates can be made on this page.'
        };
      case 7:
        return {
          title: 'Success! You have unsubscribed from Recommended Emails.'
        };
      case 8:
        return {
          title: 'You are already unsubscribed from Recommended Emails.',
          subtitle: 'If you would like to change your email preferences, updates can be made on this page.'
        };
      default: case 6:
        return {
          title: 'You are already unsubscribed from all emails.',
          subtitle: 'If you would like to receive emails again, select "Subscribe to all".'
        };
    }
  }
});
