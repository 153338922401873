import CollectionItemView from '../../collection_item_view';
import DealTilesWithBookmarks from '../../deal_tiles_with_bookmarks';
import RemoveAlertModal from '../../modals/remove_alert';
import DealsSearch from '../../../models/deals_search';
import ToggleExpander from '../../toggle_expander';
import template from '../../../templates/users/preferences/subscription.jst.ejs';
import emptyTemplate from '../../../templates/subscriptions/no_alert_matches.jst.ejs';

import brokenBellSVG from '../../../templates/svgs/broken_bell.jst.ejs'
import pauseSVG from '../../../templates/svgs/pause.jst.ejs'
import { queryStringFromHash } from '../../../lib/view_helpers'

// App.Views.User.Preferences.Subscription
export default CollectionItemView.extend({
  maxDeals: 8,

  template: template,
  emptyTemplate: () => emptyTemplate({ brokenBellSVG: brokenBellSVG }),

  events: {
    'click .help-expander': 'handleExpanderClick',
    'click .remove-alert': 'handleRemoveAlertClick',
    'click .pause-alert': 'handlePauseAlertClick',
  },

  initialize: function (options) {
    this.options = this.options || options || {};
    this.deals = new DealsSearch();
    this.expanded = false;

    this.listenTo(this.deals, 'sync', this.handleDealsFetchSuccess);
    this.listenTo(this.deals, 'error', this.handleDealsFetchError);
    this.listenTo(this.model, 'sync', this.handlePauseAlertSuccess);
    this.listenTo(this.model, 'error', this.handlePauseAlertError);
  },

  render: function () {
    this.$el.html(this.template({ pauseSVG: pauseSVG }));

    this.renderSubViews();

    return this.$el;
  },

  renderSubViews: function () {
    this.toggleExpanderView = new ToggleExpander({
      el: this.$el
    });

    this.dealsView = new DealTilesWithBookmarks({
      el: this.$('.deals'),
      collection: this.deals,
      emptyTemplate: this.emptyTemplate,
      additionalOptions: {
        classes: 'col large-3 medium-3 small-6'
      }
    });
  },

  handleExpanderClick: function () {
    this.expanded = !this.expanded;

    if (!this.expanded || (!!this.deals && this.deals.length)) return;

    this.fetchDeals();
  },

  handleRemoveAlertClick: function () {
    App.Dispatcher.trigger('showModal', {
      modalClass: RemoveAlertModal,
      model: this.model
    });
  },

  handlePauseAlertClick: function () {
    this.model.save({
      paused: !this.model.get('paused')
    });
  },

  handlePauseAlertSuccess: function () {
    this.$('.pause-alert').text(this.pauseText());

    if (this.model.get('paused')) {
      this.$('.paused-notice').removeClass('hide');
    } else {
      this.$('.paused-notice').addClass('hide');
    }
  },

  handlePauseAlertError: function () {
    this.showError();
  },

  handleDealsFetchSuccess: function () {
    // Update the deals count
    this.$('.deals-count').text('(' + this.deals.total + ')');

    // Show the more deals button if we have additional deals
    if (this.deals.total > this.maxDeals) this.$('.more-deals').removeClass('hide');
  },

  handleDealsFetchError: function () {
    this.dealsView.remove();

    this.showError();
  },

  fetchDeals: function () {
    var data = this.dealsParams();

    data.limit = this.maxDeals;

    this.deals.fetch({ data: data });
  },

  dealsParams: function () {
    var params = {};
    var query = this.model.get('query');

    if (this.model.getSearchString()) params.search_string = this.model.getSearchString();
    if (query.brands) params.brands = query.brands.join(',');
    if (query.categories) params.categories = query.categories.join(',');
    if (query.flags) params.flags = query.flags.join(',');
    if (query.genders) params.gender = query.genders.join(',');
    if (query.merchants) params.merchants = query.merchants.join(',');

    return { query: params };
  },

  showError: function () {
    this.$('.error').removeClass('hide');
  },

  pauseText: function () {
    return this.model.get('paused') ? 'Resume Alert' : 'Pause Alert';
  },

  moreDealsPath: function () {
    const params = this.dealsParams().query;
    if (params.brands) params.search_string = params.brands;
    params.query = params.search_string;

    delete params.search_string;
    let basePath = '/search'
    let queryParams = queryStringFromHash(params || {})

    return `${basePath}?${queryParams}`;
  }
});
