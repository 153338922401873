import BaseView from '../../base_view';
import template from '../../../templates/users/account_settings/social.jst.ejs';

// App.Views.User.AccountSetting.Social
export default BaseView.extend({
  template: template,

  initialize: function () {
    this.render();
    App.Dispatcher.on('user:change', this.render, this);
  },

  render: function () {
    this.$el.html(this.template());
    return this.$el;
  }
});
