import _ from 'underscore';
import sst from '../sst.js';

/**
 * Identity Object
 */
const id = {};

/**
 * Initialize identity
 */
id.init = function () {
  return $.Deferred((promise) => {
    /** Session Id */
    id.s_id = new id.CookieCacher('s_id');

    /** Session Hashcode */
    id.s_hash = new id.CookieCacher('s_hash');

    /** We want to persists the d_id and u_id for 2 years on the user's device */
    const TWO_YEARS = 730;

    /** Device Id */
    id.d_id = new id.CookieCacher('d_id', TWO_YEARS);

    /** User Id */
    id.u_id = new id.CookieCacher('u_id', TWO_YEARS);

    const s_id = id.s_id.get();
    const s_hash = id.s_hash.get();
    const d_id = id.d_id.get();
    const u_id = id.u_id.get();

    let sessionParams = id.parseSessionParams(
      sst.util.parseQueryParams(sst.config.request_data.query_string),
      sst.config.settings.params.session,
    );

    // Set default www channel_id and make sure it is a numeric.
    sessionParams = id.normalizeChannelId(sessionParams);

    const sessionParamsHash = id.generateSessionHash(
      sessionParams,
      sst.config.settings.params.session,
    );
    if (d_id == null || s_id == null || (sessionParamsHash != sst.config.constants.session_params_hash && s_hash != sessionParamsHash)) {
      id.createSession(sessionParams, sessionParamsHash, d_id).then(promise.resolve);
    } else if (sessionParamsHash != sst.config.constants.session_params_hash) {
      id.update('session', sessionParams).then(promise.resolve);
    } else {
      promise.resolve();
    }

    // Refresh the expiration date on device cookie
    id.d_id.touch();
  });
};

/**
 * Create a new session
 * @param {Object.<string, string>} sessionParams A hash of parameters for the
 *     creation of the session.
 * @param {Object.<string, Array>} sessionParamsHash A hashcode of the critical
 *     session parameters.
 * @param {string} d_id The device id for the session being created. May be
 *     null.
 */
id.createSession = function (sessionParams, sessionParamsHash, d_id) {
  const data = {};

  // Grab device id if known, else device data
  if (d_id != null) {
    data.d_id = d_id;
  } else {
    data.d_data = sst.util.mergeNonNullProperties(
      sst.config.settings.data.device, {
        prior_device_id: id.d_id.get(),
      },
    );
  }

  // Process existing local session data
  let localSessionData = id.s_data.load();
  if (!sst.util.emptyObject(localSessionData) && sessionParamsHash != 0 && sessionParamsHash != localSessionData.s_hash) {
    localSessionData = id.s_data.clear();
  }

  // Gather session params, data, and prior sessionid
  data.s_data = sst.util.mergeNonNullProperties({
    prior_session_id: id.s_id.get(),
    session_hash: sessionParamsHash,
  },
  sst.config.settings.data.session,
  localSessionData,
  sessionParams);

  // Save session data prior to ajax
  id.s_data.save(data.s_data);

  // Set ajax request options
  const options = {
    url: `${sst.config.settings.urls.id}/sessions`,
    async: false,
    data,
    success(resData) {
      id.s_hash.set(data.s_data.session_hash);
      id.s_id.set(resData.s_id);
      id.d_id.set(resData.d_id);
      id.s_data.clear();
    },
    error(jqXHR, status, error) {
      if (jqXHR.responseText && jqXHR.responseText.match('d_id not found')) {
        id.createSession(sessionParams, sessionParamsHash, null);
      }
    },
  };

  // Send ajax request
  return sst.ajax.post(options);
};

/**
 * Update session/device with new properties
 * @param {string} scope Can be either "session" or "device". Defaults to
 *     "session".
 * @param {Object} data The data that well be updated for the supplied scope
 *     resource.
 */
id.update = function (scope, data) {
  // TODO: Handle update failures

  let resource = 'sessions';
  let resource_id = id.s_id.get();
  if (scope == 'device') {
    resource = 'devices';
    resource_id = id.d_id.get();
  }

  // Guard against the edge case of s_id being 'undefined'
  if (!resource_id) return;

  // Set ajax request options
  const options = {
    url: `${sst.config.settings.urls.id}/${resource}/${resource_id}`,
    async: false,
    data,
  };

  // Send ajax request
  return sst.ajax.post(options);
};

/**
 * Parses our session params from the supplied query string according to the
 * supplied session param settings
 * @param {Object} queryParams A hash of parameter and values
 * @param {Object} sessionParamsSettings The session parameter settings as
 *     defined in sst.config.settings.params.session.
 * @return {Object} A hash of session parameters and values.
 */
id.parseSessionParams = function (queryParams, sessionParamsSettings) {
  let sessionParams = {};
  for (const key in sessionParamsSettings) {
    const sessionParamOptions = sessionParamsSettings[key];

    for (let i = 0; i < sessionParamOptions.length; i++) {
      if (!(i == sessionParamOptions.length - 1 && (sessionParamOptions[sessionParamOptions.length - 1] === false || sessionParamOptions[sessionParamOptions.length - 1] === true)) && queryParams[sessionParamOptions[i]] !== undefined) {
        const paramValue = queryParams[sessionParamOptions[i]];
        sessionParams[key] = paramValue;

        break;
      }
    }
  }

  const tidParams = id.parseTid(sessionParams.x_tid);
  sessionParams = id.mergeTidParams(sessionParams, tidParams);

  return sessionParams;
};

/**
 * Merge tid parameters into sessionParams if they are not provided.
 * tid parameters: channel_id, affiliate_id, affiliate_sid
 * @param {Object} sessionParams A hash of session parameters and values.
 * @param {Object} tidParams A hash of tid params and values.
 * @return {Object} A hash of session parameters and values.
 */
id.mergeTidParams = function (sessionParams, tidParams) {
  if (_.keys(tidParams).length) {
    if (tidParams.channel_id && !sessionParams.channel_id) {
      sessionParams.channel_id = tidParams.channel_id;
    }

    if (tidParams.affiliate_id && !sessionParams.affiliate_id) {
      sessionParams.affiliate_id = tidParams.affiliate_id;
    }

    if (tidParams.affiliate_sid && !sessionParams.affiliate_sid) {
      sessionParams.affiliate_sid = tidParams.affiliate_sid;
    }
  }

  return sessionParams;
};

/**
 * Parses x_tid param supplied in the sessionParams into channel_id,
 * affiliate_id and affiliate_sid params.
 * x_tid structure:
 * {channel_id}-{click_date}-{item_type}-{item_id}-{subaffiliate_id}-{subaffiliate_sid}
 * @param {Object} x_tid The tid query string to be parsed.
 *    returned from id.parseSessionParams.
 * @return {Object} A hash of tid params and values.
 */
id.parseTid = function (x_tid) {
  const tidParams = {};

  if (x_tid) {
    const arrTid = x_tid.split('-');
    const tid = arrTid[0].replace(/C5B1L3BR2S/i, '');
    const channel_id = tid / 1369;
    const affiliate_id = arrTid[8];
    const affiliate_sid = arrTid[9];

    if (!isNaN(tid) && tid % 1369 === 0) {
      tidParams.channel_id = channel_id;
    }

    if (affiliate_id) {
      tidParams.affiliate_id = affiliate_id;
    }

    if (affiliate_sid) {
      tidParams.affiliate_sid = affiliate_sid;
    }
  }

  return tidParams;
};

/**
 * Generates a session hashcode from the params that are used to force a new
 * session
 * @param {Object} sessionParams A hash of session parameters and values as
 *    returned from id.parseSessionParams.
 * @param {Object} sessionParamsSettings The session parameter settings as
 *     defined in sst.config.settings.params.session.
 * @return {number} The hashcode corresponding to the critical session params.
 */
id.generateSessionHash = function (sessionParams, sessionParamsSettings) {
  const criticalParams = {};
  const criticalParamKeys = [];
  let criticalParamsString = '';
  for (const key in sessionParams) {
    if (key in sessionParamsSettings) {
      const sessionParamOptions = sessionParamsSettings[key];
      let required = true;
      if (sessionParamOptions.length > 1 && (sessionParamOptions[sessionParamOptions.length - 1] === false)) {
        required = false;
      }
      if (required) {
        criticalParams[key] = sessionParams[key];
        criticalParamKeys.push(key);
      }
    }
  }
  criticalParamKeys.sort();
  for (let i = 0; i < criticalParamKeys.length; i++) {
    criticalParamsString = `${criticalParamsString + criticalParamKeys[i]}:${criticalParams[criticalParamKeys[i]]};`;
  }
  return sst.util.stringToHashCode(criticalParamsString);
};

/**
 * CookieCacher manages browser cookies with in memory caching
 * @constructor
 * @param {string} cookieName The name of the cookie connected to the item.
 * @param {number} cookieTTLDays The number of days before the cookie expires.
 *     If not provided, the cookie will expire at the end of the session.
 */
id.CookieCacher = function (cookieName, cookieTTLDays) {
  let val = null;
  var cookieName = cookieName;
  var cookieTTLDays = cookieTTLDays;

  this.get = function () {
    if (val == null) {
      val = sst.util.getCookie(cookieName);
    }
    return val;
  };

  this.set = function (newVal) {
    if (newVal == null) {
      val = null;
      sst.util.setCookie(cookieName, '', -1);
    } else {
      val = newVal;
      sst.util.setCookie(cookieName, val, cookieTTLDays);
    }
  };

  this.clear = function () {
    this.set(null);
  };

  this.touch = function () {
    this.set(this.get());
  };
};

/**
 * Local cookie based store for session data
 * @constructor
 */
id.s_data = new function () {
  const cookieName = 's_data';

  // Saves s_data to device cookie in JSON string format
  this.save = function (obj) {
    sst.util.setCookie(cookieName, JSON.stringify(obj));
  };

  // Loads s_data from local cookie and parses
  this.load = function () {
    let out = {};
    const str = sst.util.getCookie(cookieName);
    if (str != undefined) {
      out = JSON.parse(str);
    }
    return out;
  };

  // Removes s_data cookie
  this.clear = function () {
    sst.util.setCookie(cookieName, '', -1);
    return {};
  };
}();

/**
 * Helper function to deal with setting channel_id
 * to a default value if none provided.
 * Also ensure that channel_id is sent as a numeric.
 */
id.normalizeChannelId = function (sessionParams) {
  if (!('channel_id' in sessionParams)) {
    sessionParams.channel_id = sst.config.constants.www_channel_id;
  } else {
    const channelIdValue = sessionParams.channel_id;
    sessionParams.channel_id = channelIdValue > 0 ? Number(channelIdValue) : sst.config.constants.default_www_channel_id;
  }

  return sessionParams;
};

export default id;
