import CollectionView from './collection_view';
import DealTileWithBookmark from './deal_tile_with_bookmark';

var _super = CollectionView.prototype;

// App.Views.SavedItems
export default CollectionView.extend({

  initialize: function () {
    _super.initialize.apply(this, arguments);
  },

  createListItemView: function (model, index) {
    var viewClass;
    switch (model.modelClass) {
      case 'Deal':
        viewClass = DealTileWithBookmark;
        break;
      default:
        throw new Error('Unknown modelClass');
    }
    this.modelViews.push(
      new viewClass({
        model: model,
        index: index,
        additionalOptions: this.additionalOptions,
        className: this.itemClassName
      })
    );
  }
});
