import AcquisitionsController from '../../../assets/javascripts/controllers/acquisitions_controller';
import OauthController from '../../../assets/javascripts/controllers/oauth_controller';
import Ads from '../../../assets/javascripts/controllers/ads_controller';
import ApplicationController from '../../../assets/javascripts/controllers/application_controller';
import BlogsController from '../../../assets/javascripts/controllers/blogs_controller';
import ConfirmationsController from '../../../assets/javascripts/controllers/confirmations_controller';
import CookiesController from '../../../assets/javascripts/controllers/cookies_controller';
import CouponModalsController from '../../../assets/javascripts/controllers/coupon_modals_controller';
import DealsController from '../../../assets/javascripts/controllers/deals_controller';
import DiscussionController from '../../../assets/javascripts/controllers/discussion_controller';
import EmailPreferencesController from '../../../assets/javascripts/controllers/email_preferences_controller';
import FacebookPixelController from '../../../assets/javascripts/controllers/facebook_pixel_controller';
import HomePageController from '../../../assets/javascripts/controllers/home_page_controller';
import MobileDevicesController from '../../../assets/javascripts/controllers/mobile_devices_controller';
import Modals from '../../../assets/javascripts/controllers/modals_controller';
import NavigationController from '../../../assets/javascripts/controllers/navigation_controller';
import NotificationsController from '../../../assets/javascripts/controllers/notifications_controller';
import SearchesController from '../../../assets/javascripts/controllers/searches_controller';
import SingleUseCouponController from '../../../assets/javascripts/controllers/single_use_coupon_controller';
import Social from '../../../assets/javascripts/controllers/social_controller';
import DealAlertsLandingController from '../../../assets/javascripts/controllers/deal_alerts_landing_controller';
import TrackingController from '../../../assets/javascripts/controllers/tracking_controller';
import UserApplicationController from '../../../assets/javascripts/controllers/user_application_controller';
import UserProfileController from '../../../assets/javascripts/controllers/user_profile_controller';
import TravelController from '../../../assets/javascripts/controllers/travel_controller';
import SegmentController from '../../../assets/javascripts/controllers/segment_controller';

const Controllers = {
  AcquisitionsController,
  OauthController,
  Ads,
  ApplicationController,
  BlogsController,
  ConfirmationsController,
  CookiesController,
  CouponModalsController,
  DealsController,
  DiscussionController,
  EmailPreferencesController,
  FacebookPixelController,
  HomePageController,
  MobileDevicesController,
  Modals,
  NavigationController,
  NotificationsController,
  SearchesController,
  SingleUseCouponController,
  Social,
  DealAlertsLandingController,
  TrackingController,
  UserApplicationController,
  UserProfileController,
  TravelController,
  SegmentController,
};

export default Controllers;
