import Vue from 'vue';
import VueCarousel from 'vue-carousel';

import initMyDeals from './my_deals';
import initOnboarding from './onboarding';
import initHompageInterests from './homepage-interests';

require('./application');

Vue.use(VueCarousel);

function initialize() {
  // today's top deals carousel
  new Vue({ el: '.todays-top-deals' });

  // Define Prototype Methods that should be available to all Vue components
  Vue.prototype.$dataLayer = window.dataLayer;
  Vue.prototype.$dispatcher = window.App.Dispatcher;
  // Vue.prototype.$routes = window.App.Routes;

  // MyDeals Listener
  window.App.Dispatcher.on('initMyDeals', initMyDeals);

  // Onboarding Listener
  window.App.Dispatcher.on('initOnboarding', initOnboarding);

  // Asynchronously load Vue, Vue Carousel, and this component ONLY when needed
  window.App.Dispatcher.on('initDealCarouselWidget', (options) => {
    import('./deal_carousel_widget').then(({ initDealCarouselWidget }) => {
      initDealCarouselWidget(options);
    }).catch((e) => console.error(e));
  });

  // // Add fallback in case initUserApplication fires before the listener is setup.
  // fallback = setTimeout(initFallback, 1000);

  // Homepage My Deals
  window.App.Dispatcher.once('initUserApplication', initHompageInterests);
}

document.addEventListener('DOMContentLoaded', initialize);
