import { differenceInHours } from 'date-fns';

import BaseView from './base_view';
import ItemEngagementModule from '../views/item_engagement_module';
import Tooltip from '../views/tooltip';

// App.Views.DealsShowPage
export default BaseView.extend({
  initialize: function () {
    // Display advertising disclosure
    this.showAdvertiserDisclosure(this.model.get('merchant').merchant_rules);

    // Initialize engagement module
    this.initEngagementModule();

    // Initialize fakespot tooltip only if deal is fakespot verified
    if (this.model.isFakespotVerified()) this.initFakespotTooltip();

    // Initialize comments section
    if (this.model.get('topic')) {
      this.initDiscussion();
    }

    // Trigger expired deal event
    if (this.model.isExpired()) {
      this.triggerExpiredDealDetail();
    }

    // We don't need the coupon code or clipboard on expired deals
    if (!this.model.isExpired() && this.model.couponCode()) this.clipboardSetup();
  },

  initEngagementModule: function () {
    this.engagementModuleView = new ItemEngagementModule({
      el: this.$('.engagement-module-container'),
      model: this.model
    });

    this.engagementModuleView.render();
  },

  initDiscussion: function () {
    App.Dispatcher.trigger(
      'initDiscussion',
      this.model.get('topic'),
      { discussionOpen: false }
    );
  },

  initFakespotTooltip: function () {
    this.fakespotTooltip = new Tooltip({
      el: '.fakespot-tooltip',
      tooltipTrigger: '<span class="material-icons">info</span>',
      tooltipContent: 'Consumer recommended at Amazon - product reviews have been verified by Fakespot.'
    });

    this.fakespotTooltip.render();
  },

  triggerExpiredDealDetail: function () {
    const expiresAt = new Date(this.model.get('expires_at'));
    const expiredHours = differenceInHours(new Date(), expiresAt);

    App.Dispatcher.trigger('expiredDealDetail', {
      id: this.model.get('id'),
      expired: expiredHours
    });
  },

  getVariant: function () {
    return $('html').data('variant') || App.Data.currentUrlParameters().r;
  },

  clipboardSetup: function () {
    this.clipboard = new Clipboard(this.$('.copy-and-go')[0]);
    this.clipboard.on('success', this.clipboardSuccess, this);
    this.clipboard.on('error', this.clipboardError, this);
  },

  clipboardSuccess: function (event) {
    event.clearSelection();
    this.$('.clipboard-message.success').removeClass('hide');
  },

  clipboardError: function (event) {
    this.clipboard.destroy();
    this.$('.clipboard-message.error').removeClass('hide');
  }
});
