import Modal from '../modal';
import template from '../../templates/modals/remove_alert.jst.ejs';

// App.Views.RemoveAlertModal
export default Modal.extend({
  template: template,

  events: {
    'submit form': 'handleFormSubmit',
    'click .cancel-button': 'hideModal'
  },

  modalName: 'removeAlert',

  afterRender: function () {
    this.listenTo(this.model, 'remove', this.handleFormSuccess);
    this.listenTo(this.model, 'error', this.handleFormError);
  },

  handleFormSubmit: function (e) {
    e.preventDefault();

    this.model.destroy({
      data: {
        user_id: this.model.get('user_id')
      },
      processData: true,
      wait: true
    });
  },

  handleFormSuccess: function () {
    this.hideModal();
  },

  handleFormError: function () {
    this.$('.error').removeClass('hide');
  },
});
