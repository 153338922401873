// eslint-disable-next-line camelcase
import { bindAll } from 'underscore';
import { getRoute } from '../../../javascript/src/utils/route-service'

/* eslint-disable no-undef */
//
// Javascript Client User SDK
//
// More information about this SDK can be found here:
// https://github.com/shopsmart/bd_rails4/wiki/Javascript-Client-User-SDK
//
export default {
  signUp(d) {
    const data = d || {};
    this.promise = $.Deferred();

    bindAll(this, 'handleSignUpSuccess', 'handleSignUpError');

    if (!data.email || !data.email.length) {
      this.promise.reject({ errors: 'Email cannot be blank', created: false, reactivated: false });
    } else {
      const token = $('meta[name="csrf-token"]').attr('content');

      $.ajax({
        type: 'POST',
        url: getRoute('users'),
        data: {
          email: data.email,
          opt_out_newsletter: data.opt_out_newsletter,
          opt_out_promotional: data.opt_out_promotional,
        },
        dataType: 'json',
        headers: {
          'X-CSRF-Token': token,
        },
        success: this.handleSignUpSuccess,
        error: this.handleSignUpError,
      });
    }

    return this.promise;
  },

  handleSignUpSuccess(data) {
    if (data.user.created !== true) {
      this.promise.reject({ errors: 'User already exists', created: false, reactivated: data.user.reactivated });
    } else {
      window.App.Dispatcher.trigger('initUserApplication', data.user);
      window.App.Dispatcher.trigger('userSubscribe', data.user);

      this.promise.resolve({
        errors: null,
        email: data.user.email,
        created: true,
        reactivated: false,
      });
    }
  },

  handleSignUpError(xhr) {
    const data = xhr.responseJSON;
    let errorMessage;

    if (!data.errors) errorMessage = 'Something went wrong';
    if (data.errors && data.errors.email) errorMessage = 'Invalid email address';
    if (!errorMessage) errorMessage = data.errors;

    this.promise.reject({ errors: errorMessage, created: false, reactivated: false });
  },
};
