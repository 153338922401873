export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<ul class="reset engagement-module item-engagement-module row">\n  ';
 if ( !data.item.isExpired() ) { ;
__p += '\n    ';
 if ( data.item.isShareable() ) { ;
__p += '\n      <li class="share-target share-button grey-button">\n        <span class="helper-text">Share</span>\n        <i class="material-icons">share</i>\n        <ul class="reset dropdown">\n          <li class="hover-target facebook-share">\n            <span class="rounded-facebook">\n              <img class="rounded-facebook-icon" src="' +
((__t = ( App.Assets.imagePath("svgs/official_facebook_color.svg") )) == null ? '' : __t) +
'" alt="Facebook logo">\n            </span>\n            <span class="invisible-helper-text">Share on Facebook</span>\n          </li>\n          <li class="hover-target email-share">\n            <i class="material-icons">email</i>\n          </li>\n        </ul>\n      </li>\n    ';
 } ;
__p += '\n    ';
 if ( data.isFavorited ) { ;
__p += '\n      <li class="save saved hover-target saved-bookmark">\n        <i class="material-icons icon-overlay done">done</i>\n        <i class="material-icons">bookmark</i>\n      </li>\n    ';
 } else { ;
__p += '\n      <li class="save hover-target">\n        <i class="material-icons">bookmark</i>\n      </li>\n    ';
 } ;
__p += '\n  ';
 } ;
__p += '\n  <li class="section chat-pack"></li>\n</ul>\n';
return __p
}