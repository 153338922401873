export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<ul class=\'reset row-flex tab-bar desktop-tabs hide-for-small ' +
((__t = ( this.hasBorderBottom() ? 'full-border' : '' )) == null ? '' : __t) +
'\'>\n  ';
 this.options.tabs.forEach(function (tab) { ;
__p += '\n    <li class=\'tab col tight ' +
((__t = ( tab.slug )) == null ? '' : __t) +
' ' +
((__t = ( this.activeTab === tab.slug ? 'active' : '' )) == null ? '' : __t) +
'\' data-slug=\'' +
((__t = ( tab.slug )) == null ? '' : __t) +
'\'>\n      <button class="tab-button reset-button" onclick="window.location.hash =\'' +
((__t = ( tab.slug )) == null ? '' : __t) +
'\'">\n        ' +
((__t = ( tab.title )) == null ? '' : __t) +
'\n      </button>\n    </li>\n  ';
 }, this); ;
__p += '\n</ul>\n\n<ul class="reset row tab-bar mobile-tabs hide-for-large hide-for-medium">\n  <li class="tab col large-6 medium-6 small-6 my-deals ' +
((__t = ( this.activeTab === 'my-deals' ? 'active' : '' )) == null ? '' : __t) +
'">\n    <button class="tab-button reset-button" onclick="window.location.hash = \'#my-deals\'">My Deals</button>\n  </li>\n  <li class="tab col large-6 medium-6 small-6 all-deals ' +
((__t = ( this.activeTab === this.currentFilter.slug ? 'active' : '' )) == null ? '' : __t) +
'">\n    <button class="tab-button reset-button" onclick="window.location.hash =\'' +
((__t = ( this.currentFilter.slug )) == null ? '' : __t) +
'\'">All Deals</button>\n  </li>\n</ul>\n\n';
 if (this.activeTab !== 'my-deals') { ;
__p += '\n  <div class="well all-deals-well row-flex justify-space-between hide-for-large hide-for-medium">\n    <h2>' +
((__t = ( this.currentFilter.label )) == null ? '' : __t) +
' Deals</h2>\n    <div class="row-flex align-items-center feeds-select-wrapper">\n      <p>Show me:</p>\n      <button class="feed-selector">' +
((__t = ( this.currentFilter.label )) == null ? '' : __t) +
' <i class="material-icons">arrow_drop_down</i></button>\n      <ul class="reset feed-selector-dropdown fade-in hide">\n        ';
 this.FILTERS.forEach(function (filter) { ;
__p += '\n          <li class="' +
((__t = ( (this.currentFilter.slug === filter.slug ? 'selected ' : '') )) == null ? '' : __t) +
'' +
((__t = ( filter.slug)) == null ? '' : __t) +
'">\n            <a href="#' +
((__t = ( filter.slug )) == null ? '' : __t) +
'">' +
((__t = ( filter.label )) == null ? '' : __t) +
'</a>\n          </li>\n        ';
 }, this); ;
__p += '\n      </ul>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
return __p
}