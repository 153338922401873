import user from '@legacy-js/user-data/user';
import UserBadge from '../views/users/user_badge';


// App.Controllers.UserApplicationController
export default {
  initialize: function() {
    App.Dispatcher.on('initUserApplication', this.initApplication, this);
    App.Dispatcher.on('bdResetUserAction', this.resetUserAction, this);
  },

  initApplication: function(userJSON) {
    user.init(userJSON);
    this.user = user;
    this.createUser();

    this.userBadgeView = new UserBadge({
      el: '#user-badge-area',
      model: this.user
    });
  },

  createUser: function() {
    App.Dispatcher.trigger('bdUserReady');
  },

  resetUserAction: function() {
    user.queuedAction.clear();
  },
};
