import BaseView from '../base_view';
import template from '../../templates/travel_pills.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.TravelPills
export default BaseView.extend({
  el: '#pill-container',
  template: template,

  events: {
    'click .pill-button': 'handlePillClick'
  },

  initialize: function (options) {
    options = options || {};
    var slug = options.slug;
    bindAll(this, 'handlePillClick');
    this.render();

    if (slug) {
      this.activatePill(slug);
    } else {
      this.activatePill('everything');
    }
  },

  render: function () {
    return this.$el.html(this.template());
  },

  activatePill: function (slug) {
    var $buttons = this.$('.pill-button');
    var $target = this.$('[data-slug=' + slug + ']');

    $buttons.removeClass('active');
    $target.addClass('active');
  },

  handlePillClick: function (e) {
    e.preventDefault();

    this.slug = $(e.target).closest('a.pill-button').data('slug');
    this.activatePill(this.slug);

    App.Dispatcher.trigger('travel:pillClick', this.slug);
  }
});
