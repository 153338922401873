export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<span class="details">Showing ' +
((__t = ( this.model.get("records")[this.options.mainModel].length )) == null ? '' : __t) +
' of ' +
((__t = ( this.model.get("counts")[this.options.mainModel] )) == null ? '' : __t) +
'</span>\n';
 if (this.model.get('loading')) { ;
__p += '\n  ' +
((__t = ( data.loadingTemplate )) == null ? '' : __t) +
'\n';
 } ;
__p += '\n';
 if(this.hasMorePages()) { ;
__p += '\n  <span class="link">Show More</span>\n';
 } ;
__p += '\n';
return __p
}