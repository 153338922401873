export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<ul class="reset engagement-module merchant-engagement">\n  ';
 if ( data.isFavorited ) { ;
__p += '\n    <li class="save saved heart-icon">\n      <i class="material-icons">favorite</i>\n    </li>\n  ';
 } else { ;
__p += '\n    <li class="save heart-icon">\n      <i class="material-icons">favorite</i>\n    </li>\n  ';
 } ;
__p += '\n</ul>\n';
return __p
}