<template>
  <fieldset class="check-box">
    <input type="checkbox" :id="fieldId" :value="value" :checked="checked" :disabled="disabled" @change="onChange" />
    <label :for="fieldId">
      <i />{{label}}
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    checked: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    id: {
      required: false,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
  },
  computed: {
    fieldId() {
      return this.id || this.value;
    },
  },
  methods: {
    onChange($event) {
      if (this.disabled) return;
      this.$emit('change', $event.target.value, $event.target.checked);
    },
  }
}
</script>
