import { format } from 'date-fns';

import BaseView from '../base_view';
import template from '../../templates/coupons/coupon_author_info.jst.ejs';

// App.Views.CouponAuthorInfo
export default BaseView.extend({
  template: template,
  modelName: 'Author',

  initialize: function (options) {
    this.options = this.options || options || {};
    this.render();
  },

  render: function(){
    this.$el.html(this.template({item: this.model, index: this.index}));
    return this.$el;
  },

  formattedExpiration: function () {
    const expiresAt = new Date(this.model.get("expires_at"));
    return format(expiresAt, 'MMM d');
  },

  expiringSoonClass: function(){
    if (this.model.isExpiringSoon()){
      return "expiring-soon";
    };
  }
});
