import Notification from '../notification';
import template from '../../templates/notifications/info_notification.jst.ejs';

// App.Views.InfoNotification
export default Notification.extend({

  el: '<li class="info-house"></li>',

  // Subclasses should override
  template: template
});
