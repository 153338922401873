export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<section class="row preferences grid-15-large-12 small-12 grid-15-medium-12">\n  <div class="preferences-header">\n    ';
 if (this.model.get('subscribe')) { ;
__p += '\n      <input id="promotional-toggle" class="toggle-switch" type="checkbox" name="promotional-toggle">\n    ';
 } else { ;
__p += '\n      <input id="promotional-toggle" class="toggle-switch disabled-toggle" type="checkbox" name="promotional-toggle" disabled>\n    ';
 } ;
__p += '\n    <label for="promotional-toggle"></label>\n    <h2 class="header-text">Special Emails</h2>\n  </div>\n  <p class="email-description">Additional deals worth sharing: exclusives, seasonal deals, trending categories, and more.</p>\n  ';
 if (!this.model.isPromotionalSubscribed()) { ;
__p += '\n    <p class="email-description turned-off">Special Emails have been turned off.</p>\n  ';
 } ;
__p += '\n</section>\n';
return __p
}