import CollectionView from './collection_view';
import MiniDealPageItem from './mini_deal_page_item';

var _super = CollectionView.prototype;

// App.Views.MiniDeals
export default CollectionView.extend({
  modelName: "Deal",
  modelView: MiniDealPageItem,

  initialize: function (options) {
    _super.initialize.apply(this, arguments);
    this.render();
  }
});
