import { bindAll, extend } from 'underscore';
import Deferred from './deferred';

// App.FB
export default {
  initialize: function (App) {
    App.Dispatcher.on('initializeFacebook', this.initializeApplication, this);
  },

  initializeApplication: function (initSettings) {
    bindAll(
      this,
      'handleFacebookLoginClick',
      'initializeFacebook',
      'manageFacebookLoginResponse',
      'handleFacebookLoginSuccess'
    );

    extend(App.Data.facebook, initSettings);
    this.loadFacebookSDK();
  },

  loadFacebookSDK: function () {
    window.fbAsyncInit = this.initializeFacebook;

    $(() => {
      $.ajax({
        dataType: 'script',
        cache: true,
        url: App.Data.facebook.sdk_uri,
      });
    });
  },

  initializeFacebook: function () {
    FB.init({
      appId: window.App.Data.facebook.app_id,
      cookie: true,
      status: true,
      version: 'v18.0',
      xfbml: true,
    });

    // Make sure to register these events after FB is defined
    this.registerEvents();
  },

  registerEvents: function () {
    $(document).on('click', '.facebook-login-button.signup', this.handleFacebookLoginClick);
    App.Dispatcher.on('bdFacebookShare', this.handleFacebookShare);
  },

  disableEvents: function () {
    $(document).off('click', '.facebook-login-button.signup', this.handleFacebookLoginClick);
    App.Dispatcher.off('bdFacebookShare');
  },

  handleFacebookLoginClick: function () {
    const facebookPermissions = {
      scope: 'email',
    };
    this.disableEvents();
    this.deferred = new Deferred();

    FB.login(this.manageFacebookLoginResponse, facebookPermissions);

    return this.deferred.promise;
  },

  handleFacebookShare: function (options) {
    FB.ui(
      {
        method: 'share',
        href: options.model.shareURL(),
        display: 'popup',
      },
      (response) => {}
    );
  },

  manageFacebookLoginResponse: function (response) {
    if (response.authResponse) {
      this.requestUser({ access_token: response.authResponse.accessToken }).done(this.handleFacebookLoginSuccess);
    } else {
      this.registerEvents();
      this.deferred.reject({ errors: 'unable to signin to facebook', created: false });
      $(document).trigger('bdForm:failure');
    }
  },

  handleFacebookLoginSuccess: function (userJSON, status, xhr) {
    this.registerEvents();
    this.deferred.resolve(userJSON);
    this.deferred.promise.then(this.setCurrentUser);
  },

  setCurrentUser: function (userJSON) {
    App.Dispatcher.trigger('initUserApplication', userJSON);
    $(document).trigger('externalForm:success');
    App.Dispatcher.trigger('socialLoginSuccess');
    App.Dispatcher.trigger('userSubscribe', userJSON);
  },

  requestUser: function (response) {
    return $.ajax({
      type: 'POST',
      url: '/facebook',
      data: response,
    });
  },
};
