import BaseView from './base_view';
import template from '../templates/mini_deal.jst.ejs';

// App.Views.MiniDealPageItem
export default BaseView.extend({
  template: template,
  tagName: 'li',

  preinitialize: function (options) {
    this.index = options.index;
  },

  attributes: function () {
    return {
      class: "listing col",
      'data-flags': this.model.get('flag_slugs'),
      "data-item-type": "deal",
      "data-position": this.index,
      "data-id": this.model.get("id")
    };
  },

  render: function() {
    this.$el.html(this.template());
    this.showAdvertiserDisclosure(this.model.get('merchant').merchant_rules);
    return this.$el;
  }
});
