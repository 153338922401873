<template src="./overlay.html" />

<script>
import UserActions from '../user-actions/user-actions.vue';

export default {
  components: {
    'user-actions': UserActions,
  },
  data() {
    return {
      container: null,
    };
  },
  mounted() {
    // Bind component element to data attr so the sticky directive can receive it as an argument
    this.$data.container = this.$el;
  },
};
</script>

<style src="./overlay.scss" lang="scss" scoped></style>
