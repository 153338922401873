import BaseModel from './base_model';

var _super = BaseModel.prototype;

// App.Models.SingleUseCoupon
export default BaseModel.extend({

  initialize: function () {
    _super.initialize.apply(this, arguments);
    if (!this.attributes.merchantId) {
      throw Error('Merchant ID Required');
    }
  },

  urlRoot: '/coupons',

  url: function () {
    return this.urlRoot + '/' + this.get('merchantId') + '/codes';
  }

});
