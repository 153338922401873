import BaseView from '../base_view'

// App.Views.CreditCard
export default BaseView.extend({
  events: {
    'click .more-details': 'toggleDetails',
    'load .image img': 'useImageOrPlaceholder',
    'error .image img': 'usePlaceholder'
  },

  initialize: function () {
    this.open = this.$('.details').is(':visible');
    this.image = this.$('.image img');
    this.imgTag = {};

    if (this.image.length) {
      this.imgTag = this.image[0];

      // Call in case the image is already loaded at initialization
      this.useImageOrPlaceholder();
    }
  },

  toggleDetails: function () {
    if (this.open) {
      this.$('.more-details')
        .text('View More Details')
        .removeClass('open');
    } else {
      this.$('.more-details')
        .text('View Less Details')
        .addClass('open');
    }

    this.$('.details').slideToggle();
    this.open = !this.open;
  },

  useImageOrPlaceholder: function () {
    if (this.isImageLoaded() && !this.isImageValid()) {
      this.usePlaceholder();
    }
  },

  isImageLoaded: function () {
    return Boolean(this.imgTag.complete);
  },

  isImageValid: function () {
    return Boolean(this.imgTag.naturalWidth) && this.imgTag.naturalWidth > 10;
  },

  usePlaceholder: function () {
    var downloadImage = new Image();
    var image = this.imgTag;

    downloadImage.onload = function () {
      image.src = this.src;
    };

    // Make sure the placeholder image is download to the clients browser
    downloadImage.src = App.Assets.imagePath('credit-card-placeholder.png');
  }
});
