import BaseView from './base_view';
import Comment from '../models/comment';
import CreateProfileModal from './modals/create_profile';
import Forms from '../lib/forms/forms';
import SignUpModal from './modals/sign_up';
import template from '../templates/comment_form.jst.ejs';
import { bindAll } from 'underscore';
import user from '@legacy-js/user-data/user';

// App.Views.CommentForm
export default BaseView.extend({
  inheritsFromCommentForm: true,
  template: template,
  textOption: '4',

  initialize: function () {
    bindAll(this, 'handleCommentPostSuccess', 'handleAnonymousCommentSubmit', 'submitForm',
      'handleFormSubmit', 'updateText');
    this.registerEvents();
    this.render();
  },

  render: function () {
    this.$el.html(this.template());
    this.initValidator();
    return this.$el;
  },

  initValidator: function () {
    this.validator = this.$el.find('form').validate({
      ignore: [],
      onsubmit: false,
      wrapper: 'li',
      errorLabelContainer: '.errors',
      rules: {
        comment_body: Forms.Validations.rules.comment_body
      },
      messages: {
        comment_body: Forms.Validations.messages.comment_body
      }
    });
  },

  registerEvents: function () {
    $(document).on('ajax:success', '.comment-form', this.handleCommentPostSuccess);
    $(document).on('ajax:before', '.comment-form', this.handleFormSubmit);
    App.Dispatcher.on('user:change', this.updateText, this);
  },

  handleCommentPostSuccess: function (event, data, status, xhr) {
    var comment = new Comment(data);
    this.model.get('comments').unshift(comment);
    this.render();
  },

  handleAnonymousCommentSubmit: function () {
    user.queuedAction.enqueue(this.submitForm);
    if (user.loggedIn() && user.isSubscriber()) {
      App.Dispatcher.trigger('showModal', {
        modalClass: CreateProfileModal
      });
    } else {
      App.Dispatcher.trigger('showModal', {
        modalClass: SignUpModal,
        to: this.textOption
      });
    }
  },

  submitForm: function () {
    this.$el.find('form').trigger('submit.rails');
  },

  validate: function () {
    return $(this.validator.currentForm).valid();
  },

  handleFormSubmit: function () {
    if (this.validate()) {
      if (user.isFullUser()) {
        return true;
      } else {
        this.handleAnonymousCommentSubmit();
        return false;
      }
    } else {
      return false;
    }
  },

  submitButtonText: function () {
    return user.isFullUser() ? 'Add a comment' : 'Sign up and post';
  },

  updateText: function () {
    this.$('input.rounded-button').attr('value', this.submitButtonText());
    this.$('.sign-up-warning').text(this.signUpWarningText());
    this.$('textarea').attr('placeholder', this.placeholderText());
  },

  placeholderText: function () {
    return user.isFullUser() ? 'Your comment' :
    'To post a comment, you need to be logged in.';
  },

  signUpWarningText: function () {
    return user.isFullUser() ? '' : 'Please sign up or log in to post a comment.';
  }
});
