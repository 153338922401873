import BaseSearchView from './base_search_view';
import DealsView from '../views/deals';
import Deals from '../models/deals';
import SearchPopularDeals from '../views/search_popular_deals';
import SearchPagination from '../views/search_pagination';
import emptyTemplate from '../templates/no_deal_search_results.jst.ejs';

// App.Views.SearchDealsPage
export default BaseSearchView.extend({
  createViews: function () {
    this.collectionView = new DealsView({
      el: this.$el.find('.collection'),
      collection: this.model.deals(),
      emptyTemplate: emptyTemplate,
      collectionName: 'deals'
    });

    this.collectionView.render();

    if (this.model.deals().isEmpty()) {
      this.searchPopularDeals = new SearchPopularDeals({
        el: '#deals-feed',
        collection: new Deals()
      });
    } else {
      this.searchPagination = new SearchPagination({
        model: this.model,
        el: '.deals .pagination',
        mainModel: 'deals'
      });
    }
  },

  removeViews: function () {
    if (this.collectionView) this.collectionView.remove();
    if (this.searchPopularDeals) this.searchPopularDeals.remove();
    if (this.searchPagination) this.searchPagination.remove();
  }
});
