import BaseView from '../base_view';
import template from '../../templates/engagements/comment_link_module.jst.ejs';

// App.Views.CommentLinkModule
export default BaseView.extend({
  template: template,

  initialize: function (options) {
    if (this.model.get("topic")) {
      this.model.get("topic").get("comments").on("all", this.render, this);
    }
    this.render();
  },

  render: function () {
    return this.$el.html(this.template());
  },

  discussionPath: function () {
    if (this.model.modelClass === "Deal") {
      return `/deals/${this.model.get("param")}`;
    } else if (this.model.modelClass === "Coupon") {
      return `/coupons/${this.model.get("param")}`;
    } else {
      return `/blog/${this.model.get("slug")}`;
    }
  }
});
