import BaseView from '../../base_view';
import Forms from '../../../lib/forms/forms';
import template from '../../../templates/users/account_settings/profile.jst.ejs';
import { bindAll, reduce } from 'underscore';

// App.Views.User.AccountSetting.Profile
export default BaseView.extend({
  template: template,

  events: {
    'submit .edit-user-profile form': 'handleFormSubmit'
  },

  initialize: function () {
    bindAll(this, 'activateFormSubmit');
    App.Dispatcher.on('userProfile:updated', this.handleProfileSync, this);
    App.Dispatcher.on('user:change', this.render, this);
    this.render();
  },

  render: function () {
    this.$el.html(this.template());
    this.$('form').one('change', this.activateFormSubmit);
    this.initFormValidation();
    return this.$el;
  },

  initFormValidation: function () {
    this.$('form').validate({
      rules: {
        first_name: { required: true },
        last_name: { required: true },
      },
      groups: {
        full_name: 'first_name last_name'
      },
      messages: {
        first_name: 'Be sure to include your <em>first and last name</em>.',
        last_name: 'Be sure to include your <em>first and last name</em>.',
      },
      errorLabelContainer: '.errors'
    });
  },

  activateFormSubmit: function () {
    var $button = this.$('input[type=submit]');
    $button.removeClass('disabled').removeAttr('disabled');
  },

  handleFormSubmit: function (event) {
    event.preventDefault();
    var attributes = reduce($(event.target).serializeArray(),
      function (object, item) {
        object[item.name] = item.value;
        return object;
      },
      {});

    this.model.get('userProfile').save(attributes);
  },

  birthMonthOptions: function () {
    var userBirthMonth = parseInt(this.model.get('userProfile').get('month'), 10);
    var html = '';
    var months = ['January', 'February', 'March', 'April', 'May',
                  'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    for (var i = 1; i <= 12; i++) {
      if (userBirthMonth && userBirthMonth == i) {
        html += '<option selected value="' + i + '">' + months[i - 1] + '</option>';
      } else {
        html += '<option value="' + i + '">' + months[i - 1] + '</option>';
      }
    }

    return html;
  },

  birthDateOptions: function () {
    var userBirthDate = parseInt(this.model.get('userProfile').get('day'), 10);
    var html = '';

    for (var i = 1; i <= 31; i++) {
      if (userBirthDate && userBirthDate === i) {
        html += '<option selected>' + i + '</option>';
      } else {
        html += '<option>' + i + '</option>';
      }
    }

    return html;
  },

  birthYearOptions: function () {
    var userBirthYear = parseInt(this.model.get('userProfile').get('year'), 10);
    var html = '';

    for (var i = 2014; i >= 1914; i--) {
      if (userBirthYear && userBirthYear === i) {
        html += '<option selected>' + i + '</option>';
      } else {
        html += '<option>' + i + '</option>';
      }
    }

    return html;
  },

  genderOptions: function () {
    var userGender = this.model.get('gender');
    var html = '';
    var genders = [
      {
        name:'Female',
        value: 'f'
      },
      {
        name:'Male',
        value: 'm'
      }
    ];

    genders.forEach(function (gender) {
      if (userGender && userGender === gender.value) {
        html += '<option selected value="' + gender.value + '">' + gender.name + '</option>';
      } else {
        html += '<option value="' + gender.value + '">' + gender.name + '</option>';
      }
    });

    return html;
  },

  handleProfileSync: function () {
    Forms.Profile.setSavedMessage(this.$('form'));
  }
});
