import Deals from '../models/deals';
import Coupons from '../models/coupons';
import Merchants from '../models/merchants';
import PageItemsCollection from './page_items';

// App.Data.facebook
const facebook = {
  sdk_uri: '//connect.facebook.net/en_US/sdk.js'
};

// App.Data.google
const google = {
  sdk_uri: '//apis.google.com/js/client:platform.js'
};

const PageItems = {
  Deals: new PageItemsCollection(Deals),
  Coupons: new PageItemsCollection(Coupons),
  Merchants: new PageItemsCollection(Merchants)
};

export { google, facebook, PageItems };
