import CollectionView from './collection_view';
import MiniCouponPageItem from './mini_coupon_page_item';

var _super = CollectionView.prototype;

// App.Views.MiniCoupons
export default CollectionView.extend({
  modelName: "Coupon",
  modelView: MiniCouponPageItem,

  initialize: function (options) {
    _super.initialize.apply(this, arguments);
    this.render();
  }
});
