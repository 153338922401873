import BaseView from '../base_view';
import SubscriptionStatus from './preferences/subscription_status';
import NewsletterPreferences from './preferences/newsletter_preferences';
import PromotionalPreferences from './preferences/promotional_preferences';
import RecommendedPreferences from './preferences/recommended_preferences';
import Subscriptions from './preferences/subscriptions';
import CreateSubscriptionForm from '../create_subscription_form';
import template from '../../templates/users/email_preferences.jst.ejs';

// App.Views.User.EmailPreferences
export default BaseView.extend({
  template: template,

  initialize: function (options) {
    this.options = this.options || options || {};
    this.render();
    this.createSubViews();
  },

  render: function () {
    this.$el.html(this.template());
    return this.el;
  },

  createSubViews: function () {
    // This is the subview for the Subscribe Button
    this.subscriptionStatus = new SubscriptionStatus({
      el: this.$('#subscription-status'),
      model: this.model
    });

    // This is the subview for the Newsletter Email settings
    this.newsletterPreferences = new NewsletterPreferences({
      el: this.$('#newsletter-preferences'),
      model: this.model
    });

    // This is the subview for the Promotional Email settings
    this.promotionalPreferences = new PromotionalPreferences({
      el: this.$('#promotional-preferences'),
      model: this.model
    });

    // This is the subview for the Recommended Email settings
    this.recommendedPreferences = new RecommendedPreferences({
      el: this.$('#recommended-preferences'),
      model: this.model
    });

    // This is the subview for the Deal Alerts that the user has set up
    this.subscriptions = new Subscriptions({
      el: this.$('#user-subscriptions'),
      collection: this.options.user.subscriptions,
      preferences: this.model
    });

    // This is the subview for Creating a Deal Alert
    this.createSubscriptionForm = new CreateSubscriptionForm({
      el: this.$('#create-deal-alert-form'),
      user: this.options.user
    });
  }
});
