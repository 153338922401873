import BaseView from './base_view';
import Forms from '../lib/forms/forms';
import Subscription from '../models/subscription';
import template from '../templates/subscription_module.jst.ejs';
import emailTemplate from '../templates/subscriptions/email.jst.ejs';
import { bindAll, escape } from 'underscore';

// App.Views.SubscriptionModule
export default BaseView.extend({
  template: template,
  emailTemplate: emailTemplate,

  events: {
    'click .subscription-create': 'handleSubscriptionCreateClick',
    'ajax:before .subscription-user-form': 'validateEmail',
    'ajax:success .subscription-user-form': 'handleUserCreateSuccess',
    'ajax:error .subscription-user-form': 'handleUserCreateError'
  },

  initialize: function (options) {
    this.options = this.options || options || {};

    this.model = new Subscription({
      user_id: this.options.user.get('id'),
      trigger: this.options.trigger,
      name: this.options.name || null,
      query: {
        brands: this.options.brands || null,
        categories: this.options.categories || null,
        flags: this.options.flags || null,
        genders: this.options.genders || null,
        merchants: this.options.merchants || null,
        search_string: this.options.searchString || null
      }
    });

    bindAll(
      this,
      'handleSubscriptionSaveError',
      'handleSubscriptionSaveSuccess',
      'handleUserCreateError'
    );

    this.label = this.options.label || 'Keyword';

    this.listenTo(this.options.user.get('subscriptions'), 'sync', this.render);

    this.render();
  },

  render: function (options) {
    options = options || {};

    var buttonText = this.options.buttonText || 'Create Alert';
    var title = options.title || this.alreadyExistsTitle();

    return this.$el.html(
      this.template({ title: title, buttonText: buttonText })
    );
  },

  renderEmailTemplate: function () {
    if (this.$('.subscription-user-form').length < 1) {
      this.$el.append(this.emailTemplate());
      this.disableCreateAlertButton();
      this.initializeValidator();
    }
  },

  disableCreateAlertButton: function () {
    this.$('.button-container, .subscription-create').addClass('disabled');
  },

  handleSubscriptionCreateClick: function () {
    if (this.options.user.loggedIn()) {
      this.saveSubscription();

      if (!this.options.user.get('confirmed')) {
        this.options.user.resendConfirmation();
      }
    } else {
      this.renderEmailTemplate();
    }
  },

  handleUserCreateSuccess: function (event, response, status, xhr) {
    App.Dispatcher.trigger('initUserApplication', response.user);
    App.Dispatcher.trigger('userSubscribe', response.user);

    this.saveSubscription();
  },

  handleUserCreateError: function (event, xhr, status, error) {
    var responseJSON = xhr.responseJSON || {};

    if (responseJSON.errors && responseJSON.errors.email) {
      this.showEmailValidationError();
    }
  },

  handleSubscriptionSaveError: function (subscription, response, options) {
    // TODO: Handle failure messaging (i.e. existing subscription). This requires a more detailed
    // response from the www server in order to determine the nature of the error
    this.render();
  },

  handleSubscriptionSaveSuccess: function (subscription, textStatus, xhr) {
    this.options.user.get('subscriptions').add(subscription);

    this.render({ title: this.confirmationTitle() });
  },

  validateEmail: function () {
    return this.$('form.subscription-user-form').valid();
  },

  saveSubscription: function (options) {
    // Set the user ID just before saving in order to avoid race conditions
    this.model.set({
      user_id: this.options.user.get('id')
    });

    this.model
      .save()
      .done(this.handleSubscriptionSaveSuccess)
      .fail(this.handleSubscriptionSaveError);
  },

  isSubscribed: function () {
    var query = this.model.get('query');
    var trigger = this.model.get('trigger');
    var subscriptions = this.options.user.get('subscriptions');

    return !!subscriptions.findByQueryAndTrigger(query, trigger);
  },

  alreadyExistsTitle: function () {
    return this.options.alreadyExistsTitle || 'Looks like a deal alert is already created';
  },

  confirmationTitle: function () {
    var confirmationTitle = this.options.confirmationTitle || 'Your Alert Is Active!';
    if (this.options.user.get('confirmed')) {
      return confirmationTitle;
    } else {
      return 'Success!';
    }
  },

  confirmationMessage: function () {
    if (this.options.user.get('confirmed')) {
      return 'You have been signed up to start receiving Deal Alerts. To manage or remove a Deal Alert, Go to Your <a href="/me/email-preferences">Email Settings </a>';
    } else {
      return 'Just one more step! Go to your email to confirm your' +
       ' address, and your alert will be ready to go.';
    }
  },

  name: function () {
    return this.model.getName() || this.searchString();
  },

  searchString: function () {
    return escape(
      this.truncate(
        this.model.get('query').search_string || ''
      )
    );
  },

  truncate: function (string, maxLength) {
    if (!maxLength) {
      maxLength = 50;
    }

    if (string.length > maxLength) {
      return string
        .substring(0, maxLength - 3)
        .concat('...');
    } else {
      return string;
    }
  },

  initializeValidator: function () {
    this.$validator = this.$('form.subscription-user-form').validate({
      rules: {
        email: {
          required: Forms.Validations.rules.email.required,
          email: Forms.Validations.rules.email.email
        }
      },
      messages: {
        email: {
          required: Forms.Validations.messages.email.required,
          email: Forms.Validations.messages.email.email
        }
      },
      errorPlacement: Forms.Validations.errorPlacement.before
    });
  },

  showEmailValidationError: function () {
    if (this.$validator) {
      this.$validator.showErrors({
        email: Forms.Validations.messages.email.email
      });
    }
  }
});
