import Validations from './validations';

// App.Forms.Password
export default {
  initialize: function(){
    this.initValidation();
  },
  initValidation: function(){
    $(".password-edit-form").validate({
      errorLabelContainer: ".errors",
      rules: {
        password: Validations.rules.password
      },
      messages: {
        password: Validations.messages.password
      }
    });
  }
};
