export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 if( this.model.isFullUser() ) { ;
__p += '\n  <div class="user-account logged-in">\n    <nav class="user-info clearfix has-submenu">\n      <button class="reset-button menu-trigger user-badge-button" aria-expanded="false" aria-haspopup="true">\n        <span class="user-badge-icon">\n          <i class="material-icons">account_circle</i>\n        </span>\n        <span class="user-badge-details">\n          Hello!\n          <span>' +
((__t = ( this.model.displayName() )) == null ? '' : __t) +
'</span>\n        </span>\n      </button>\n      <ul class="reset dropdown" aria-label="User Menu" role="menu">\n        <li>\n          <a href="/me/favorites" role="menuitem">\n            <i class="material-icons">bookmark</i>\n            <span>Saved Items</span>\n          </a>\n        </li>\n        <li>\n          <a href="/me/email-preferences" role="menuitem">\n            <i class="material-icons">email</i>\n            <span>Email Settings</span>\n          </a>\n        </li>\n        <li>\n          <a href="/me" role="menuitem">\n            <i class="material-icons">account_circle</i>\n            <span>Account Settings</span>\n          </a>\n        </li>\n        <li>\n          <a href="/faq" role="menuitem">\n            <i class="material-icons">info</i>\n            <span>Help Center</span>\n          </a>\n        </li>\n        <li>\n          <a id="log-out" href="/sessions" role="menuitem" data-method="delete" rel="nofollow" class="rounded-button">\n            Log out\n          </a>\n        </li>\n      </ul>\n    </nav>\n  </div>\n';
 } else { ;
__p += '\n  <div class="user-account subscribed clearfix">\n    <div class="label">\n      <span class="link modal-link" data-modal="create_profile">Complete <br />your profile</span>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
return __p
}