import CollectionView from './collection_view';
import CouponsPageItem from './coupons_page_item';

var _super = CollectionView.prototype;

// App.Views.Coupons
export default CollectionView.extend({
  modelName: 'Coupon',
  modelView: CouponsPageItem,

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.initializeViews();
  }
});
