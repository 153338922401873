import BaseView from '../base_view';
import FuzzyTime from '../../lib/fuzzy_time';
import template from '../../templates/deals/deal_author_info.jst.ejs';

// App.Views.DealAuthorInfo
export default BaseView.extend({
  template: template,
  modelName: 'Author',

  initialize: function (options) {
    this.options = this.options || options || {};
    this.render();
  },

  render: function () {
    this.$el.html(this.template({ item: this.model, index: this.index }));
    FuzzyTime.setFuzzyTime(this.$el.find('[data-created-at]')[0]);
    return this.$el;
  },

  authorAvatar: function () {
    return this.model.get('author').avatarUrl();
  }
});
