
// App.Mixins.Commentable
export default {
  extendsCommentable: true,

  commentCount: function(){
    var count = this.get("topic") ? this.get("topic").get("comments").length : this.get("comment_count")
    count = count || '';
    return (count > 99) ? "99+" : count;
  }
};
