export default function(data) {
var __t, __p = '';
__p += '<div class="coupon-flex-content">\n  <h5 class="text-stripe expiration-date"></h5>\n  <div class="discount-module">\n    <h3 class="' +
((__t = ( this.headerClass() )) == null ? '' : __t) +
'">\n      ' +
((__t = ( this.model.get('title') )) == null ? '' : __t) +
'\n      <span class="advertiser-disclosure-link" hidden>*</span>\n    </h3>\n    <div class="coupon-description details">\n      <p>\n        ' +
((__t = ( this.model.get('description') )) == null ? '' : __t) +
'\n        ' +
((__t = ( this.model.get('instructions') )) == null ? '' : __t) +
'\n      </p>\n    </div>\n  </div>\n   <a href="' +
((__t = ( this.model.goLink() )) == null ? '' : __t) +
'" class="rounded-button secondary bottom-btn">Go To Sale</a>\n</div>\n';
return __p
}