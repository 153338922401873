import BaseView from './base_view';
import { bindAll } from 'underscore';

// App.Views.ToggledNavigation
export default BaseView.extend({

  hashRegex: /^#[\w\-_]+$/,

  initialize: function () {
    bindAll(this, 'showLeftSide', 'reloadView');
    this.$parentHeader   = this.$('.tappable-header');
    this.$leftHeader     = this.$('.left-side-header');
    this.$rightHeader    = this.$('.right-side-header');
    this.$leftView       = this.$('.left-side-view');
    this.$rightView      = this.$('.right-side-view');
    if (!this.hashRegex.test(this.$leftHeader.attr('href')) ||
        !this.hashRegex.test(this.$rightHeader.attr('href'))) {
      throw 'Header links must have valid hash road defined';
    } else {
      this.hashLeft   = this.$leftHeader.attr('href').substr(1);
      this.hashRight  = this.$rightHeader.attr('href').substr(1);

      App.Dispatcher.on('hashChange:' + this.hashLeft, this.showLeftSide, this);
      App.Dispatcher.on('hashChange:' + this.hashRight, this.showRightSide, this);
    }

    // Listen for window resize and redraw active pane
    $(window).on('resize', this.reloadView);

    // Backup original remove function so we can replace it with our own
    this.oldRemove = this.remove;

    // Override remove function
    this.remove    = function () {
      $(window).unbind('resize');
      this.oldRemove();
    };
  },

  reloadView: function () {
    var $hiddenView = $('.out-of-view');

    if ($hiddenView.hasClass('left-side-view')) {
      this.showRightSide();
    } else {
      this.showLeftSide();
    }
  },

  showLeftSide: function () {
    this.$parentHeader.removeClass('right');

    this.$leftView.removeClass('out-of-view');
    this.$rightView.addClass('out-of-view');
    this.$el.css('min-height', this.$parentHeader.height() + this.$leftView.height());
    this.$rightHeader.removeClass('active');
    this.$leftHeader.addClass('active');
  },

  showRightSide: function () {
    this.$parentHeader.addClass('right');

    this.$rightView.removeClass('out-of-view');
    this.$leftView.addClass('out-of-view');
    this.$el.css('min-height', this.$parentHeader.height() + this.$rightView.height());

    this.$leftHeader.removeClass('active');
    this.$rightHeader.addClass('active');
  }
});
