import Cookies from 'js-cookie';

// App.GeoLocation
export default function (host) {
  var STAGING = 'https://www-staging.bradsdeals.com';

  var cachedPromise;
  var geoLocationUrl = '/fastly/geoip';
  var locationCache = {};

  // If the Demo, Sandbox or Staging use absolute URL to Staging
  if ((/^www.+\.bradsdeals\.com$/).test(host)) geoLocationUrl = STAGING + geoLocationUrl;

  function loadGeoFromSessionStorage() {
    return attemptToParse(sessionStorage.getItem('geoloc'));
  };

  function loadGeoFromCookie() {
    return attemptToParse(Cookies.get('geoloc'));
  };

  function attemptToParse(geo) {
    if (!geo) return {};

    try {
      return JSON.parse(geo);
    } catch (e) {
      return {};
    }
  };

  function saveGeo(geo) {
    var jsonString = JSON.stringify(geo);
    locationCache = geo;
    sessionStorage.setItem('geoloc', jsonString);
    Cookies.set('geoloc', jsonString);
    return locationCache;
  };

  function isGeoValid(geo) {
    if (!geo) {
      return false
    }

    var geoKeys = Object.keys(geo);
    var requiredKeys = [
      'city',
      'country_code',
      'metro_code',
      'postal_code',
      'region',
      'utc_offset'
    ]

    return requiredKeys.every(function(key) {
      return geoKeys.includes(key)
    })
  };

  function resolveLocationCache(geo) {
    return cachedPromise = Promise.resolve(geo);
  }

  this.get = function () {
    if (cachedPromise) return cachedPromise;

    if (isGeoValid(locationCache)) return resolveLocationCache(locationCache);

    locationCache = loadGeoFromSessionStorage();

    if (isGeoValid(locationCache)) return resolveLocationCache(locationCache);

    locationCache = loadGeoFromCookie();

    if (isGeoValid(locationCache)) return resolveLocationCache(locationCache);

    return cachedPromise = new Promise(function (resolve, reject) {
      $.get(geoLocationUrl)
        .done(function (resp, status, xhr) {
          if (!isGeoValid(resp)) return reject('Invalid geolocation');
          resolve(saveGeo(resp));
        })
        .fail(function (xhr, status, error) {
          reject('Unable to connect to fastly geolocation endpoint');
        });
    });
  };

  this.clear = function () {
    sessionStorage.removeItem('geoloc');
    Cookies.remove('geoloc');
    cachedPromise = undefined;
    locationCache = {};
  };
};
