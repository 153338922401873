<template src="./my_deals_sign_up.html" />

<script>
import user from '@legacy-js/user-data/user';
import { get, post } from '../../../utils/api-service';
import parseErrors from '../../../utils/error-handler';
import InfoLinks from '../../info_links/info_links.vue';

export default {
  name: 'MyDealsSignUp',
  components: {
    'info-links': InfoLinks,
  },

  props: {
    emailUser: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      email: '',
      errors: '',
      changeEmail: false,
    };
  },

  methods: {
    handleFacebookSignup() {
      this.$FB.handleFacebookLoginClick()
        .then(this.handleSaveUsersInterests, this.handleSignupError)
        .then(this.advanceStepAfterSocialMediaSignUp);
    },
    handleGoogleSignup() {
      this.$Google.handleGoogleLoginClick()
        .then(this.handleSaveUsersInterests, this.handleSignupError)
        .then(this.advanceStepAfterSocialMediaSignUp);
    },
    handleSignupError(body) {
      console.error(body.errors);
    },
    handleSubmitSuccess(response) {
      return new Promise((resolve) => {
        const { status, body } = response;

        if (status === 201) {
          resolve(body.user);
        } else if (body.errors) {
          throw response;
        }
      });
    },
    handleSubmitError(response) {
      const { body } = response;
      this.errors = this.parseErrors(body.errors);
    },
    handleSaveUsersInterests() {
      if (user.loggedIn()) this.$emit('saveUsersInterests');
    },
    handleCreateUser() {
      const { email } = this;
      post('/users', { body: { email } })
        .then(this.handleSubmitSuccess)
        .then(this.triggerSubscribedEvents)
        .then(this.handleSaveUsersInterests)
        .then(this.advanceStepFn(4))
        .then(this.triggerStepCompletedEvent)
        .catch(this.handleSubmitError);
    },
    handleConfirmUser() {
      const { email } = this;
      const normalizedEmail = email.trim().toLowerCase();
      const md5 = window.CryptoJS.MD5(normalizedEmail).toString();

      this.processConfirmation(md5);
    },
    handleEmailCheck(response) {
      const { body: { is_full_user: isFullUser, error } } = response;
      const { email } = this;

      // New user
      if (error === 'Email not found') this.handleCreateUser();

      // Existing Full User
      if (isFullUser) {
        this.$emit('setUserEmail', email);
        this.advanceStepFn(5)();
        this.triggerStepCompletedEvent();
      }

      // Existing Subscriber
      if (isFullUser === false) this.handleConfirmUser();
    },
    triggerSubscribedEvents(userJSON) {
      this.$dispatcher.trigger('initUserApplication', userJSON);
      this.$dispatcher.trigger('userSubscribe', userJSON);
    },
    triggerStepCompletedEvent() {
      window.Segment.track(
        'User Flow Step Completed',
        {
          flow_name: 'mydeals_web_signup',
          step_name: 'create_account',
          step_number: 2,
        },
      );
    },
    advanceStepFn(step) {
      return () => this.$emit('advanceStep', step);
    },
    advanceStepAfterSocialMediaSignUp() {
      if (user.loggedIn()) {
        this.advanceStepFn(3)();
        this.triggerStepCompletedEvent();
      }
    },
    checkEmail(email) {
      const normalizedEmail = email.trim().toLowerCase();
      const md5 = window.CryptoJS.MD5(normalizedEmail).toString();

      return get(`/me/email/${md5}/check`);
    },

    sendOutConfirmation(md5) {
      return post(`/me/email/${md5}/send_confirmation`);
    },

    processConfirmation(md5) {
      this.$emit('saveUsersInterestsLocal');
      this.sendOutConfirmation(md5)
        .then(this.advanceStepFn(6))
        .then(this.triggerStepCompletedEvent);
    },

    onSubmit() {
      // Email validations happening serverside and displayed via handleSubmitError + Vue template
      const { email } = this;
      this.checkEmail(email).then(this.handleEmailCheck);
    },
    parseErrors,
  },
};
</script>

<style src="./my_deals_sign_up.scss" lang="scss" scoped></style>
