import BaseView from './base_view';
import template from '../templates/notifications/info_notification.jst.ejs';
import { bindAll, defaults } from 'underscore';

// App.Views.Notification
export default BaseView.extend({
  baseDefaultOptions: {
    msg: '',
    timeout: 30000 // 30 seconds
  },

  // Subclasses should override
  defaultOptions: {},

  // Subclasses should override
  el: '<li class="msg"></li>',

  events: {
    'click .close-msg': 'hideMsgClick'
  },

  // Subclasses should override
  template: template,

  initialize: function (options) {
    // backbone views have options set automagically already
    this.options = defaults(options, this.defaultOptions, this.baseDefaultOptions);
    bindAll(this, 'hideMsg', 'remove');
  },

  render: function () {
    this.$el.html(this.template({
      msg: this.options.msg,
    }));

    if (this.options.timeout > 0) this.timeout = this.setTimeout();

    return this.$el;
  },

  hideMsgClick: function (e) {
    e.preventDefault();
    this.clearTimeout();
    this.hideMsg();
  },

  hideMsg: function () {
    this.$el.fadeOut({
      complete: this.remove
    });
  },

  setTimeout: function () {
    return setTimeout(this.hideMsg, this.options.timeout);
  },

  clearTimeout: function () {
    return clearTimeout(this.timeout);
  }
});
