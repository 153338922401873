// App.Twitter
export default {
  initialize: function(App){
    App.Dispatcher.on("initializeTwitter", this.initTwitter, this);
  },

  initTwitter: function(){
    $.getScript("https://platform.twitter.com/widgets.js");
  }
};
