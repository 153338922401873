<template src="./success-panel.html" />
<style src="./success-panel.scss" lang="scss" scoped></style>

<script>
export default {
  name: 'SuccessPanel',
  props: {
    close: {
      type: Function,
      required: true,
    },
    opened: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    scrollToDeals() {
      const target = document.querySelector('#tab-bar');

      target.scrollIntoView({ behavior: 'smooth' });
      this.close();
    }
  },
};
</script>
