import AcquisitionModal from './acquisition';
import template from '../../templates/modals/acquisition_mobile.jst.ejs';
import bdPrimaryReversedSVG from '../../templates/svgs/bd_primary_reversed.jst.ejs';

// App.Views.AcquisitionMobileModal
export default AcquisitionModal.extend({
  template: function () { return template.call(this, { bdPrimaryReversedSVG: bdPrimaryReversedSVG }) },

  isClosable: function() {
    var params = App.Data.currentUrlParameters();
    return params.btn !== 'false';
  },

  closeModalPath: function() {
    var params = App.Data.currentUrlParameters();

    if (params.redirect_path) {
      return params.redirect_path;
    } else {
      return '/';
    }
  },

  modalText: function() {
    switch (+this.options.to) {
      case 9:
        return {
          h1: 'Not A Member? Sign Up & Save.',
          h2: 'Deals like this are posted daily.',
          h3: 'Take a second to sign up before we take you to the featured deal.'
        };
      case 10:
        return {
          h1: 'Our Collections Grow Every Day.',
          h2: 'Be the first to know about new deals.',
          h3: 'Take a second to sign up before we take you to the featured collection.'
        };
      case 11:
        return {
          h1: 'New Collections Every Day.',
          h2: 'Never miss out on a great deal.',
          h3: 'Sign up to access our featured collection.'
        };
      case 12:
        return {
          h1: 'New Day, New Collections.',
          h2: 'Always know about the best deals.',
          h3: 'Sign up to access our featured collection.'
        };
      case 13:
        return {
          h1: 'New Day,<br> New Deals.',
          h2: 'Learn how easy it is to save.',
          h3: 'Sign up to access our featured deal.'
        };
      case 14:
        return {
          h1: 'Saving Money Can Be Easy',
          h2: 'Join over 20 million members and let us show you how.',
          h3: 'Sign up to access exclusive savings.'
        };
      case 15:
        return {
          h1: 'Stretch Your Dollar Further',
          h2: 'We do the work, you enjoy the deals.',
          h3: 'Sign up to access exclusive savings.'
        };
      default: case 8:
        return {
          h1: 'Brands You Love. Prices You Want.',
          h2: 'Get alerts on deals like this.',
          h3: 'Take a second to sign up before we take you to the featured deal.'
        };
    }
  }
});
