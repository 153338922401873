export function searchTrimmer(inputSelector) {
  return function (event) {
    event.preventDefault()

    const searchTerm = document.querySelector(inputSelector).value

    const cleanSearchTerm = encodeURIComponent(searchTerm.trim())
      .replace(/(?:%20)+/g, '+')

    window.location.assign(`/search?query=${cleanSearchTerm}`)
  }
}
