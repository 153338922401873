import BaseModel from './base_model';

// App.Models.EmailSubscription
export default BaseModel.extend({

  url: function () {
    return `/me/email/${this.get('md5')}/preferences`;;
  },

  // FORCES ALL SAVE CALLS TO SUBSCRIPTION TO USE PUT METHOD
  isNew: function() {
    return false;
  }
});
