export default function(data) {
var __t, __p = '';
__p += '<div class="well stale-deal">\n  <div class="image-container">\n    <div class="close-button">\n      ' +
((__t = ( data.xSVG() )) == null ? '' : __t) +
'\n    </div>\n    ' +
((__t = ( this.model.get('image') )) == null ? '' : __t) +
'\n  </div>\n  <div class="text-container">\n    <div class="stale-deal-title">\n      ' +
((__t = ( this.model.get('title') )) == null ? '' : __t) +
'\n    </div>\n    <h2 class="stale-deal-headline">We\'re sorry, this deal has expired.</h2>\n    <div class="stale-deal-explanation">\n      This can happen for a variety of reasons, but usually it’s because the product either sold out or has increased in price. Still interested in this offer? It may still be available at ' +
((__t = ( this.model.get('merchant').name )) == null ? '' : __t) +
' <a href="' +
((__t = ( this.model.get('go_link_href') )) == null ? '' : __t) +
'">Click Here</a>\n    </div>\n  </div>\n</div>\n';
return __p
}