import SingleUseCoupon from '../models/single_use_coupon';
import SingleUseCouponView from '../views/single_use_coupon';

// App.Controllers.SingleUseCouponController
export default {
  initialize: function () {
    App.Dispatcher.on('showSingleUseCoupon', this.showSingleUseCoupon, this);
  },

  showSingleUseCoupon: function (options) {
    new SingleUseCouponView({
      el: options.el,
      model: new SingleUseCoupon({ merchantId: options.merchantId }),
      codeLink: options.codeLink,
      goLink: options.goLink,
      goLinkText: options.goLinkText || 'Copy Code',
      errorLink: options.errorLink,
      errorLinkText: options.errorLinkText || 'View Coupons',
      getCouponText: options.getCouponText || 'Click to Reveal',
      disableWithText: options.disableWithText || 'Retrieving Code...',
      copySuccessText: options.copySuccessText || 'Code Copied',
      copyErrorText: options.copyErrorText || 'Could not copy your code. Please copy the' +
        'coupon code and click the link again'
    });
  }

};
