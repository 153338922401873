import BaseCollection from './base_collection';
import Subscription from './subscription';
import { getRoute } from '../../../javascript/src/utils/route-service'
import { omit, isEqual } from 'underscore';

// App.Collections.Subscriptions
export default BaseCollection.extend({
  url: getRoute('users-subscriptions-route'),
  model: Subscription,
  
  findByQueryAndTrigger: function (query, trigger) {
    var matching = this.filter(function (subscription) {
      var thisQuery = omit(subscription.get('query'), 'name');
      var otherQuery = omit(query, 'name');

      return subscription.get('trigger') === trigger && isEqual(thisQuery, otherQuery);
    });

    if (!!matching && matching.length) {
      return matching[0]
    }
  },

  // Sort the subscriptions by descending updated_at timestamp
  // This works because Date.parse() will return an Integer
  // representing the # of milliseconds since Unix epoch.
  comparator: function (subscription) {
    return -1 * Date.parse(subscription.get('updated_at'));
  }
});
