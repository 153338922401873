import CollectionView from '../collection_view';
import DealsPageItem from '../deals_page_item';
import Deals from '../../models/deals';

var _super = CollectionView.prototype;

// App.Views.HomePageMyDeals
export default CollectionView.extend({
  modelName: 'Deal',
  modelView: DealsPageItem,
  dealParams: {},

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.$wrapper = this.$el.parent();
    this.myDealsCollection = this.collection;
    this.fallbackCollection = new Deals();
    this.collection.off('add reset remove sync', this.render);
    this.collection.on('sync', this.render, this);
    this.fallbackCollection.on('sync', this.render, this);
    this.collection.on('error', this.rescueWithFallbackCollection, this);
  },

  render: function () {
    if (!this.collection || !this.collection.length) this.rescueWithFallbackCollection();
    _super.render.apply(this, arguments);
  },

  renderListItemViews: function () {
    this.modelViews.forEach((view, index) => {
      this.renderListItemView(view);
    }, this);
  },

  renderLoading: function () {
    return this.$el.html(this.loadingTemplate());
  },

  show: function () {
    App.Dispatcher.on('sync:userInterests', this.fetchMyDeals, this);
    this.fetchMyDeals();
  },

  hide: function () {
    App.Dispatcher.off('sync:userInterests', this.fetchMyDeals, this);
  },

  fetchMyDeals: function (options) {
    if (options && options.userInterests.length === 0) return this.rescueWithFallbackCollection();

    this.$wrapper.removeClass('blurry-mask');
    this.collection = this.myDealsCollection;
    this.collection.fetch({ data: this.dealParams });
  },

  rescueWithFallbackCollection: function () {
    this.$wrapper.addClass('blurry-mask');
    this.collection = this.fallbackCollection;
    !this.collection || !this.collection.length ? this.collection.fetch() : this.render();
  }
});
