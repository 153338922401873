import Modal from '../modal';
import ResetPassword from '../users/reset_password';
import template from '../../templates/modals/reset_password.jst.ejs';

// App.Views.ResetPasswordModal
export default Modal.extend({
  template: template,

  afterRender: function() {
    this.subView = new ResetPassword({
      el: '#modal #reset-password'
    });
    this.subView.render();
  },
});
