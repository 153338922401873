<template src="./my_deals_password.html" />
<style src="./my_deals_password.scss" lang="scss" scoped></style>

<script>
import { post } from '../../../utils/api-service';
import parseErrors from '../../../utils/error-handler';
import InfoLinks from '../../info_links/info_links.vue';

export default {
  name: 'MyDealsPassword',
  components: {
    'info-links': InfoLinks,
  },

  props: {
    email: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      password: '',
      errors: [],
    };
  },

  methods: {
    handleCancelClick() {
      this.$emit('goToStep', 2);
    },

    handleSaveUsersInterests() {
      this.$emit('saveUsersInterests');
    },

    handleSubmitSuccess(response) {
      return new Promise((resolve) => {
        const { body: { user } } = response;
        if (user) {
          resolve(user);
        } else {
          throw response;
        }
      });
    },

    handleSubmitError() {
      this.errors = this.parseErrors('Password is incorrect');
    },

    closeDrawer() {
      this.$emit('closeDrawer');
    },

    scrollToDeals() {
      const target = document.querySelector('#tab-bar');

      // If on the homepage where my deals tab lives
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }

      this.closeDrawer();
    },

    triggerInitUser(userJSON) {
      this.$dispatcher.trigger('initUserApplication', userJSON);
    },

    triggerStepCompletedEvent() {
      window.Segment.track(
        'User Flow Step Completed',
        {
          flow_name: 'mydeals_web_signup',
          step_name: 'email_identified',
          step_number: 4,
        },
      );

      window.Segment.track('User Flow Completed', { flow_name: 'mydeals_web_signup' });
    },

    onSubmit() {
      const { email, password } = this;

      post('/sessions.json', { body: { email, password } })
        .then(this.handleSubmitSuccess)
        .then(this.triggerInitUser)
        .then(this.triggerStepCompletedEvent)
        .then(this.handleSaveUsersInterests)
        .then(this.scrollToDeals)
        .catch(this.handleSubmitError);
    },
    parseErrors,
  },
};
</script>
