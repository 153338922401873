import BaseView from './base_view';
import Forms from '../lib/forms/forms';
import ToggleExpander from '../views/toggle_expander';

// App.Views.ContactUs
export default BaseView.extend({
  events: {
    'click .send-form': 'validateForm'
  },

  initialize: function () {
    new ToggleExpander({
      el: this.$el
    });
  },

  validateForm: function (e) {
    var $form = $(e.target).parents('.clean-form');

    $form.validate({

      errorElement: 'span',

      messages: {
        'help_form[extra][order_number]': {
          required: "Please enter an order number. If you're not sure, enter &quot;n/a&quot;."
        },
        'help_form[extra][order_date]': {
          required: "Please enter an order date. If you're not sure, enter &quot;n/a&quot;."
        },
        'help_form[extra][price]': {
          required: "Please enter a price. If you're not sure, enter &quot;n/a&quot;."
        },
        'help_form[extra][deal]': {
          required: "Please enter a deal. If you're not sure, enter &quot;n/a&quot;."
        },
        'help_form[extra][price_received]': {
          required: 'Please enter the price you received for the deal.'
        }
      },
      errorPlacement: Forms.Validations.errorPlacement.before
    });
  }

});
