import NavigationView from '../views/navigation';
import { detectMobileDevice } from '../lib/view_helpers';

// App.Controllers.NavigationController
export default {
  initialize: function() {
    App.Dispatcher.on('initNavigation', this.initNavigation, this);
  },

  setStickyNav: function($stickyNav) {
    var windowPosition = $(window).scrollTop();
    var windowHeight = $(window).height();
    var $leaderboardEl = $('.leaderboard');

    // how far down the page we want to see the sticky nav
    var clearanceSpace = 300;
    if ($leaderboardEl.length) {
      clearanceSpace = $leaderboardEl.height();
    }

    // how far down the page the user needs to be to not see the large logo
    var navNotVisible = 250;

    // don't do anything if window height is shorter than stickynav height
    if (!(windowHeight > $stickyNav.height())) return;

    if ( (windowPosition < clearanceSpace) && (windowPosition > navNotVisible) ) {
      $stickyNav.addClass('invisible');
    } else if (windowPosition > clearanceSpace) {
      $stickyNav.css({position: '', bottom: ''}).removeClass('invisible').addClass('fixed');
    } else {
      $stickyNav.removeClass('fixed invisible').css({position: '', bottom: ''});
    };
  },

  initNavigation: function () {
    this.navigationView = new NavigationView({
      el: 'nav.site-wide'
    });
  }
};
