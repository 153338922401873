export default function(data) {
var __t, __p = '';
__p += '<ul class="reset clearfix">\n  <li class="col tight tab large-4 medium-4 small-4 deals">\n    <a href="' +
((__t = ( this.searchPath('deals') )) == null ? '' : __t) +
'">\n      Deals (' +
((__t = ( this.model.get("counts").deals )) == null ? '' : __t) +
')\n    </a>\n  </li>\n  <li class="col tight tab large-4 medium-4 small-4 coupons">\n    <a href="' +
((__t = ( this.searchPath('coupons') )) == null ? '' : __t) +
'">\n      Coupons (' +
((__t = ( this.model.get("counts").coupons )) == null ? '' : __t) +
')\n    </a>\n  </li>\n  <li class="col tight tab large-4 medium-4 small-4 stores">\n    <a href="' +
((__t = ( this.searchPath('stores') )) == null ? '' : __t) +
'">\n      Stores (' +
((__t = ( this.model.get("counts").merchants )) == null ? '' : __t) +
')\n    </span>\n  </li>\n</ul>\n';
return __p
}