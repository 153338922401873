export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="coupon">\n  <div class="large-3 medium-3 small-3 merchant-logo-container">\n    <a href="' +
((__t = ( data.coupon.shareURL() )) == null ? '' : __t) +
'">\n      <img src="' +
((__t = ( data.coupon.get('merchant').logo.large )) == null ? '' : __t) +
'" class="large-10 medium-10 small-10" alt="">\n    </a>\n    ' +
((__t = ( data.coupon.third_party_tracking_pixel )) == null ? '' : __t) +
'\n  </div>\n  <div class="large-6 medium-6 small-8 coupon-details">\n    ';
 if (data.coupon.hasCode()) { ;
__p += '\n      <div class="coupon-flag code">\n         Code\n      </div >\n    ';
 } else { ;
__p += '\n      <div class="coupon-flag sale">\n        Sale\n      </div>\n    ';
 } ;
__p += '\n    <h3>\n      <meta item-prop="category" content="Coupon">\n      <a class="go-link title" href="' +
((__t = ( data.coupon.goLink() )) == null ? '' : __t) +
'" target="_blank">\n        ' +
((__t = ( data.coupon.get('name') )) == null ? '' : __t) +
'\n      </a>\n    </h3>\n    <div class="row expiration-info">\n      ';
 if (data.coupon.get('redemption_count')) { ;
__p += '\n        <span class="redemptions">\n          ' +
((__t = ( data.coupon.get('redemption_text') )) == null ? '' : __t) +
'\n        </span>\n        <span class="separator">|</span>\n      ';
 } ;
__p += '\n      <span>Expires ' +
((__t = ( data.coupon.get('expiration') )) == null ? '' : __t) +
'</span>\n    </div>\n  </div>\n  <div class="large-3 medium-3 small-1 coupon-link-house">\n    <div class="hide-for-small">\n      ';
 if (!data.coupon.isExpired()) { ;
__p += '\n        <div class="coupon-go-link">\n          <a href="' +
((__t = ( data.coupon.goLink() )) == null ? '' : __t) +
'" class="rounded-button go-link large-10 medium-10 ' +
((__t = ( data.coupon.hasCode() ? 'coupon-code-link' : 'coupon-link' )) == null ? '' : __t) +
'" target="_blank">\n            ' +
((__t = ( data.coupon.get('link_text') )) == null ? '' : __t) +
'\n          </a>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n    <a href="' +
((__t = ( data.coupon.goLink() )) == null ? '' : __t) +
'" class="go-link hide-for-large hide-for-medium mobile-coupon-link ">&rsaquo;</a>\n    <div class="advertiser-disclosure-link" hidden=true>\n      <a href="#disclosure">Advertiser Disclosure</a>\n    </div>\n  </div>\n</div>\n';
return __p
}