import CouponModal from '../views/modals/coupon';
import Coupon from '../models/coupon';

// App.Controllers.CouponModalsController
export default {
  initialize: function () {
    var params = App.Data.currentUrlParameters();

    if (params.m === 'coupon' && params.coupon_id) {
      this.initCouponModals(params.coupon_id);
    }
  },

  initCouponModals: function (couponId) {
    this.createCouponModalOverlay();

    var coupon = new Coupon({ id: couponId });

    this.couponModal = new CouponModal({
      el: '#coupon-overlay',
      model: coupon
    });

    coupon.fetch().then(() => {
      const closeLink = document.getElementsByClassName('modal-close-button')[0];
      if (!!closeLink) {
        closeLink.focus();
      }
    });
  },

  createCouponModalOverlay: function () {
    if (!$('#coupon-overlay').length) {
      $('<div id="coupon-overlay" class="overlay-transparent-mobile"></div>').appendTo('body');
    }
  }
};
