import BaseView from './base_view';
import Forms from '../lib/forms/forms';
import template from '../templates/email_gate.jst.ejs';

// App.Views.EmailGate
export default BaseView.extend({
  template: template,

  events: {
    'submit form': 'getFormEmail'
  },

  initialize: function(options) {
    this.options = this.options || options || {};
    this.render();
    this.setValidation();
  },

  render: function(){
    return this.$el.html(this.template());
  },

  setValidation: function() {
    var validator = this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      rules: {
        email: Forms.Validations.rules.email
      },
      messages: {
        email: Forms.Validations.messages.email
      },
      showErrors: Forms.Validations.showErrors.inline
    });

    if (this.options.email) {
      validator.showErrors({
        "email": "Sorry, " + this.options.email + " is not subscribed to our emails. Please double check the email address and try again."
      });
    }
    return validator;
  },

  getFormEmail: function(e) {
    e.preventDefault();
    var email = this.$('input[type="email"]').val();
    App.Dispatcher.trigger('emailPreferencesControllerGateShow', {email: email});
  }
});
