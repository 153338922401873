import BaseModel from './base_model';
import { defaults } from 'underscore';

var _super = BaseModel.prototype;

// App.Models.DealCount
export default BaseModel.extend({
  url: '/deals/count',

  fetch: function (options) {
    options = options || { data: {} };
    options.data = defaults(options.data, this.get('params'));

    return _super.fetch.apply(this, [options]);
  }
});
