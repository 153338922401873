import UserApplicationController from '../controllers/user_application_controller';

// App.UserHelpers
export default {
  logIn: function(email, password){
    return $.ajax({
      type: "POST",
      url: "/sessions",
      dataType: "json",
      data: {
        email: email,
      }
    }).done(this.initializeUser);
  },

  initializeUser: function(data){
    UserApplicationController.initApplication(data.user);
  }
};
