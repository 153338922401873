import HomePageMyDeals from './home_page_my_deals';
import DealTileWithBookmark from '../deal_tile_with_bookmark';

// App.Views.HomePageMyDealsGrid
export default HomePageMyDeals.extend({
  modelView: DealTileWithBookmark,
  dealParams: { limit: 90 },

  renderListItemViews: function () {
    this.$el.addClass('css-grid desktop-3 tablet-3 mobile-2');
    this.modelViews.forEach(function (view) {
      this.renderListItemView(view);
    }, this);
  }
});
