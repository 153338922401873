import CollectionItemView from './collection_item_view';
import DealAuthorInfo from '../views/deals/deal_author_info';
import DealGoLink from '../views/deal_go_link';
import ItemEngagementModule from '../views/item_engagement_module';
import Tooltip from '../views/tooltip';

import template from '../templates/deals_page_item.jst.ejs';

import fakespotSVG from '../../../assets/javascripts/templates/svgs/fakespot.jst.ejs'

var _super = CollectionItemView.prototype;

// App.Views.DealsPageItem
export default CollectionItemView.extend({
  template: template,
  tagName: 'li',

  preinitialize: function (options) {
    this.index = options.index;
  },

  attributes: function () {
    return {
      class: 'block deal',
      'data-flags': this.model.get('flag_slugs'),
      'data-id': this.model.get('id'),
      'data-item-type': 'deal',
      'data-position': this.index
    };
  },

  events: {
    'click .more-details': 'toggleDealInfo',
    'click .show-details': 'toggleExpiredDetails'
  },

  initialize: function (options) {
    this.options = options;
    _super.initialize.apply(this, arguments);
  },

  initOrphan: function () {
    this.engagementModuleView = new ItemEngagementModule({ model: this.model });

    if (this.model.isFakespotVerified()) {
      this.initFakespotTooltip();
      this.renderFakespotTooltip();
    };

    if (this.model.isBlackFridayValue()) {
      this.initBfValueTooltip();
      this.renderBfValueTooltip();
    };

    this.renderEngagementModule();
    this.showAdvertiserDisclosure(this.model.get('merchant').merchant_rules);
  },

  render: function () {
    this.$el.html(this.template({ deal: this.model, index: this.index, fakespotSVG: fakespotSVG }));
    this.createSubViews();
    this.renderSubViews();
    this.showAdvertiserDisclosure(this.model.get('merchant').merchant_rules);
    return this.$el;
  },

  createSubViews: function () {
    this.authorInfoView = new DealAuthorInfo({ model: this.model });
    this.engagementModuleView = new ItemEngagementModule({ model: this.model });
    this.dealGoLink = new DealGoLink({ model: this.model });
    if (this.model.isFakespotVerified()) {
      this.initFakespotTooltip();
    };

    if (this.model.isBlackFridayValue()) {
      this.initBfValueTooltip();
    };
  },

  initFakespotTooltip: function () {
    this.fakespotTooltip = new Tooltip({
      tooltipTrigger: '<span class="material-icons">info</span>',
      tooltipContent: 'Consumer recommended at Amazon - product reviews have been verified by Fakespot.'
    });
  },

  initBfValueTooltip: function () {
    this.bfValueTooltip = new Tooltip({
      tooltipTrigger: '<span class="material-icons">info</span>',
      tooltipContent: 'This deal reflects predicted Black Friday pricing and is worth buying now.'
    });
  },

  renderSubViews: function () {
    this.renderAuthorInfoModule();
    this.renderEngagementModule();
    this.renderDealGoLink();

    // only render fakespot tooltip if deal is fakespot verified
    if ('fakespotTooltip' in this) this.renderFakespotTooltip();
    if ('bfValueTooltip' in this) this.renderBfValueTooltip();
  },

  renderAuthorInfoModule: function () {
    this.authorInfoView.setElement(this.$('.author-info'));
    this.authorInfoView.render();
  },

  renderBfValueTooltip: function () {
    this.bfValueTooltip.setElement(this.$('.bf-value-tooltip'));
    this.bfValueTooltip.render();
  },

  renderEngagementModule: function () {
    this.engagementModuleView.setElement(this.$('.engagement-module-container'));
    this.engagementModuleView.render();
  },

  renderDealGoLink: function () {
    this.dealGoLink.setElement(this.$('.deal-go-link'));
    this.dealGoLink.render();
  },

  renderFakespotTooltip: function () {
    this.fakespotTooltip.setElement(this.$('.fakespot-tooltip'));
    this.fakespotTooltip.render();
  },

  toggleDealInfo: function () {
    var $description = this.$('.description');

    var content = $description.is(':visible') ? 'More Details' : 'Less Details';

    this.$('.more-details').text(content);
    $description.slideToggle();
  },

  toggleExpiredDetails: function () {
    var $details = this.$('.expired-details');

    var content = $details.is(':visible') ? 'Show Details' : 'Hide Details';

    this.$('.show-details').text(content);
    $details.slideToggle();
  }
});
