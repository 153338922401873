export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="view-container">\n  <h2 class=\'modal-title\'>' +
((__t = ( this.modalTitle() )) == null ? '' : __t) +
'</h2>\n  <div class="row">\n    <div class="col large-8 medium-8 large-push-2 medium-push-2 small-12 form-block">\n      <form action=\'/users\' method=\'POST\' data-remote=\'true\' class=\'sign-up-form\' data-type=\'json\' novalidat=\'novalidate\'>\n        ';
 if ( !this.hideEmail() ) { ;
__p += '\n          <ul class="reset input-list clearfix">\n            <li class="clearfix">\n              <div id="hinput" class="col large-12 medium-12 small-12 tight">\n                <input type=\'text\' name=\'name\' id=\'name\' autocomplete=\'off\'>\n              </div>\n              <div class="col large-12 medium-12 small-12 tight">\n                <label for=\'email\'>\n                  <span class=\'default-copy\'>Email</span>\n                  <span class=\'error\'></span>\n                </label>\n              </div>\n              <div class="col large-12 medium-12 small-12 tight">\n                <input type=\'email\' id=\'email\' name=\'email\' autocomplete=\'email\' />\n              </div>\n            </li>\n            <li class="clearfix gender-field">\n              <div class="col small-12 tight">Send me deals for:</div>\n              <div class="col small-12">\n                <input type=\'radio\' name=\'gender\' id=\'gender-male\' value=\'m\' autocomplete=\'sex\'>\n                <label for=\'gender-male\'>Men</label>\n                <input type=\'radio\' name=\'gender\' id=\'gender-female\' value=\'f\' autocomplete=\'sex\'>\n                <label for=\'gender-female\'>Women</label>\n              </div>\n              <div class="col small-12 tight">\n                <span id=\'gender-tooltip\'></span>\n              </div>\n            </li>\n          </ul>\n        ';
 } ;
__p += '\n        <ul class="reset horizontal centered submit-list">\n          ';
 if ( !this.hideEmail() ) { ;
__p += '\n            <li>\n              <input type=\'submit\' value=\'Sign Up\' class=\'rounded-button secondary large-12 medium-12 small-12\' data-disable-with="Sign Up">\n            </li>\n          ';
 } ;
__p += '\n          ';
 if ( !this.hideFacebook() ) { ;
__p += '\n            <li>\n              <button type="button" class="rounded-button large-12 medium-12 small-12 facebook-login-button signup">\n                <img alt="Facebook logo" src="' +
((__t = ( App.Assets.imagePath("svgs/official_facebook_white.svg") )) == null ? '' : __t) +
'">\n                Log In With Facebook\n              </button>\n            </li>\n          ';
 } ;
__p += '\n          ';
 if ( !this.hideGoogle() ) { ;
__p += '\n          <li>\n            <button type="button" class="rounded-button large-12 medium-12 small-12 google-login-button google signup">\n              Log In with Google\n            </button>\n          </li>\n          ';
 } ;
__p += '\n        </ul>\n      </form>\n    </div>\n  </div>\n  <p class="centered">\n    Already have an account?\n    <a href=\'#\' class=\'modal-link\' data-modal=\'login\'>Log in</a>\n  </p>\n  <p class="centered small row">\n    <small>\n      By signing up, you agree to our\n      <a href="/terms-of-use" target="_blank">Terms of Service</a>\n      and\n      <a href="/privacy" target="_blank">Privacy Policy</a>.\n    </small>\n  </p>\n</div>\n';
return __p
}