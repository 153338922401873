<template src="./navigation-dots.html" />
<style src="./navigation-dots.scss" lang="scss" scoped></style>

<script>
export default {
  name: 'NavigationDots',
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Function,
      required: false,
      default: () => false,
    },
    onClick: {
      type: Function,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>
