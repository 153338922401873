import BaseView from '../../base_view';
import Forms from '../../../lib/forms/forms';
import template from '../../../templates/users/account_settings/password.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.User.AccountSetting.Password
export default BaseView.extend({
  template: template,

  events: {
    'ajax:error form': 'handleFormError',
    'ajax:success form': 'handleFormSuccess',
    'click .toggle-link': 'togglePasswordLink'
  },

  initialize: function () {
    bindAll(this, 'activateFormSubmit');
    App.Dispatcher.on('user:change', this.render, this);
    this.render();
  },

  render: function () {
    this.$el.html(this.template());
    this.$('form').one('change', this.activateFormSubmit);
    this.initFormValidation();
    return this.$el;
  },

  initFormValidation: function () {
    this.$('form').validate({
      rules: {
        new_password: Forms.Validations.rules.password,
        password_confirmation: Forms.Validations.rules.password_confirmation,
        current_password: Forms.Validations.rules.current_password
      },
      messages: {
        new_password: Forms.Validations.messages.password,
        password_confirmation: Forms.Validations.messages.password_confirmation,
        current_password: Forms.Validations.messages.current_password
      },
      errorPlacement: Forms.Validations.errorPlacement.inputListBefore,
      wrapper: 'li'
    });
  },

  activateFormSubmit: function () {
    this.$('input[type=submit]')
      .removeClass('disabled')
      .removeAttr('disabled');
  },

  handleFormError: function () {
    this.$('form').validate().showErrors({
      current_password: 'That was not your current password.'
    });
  },

  handleFormSuccess: function () {
    this.render();
    Forms.Profile.setSavedMessage(this.$('.clean-form'));
  },

  togglePasswordLink: function (event) {
    var $field = $(event.target).parent('.has-visibility-toggle');
    var $input = $field.find('input');
    var newType = $input.prop('type') === 'password' ? 'text' : 'password';
    var content = $input.prop('type') === 'password' ? 'Hide' : 'Show';
    var $toggleLink = $field.find('.toggle-link');

    $toggleLink.text(content);
    $input.prop('type', newType);
  }
});
