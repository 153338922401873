import BaseModel from './base_model';
import Author from './author';
import Topic from './topic';
import { formatDistanceToNow } from 'date-fns';

const FOURTEEN_DAYS = 1000 * 60 * 60 * 24 * 14;

// App.Models.Coupon
export default BaseModel.extend({
  wrappedAttributes: {
    author: Author,
    topic: Topic
  },

  urlRoot: '/coupons',

  modelClass: 'Coupon',

  goLink: function () {
    return '/go/c/' + this.get('id');
  },

  isExpired: function () {
    return this.get('expired');
  },

  isExpiringSoon: function () {
    return this.get('expiring_soon');
  },

  shareURL: function () {
    return `${this.get('rails_env_base_url')}/coupons/${this.get('merchant').slug}`;
  },

  shareImage: function () {
    return this.get('logo');
  },

  shareName: function () {
    return this.get('title');
  },

  shareDescription: function () {
    return this.get('description') || this.get('name');
  },

  storesURL: function () {
    return `/stores/${this.get('merchant').slug}`;
  },

  isShareable: function () {
    var rules = this.get('merchant').merchant_rules;
    if (rules) {
      return !rules.disable_social_sharing;
    } else {
      return true;
    }
  },

  couponDisplayName: function () {
    return this.get('coupon_display_name');
  },

  verifiedDate: function () {
    const startsAt = new Date(this.get('starts_at'));
    const fourteenDaysAgo = new Date(new Date().getTime() - FOURTEEN_DAYS);

    if (startsAt > fourteenDaysAgo) {
      return formatDistanceToNow(startsAt, { addSuffix: true });
    }
  },

  codePreview: function () {
    var code = this.get('code');
    return code.substr(code.length - 3);
  },

  linkText: function () {
    var type = this.get('type');
    if (this.isExpired()) {
      return 'No longer available';
    } else if (type === 'printable') {
      return 'Print Coupon';
    } else if (type === 'online' && this.get('code')) {
      return 'Reveal Code';
    } else if (type === 'online') {
      return 'Click to Redeem';
    } else if (type === 'promotion') {
      return 'Go to Sale';
    }
  },

  hasCode: function () {
    return !!this.get('code');
  }
});
