export default function(data) {
var __t, __p = '';
__p += '<div class="block merchant">\n  <a href="/coupons/' +
((__t = ( this.model.get('slug') )) == null ? '' : __t) +
'">\n    <img alt="' +
((__t = ( this.model.get("name") )) == null ? '' : __t) +
' Logo" src="' +
((__t = ( this.model.get('logo') )) == null ? '' : __t) +
'" />\n  </a>\n  <div class="store-actions">\n    <a href="/coupons/' +
((__t = ( this.model.get('slug') )) == null ? '' : __t) +
'">' +
((__t = ( this.model.get("counts").coupons )) == null ? '' : __t) +
' ' +
((__t = ( data.capitalize(this.model.couponDisplayName()) )) == null ? '' : __t) +
's</a>\n    <a href="/stores/' +
((__t = ( this.model.get('slug') +'#deals' )) == null ? '' : __t) +
'">' +
((__t = ( this.model.get("counts").deals )) == null ? '' : __t) +
' Deals</a>\n    <div class="engagement-module-container"></div>\n  </div>\n</div>\n';
return __p
}