import Modal from '../modal';
import template from '../../templates/modals/subscription_pause.jst.ejs';

// App.Views.SubscriptionPauseModal
export default Modal.extend({
  template: template,

  title: function () {
    var params = App.Data.currentUrlParameters();

    switch (+params.to) {
      case 1:
        return 'Looks like this alert has already been removed.';
      default: case 0:
        return 'Success! You have paused the deal alert.';
    }
  }
});
