import BaseView from '../base_view';
import template from '../../templates/modals/coupon.jst.ejs';

// App.Views.CouponModal
export default BaseView.extend({
  template: template,

  events: {
    'click': 'handleCloseOverlay',
    'click .close-coupon-modal': 'handleCloseOverlay'
  },

  initialize: function () {
    this.listenTo(this.model, 'request', this.renderLoading);
    this.listenTo(this.model, 'sync', this.render);
    $('body').addClass('overlay-active');
  },

  render: function () {
    this.$el.html(this.template());
    this.clipboardSetup();
  },

  handleCloseOverlay: function (event) {
    if ((event.target.id === 'coupon-overlay') || $(event.currentTarget).hasClass('close-coupon-modal')) {
      this.hideOverlay();
    }
  },

  hideOverlay: function () {
    this.$el.fadeOut({
      complete: this.afterHideOverlay
    });
  },

  afterHideOverlay: function () {
    this.remove();
    $('body').removeClass('overlay-active');
  },

  clipboardSetup: function () {
    this.clipboard = new Clipboard(this.$('.copy-and-go')[0]);
    this.clipboard.on('success', this.clipboardSuccess, this);
    this.clipboard.on('error', this.clipboardError, this);
  },

  clipboardSuccess: function (event) {
    event.clearSelection();
    this.$('.clipboard-message.success').removeClass('hide');
  },

  clipboardError: function (event) {
    this.clipboard.destroy();
    this.$('.clipboard-message.error').removeClass('hide');
  }
});
