import CollectionItemView from './collection_item_view';
import EngagementModule from '../views/engagement_module';
import template from '../templates/merchant_page_item.jst.ejs';
import { capitalize } from '@legacy-js/lib/view_helpers';

var _super = CollectionItemView.prototype;

// App.Views.MerchantsPageItem
export default CollectionItemView.extend({
  template: template,
  tagName: "li",

  initialize: function (options) {
    _super.initialize.call(this);
    this.createViews();
    this.showAdvertiserDisclosure(this.model.get('merchant_rules'));
  },

  render: function() {
    this.$el.html(this.template({ index: this.index, capitalize: capitalize }));
    this.renderSubViews();
    return this.$el;
  },

  createViews: function () {
    this.engagementModuleView = new EngagementModule({
      model: this.model
    });
  },

  renderSubViews: function () {
    this.engagementModuleView.setElement(this.$el.find(".engagement-module-container"));
    this.engagementModuleView.render();
  }
});
