import BaseView from './base_view';
import template from '../templates/mini_coupon.jst.ejs';

// App.Views.MiniCouponPageItem
export default BaseView.extend({
  template: template,
  tagName: "li",

  preinitialize: function (options) {
    this.index = options.index;
  },

  attributes: function () {
    return {
      class: "listing col clearfix",
      "data-item-type": "coupon",
      "data-position": this.index,
      "data-id": this.model.get("id")
    };
  },

  render: function() {
    this.$el.html(this.template({ index: this.index }));
    this.showAdvertiserDisclosure(this.model.get('merchant').merchant_rules);
    return this.$el;
  }
});
