import CollectionView from '../collection_view';
import DealsPageItem from '../deals_page_item';

var _super = CollectionView.prototype;

// App.Views.HomePageDealsWithAds
export default CollectionView.extend({
  modelName: 'Deal',
  modelView: DealsPageItem,
  dealParams: {},

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.collection.off('add reset remove sync', this.render);
    this.collection.once('sync', this.render, this);
  },

  render: function () {
    _super.render.apply(this, arguments);
  },

  renderLoading: function () {
    return this.$el.html(this.loadingTemplate());
  },

  show: function () {
    if (this.collection.length > 0) return;
    this.collection.fetch({ data: this.dealParams });
  },

  hide: function () {
    // Do nothing
  },
});
