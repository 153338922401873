import { hashFromQueryString } from '../lib/view_helpers';
import { bindAll, debounce } from 'underscore';

// App.Controllers.Social
export default {
  initialize: function () {
    bindAll(
      this,
      'handleFacebookShareClick',
      'handleTwitterShareClick',
      'handleGoogleShareClick'
    );

    App.Dispatcher.on('initSocialShare', this.initSocialShare, this);
  },

  initSocialShare: function () {
    $(document).on('click', 'a.social.facebook', this.handleFacebookShareClick);
    $(document).on('click', 'a.social.twitter', this.handleTwitterShareClick);
    $(document).on('click', 'a.social.google-plus', this.handleGoogleShareClick);
  },

  //
  // Handle links with Facebook's share url format
  // https://www.facebook.com/sharer/sharer.php?u={urlEncodedLink}
  //
  handleFacebookShareClick: function (e) {
    e.preventDefault();

    var linkQueryStr = e.currentTarget.href.split('?')[1];
    var linkQuery = hashFromQueryString(linkQueryStr);

    this.triggerFbUi(linkQuery.u);
  },

  //
  // Handle links with Twitter's share url format
  // https://twitter.com/share
  //    ?url={urlEncodedLink}
  //    &via=bradsdeals
  //    &hashtags=example%2Cdemo
  //    &text={urlEncodedText}
  //
  handleTwitterShareClick: function (e) {
    e.preventDefault();

    this.triggerWindowOpen({
      href: e.currentTarget.href,
      width: 600,
      height: 450
    });
  },

  //
  // Handle links with Google's share url format
  // https://plus.google.com/share?url={urlEncodedLink}
  //
  handleGoogleShareClick: function (e) {
    e.preventDefault();

    this.triggerWindowOpen({
      href: e.currentTarget.href,
      width: 400,
      height: 500
    });
  },

  //
  // Open a new window with the desired height, width, and href
  // Must be called from a click action or will be browser blocked
  // Add a debounce to prevent users from erroneously double-clicking
  //
  triggerWindowOpen: debounce(function (options) {
    var width = options.width || 600;
    var height = options.height || 500;

    var windowOptions = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes';

    var left = Math.round((window.screen.width * 0.5) - (width * 0.5));
    var top = 0;

    if (window.screen.height > height) {
      top = Math.round((window.screen.height * 0.5) - (height * 0.5));
    }

    windowOptions += ',width=' + width + ',height=' + height + ',left=' + left + ',top=' + top;

    window.open(options.href, '', windowOptions);
  }, 500, true),

  triggerFbUi: debounce(function (linkUrl) {
    FB.ui({
      method: 'feed',
      link: linkUrl,
      display: 'popup'
    });
  }, 500, true)
};
