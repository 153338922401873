import ErrorNotification from '../views/notifications/error_notification';
import InfoNotification from '../views/notifications/info_notification';
import SuccessNotification from '../views/notifications/success_notification';

// App.Controllers.NotificationsController
export default {
  initialize: function () {
    App.Dispatcher.on('addError', this.addError, this);
    App.Dispatcher.on('addInfo', this.addInfo, this);
    App.Dispatcher.on('addSuccess', this.addSuccess, this);

    this.$el = $('.notification-house ul');
  },

  addNotification: function (message, view, duration) {
    if (this.$el.length > 0) {
      var notification = new view({ msg: message, timeout: duration });
      this.$el.append(notification.render());
    }
  },

  addError: function (errorMessage, duration) {
    this.addNotification(errorMessage, ErrorNotification, duration);
  },

  addInfo: function (infoMessage, duration) {
    this.addNotification(infoMessage, InfoNotification, duration);
  },

  addSuccess: function (successMessage, duration) {
    this.addNotification(successMessage, SuccessNotification, duration);
  }

};
