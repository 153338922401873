export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="create-alert-container ' +
((__t = ( this.isSubscribed() ? "confirm" : "" )) == null ? '' : __t) +
'">\n  ';
 if (this.isSubscribed()) { ;
__p += '\n    <div class="confirm-message">\n      <h5>' +
((__t = ( data.title )) == null ? '' : __t) +
'</h5>\n      <div class="confirmation-message">' +
((__t = ( this.confirmationMessage() )) == null ? '' : __t) +
'</div>\n    </div>\n  ';
 } else { ;
__p += '\n    <div class="search-content">\n      <h5>' +
((__t = ( this.label )) == null ? '' : __t) +
'</h5>\n      <strong class="search-string">' +
((__t = ( this.name() )) == null ? '' : __t) +
'</strong>\n    </div>\n    <div class="button-container">\n      <span class="rounded-button subscription-create">\n        ' +
((__t = ( data.buttonText )) == null ? '' : __t) +
'\n      </span>\n    </div>\n  ';
 } ;
__p += '\n</div>\n';
return __p
}