import CollectionView from './collection_view';
import DealsPageItem from './deals_page_item';

var _super = CollectionView.prototype;

// App.Views.Deals
export default CollectionView.extend({
  modelName: 'Deal',
  modelView: DealsPageItem,

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.render();
  }
});
