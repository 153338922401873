import { bindAll } from 'underscore';
import BaseView from '../base_view';
import template from '../../templates/users/user_badge.jst.ejs';

// App.Views.UserBadge
export default BaseView.extend({
  template: template,

  events: {
    'click .menu-trigger': 'handleMenuTriggerClick',
    'blur .dropdown a': 'handleDropdownBlur',
  },

  initialize: function () {
    bindAll(this, 'handleCloseUserDropdown');
    this.render();
    this.registerEvents();
  },

  registerEvents: function () {
    if (this.model.isFullUser()) {
      App.Dispatcher.on('userProfile:updated', this.render, this);
    }
  },

  render: function () {
    if (this.model.loggedIn()) this.$el.html(this.template());
    this.$dropdown = this.$('.has-submenu');
    this.$menuTrigger = this.$('.menu-trigger');
  },

  handleMenuTriggerClick: function () {
    this.$dropdown.toggleClass('open');
    $('body').toggleClass('overlay-active');
    this.$menuTrigger.attr('aria-expanded', this.$dropdown.hasClass('open').toString());
    this.registerCloseEvent();
  },

  registerCloseEvent: function () {
    if (this.$dropdown.hasClass('open')) {
      $(document).on('click', this.handleCloseUserDropdown);
    }
  },

  handleCloseUserDropdown: function (event) {
    const isDropdownTarget = $(event.target).is('.has-submenu *, .has-submenu');
    if (this.$dropdown.hasClass('open') && !isDropdownTarget) {
      this.handleMenuTriggerClick();
      this.deregisterCloseEvent();
    }
  },

  deregisterCloseEvent: function () {
    $(document).off('click', this.handleCloseUserDropdown);
  },

  // this function is solely for keyboard users
  handleDropdownBlur: function (event) {
    const $lastItem = $('.dropdown a').last();
    if (event.target.innerHTML !== $lastItem.text()) return;
    this.handleMenuTriggerClick();
  }
});
