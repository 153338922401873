<template src="./interests.html" />
<style src="./interests.scss" lang="scss" scoped></style>

<script>
import { put } from '../../../utils/api-service';
import CheckBox from '../../check-box/check-box.vue';

export default {
  components: {
    CheckBox,
  },
  data() {
    return {
      pendingUserInterests: [],
    };
  },
  props: {
    interests: {
      type: Array,
      required: true,
      default: [],
    },
    userInterests: {
      type: Array,
      required: true,
      default: [],
    },
  },
  mounted() {
    this.pendingUserInterests = [...this.$props.userInterests];
    this.$dispatcher.on('sync:userInterests', this.updateUserInterests);
  },
  beforeDestroy() {
    this.$dispatcher.off('sync:userInterests', this.updateUserInterests);
  },
  methods: {
    onSubmit() {
      const interests = this.pendingUserInterests;
      return put('/api/users/interests', { body: { users_interests: { interests } } })
        .then(this.handleSaveSuccess, this.handleSaveError)
        .finally(this.handleFinishedRequest);
    },
    interestsChecked(userInterests, interest) {
      return userInterests.includes(interest);
    },
    setUserInterests(interestSlug, checked) {
      const idx = this.pendingUserInterests.indexOf(interestSlug);
      if (checked && idx === -1) {
        this.pendingUserInterests.push(interestSlug);
      } else if (!checked && idx !== -1) {
        this.pendingUserInterests.splice(idx, 1);
      }
    },
    handleSaveSuccess() {
      this.$emit('updateInterests', this.pendingUserInterests);
      this.$emit('close');
      const mydealsTab = document.getElementById('my-deals-tab');
      if (mydealsTab) {
        mydealsTab.scrollIntoView({ behavior: 'smooth' })
      }
    },
    handleSaveError() {
      console.error('Something went wrong.');
    },
    updateUserInterests(obj) {
      this.pendingUserInterests = obj.userInterests;
    },
  },
};
</script>
