import BaseView from './base_view';
import BlogPostTiles from '../views/blog/blog_post_tiles';
import template from '../templates/expandable_blog_posts.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.ExpandableBlogPosts
export default BaseView.extend({
  template: template,

  events: {
    'click .more-posts': 'handlePostFetch'
  },

  initialize: function () {
    bindAll(this, 'enableButton');
    this.collection.on('sync', this.render, this);
    this.render();
  },

  render: function () {
    this.$el.html(this.template());
    if (this.collectionView) {
      this.collectionView.remove();
    }

    this.createViews();

    return this.$el;
  },

  createViews: function () {
    this.collectionView = new BlogPostTiles({
      el: '#blog-post-collection',
      collection: this.collection
    });
    this.collectionView.render();
  },

  handlePostFetch: function () {
    this.disableButton();
    this.collection.fetchNext().fail(this.enableButton);
  },

  enableButton: function () {
    this.$('.more-posts').removeClass('disabled');
  },

  disableButton: function () {
    this.$('.more-posts').addClass('disabled');
  }
});
