import BaseModel from './base_model';
import Engagements from './engagements';
import Engagement from './engagement';
import Subscriptions from './subscriptions';
import UserProfile from './user_profile';
import { bindAll } from 'underscore';

// App.Models.User
export default BaseModel.extend({
  urlRoot: '/users.json',
  userAction: $.Deferred(),
  wrappedAttributes: {
    engagements: Engagements,
    user_profile: UserProfile
  },

  initialize: function () {
    bindAll(this, 'handleEngagementSave');
    this.populateEngagements();
    this.populateSubscriptions();
    this.registerEvents();
  },

  registerEvents: function () {
    App.Dispatcher.on('bdEngagement:save', this.handleEngagementSave);
    this.on('change', this.populateEngagements, this);
    this.on('change:id', this.populateSubscriptions, this);
    this.on('change:user_profile', this.wrapProfile);
    this.listenTo(this.get('engagements'), 'sync', this.resolveQueuedAction);
    this.listenTo(this.get('subscriptions'), 'sync', this.resolveQueuedAction);
  },

  handleEngagementSave: function (options) {
    var newEngagement = new Engagement({
      user_id: this.get('id'),
      item_id: options.model.get('id'),
      item_type: options.model.modelClass.toLowerCase(),
      engagement_type: 'save'
    });

    this.get('engagements').add(newEngagement);

    newEngagement.save().done(function () {
      App.Dispatcher.trigger('engagement:saved', newEngagement, options.model);
    });
  },

  loggedIn: function () {
    return !this.isNew();
  },

  isFullUser: function () {
    return !!this.get('user_profile');
  },

  isSubscriber: function () {
    return this.loggedIn() && !this.isFullUser();
  },

  isFacebookUser: function () {
    return !!this.get('facebook_user');
  },

  isGoogleUser: function () {
    return this.get('google_user');
  },

  isConfirmed: function () {
    return !!this.get('confirmed');
  },

  hasSubscription: function (subscription) {
    return !!this.get('subscriptions').findByQueryAndTrigger(
      subscription.get('query'),
      subscription.get('trigger')
    );
  },

  populateEngagements: function () {
    if (!this.get('engagements')) {
      this.set('engagements', new Engagements());
    }

    if (this.isFullUser() && this.get('engagements').isEmpty()) {
      this.get('engagements').fetch();
    }
  },

  populateSubscriptions: function () {
    if (!this.get('subscriptions')) {
      this.set('subscriptions', new Subscriptions());
    }

    if (this.loggedIn() && (!this.get('subscriptions') || !this.get('subscriptions').length)) {
      return this.get('subscriptions').fetch({
        data: {
          user_id: this.get('id')
        }
      });
    } else {
      return $.Deferred().resolve();
    }
  },

  wrapProfile: function (user, attributes) {
    if (!attributes) return;
    if (UserProfile == attributes.constructor) return;
    var previousProfile = this.previous('user_profile');
    if (previousProfile) {
      this.set({ user_profile: previousProfile });
      previousProfile.set(attributes);
    } else {
      this.set({ user_profile: new UserProfile(attributes) });
      this.trigger('logUp');
    }
  },

  resolveQueuedAction: function () {
    if (this.isFullUser()) {
      this.userAction.resolve();
    }
  },

  facebookConnectStatus: function () {
    return (this.get('facebook_user') ? 'Connected' : 'Not Connected');
  },

  birthday: function () {
    return this.get('user_profile').get('birthday');
  },

  avatar: function () {
    return this.get('user_profile').get('avatar');
  },

  displayName: function () {
    var fName = this.truncedFirstName();
    var lName = this.lastInitial();

    if (fName && lName) return fName + ' ' + lName;

    return fName || lName || 'Friend';
  },

  firstName: function () {
    if (!this.isFullUser()) return '';

    return this.get('user_profile').get('first_name') || '';
  },

  truncedFirstName: function () {
    var fName = this.firstName();

    return fName.length > 13 ? fName.substring(0, 13) + '&hellip;' : fName;
  },

  lastName: function () {
    if (!this.isFullUser()) return '';

    return this.get('user_profile').get('last_name') || '';
  },

  lastInitial: function () {
    var lName = this.lastName();

    return lName.length > 0 ? lName[0] + '.' : lName;
  },

  location: function () {
    return this.get('user_profile').get('location');
  },

  email: function () {
    return this.get('email');
  },

  createdAt: function () {
    return this.get('created_at');
  },

  resendConfirmation: function () {
    return $.ajax({
      url: `/me/email/${this.get('md5_hash')}/resend_confirm`,
      type: 'POST',
    });
  }
});
