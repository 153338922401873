import CollectionItemView from './collection_item_view';
import DealAuthorInfo from './deals/deal_author_info';
import template from '../templates/comments_page_item.jst.ejs';
import confirmDeleteTemplate from '../templates/comments_confirm_delete.jst.ejs';
import { bindAll } from 'underscore';
import user from '../user-data/user';

// App.Views.CommentsPageItem
export default CollectionItemView.extend({
  template: template,
  tagName: 'li',

  preinitialize: function (options) {
    this.options = options || {};
  },

  attributes: function () {
    return {
      class: this.determineClassName(),
    };
  },

  initialize: function () {
    bindAll(this, 'confirmDelete', 'deleteComment', 'render');
    this.createViews();
    this.render();
    this.registerEvents();
  },

  render: function () {
    this.$el.html(this.template({ index: this.index }));
    this.renderSubViews();
    return this.$el;
  },

  registerEvents: function () {
    this.$el.on('click', '.delete', this.confirmDelete);
    this.$el.on('click', '.yes-delete', this.deleteComment);
    this.$el.on('click', '.no-delete', this.render);
  },

  createViews: function () {
    this.authorInfoView = new DealAuthorInfo({
      model: this.model
    });
  },

  renderSubViews: function () {
    this.authorInfoView
      .setElement(this.$el.find('.author-info'))
      .render();
  },

  determineClassName: function () {
    var classes = 'comment clearfix';

    if (this.isPeekContent()) {
      classes += ' mini';
    }else {
      classes += ' not-mini';
    };

    if (this.model.get('by_editor')) {
      classes += ' editor';
    };

    return classes;
  },

  deleteComment: function () {
    this.model.destroy();
  },

  confirmDelete: function (e) {
    $(e.target)
      .hide()
      .after(confirmDeleteTemplate());
  },

  isDeleteable: function () {
    return (this.model.get("author").get("id") === user.id && !this.options.additionalOptions.peekContent)
  },

  isPeekContent: function () {
    return this.options.additionalOptions.peekContent;
  }
});
