import BaseView from '../base_view';
import Password from './account_settings/password';
import Profile from './account_settings/profile';
import Social from './account_settings/social';
import template from '../../templates/users/account_settings.jst.ejs';

// App.Views.User.AccountSettings
export default BaseView.extend({
  template: template,

  initialize: function () {
    this.render();
    this.createSubViews();
  },

  render: function () {
    this.$el.html(this.template());
    return this.$el;
  },

  createSubViews: function () {
    this.profile = new Profile({
      el: this.$('#user-profile'),
      model: this.model
    });

    this.password = new Password({
      el: this.$('#user-password'),
      model: this.model
    });

    this.social = new Social({
      el: this.$('#user-social'),
      model: this.model
    });
  }
});
