export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<section class="row preferences grid-15-large-12 small-12 grid-15-medium-12">\n  <div class="preferences-header">\n    ';
 if (this.model.get('subscribe')) { ;
__p += '\n      <input id="newsletter-toggle" class="toggle-switch" type="checkbox" name="newsletter-toggle">\n    ';
 } else { ;
__p += '\n      <input id="newsletter-toggle" class="toggle-switch disabled-toggle" type="checkbox" name="newsletter-toggle" disabled>\n    ';
 } ;
__p += '\n    <label for="newsletter-toggle"></label>\n    <h2 class="header-text">Newsletter Emails</h2>\n  </div>\n  <p class="email-description">The best deals discovered and delivered to your inbox.</p>\n  ';
 if (!this.model.isNewsletterSubscribed()) { ;
__p += '\n    <p class="email-description turned-off">Newsletter Emails have been turned off.</p>\n  ';
 } ;
__p += '\n  <form>\n    <fieldset class="oversized-radios">\n      <p class="main-label">Frequency:</p>\n      <ul class="reset">\n        ';
 this.frequencies.forEach(function (frequency) { ;
__p += '\n          <li>\n            <input type=\'radio\' name=\'frequency\' value="' +
((__t = ( frequency.val )) == null ? '' : __t) +
'" id="' +
((__t = ( frequency.id )) == null ? '' : __t) +
'">\n            <label for="' +
((__t = ( frequency.id )) == null ? '' : __t) +
'">' +
((__t = ( frequency.label )) == null ? '' : __t) +
'</label>\n            <div class=\'check\'></div>\n          </li>\n        ';
 }); ;
__p += '\n      </ul>\n    </fieldset>\n  </form>\n</section>\n';
return __p
}