import BaseCollection from './base_collection';
import NewsletterType from './newsletter_type';

// App.Collections.NewsletterTypes
export default BaseCollection.extend({
  model: NewsletterType,

  url: function () {
    // I don't think this routes exists anymore?
    return "/"
  }
});
