import { queryStringFromHash } from '../../../assets/javascripts/lib/view_helpers'

const ROUTES = {
  'acquisitions-deal': `/landing/deals`,
  'collections': `/collections/`,
  'deals': `/deals`,
  'my-deals': `/my-deals`,
  'newest-deals': `/newest-deals`,
  'users-subscriptions-route': `/users/subscriptions`,
  'user-profiles': `/user_profiles`,
  'users': `/users`,
  'subscription-route': `/subscriptions`
}

const buildRouteFunc = (route) => {
  return (urlParameters) => {
    let queryParams = queryStringFromHash(urlParameters || {})
    return `${route}?${queryParams}`
  }
}

const getRoute = (route) => {
  let fetchedRoute = ROUTES[route];

  if (fetchedRoute === undefined) {
    throw new Error('Route is not a defined');
  }

  return fetchedRoute;
}

export { buildRouteFunc, getRoute };
