import Backbone from 'backbone';

// App.Lib.PageItems
export default function (CollectionClass) {
  var _this = this;

  if (!CollectionClass || CollectionClass.prototype.get !== Backbone.Collection.prototype.get) {
    throw new Error('CollectionClass must be a Backbone Collection');
  }

  var collection = new CollectionClass();

  _this.get = function (itemId) {
    return collection.get(itemId);
  };

  _this.add = function (data) {
    return collection.add(data);
  };

  _this.registerCollection = function (watchedCollection) {
    handleCollectionSync(watchedCollection);
    watchedCollection.on('sync', handleCollectionSync);
    watchedCollection.on('add', handleCollectionAdd);
  };

  // PRIVATE

  function handleCollectionSync(newCollection) {
    collection.add(newCollection.models);
  }

  function handleCollectionAdd(newModel) {
    collection.add(newModel);
  }

  return _this;
};
