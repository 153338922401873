import { put } from '../../../javascript/src/utils/api-service';

const userProfile = {
  birthday: undefined,
  year: undefined,
  month: undefined,
  day: undefined,
  firstName: undefined,
  lastName: undefined,
  location: undefined,
  avatar: undefined,
  userId: undefined,
  createdAt: undefined,

  init: (profileData) => {
    userProfile.birthday = profileData.birthday;
    userProfile.firstName = profileData.first_name;
    userProfile.lastName = profileData.last_name;
    userProfile.location = profileData.location;
    userProfile.avatar = profileData.avatar;
    userProfile.userId = profileData.user_id;
    userProfile.createdAt = profileData.created_at;

    if (userProfile.birthday) {
      [userProfile.year, userProfile.month, userProfile.day] = userProfile
        .birthday
        .split('-')
        .map((s) => parseInt(s, 10));
    }

    window.App.Dispatcher.trigger('userProfile:updated');
  },

  /**
   *
   * @returns {object} - the `birthday`, `firstName`, `lastName`, and `location` properties of the userProfile
   */
  toJSON: () => ({
    birthday: userProfile.birthday,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    location: userProfile.location,
  }),

  /**
   * Retrieve the value of a property on the userProfile
   *
   * @deprecated prefer direct object access
   * @param key string - the property you want to retrieve
   */
  get: (key) => userProfile[key],

  /**
   * Update the userProfile via a `PUT` call
   *
   * @param {object} attributes - userProfile properties to be saved
   * @emits `userProfile:updated`
   */
  save: (attributes) => {
    const params = { ...attributes };
    // The birthday is not built in the provided params, so we build it ourselves
    if (attributes.day && attributes.month && attributes.year) {
      params.birthday = `${attributes.year}-${attributes.month}-${attributes.day}`;
    }

    put('/user_profiles', { body: params })
      .then(() => {
        userProfile.firstName = params.first_name;
        userProfile.lastName = params.last_name;
        userProfile.location = params.location;
        userProfile.day = params.day;
        userProfile.month = params.month;
        userProfile.year = params.year;
        userProfile.birthday = params.birthday;

        window.App.Dispatcher.trigger('userProfile:updated', params);
      });
  },
};

export default userProfile;
