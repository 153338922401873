import BaseView from '../base_view';
import template from '../../templates/coupons/coupon_go_link.jst.ejs';

// App.Views.CouponGoLink
export default BaseView.extend({
  template: template,

  render: function () {
    this.$el.html(this.template());
    return this.$el;
  },

  wrapperClass: function () {
    if (this.model.get('code')) {
      return 'coupon-code-link';
    } else {
      return 'coupon-link';
    }
  }
});
