import Subscriptions from '../models/subscriptions';
import Engagement from '../models/engagement';
import Engagements from '../models/engagements';
import userProfile from './user-profile';
import { post } from '../../../javascript/src/utils/api-service';

/**
 * Does nothing.
 */
const noOp = () => {};

/**
 * An object used to stash a function call for later invocation.
 */
const delayedFunction = {
  /**
   * Will call doneFn and then reset its value via `delayedFunction.reset()`
   * @see `delayedFunction.reset`
   */
  invoke: () => {
    delayedFunction.doneFn();
    delayedFunction.reset();
  },

  /**
   * Will reset doneFn to noOp without calling doneFn
   */
  reset: () => { delayedFunction.doneFn = noOp; },

  /**
   * Property to hold the delayed function
   */
  doneFn: noOp,

  /**
   * Store a function call to be executed later via the invoke function
   *
   * @param {Function} cbFn - the function who's execution should be delayed
   */
  stash: (cbFn) => { delayedFunction.doneFn = cbFn; },
};

const registerUserEvents = (incomingUser) => {
  window.App.Dispatcher.on('bdEngagement:save', incomingUser.handleEngagementSave);
  window.App.Dispatcher.on('userProfile:updated', incomingUser.handleUserProfileUpdate);
};

/**
 * The User Data Object. Contains all properties of the user as well as helpful functions
 * for interacting with the user data or determining common user states.
 *
 * Changes made to this object are **GLOBAL** and reflected in the `user` import of any
 * consumers of this object.
 */
const user = {
  userProfile: undefined,
  engagements: undefined,
  subscriptions: undefined,
  id: undefined,
  facebookUser: undefined,
  googleUser: undefined,
  email: undefined,
  createdAt: undefined,
  md5Hash: undefined,
  reactivated: undefined,
  gender: undefined,
  confirmed: undefined,

  /**
   * Initialize the user object. If no userData is provided the user will simply
   * dispatch a change event.
   *
   * Will new up Backbone collections for engagements and subscriptions
   *
   * @param {object} userData - an object containing the data about the user
   * @emits `user:change` emitted on all invocations
   * @emits `user:logUp` if `userData.user_profile_created` is true
   */
  init: (userData) => {
    if (userData) {
      userProfile.init(userData?.user_profile || {});

      user.id = userData.id || userData.user_id;
      user.facebookUser = userData.facebook_user;
      user.googleUser = userData.google_user;
      user.email = userData.email;
      user.createdAt = userData.created_at;
      user.md5Hash = userData.md5_hash || userData.md5;
      user.reactivated = userData.reactivated;
      user.gender = userData.gender;
      user.confirmed = userData.confirmed;

      user.userProfile = userProfile;

      if (!user.engagements) {
        user.engagements = new Engagements();
      }
      user.fetchEngagements();
      if (!user.subscriptions) {
        user.subscriptions = new Subscriptions();
      }
      user.fetchSubscriptions();

      user.registerEvents();

      if (userData.user_profile?.user_id) {
        window.App.Dispatcher.trigger('user:logUp');
      }
    }

    window.App.Dispatcher.trigger('user:change');
  },

  /**
   * Object containing functions to interact with the delayedFunction API.
   * Interactions are wrapped in functions to ensure the order of operations on
   * the delayedFunction.
   *
   * The Delayed function will be executed after a successful fetch of `user.engagements`
   * or `user.subscriptions`. These are the chosen exection points to emulate past behavior
   * as they occur when the user is updated (i.e. log in/log up)
   *
   * Loop can be broken by setting an action using enqueue and then calling execute
   * once you need to.
   *
   * @see delayedFunction for the underlying API.
   * @see `user.fetchSubscriptions`
   * @see `user.fetchEngagements`
   */
  queuedAction: {

    /**
     *
     * @param {Function} cbFn - the function you want to have executed
     */
    enqueue: (cbFn) => {
      delayedFunction.stash(cbFn);
    },

    /**
     * Will execute the enqueud action if the user is a full user
     */
    execute: () => {
      if (user.isFullUser()) {
        delayedFunction.invoke();
      }
    },

    /**
     * Will clear the enqueued action. The function currently enqueued will
     * not be called and will be reset to a NoOp.
     */
    clear: () => {
      delayedFunction.reset();
    },
  },

  fetchEngagements: () => {
    if (user.isFullUser() && user.engagements.isEmpty()) {
      user.engagements.fetch()
        .then(() => user.queuedAction.execute())
        .catch(() => user.queuedAction.clear());
    }
  },

  fetchSubscriptions: () => {
    if (user.loggedIn() && (user.subscriptions.isEmpty())) {
      return user.subscriptions.fetch({
        data: {
          user_id: user.id,
        },
        reset: true
      });
    }
    return user.queuedAction.execute();
  },

  handleEngagementSave: (options) => {
    const newEngagement = new Engagement({
      user_id: user.id,
      item_id: options.model.get('id'),
      item_type: options.model.modelClass.toLowerCase(),
      engagement_type: 'save',
    });

    user.engagements.add(newEngagement);

    newEngagement.save().then(() => {
      window.App.Dispatcher.trigger('engagement:saved', newEngagement, options.model);
    });
  },

  handleUserProfileUpdate: (updateParams) => {
    user.gender = updateParams?.gender;
  },

  /**
   * Should **not** be invoked by outside access.
   * Calls helper function to bind some user functions to App.Dispatcher events.
   * Sets itself to a NoOp after invocation.
   *
   * @todo make this private or something?
   */
  registerEvents: () => {
    registerUserEvents(user);
    user.registerEvents = noOp;
  },

  /**
   * @deprecated prefer direct object access
   * @param key string - the property you want to retrieve
   */
  get: (key) => user[key],

  /**
   *
   * @returns {boolean} if the user has an `id` property set
   */
  loggedIn: () => !!user.id,

  /**
   *
   * @returns whether or not the user's userProfile has a userId
   */
  isFullUser: () => !!user.userProfile.userId,

  /**
   *
   * @returns {boolean} if the user has an id but no userProfile.userId
   * @see `user.loggedIn`
   * @see `user.isFullUser`
   */
  isSubscriber: () => user.loggedIn() && !user.isFullUser(),

  /**
   *
   * @returns if the facebookUser property is set
   */
  isFacebookUser: () => !!user.facebookUser,

  /**
   *
   * @returns if the googleUser property is set
   */
  isGoogleUser: () => !!user.googleUser,

  /**
   *
   * @returns if the confirmed property is set
   */
  isConfirmed: () => !!user.confirmed,

  /**
   *
   * @returns {string} 'Connected' if the user is a Facebook User else 'Not Connected'
   * @see `user.isFacebookUser`
   */
  facebookConnectStatus: () => (user.isFacebookUser() ? 'Connected' : 'Not Connected'),

  /**
   *
   * @returns the birthday property of the `userProfile` if set, else undefined
   */
  birthday: () => user.userProfile?.birthday,

  /**
   *
   * @returns the avatar property of the `userProfile` if set, else undefined
   */
  avatar: () => user.userProfile?.avatar,

  /**
   *
   * @returns the first name property of the `userProfile` if the user is a full user, else empty string
   * @see `user.isFullUser`
   */
  firstName: () => (user.isFullUser() ? user.userProfile.firstName : ''),

  /**
   *
   * @returns the last name property of the `userProfile` if the user is a full user, else empty string
   * @see `user.isFullUser`
   */
  lastName: () => (user.isFullUser() ? user.userProfile.lastName : ''),

  /**
   *
   * @returns the location property of the `userProfile`
   */
  location: () => user.userProfile?.location,

  /**
   *
   * @returns {string} A truncated first name, last initial, or 'Friend' depending on available user information
   */
  displayName: () => {
    const firstName = user.firstName();
    const lastName = user.lastName();

    const truncatedFirstName = firstName.length > 13 ? `${firstName.substring(0, 13)}&hellip;` : firstName;
    const lastInitial = lastName.length > 0 ? `${lastName[0]}.` : lastName;

    if (firstName && lastName) {
      return `${truncatedFirstName} ${lastInitial}`;
    }

    return truncatedFirstName || lastInitial || 'Friend';
  },

  /**
   * Attempt to tell the backend to resend the user's confirmation email
   *
   * @returns {Promise} the promise returned by the POST call made by apiService to the resend confirmation path
   * @see `apiService.post`
   */

  resendConfirmation: () => post(`/me/email/${user.md5Hash}/resend_confirm`),

};

export default user;
