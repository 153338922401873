import BaseCollection from './base_collection';
import Deal from './deal';
import { queryStringFromHash } from '../../javascripts/lib/view_helpers'

// App.Collections.DealsSearch
export default BaseCollection.extend({
  model: Deal,

  url: function () {
    let api_search_path = '/api/search'
    let queryParams = queryStringFromHash(this.options.query || {})

    return `${api_search_path}?types=deals&${queryParams}`
  },

  initialize: function (models, options) {
    this.options = options || {};
  },

  parse: function (data) {
    if (data.counts) {
      this.total = data.counts.deals;
    }

    if (data.records) {
      return data.records.deals;
    }

    return data;
  }
});
