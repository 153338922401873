import BaseView from './base_view';
import template from '../templates/search_pagination.jst.ejs';
import loadingTemplate from '../templates/loading.jst.ejs';

// App.Views.SearchPagination
export default BaseView.extend({
  template: template,

  initialize: function (options) {
    this.options = this.options || options || {};

    if (this.model) {
      this.model[this.options.mainModel]().on("all", this.render, this);
      this.model.on('change:loading', this.render, this);
      this.render();
    }
  },

  render: function(){
    this.$el.html(this.template({ loadingTemplate: loadingTemplate() }));
  },

  hasMorePages: function(){
    return (
      this.model.get("records")[this.options.mainModel].length <
      this.model.get("counts")[this.options.mainModel]
    );
  }
});
