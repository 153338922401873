export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<section>\n  ';
 if (this.options.hideAvatar) { ;
__p += '\n    <div class="reset post-information no-image">\n      <strong class="author-name">\n        ' +
((__t = ( data.item.get("author_text") )) == null ? '' : __t) +
'\n      </strong>\n      ';
 if(data.item.get("starts_at")){ ;
__p += '\n        <span class="posted-time" data-created-at="' +
((__t = ( data.item.get('starts_at') )) == null ? '' : __t) +
'"></span>\n      ';
 } ;
__p += '\n    </div>\n  ';
 } else { ;
__p += '\n    <div class="author-avatar">\n      <img src="' +
((__t = ( this.authorAvatar() )) == null ? '' : __t) +
'">\n    </div>\n    <ul class="reset post-information">\n      <li class="author-name">\n        ' +
((__t = ( data.item.get("author_text") )) == null ? '' : __t) +
'\n        ';
 if(data.item.get("by_editor")) { ;
__p += '\n          <span class="editor-text"> - Editor at Brad\'s Deals</span>\n        ';
 } ;
__p += '\n      </li>\n      ';
 if(data.item.get("starts_at")) { ;
__p += '\n        <li class="posted-time" data-created-at="' +
((__t = ( data.item.get('starts_at') )) == null ? '' : __t) +
'"></li>\n      ';
 } ;
__p += '\n    </ul>\n  ';
 } ;
__p += '\n</section>\n';
return __p
}