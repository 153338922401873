import BaseModel from './base_model';
import Brands from './brands';
import Coupons from './coupons'
import Deals from './deals'
import Merchants from './merchants'
import { bindAll, isEqual } from 'underscore';
import { capitalize } from '@legacy-js/lib/view_helpers';

var _super = BaseModel.prototype;

const COLLECTIONS = { coupons: Coupons, deals: Deals, merchants: Merchants };

// App.Models.Search
export default BaseModel.extend({
  DEFAULT_TYPE: 'deals',
  VALID_FILTERS: ['brands', 'categories', 'flags', 'merchants'],

  urlRoot: '/api/search',
  defaults: {
    'loading': false
  },

  initialize: function () {
    _super.initialize.apply(this, arguments);
    bindAll(this, 'parseAndMerge');
  },

  parse: function (data) {
    var wrappedRecords = {};

    if (data.records) {
      Object.keys(COLLECTIONS).forEach(function (key) {
        var Class = capitalize(key);
        var newCol = new COLLECTIONS[key](data.records[key]);
        wrappedRecords[key] = newCol;
        App.Data.PageItems[Class].registerCollection(newCol);
      }, this);

      wrappedRecords.brands = new Brands(data.records.brands);

      data.records = wrappedRecords;
    }

    if (data.query) data.query = this.queryFromParams(data.query);

    return data;
  },

  brands: function () {
    return this.get('records').brands;
  },

  coupons: function () {
    return this.get('records').coupons;
  },

  deals: function () {
    return this.get('records').deals;
  },

  merchants: function () {
    return this.get('records').merchants;
  },

  parseAndMerge: function (data) {
    this.set('loading', false);

    if (data.records.brands) this.brands().add(data.records.brands, { silent: true });
    if (data.records.coupons) this.coupons().add(data.records.coupons, { silent: true });
    if (data.records.deals) this.deals().add(data.records.deals, { silent: true });
    if (data.records.merchants) this.merchants().add(data.records.merchants, { silent: true });

    this.trigger('sync');
  },

  fetchNext: function () {
    var params = this.queryToApiParams();
    var type = App.Router.currentParams().type || this.DEFAULT_TYPE;
    var records;

    if (type === 'stores') {
      params.types = 'merchants';
    } else {
      params.types = type;
    }

    records = this.get('records')[params.types];

    if (records) params.offset = records.length;

    this.set('loading', true);

    $.ajax({
      url: '/api/search',
      type: 'GET',
      data: params,
      dataType: 'json'
    }).done(this.parseAndMerge);
  },

  queryFromParams: function (params) {
    var query = {};

    this.VALID_FILTERS.forEach(function (filter) {
      if (params[filter]) query[filter] = params[filter].split(',');
    });

    if (params.query) query.search_string = params.query;
    if (params.search_string) query.search_string = params.search_string;

    if (params.shipping_cost !== undefined) {
      query.shipping_cost = parseFloat(params.shipping_cost);
    }

    if (params.max_price !== undefined) {
      query.max_price = parseFloat(params.max_price);
    }

    if (params.min_price !== undefined) {
      query.min_price = parseFloat(params.min_price);
    }

    return query;
  },

  queryToParams: function () {
    var params = {};
    var query = this.get('query');

    if (query.search_string) params.query = query.search_string;

    if (query.shipping_cost !== undefined) {
      params.shipping_cost = query.shipping_cost;
    }

    if (query.max_price !== undefined) {
      params.max_price = query.max_price;
    }

    if (query.min_price !== undefined) {
      params.min_price = query.min_price;
    }

    this.VALID_FILTERS.forEach(function (filter) {
      if (query[filter] && query[filter].length) params[filter] = query[filter].join(',');
    });

    return params;
  },

  queryToApiParams: function (queryParams) {
    queryParams = queryParams || this.queryToParams();

    var params = {};

    if (queryParams.query) {
      queryParams.search_string = queryParams.query;

      delete queryParams.query;
    }

    params.query = queryParams;

    return params;
  },

  isEqualToParams: function (query) {
    return isEqual(this.get('query'), this.queryFromParams(query));
  },
});
