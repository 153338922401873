import CollectionItemView from './collection_item_view';
import template from '../templates/coupons_page_item.jst.ejs';

// App.Views.CouponsPageItem
export default CollectionItemView.extend({
  template: template,

  tagName: 'li',

  preinitialize: function (options) {
    this.index = options.index;
  },

  attributes: function () {
    return {
      'class': 'has-coupon-modal-flow',
      'data-id': this.model.get('id'),
      'data-item-type': 'coupon',
      'data-position': this.index
    }
  },

  render: function () {
    this.$el.html(this.template({ coupon: this.model }));
    return this.$el;
  },

  initOrphan: function () {
    this.showAdvertiserDisclosure(this.model.get('merchant').merchant_rules);
  }
});
