import {
  getFID,
  getFCP,
  getLCP,
  getCLS,
} from 'web-vitals';

const buildWebVitalsCallback = (eventName, propName = 'value') => (({ value }) => {
  if (!window.heap) {
    return undefined;
  }

  const properties = {
    [propName]: value,
  };

  return window.heap.track(eventName, properties);
});

getCLS(buildWebVitalsCallback('Web_Vitals_CLS', 'score'));
getFCP(buildWebVitalsCallback('Web_Vitals_FCP'));
getFID(buildWebVitalsCallback('Web_Vitals_FID'));
getLCP(buildWebVitalsCallback('Web_Vitals_LCP'));
