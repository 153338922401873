import BaseCollection from './base_collection';
import Coupons from './coupons';
import Deals from './deals';
import { bindAll, compact, map, where } from 'underscore';

var _super = BaseCollection.prototype;

// App.Collections.SavedItems
export default BaseCollection.extend({

  initialize: function() {
    this.deals = new Deals;
    this.coupons = new Coupons;
    _super.initialize.apply(this, arguments);
    bindAll(this, "resetCollectionWithResponse");
  },

  fetch: function(requestedItems) {
    this.requestedItems = requestedItems;
    var deal_ids = where(requestedItems, { item_type: "deal" })
      .map((model) => model.item_id)
      .join(",");
    var coupon_ids = where(requestedItems, { item_type: "coupon" })
      .map((model) => model.item_id)
      .join(",");
    this.trigger("request");
    $.when(this.fetchDeals(deal_ids), this.fetchCoupons(coupon_ids)).done(this.resetCollectionWithResponse);
  },

  fetchDeals: function(ids) {
    if (ids.length > 0) {
      return this.deals.fetch({ data: { ids: ids }});
    }
  },

  fetchCoupons: function(ids) {
    if (ids.length > 0) {
      return this.coupons.fetch({ data: { ids: ids }});
    }
  },

  resetCollectionWithResponse: function() {
    var deals = this.deals;
    var coupons = this.coupons;
    this.reset(compact(map(this.requestedItems, function(item) {
      switch(item.item_type) {
        case "deal":
          return deals.where({ id: item.item_id })[0];
        case "coupon":
          return coupons.where({ id: item.item_id })[0];
      }
    })));
    this.trigger("sync");
  }
})
