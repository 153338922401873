import BaseView from '../base_view';
import template from '../../templates/home_page/tab_bar.jst.ejs';

// App.Views.TabBar
export default BaseView.extend({
  template: template,

  events: {
    'click .feed-selector': 'handleFeedSelectorClick'
  },

  FILTERS: [{
    slug: 'newest',
    label: 'Newest'
  }, {
    slug: 'popular',
    label: 'Popular'
  }, {
    slug: 'collection',
    label: 'Exclusive'
  }],

  initialize: function (options) {
    this.options = options;
    this.currentFilter = this.FILTERS.find(function (filter) { return filter.slug === options.defaultTab });

    this.render();
  },

  render: function () {
    this.$el.html(this.template());
  },

  setActiveTab: function (newActiveTab) {
    // If the tab didn't change, return early
    if (this.activeTab === newActiveTab) return;

    this.activeTab = newActiveTab;

    if (newActiveTab !== 'my-deals') {
      this.currentFilter = this.FILTERS.find(function (filter) { return filter.slug === newActiveTab });
    }

    this.render();
  },

  handleFeedSelectorClick: function () {
    this.$('.feed-selector').toggleClass('active');
    this.$('.feed-selector-dropdown').toggleClass('hide');
  },

  hasBorderBottom: function () {
    return this.activeTab === 'newest' || this.activeTab === 'collection';
  }
});
