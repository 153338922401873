import { extend } from 'underscore';
import ContactUs from '../views/contact_us'
import InlineSubscriptionForm from '../views/inline_subscription_form'
import {
  hashFromQueryString, isDealDetailAndExpired,
} from '../lib/view_helpers';
import user from '@legacy-js/user-data/user';

// App.Controllers.ApplicationController
export default {
  initialize: function () {
    App.Dispatcher.on('applicationLoaded', this.initApplication, this);
    App.Dispatcher.on('showAdvertiserDisclosure', this.showAdvertiserDisclosure, this);
  },

  initApplication: function (pageType) {
    user.init({});
    this.user = user;

    this.initMobileCode();
    this.initCookies();
    this.initUserApplication();
    this.initConfirmation();
    App.Dispatcher.trigger('initModals');
    App.Dispatcher.once('bdUserReady', this.initAcquisition, this);
    if (pageType !== 'lightPage') {
      this.initFuzzyTime();
      this.initScrollTracking();
      this.initInlineSubscribe();
      this.initContactUs();
      this.initNavigation();
      this.initSocialShare();
    }
  },

  initAcquisition: function () {
    var params = hashFromQueryString(window.location.search);

    if (params.acq !== 'true' || isDealDetailAndExpired()) return;

    params.user = this.user;
    App.Dispatcher.trigger('bdAcquisition:initialize', params);
  },

  initConfirmation: function () {
    App.Dispatcher.trigger('initConfirmation');
  },

  initFuzzyTime: function () {
    App.Dispatcher.trigger('bdFuzzyTime:initialize');
  },

  initUserApplication: function () {
    var doneCallback = this.triggerUserInit;
    var makeRequest = function (ajaxOptions) {
      $.ajax(extend({ url: '/me.json', type: 'GET' }, ajaxOptions));
    };

    makeRequest({
      success: doneCallback,
      error: function () {
        makeRequest({
          url: '/me.json?retry=true',
          success: doneCallback,
          error: doneCallback
        });
      }
    });
  },

  initScrollTracking: function () {
    App.Dispatcher.trigger('trackScrolling');
  },

  initNavigation: function () {
    App.Dispatcher.trigger('initNavigation');
  },

  triggerUserInit: function (userData) {
    var mdfiveHash = userData.user && userData.user.md5_hash;
    var sha1Hash = userData.user && CryptoJS.SHA1(userData.user.email).toString();
    var sha256Hash = userData.user && CryptoJS.SHA256(userData.user.email).toString();
    App.Dispatcher.trigger('initUserApplication', userData.user);
    $('head').append(
      '<meta content="authenticity_token" name="csrf-param">' +
      '<meta name="csrf-token" content="' + userData.token + '"/>' +
      '<meta name="user-md5-hash" content="' + mdfiveHash + '"/>' +
      '<meta name="user-sha1-hash" content="' + sha1Hash + '"/>' +
      '<meta name="user-sha256-hash" content="' + sha256Hash + '"/>');
    $('form input[name="authenticity_token"]').val(userData.token);
  },

  initMobileCode: function () {
    App.Dispatcher.trigger('initMobileCode');
  },

  initInlineSubscribe: function () {
    App.Dispatcher.on('initInlineSubscribe', function () {
      new InlineSubscriptionForm({
        el: '#subscription-form',
        model: user
      });
    });
  },

  initCookies: function () {
    App.Dispatcher.trigger('initCookies');
  },

  showAdvertiserDisclosure: function () {
    $('.advertiser-disclosure').attr('hidden', false);
  },

  initContactUs: function () {
    App.Dispatcher.on('initContactUs', function () {
      new ContactUs({
        el: '.body-content'
      });
    });
  },

  initSocialShare: function () {
    App.Dispatcher.trigger('initSocialShare');
  }
};
