import UId from '../lib/u_id';
import sst from '@vendor-js/sst';

// App.Controllers.TrackingController
export default {
  initialize: function () {
    App.Dispatcher.on('initUserApplication', this.determineUserConversion);
    App.Dispatcher.on('initUserApplication', this.trackUser);
    App.Dispatcher.on('initUserApplication', this.sendUserInitializedEventToGTM, this);
    App.Dispatcher.on('initTracking', this.initTracking, this);
    App.Dispatcher.on('updateTracking', this.handleUpdateTracking, this);
  },

  initTracking: function (initSettings) {
    sst.config.settings.urls.id = initSettings.idservice_url;

    var data = $('html').data();

    this.loc = data.loc;
    this.variant = data.variant;

    sst.init().then(this.trackUser);

    App.Dispatcher.on('route', this.handleRouteChange, this);
  },

  handleRouteChange: function (path, params) {
    this.sendRouteChangeToGTM(path, params);
  },

  trackUser: function (userJSON) {
    UId.trackUser(userJSON);
  },

  /*
    This method takes an object with any of the keys shown below and coerces them to the correct keys and
    applies default params for ones that are missing. It also coerces c_id to an integer if it is a string.
    Once the new tracking hash is generated it creates a session hash to compare the existing session to
    the new one. If they are the same it will update the existing session. If they are different
    it will create a new session.

    Example:

    handleUpdateTracking({
      c_id: '679',
      s: 'site|onboarding|onboarding-welcome|date|homepage|test-original-copy',
      utm_medium: 'site',
      utm_source: 'onboarding',
      utm_campaign: 'onboarding-welcome',
      utm_content: 'some-content',
      utm_term: 'some-term'
    });

  */
  handleUpdateTracking: function (trackingParams) {
    // Translate parameters to tracking params
    var sessionParams = sst.id.parseSessionParams(
      trackingParams,
      sst.config.settings.params.session
    );

    // Set default www channel_id and make sure it is a numeric.
    sessionParams = sst.id.normalizeChannelId(sessionParams);

    // Generate a session hash
    var sessionParamsHash = sst.id.generateSessionHash(
      sessionParams,
      sst.config.settings.params.session
    );

    if (sessionParamsHash != sst.id.s_hash.get()) {
      var dId = sst.id.d_id.get();
      sst.id.createSession(
        sessionParams,
        sessionParamsHash,
        dId
      );
    } else {
      sst.id.update('session', sessionParams);
    };
  },

  sendRouteChangeToGTM: function (path, params) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'route',
        path: path,
        params: params,
        title: document.title
      });
    }
  },

  sendUserInitializedEventToGTM: function (userJSON) {
    if (!window.dataLayer || !userJSON || !userJSON.id) return;

    window.dataLayer.push({
      event: 'userInitialized',
      user_id: userJSON.id
    });
  },

  determineUserConversion: function (userJson) {
    if (!window.dataLayer || !userJson) return;

    if (userJson.created) {
      window.dataLayer.push({ event: 'userCreated' });
    } else if (userJson.reactivated) {
      window.dataLayer.push({ event: 'userReactivated' });
    }
  }
};
