import BaseView from '../base_view';
// import template from '../../templates/users/facebook_connect.jst.ejs';
// @TODO: Remove since templates/users/facebook_connect.jst.ejs is not a thing
// App.Views.User.FacebookConnect
export default BaseView.extend({
  template: null,

  initialize: function (options) {
    this.options = this.options || options || {};
    this.render();
    this.listenTo(this.model, "change:facebook_user", this.render);
  },

  render: function() {
    this.$el.html(this.template());
  }
});
