import BaseView from './base_view';
import template from '../templates/message_preview.jst.ejs';

// App.Views.MessagePreviewView
export default BaseView.extend({
  template: template,

  initialize: function () {
    this.render();
    this.model.on('all', this.render, this);
  },

  render: function () {
    this.$el.html(this.template({ model: this.model, index: this.index }));
  },

  description: function () {
    return '<h3>' + this.model.get('item').shareName() + '</h3>';
  }
});
