import CollectionItemView from './collection_item_view';
import template from '../templates/deals/tile.jst.ejs';

// App.Views.DealTile
export default CollectionItemView.extend({
  template: template,

  tagName: 'li',

  className: 'col large-4 medium-4 small-6',

  preinitialize: function (options) {
    this.index = options.index;
  },

  attributes: function () {
    return {
      'data-position': this.index,
      'data-id': this.model.get('id'),
      'data-item-type': 'deal',
      'data-flags': this.model.get('flag_slugs')
    };
  },

  render: function () {
    this.$el.html(this.template());

    // Display advertising disclosure, if any
    if (this.model.get('merchant').merchant_rules.advertising_disclosure) {
      App.Dispatcher.trigger('showAdvertiserDisclosure');

      this.$('.advertiser-disclosure-link').attr('hidden', false);
    }

    return this.$el;
  }
});
