import BaseView from './base_view';
import Forms from '../lib/forms/forms';
import template from '../templates/inline_subscription_form.jst.ejs';

// App.Views.InlineSubscriptionForm
export default BaseView.extend({
  template: template,

  events: {
    'ajax:success form': 'success',
    'ajax:error form': 'error',
    'click span.rounded-button': 'showForm'
  },

  initialize: function () {
    App.Dispatcher.on('user:change', this.render, this);
    this.render();
  },

  render: function () {
    if (this.model.isFullUser() || this.model.isSubscriber()) {
      this.hide();
    } else {
      this.show();
      this.$el.html(this.template());
      this.setValidation();
    }
  },

  setValidation: function () {
    this.$validator = this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      rules: {
        email: Forms.Validations.rules.email
      },
      messages: {
        email: Forms.Validations.messages.email
      },
      showErrors: Forms.Validations.showErrors.inline
    });
  },

  showForm: function () {
    this.$('.cta').hide();
    this.$('form').show();
  },

  success: function (event, data, status, xhr) {
    this.stopListening(this.model);
    App.Dispatcher.trigger('initUserApplication', data.user);
    App.Dispatcher.trigger('userSubscribe', data.user);
    this.$('form').remove();
    this.$('.subscription-container').append("<h3>Great!  You're subscribed!</h3>");
    var _this = this;
    setTimeout(function () {
      App.Dispatcher.trigger('disableStickyAds');
      _this.remove();
    }, 3000);
  },

  error: function (event, xhr, status, error) {
    var responseJSON = xhr.responseJSON || {};

    if (responseJSON.errors && responseJSON.errors.email) {
      this.$validator.showErrors({
        email: Forms.Validations.messages.email.email
      });
    }
  }
});
