import CollectionView from '../collection_view';
import CouponTile from './coupon_tile';

var _super = CollectionView.prototype;

// App.Views.CouponTiles
export default CollectionView.extend({
  modelName: 'Coupon',
  modelView: CouponTile,

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.initializeViews();
  }
});
