import { bind, constant, pick } from 'underscore';

import user from '@legacy-js/user-data/user';
import Forms from '../../lib/forms/forms';
import Modal from '../modal';
import template from '../../templates/modals/acquisition.jst.ejs';
import { detectMobileDevice, queryStringFromHash } from '../../lib/view_helpers';
import { getRoute } from '../../../../javascript/src/utils/route-service'

// App.Views.AcquisitionModal
export default Modal.extend({
  USER_ATTRS: ['created', 'reactivated'],

  template: template,

  defaultOptions: {
    closable: false,
  },

  events: {
    'ajax:success form': 'success',
    'ajax:error form': 'ajaxError',
    'ajax:before form': 'disable',
  },

  modalName: 'acquisition',

  afterRender: function () {
    this.location = this.options.location || window.location;
    $(document).one('externalForm:success', bind(this.facebookSuccess, this));
    this.$validator = this.$('form').validate({
      ignore: [],
      errorLabelContainer: '.errors',
      onfocusout: false,
      onkeyup: false,
      rules: {
        email: Forms.Validations.rules.email,
      },
      messages: {
        email: "Hmm, something isn't right. Please try again.",
      },
    });
  },

  disable: function () {
    this.$('.facebook-login-button').click(constant(false));
  },

  facebookSuccess: function () {
    this.$('input.facebook-login-button').attr('disabled', true);
    this.$('.facebook-login-button')
      .text('Signing up…')
      .click(constant(false));
    this.redirect(pick(user, this.USER_ATTRS));
  },

  success: function (event, data) {
    App.Dispatcher.trigger('initUserApplication', data.user);
    App.Dispatcher.trigger('userSubscribe', data.user);
    this.redirect(data.user && pick(data.user, this.USER_ATTRS));
  },

  redirect: function (userAttrs) {
    const params = App.Data.currentUrlParameters();
    const queryParams = {};

    // Get base redirect path
    let newLocation = '/';

    if (params.redirect_path) {
      newLocation = params.redirect_path;
    } else if (params.deal_id) {
      let baseRoute = getRoute('acquisitions-deal')
      newLocation = `${baseRoute}/${params.deal_id}`;
    }

    // In case no user attributes where passed
    userAttrs = userAttrs || {};

    // Assign base query params
    queryParams.newsub = !!userAttrs.created;
    queryParams.p = params.p;
    queryParams.query = params.query;
    queryParams.reactivated = !!userAttrs.reactivated;
    queryParams.ce = params.ce;

    // New subscriber only params
    if (queryParams.newsub) {
      queryParams.sub_id = params.sub_id;

      if (!detectMobileDevice()) queryParams.d = true;
    }

    // Remove empty query params and generate query param string
    const queryString = queryStringFromHash(queryParams);

    // Combine path and query params
    newLocation += `?${queryString}`;

    this.location.assign(newLocation);
  },

  hideEmail: function () {
    // use == instead of === to get coercion to int
    return this.options.lo == 2;
  },

  hideFacebook: function () {
    // use == instead of === to get coercion to int
    return this.options.lo == 1;
  },

  modalTitle: function () {
    // coerce to integer just in case it's a string
    switch (+this.options.to) {
      case 1:
        return "Don't Miss the Next Great Deal!";
      case 2:
        return 'Save 50 - 90%';
      case 4:
        return 'Let the deals come to you!';
      case 5:
        return 'The Most Exciting Deals, in Your Inbox';
      default:
      case 0:
      case 3:
        return "Brad's Deals of the Day";
    }
  },

  modalSubTitle: function () {
    switch (+this.options.to) {
      case 1:
      case 2:
        return 'Sign up to get our top 25 deals sent to you daily';
      case 3:
        return 'Sign up for our free email newsletter, with handpicked deals from over 4,000 retailers.';
      case 4:
        return 'Get all the best online deals, right in your inbox.';
      case 5:
        return 'Our experts add new deals all day, every day.';
      default:
      case 0:
        return 'Take 50 to 90% off Top Brands at the Best Stores';
    }
  },

  isValidHex: function (hexString) {
    const pattern = /^([0-9A-F]{3}){1,2}$/i;
    return pattern.test(hexString);
  },

  buttonStyling: function () {
    const btnColor = App.Data.currentUrlParameters().btn;
    if (btnColor && this.isValidHex(btnColor)) {
      return `background-color: #${btnColor};`;
    }

    return '';
  },
});
