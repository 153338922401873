import BaseView from '../base_view';
import Forms from '../../lib/forms/forms';
import template from '../../templates/oauth/alexa_sign_up.jst.ejs';

// App.Views.AlexaSignUp
export default BaseView.extend({
  template: template,

  events: {
    'ajax:success form.sign-up-form': 'success',
    'ajax:before  form.sign-up-form': 'validate'
  },

  initialize: function () {
    this.render();
    this.setValidation();
  },

  render: function () {
    return this.$el.html(this.template());
  },

  validate: function () {
    return this.$('form').valid();
  },

  setValidation: function () {
    this.$validator = this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      rules: {
        email: Forms.Validations.rules.email
      },
      messages: {
        email: Forms.Validations.messages.email
      },
      showErrors: Forms.Validations.showErrors.inline
    });
  },

  success: function (event, data, status, xhr) {
    if (data.errors === 'User account exists.') {
      App.Dispatcher.trigger('initAlexaPasswordSignIn', data.user);
    } else {
      App.Dispatcher.trigger('initUserApplication', data.user);
      App.Dispatcher.trigger('userSubscribe', data.user);
    }
  }
});
