import DealsPageItem from './deals_page_item';
import DealAuthorInfo from './deals/deal_author_info';
import DealGoLink from './deal_go_link';
import EngagementBookmark from './engagement_bookmark';
import EngagementShareModule from './engagement_share_module';
import template from '../templates/deal_tile_with_bookmark.jst.ejs';
import { numberWithCommas } from '../lib/view_helpers';


// App.Views.DealTileWithBookmark
export default DealsPageItem.extend({
  template: template,
  tagName: 'li',

  preinitialize: function (options) {
    this.index = options.index;
  },

  attributes: function () {
    return {
      class: 'tile with-bookmark',
      'data-flags': this.model.get('flag_slugs'),
      'data-id': this.model.get('id'),
      'data-item-type': 'deal',
      'data-position': this.index
    };
  },

  initOrphan: function() {
    this.createSubViews();
    this.renderSubViews();
  },

  createSubViews: function () {
    this.authorInfoView = new DealAuthorInfo({ model: this.model, hideAvatar: true });
    this.engagementBookmarkView = new EngagementBookmark({ model: this.model });
    this.engagementShareModuleView = new EngagementShareModule({ model: this.model });
    this.dealGoLink = new DealGoLink({ model: this.model });

    if (this.model.isFakespotVerified()) {
      this.initFakespotTooltip();
    };
    if (this.model.isBlackFridayValue()) {
      this.initBfValueTooltip();
    };
  },

  renderSubViews: function () {
    this.renderAuthorInfoModule();
    this.engagementBookmarkView.setElement(this.$(".engagement-bookmark"));
    this.engagementShareModuleView.setElement(this.$(".engagement-share-module"));

    this.engagementShareModuleView.render();
    this.engagementBookmarkView.render();
    this.renderDealGoLink();

    // only render fakespot tooltip if deal is fakespot verified
    if ('fakespotTooltip' in this) this.renderFakespotTooltip();
    if ('bfValueTooltip' in this) this.renderBfValueTooltip();
  },

  isPriceSectionShown: function () {
    // Only show price & ship info if not general sale, has price info, and isn't from an excluded category
    return !this.model.get('general_sale') && this.model.hasDealPrice() && !this.model.isPriceExcludedCategory();
  },

  priceWithCommas: function () {
    return numberWithCommas(this.model.priceZeroCentsDrop());
  },

  shippingInfo: function () {
    if (this.model.isShippingExcludedCategory()) return '';

    switch (this.model.shippingType()) {
      case 'free':
        return '+ Free Shipping';
      case 'options':
        return '+ Free Shipping Options';
      case 'prime':
        return '+ Free Prime Shipping';
      case 'shipping':
        return '+ Shipping';
      case 'none':
      default:
        return '';
    }
  }
});
