/* eslint-disable no-param-reassign  */
/* eslint-disable prefer-destructuring */

// This directive takes a container argument to give the
// element its top and bottom constraints.

import Vue from 'vue';

const NAV_OFFSET = 100;

Vue.directive('sticky', {
  componentUpdated(el, binding, vnode) {
    if (!binding.arg) return;

    if (el.directiveFunction) vnode.context.$dispatcher.off('viewDidScroll', el.directiveFunction);

    const container = binding.arg;

    const onScroll = () => {
      const { pageYOffset } = window;
      const { height, top } = container.getBoundingClientRect();
      const { clientHeight } = el;

      // Calculate offset from top of page based on users current position on page
      const offset = Math.ceil(top + pageYOffset - container.clientTop - NAV_OFFSET);

      // The furthest the element can travel
      const max = offset + height - clientHeight;

      if (pageYOffset > offset && pageYOffset < max) {
        el.classList.add('fixed');
        el.style.removeProperty('margin-top');
      } else if (pageYOffset >= max) {
        el.classList.remove('fixed');
        el.style['margin-top'] = `${height - el.clientHeight}px`;
      } else {
        el.classList.remove('fixed');
        el.style.removeProperty('margin-top');
      }
    };

    // Assign function to element so it can be turned off on unbind
    el.directiveFunction = onScroll;

    // Setup scroll listener
    vnode.context.$dispatcher.on('viewDidScroll', onScroll);
  },

  unbind(el, binding, vnode) {
    // Remove scroll tracking listener
    if (el.directiveFunction) vnode.context.$dispatcher.off('viewDidScroll', el.directiveFunction);
  },
});
