<template>
  <!-- Note: SVG Inline is not currently compatible with plain HTML files -->
  <a class="cross-circle" @click.prevent="trigger">
    <svg class="svg-cross-circle" xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 38 38" v-bind:svg-inline="''" v-bind:src="'svgs/cross-circle.svg'" v-bind:alt="'close'" v-bind:role="'presentation'" v-bind:tabindex="'-1'"><path class="inner" d="M16 33.5C6.4 33.5-1.5 25.6-1.5 16S6.4-1.5 16-1.5 33.5 6.4 33.5 16 25.6 33.5 16 33.5z"/><path class="outer" d="M16 0c8.8 0 16 7.2 16 16s-7.2 16-16 16S0 24.8 0 16 7.2 0 16 0m0-3C5.5-3-3 5.5-3 16s8.5 19 19 19 19-8.5 19-19S26.5-3 16-3z"/><path class="x" d="M23.9 21.1l-2.8 2.8-5.1-5.1-5.1 5.1-2.8-2.8 5.1-5.1-5.1-5.1 2.8-2.8 5.1 5.1 5.1-5.1 2.8 2.8-5.1 5.1z"/></svg>
  </a>
</template>

<script>
export default {
  props: {
    trigger: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style src="./cross-circle.scss" lang="scss" scoped></style>
