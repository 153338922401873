import BaseView from './base_view';
import { bindAll } from 'underscore';
import { searchTrimmer } from '../lib/search-trim';

// App.Views.NavigationView
export default BaseView.extend({
  events: {
    'click .navigation-toggle[data-nav-item]': 'toggleNavOption',
    'click .search-trigger': 'handleOpenSearch',
    'click .search-overlay': 'handleCloseSearch',
    'transitionend .search-dropdown': 'handleSearchTransitionEnd',
    'webkitTransitionEnd .search-dropdown': 'handleSearchTransitionEnd',
    'oTransitionEnd .search-dropdown': 'handleSearchTransitionEnd',
    'MSTransitionEnd .search-dropdown': 'handleSearchTransitionEnd',
    'click .skip-navbar-link': 'addTabindex',
  },

  initialize: function (options) {
    this.options = this.options || options || {};
    bindAll(this, 'handleCloseSearch', 'deregisterCloseEvent');

    document
    .querySelector('form.autocomplete-form.horizontal-form')
    .addEventListener('submit', searchTrimmer('#header-search')
    )
  },

  toggleNavOption: function (event) {
    var $target = $(event.currentTarget);
    var navItem = $target.data().navItem;
    this.$('.active').removeClass('active');
    $target.addClass('active');
    this.$('.nav-item[data-nav-item="' + navItem + '"]').addClass('active');

    this.toggleNavHeight($target);
  },

  toggleNavHeight: function($target) {
    var height;
    if (($target).hasClass('childless')) {
      height = '67px';
    } else {
      height = '100px';
    };

    $('header.masthead').css('min-height', height);
  },

  handleOpenSearch: function (event) {
    this.$('.dropdown-full-width').toggleClass('nav-hidden');
    this.$('.search-trigger').toggleClass('active');
    this.registerCloseEvent();
  },

  registerCloseEvent: function () {
    $(document).on('click', this.handleCloseSearch);
  },

  //There is an issue with rails where for IPad users when the search bar is selected the keyboard doesn't appear.
  //Specifically, a different tech stack is being added to the search bar for IPad users using safari.
  //This was fixed in nuxt, but not rails because it would require a rewrite for rails.
  //This issue should be addressed when the search bar is being migrated to nuxt.
  handleSearchTransitionEnd: function (event) {
    this.$('#header-search').get(0).focus();
  },

  handleCloseSearch: function (event) {
    // isSearchElement: search elements that we don't want to cause a close
    var isSearchElement = ($(event.target).is('.dropdown-full-width, .dropdown-wrapper, .dropdown-wrapper *, .search-trigger, .search-trigger *'));
    if ((!$('.dropdown-full-width').hasClass('nav-hidden')) && (!isSearchElement)) {
      this.$('.dropdown-full-width').addClass('nav-hidden');
      this.$('.search-trigger').removeClass('active');
      this.deregisterCloseEvent();
    }
  },

  deregisterCloseEvent: function () {
    $(document).off('click', this.handleCloseSearch);
  },

  addTabindex: function (e) {
    e.preventDefault();

    const bodyContent = document.getElementById('body-content');

    if(!bodyContent) return;

    bodyContent.setAttribute('tabindex', '-1');
    bodyContent.addEventListener('blur', () => {
      bodyContent.removeAttribute('tabindex');
    }, {once: true});
    bodyContent.focus();
  },
});
