import Vue from 'vue';
import MyDealsWrapper from '../src/components/my_deals/my_deals_wrapper/my_deals_wrapper.vue';
import CrossCircle from '../src/components/cross-circle/cross-circle.vue';

const initMyDeals = (target) => {
  // If Vue already rendered My Deals Sign In
  if (!document.querySelector(target.el)) {
    Vue.prototype.$dispatcher.trigger('openMyDealsDrawer');
    return;
  }

  // Define Prototype Methods that should be available to all Vue components
  Vue.prototype.$FB = window.App.FB;
  Vue.prototype.$Google = window.App.Google;

  // Globally registered components available from any component within the app without importing
  Vue.component('CrossCircle', CrossCircle);

  const {
    el = '#vue-app-root',
    completeProfile,
    emailUser = {},
  } = target;

  new Vue({
    el,
    render: (root) => root(MyDealsWrapper, { props: { completeProfile, emailUser } }),
  });
};

export default initMyDeals;
