

// App.Assets

export default {
  images: {
    'logos/bd-logo-2019-reversed.svg': 'https://www-assets-staging.bradsdeals.com/assets/logos/bd-logo-2019-reversed-b2027ffc8770643558b90445c8abde2c04628acda6b153819689cadf6d56a7c7.svg',
    'svgs/official_facebook_color.svg': 'https://www-assets-staging.bradsdeals.com/assets/svgs/official_facebook_color-933807ea7b576324aee6c5a4fc2a8075649004616296077147e260e8aefe8783.svg',
    'svgs/official_facebook_white.svg': 'https://www-assets-staging.bradsdeals.com/assets/svgs/official_facebook_white-489023c7be414cb453e5298e29ec8b90c710dc57387fbcc12e9133b34c2e4186.svg',
    'credit-card-placeholder.png': 'https://www-assets-staging.bradsdeals.com/assets/credit-card-placeholder-193ec72de2b7b2b20a851dce6f7585053b469b36e86149ec97513b1d306d2cb7.png',
    'loading-spinnger.gif': 'https://www-assets-staging.bradsdeals.com/assets/loading-spinner-b0781cd248145d0d8329b67b9bfe7168b867e1f11d35c2c15596b15b2dc4fc56.gif'
  },

  imagePath: function(imageName){
    return App.Assets.images[imageName];
  }
};


/* Unused images
  'users/avatar-anon@2x.png': 'https://www-assets-staging.bradsdeals.com/assets/users/avatar-anon@2x-36810cdf4dd69f9589aff369a501f570651a8e7f0df6a762f69758b333cf7e5a.png',
  'sad-face.png': 'https://www-assets-staging.bradsdeals.com/assets/sad-face-92fff243f64d0ae98a72ab34c052a8eacb10abe858b5a42b48884ab425f9509c.png',
  'close-button.png': 'https://www-assets-staging.bradsdeals.com/assets/close-button-ab9824c9833f2cc8d1a8bc4fda1baa1d9427b275eb13c06e34b3892bd9766f9d.png',
  'logos/bd-seal-with-tag@2x.png': 'https://www-assets-staging.bradsdeals.com/assets/logos/bd-seal-with-tag@2x-d1b3d3c8aa51897c825b65c104daca6d2b7a3d187000f6509c40efd25359ecaf.png',
  'logos/bd-2tone-logo.png': 'https://www-assets-staging.bradsdeals.com/assets/logos/bd-2tone-logo-ca12ebded39586ad0e7bf3cd297de2408007f134c25c8b1399918341d72df0a5.png',
  'svgs/btn_email_icon.svg': 'https://www-assets-staging.bradsdeals.com/assets/svgs/btn_email_icon-2c5d2666413fae037f72a16775241c92879173a4e7e08a13b0afc17a5529d686.svg',
  'svgs/btn_facebook_icon.svg': 'https://www-assets-staging.bradsdeals.com/assets/svgs/btn_facebook_icon-9039cb38d9a4875b122d6ac3505e7168ca5d8a8d4c21b14ee3d93859d4fee94c.svg',
  'svgs/btn_google_icon.svg': 'https://www-assets-staging.bradsdeals.com/assets/svgs/btn_google_icon-ee69faac3d8c3cf929967a931688900003102c2f2db582a2d1f0255d891e83c3.svg',
  'default-acq-img.png': 'https://www-assets-staging.bradsdeals.com/assets/default-acq-img-f5d872bea1cd57195b7ca1b289d9d9fdddca46a91ee50d32a6d077acabfc098c.png',
  'dd-acq-slide-1.png': 'https://www-assets-staging.bradsdeals.com/assets/dd-acq-slide-1-4fdfa8bc3d88d3d2900e5b2289fc40e6e024a93408eb7e47577f743c348df48b.png',
  'dd-acq-slide-2.png': 'https://www-assets-staging.bradsdeals.com/assets/dd-acq-slide-2-e6af9577c577edd9fdfc3e5e36336770630aca279981518715ccc69ecdcfd4b0.png',
  'dd-acq-slide-3.png': 'https://www-assets-staging.bradsdeals.com/assets/dd-acq-slide-3-e0c4561588b64cb5f8342d4443fa4c868ae6e49ec833e0d11ae8944fc5756956.png',
  'empty_screens/no_search_results/no_coupons.png': 'https://www-assets-staging.bradsdeals.com/assets/empty_screens/no_search_results/no_coupons-44cfed85abef8e2bc269edb42170cbc0a3ac3919337834ed3ddccce357827009.png',
  'empty_screens/empty_search.png': 'https://www-assets-staging.bradsdeals.com/assets/empty_screens/empty_search-e9eef722ab06f3ea38e7ba35e764e4200ce9ceb510a627976245b6ff7338c052.png',
  'deal_alerts/60_days.jpg': 'https://www-assets-staging.bradsdeals.com/assets/deal_alerts/60_days-850aac347465e32716a52ec42c588cf00424939ea525994c6480f61bc90857dc.jpg',
  'deal_alerts/video_games.jpg': 'https://www-assets-staging.bradsdeals.com/assets/deal_alerts/video_games-164a45f41c6cbbde75606bbc25585773a3414c6809082e39b5052923bf6cd848.jpg',
  'deal_alerts/winter_wear_markdowns.jpg': 'https://www-assets-staging.bradsdeals.com/assets/deal_alerts/winter_wear_markdowns-bd498a8d68c608731b9a895d1c083f75565b49b948e4d4b342c28e16529c2a1f.jpg',
**/
