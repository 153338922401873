import Author from './author';
import BaseModel from './base_model';
import Commentable from '../mixins/commentable';
import Topic from './topic';
import { extend } from 'underscore';

const PRICE_EXCLUDED_CATEGORIES = [
  'travel',
  'tickets',
  'entertainment/tickets-events',
  'credit-cards',
  'electronics/phone-services-data-plans',
  'insurance',
  'tools-and-diy-supplies/home-improvement-services'
];

const SHIPPING_EXCLUDED_CATEGORIES = [
  'office-education/education',
  'gifts/department-store-gift-cards',
  'gifts/electronics-gift-cards',
  'gifts/food-gift-cards',
  'gifts/general-store-gift-cards',
  'gifts/home-gift-cards',
  'entertainment/books-and-magazines',
  'entertainment/movies'
];

// App.Models.Deal
var Deal = BaseModel.extend({
  urlRoot: '/deals',

  wrappedAttributes: {
    author: Author,
    topic: Topic
  },

  modelClass: 'Deal',

  shareURL: function () {
    return `${this.get('rails_env_base_url')}/deals/${this.get('param')}`;
  },

  shareImage: function () {
    return this.get('image_urls').large;
  },

  shareName: function () {
    return this.get('title');
  },

  shareDescription: function () {
    return this.get('description');
  },

  isShareable: function () {
    var rules = this.get('merchant').merchant_rules;
    if (rules) {
      return !rules.disable_social_sharing;
    } else {
      return true;
    }
  },

  isExpired: function () {
    return new Date(this.get('expires_at')) < new Date();
  },

  hasFlag: function (flag_slug) {
    return !!this.get('flag_slugs') && this.get('flag_slugs').includes(flag_slug);
  },

  isBlackFridayValue: function () {
    return this.hasFlag('black-friday-value');
  },

  isFakespotVerified: function () {
    return this.hasFlag('fakespot-verified');
  },

  hasCreditCardCtaRemovalFlag: function () {
    return this.hasFlag('credit-card-cta-removal');
  },

  couponCode: function () {
    return this.get('coupon_code');
  },

  primaryCategoryPath: function () {
    if (!this.get('primary_category')) return '';
    return `/categories/${this.get('primary_category').slug}/deals`;
  },

  priceZeroCentsDrop: function () {
    if (this.get('price')) return this.get('price').replace('.00', '');
  },

  hasFreeShipping: function () {
    return (this.get('shipping_cost') && parseFloat(this.get('shipping_cost')) === 0);
  },

  hasFreePrimeShipping: function () {
    return this.hasFlag('prime-free-shipping');
  },

  hasFreeShippingOptions: function () {
    return this.hasFlag('free-shipping-options');
  },

  hasDealPrice: function () {
    return this.get('price') && parseFloat(this.get('price')) !== 0;
  },

  valueToFloatOrNull: function (value) {
    const parsedValue = parseFloat(this.get(value));
    return isNaN(parsedValue) ? null : parsedValue;
  },

  shippingType: function () {
    // this is in a specific order to return 'prime' when the deal still has shipping cost set as 0
    if (this.hasFreePrimeShipping()) return 'prime';
    if (this.hasFreeShippingOptions()) return 'options';
    if (this.hasFreeShipping()) return 'free';
    if (this.get('shipping_cost') && this.get('shipping_cost') > 0) return 'shipping';
    return 'none';
  },

  isPriceExcludedCategory: function () {
    const primaryCategorySlug = this.get('primary_category').slug;
    const parentCategorySlug = primaryCategorySlug.split('/')[0];

    return PRICE_EXCLUDED_CATEGORIES.includes(primaryCategorySlug) || PRICE_EXCLUDED_CATEGORIES.includes(parentCategorySlug);
  },

  isShippingExcludedCategory: function () {
    const dealPrimaryCategory = this.get('primary_category').slug;

    return SHIPPING_EXCLUDED_CATEGORIES.includes(dealPrimaryCategory);
  }
});

extend(Deal.prototype, Commentable);

export default Deal
