import BaseView from '../base_view';
import template from '../../templates/oauth/alexa_authorization.jst.ejs';

// App.Views.AlexaAuthorization
export default BaseView.extend({
  template: template,

  initialize: function () {
    this.render();
  },

  render: function () {
    // TODO: Handle error state
    var urlParams = App.Data.currentUrlParameters();

    return this.$el.html(this.template({
      redirectUri: urlParams.redirect_uri,
      responseType: urlParams.response_type,
      scope: urlParams.scope,
      state: urlParams.state
    }));
  }
});
