export default function(data) {
var __t, __p = '';
__p += '<div class="col tight large-12 medium-12 small-12 subscription">\n  <div class="row question-section">\n    <h3 class="help-expander">\n      ' +
((__t = ( this.model.getName() )) == null ? '' : __t) +
'\n      <span class="deals-count"></span>\n      <span class="expand-icon">+</span>\n    </h3>\n    <div class="auto-answer col tight large-12 medium-12">\n      <ul class="reset row deals"></ul>\n      <div class="row centered-container more-deals hide">\n        <a href="' +
((__t = ( this.moreDealsPath() )) == null ? '' : __t) +
'" class="large-2 medium-3 small-12 rounded-button">\n          Show More\n        </a>\n      </div>\n      <div class="well row ' +
((__t = ( this.model.get('paused') ? '' : 'hide' )) == null ? '' : __t) +
' paused-notice">\n        <div class="col large-1 medium-1 small-12 centered-container">\n          ' +
((__t = ( data.pauseSVG() )) == null ? '' : __t) +
'\n        </div>\n        <h3 class="col large-11 medium-11 small-12">\n          This deal alert is currently paused.\n        </h3>\n      </div>\n      <h3 class="error well hide">\n        Something went wrong. Try refreshing the page.\n      </h3>\n      <div class="row centered-container">\n        <span class="large-3 medium-4 small-12 rounded-button secondary remove-alert">\n          Remove Alert\n        </span>\n        <span class="large-3 medium-4 small-12 rounded-button pause-alert">\n          ' +
((__t = ( this.pauseText() )) == null ? '' : __t) +
'\n        </span>\n      </div>\n    </div>\n  </div>\n</div>\n';
return __p
}