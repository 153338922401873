import template from '../templates/loading.jst.ejs';
import Backbone from 'backbone';

// App.Views.BaseView
const BaseView = Backbone.View.extend({
  inheritsFromBaseView: true,
  loadingTemplate: template,

  initialize: function (options) {
    this.options = this.options || options || {};
    if (BaseView == this.constructor) throw new Error("App.Views.BaseView should not be instantiated directly");
  },

  show: function() {
    this.$el.show();
  },

  hide: function() {
    this.$el.hide();
  },

  showAdvertiserDisclosure: function(merchantRules){
    if(merchantRules.advertising_disclosure) {
     App.Dispatcher.trigger("showAdvertiserDisclosure");
     this.showAdvertiserDisclosureLink();
    }
  },

  showAdvertiserDisclosureLink: function(){
    this.$('.advertiser-disclosure-link').attr('hidden', false);
  },

  renderLoading: function() {
    return this.$el.html(this.loadingTemplate());
  },

  sever: function() {
    this.$el.unbind();
    this.stopListening();
  }
});

export default BaseView;
