import BaseView from './base_view';

// App.Views.ToggleExpander
export default BaseView.extend({
  events: {
    'click .help-expander': 'toggleDetails'
  },

  toggleDetails: function (e) {
    var $answer = $(e.currentTarget).next('.auto-answer');
    var $icon = $(e.currentTarget).children('.expand-icon');
    var toggleState = $answer.is(':visible') ? '+' : '–';

    $icon.text(toggleState);
    $answer.slideToggle();
  }

});
