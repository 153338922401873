import Modal from '../modal';
import template from '../../templates/modals/subscription_unsubscribe.jst.ejs';

// App.Views.SubscriptionUnsubscribeModal
export default Modal.extend({
  template: template,

  title: function () {
    var params = App.Data.currentUrlParameters();

    switch (+params.to) {
      case 1:
        return 'Success! You have unsubscribed from the Luxury email.';
      default: case 0:
        return 'Success! You have unsubscribed from the deal alert.';
    }
  }
});
