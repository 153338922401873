import BaseView from './base_view';
import template from '../templates/tooltip.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.Tooltip
export default BaseView.extend({
  template: template,
  events: {
    'mouseover .tooltip-trigger': 'handleTooltipOpen',
    'mouseout .tooltip-trigger': 'handleTooltipClose'
  },

  initialize: function(params) {
    bindAll(this, 'handleTooltipClose');
    this.tooltipContent = params.tooltipContent;
    this.tooltipTrigger = params.tooltipTrigger;
    return this;
  },

  render: function() {
    this.$el.html(this.template());
    return this;
  },

  handleTooltipOpen: function(event) {
    event.stopPropagation();
    this.$('.tooltip-content').css({display: 'block'});
  },

  handleTooltipClose: function() {
    this.$('.tooltip-content').attr('style', '');
  }
});
