import Notification from '../notification';
import template from '../../templates/notifications/success_notification.jst.ejs';

// App.Views.SuccessNotification
export default Notification.extend({

  el: '<li class="success-house"></li>',

  // Subclasses should override
  template: template

});
