import BaseView from './base_view';
import template from '../templates/deal_go_link.jst.ejs';

// App.Views.DealGoLink
export default BaseView.extend({
  template: template,

  render: function () {
    this.$el.html(this.template());
    return this.$el;
  },

  goLinkStylingClass: function () {
    if (this.model.hasCreditCardCtaRemovalFlag()) {
      return 'link';
    } else {
      return 'rounded-button';
    }
  }
});
