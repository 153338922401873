import BaseView from './base_view';
import template from '../templates/stale_deal_module.jst.ejs';
import xSVG from '../templates/svgs/x.jst.ejs'

// App.Views.StaleDealModule
export default BaseView.extend({
  template: template,

  events: {
    'click .close-button': 'remove'
  },

  initialize: function () {
    this.render();
  },

  render: function () {
    this.$el.html(this.template({ xSVG: xSVG }));
  }
});
