import BaseView from '@legacy-js/views/base_view';
import CollectionItemView from '@legacy-js/views/collection_item_view';
import CollectionView from '@legacy-js/views/collection_view';
import Modal from '@legacy-js/views/modal';
import AcquisitionModal from '@legacy-js/views/modals/acquisition';
import SignUpModal from '@legacy-js/views/modals/sign_up';
import AcquisitionSignUpModal from '@legacy-js/views/modals/acquisition_sign_up';
import AcquisitionMobileModal from '@legacy-js/views/modals/acquisition_mobile';
import BlogPostTile from '@legacy-js/views/blog/blog_post_tile';
import BlogPostTiles from '@legacy-js/views/blog/blog_post_tiles';
import CouponAuthorInfo from '@legacy-js/views/coupons/coupon_author_info';
import CouponGoLink from '@legacy-js/views/coupons/coupon_go_link';
import CommentsPageItem from '@legacy-js/views/comments_page_item';
import CouponsPageItem from '@legacy-js/views/coupons_page_item';
import CouponTile from '@legacy-js/views/coupons/coupon_tile';
import CouponTiles from '@legacy-js/views/coupons/coupon_tiles';
import MerchantCouponsPageItem from '@legacy-js/views/coupons/merchant_coupons_page_item';
import CreditCard from '@legacy-js/views/credit_cards/credit_card';
import DealAuthorInfo from '@legacy-js/views/deals/deal_author_info';
import CommentLinkModule from '@legacy-js/views/engagements/comment_link_module';
import DealsPageItem from '@legacy-js/views/deals_page_item';
import HomePageDealsWithAds from '@legacy-js/views/home_page/home_page_deals_with_ads';
import HomePageMyDeals from '@legacy-js/views/home_page/home_page_my_deals';
import HomePageTab from '@legacy-js/views/home_page/home_page_tab';
import TabBar from '@legacy-js/views/home_page/tab_bar';
import HomePage from '@legacy-js/views/home_page/home_page';
import CouponModal from '@legacy-js/views/modals/coupon';
import CreateProfileModal from '@legacy-js/views/modals/create_profile';
import EmailPreferenceModal from '@legacy-js/views/modals/email_preference';
import EmailShareModal from '@legacy-js/views/modals/email_share';
import LoginModal from '@legacy-js/views/modals/login';
import RemoveAlertModal from '@legacy-js/views/modals/remove_alert';
import ResetPasswordModal from '@legacy-js/views/modals/reset_password';
import SubscriptionPauseModal from '@legacy-js/views/modals/subscription_pause';
import SubscriptionUnsubscribeModal from '@legacy-js/views/modals/subscription_unsubscribe';
import UnsubscribeModal from '@legacy-js/views/modals/unsubscribe';
import Notification from '@legacy-js/views/notification';
import ErrorNotification from '@legacy-js/views/notifications/error_notification';
import InfoNotification from '@legacy-js/views/notifications/info_notification';
import SuccessNotification from '@legacy-js/views/notifications/success_notification';
import AlexaAuthorization from '@legacy-js/views/oauth/alexa_authorization';
import AlexaPasswordSignIn from '@legacy-js/views/oauth/alexa_password_sign_in';
import AlexaSignUp from '@legacy-js/views/oauth/alexa_sign_up';
import MerchantsPageItem from '@legacy-js/views/merchants_page_item';
import SearchPopularMerchants from '@legacy-js/views/search/search_popular_merchants';
import TravelPills from '@legacy-js/views/travel/travel_pills';
import Travel from '@legacy-js/views/travel/travel';
import AccountSettings from '@legacy-js/views/users/account_settings';
import AccountSettingsPassword from '@legacy-js/views/users/account_settings/password';
import AccountSettingsProfile from '@legacy-js/views/users/account_settings/profile';
import AccountSettingsSocial from '@legacy-js/views/users/account_settings/social';
import EmailPreferences from '@legacy-js/views/users/email_preferences';
import EmailSubscriptions from '@legacy-js/views/users/preferences/email_subscriptions';
import NewsletterPreferences from '@legacy-js/views/users/preferences/newsletter_preferences';
import PromotionalPreferences from '@legacy-js/views/users/preferences/promotional_preferences';
import RecommendedPreferences from '@legacy-js/views/users/preferences/recommended_preferences';
import SubscriptionStatus from '@legacy-js/views/users/preferences/subscription_status';
import Subscription from '@legacy-js/views/users/preferences/subscription';
import Subscriptions from '@legacy-js/views/users/preferences/subscriptions';
import CreateSubscriptionForm from '@legacy-js/views/create_subscription_form';
import FacebookConnect from '@legacy-js/views/users/facebook_connect';
import UserResetPassword from '@legacy-js/views/users/reset_password';
import SavedItemsPage from '@legacy-js/views/users/saved_items_page';
import UserBadge from '@legacy-js/views/users/user_badge';
import BaseSearchView from '@legacy-js/views/base_search_view';
import CommentForm from '@legacy-js/views/comment_form';
import CommentArea from '@legacy-js/views/comments_area';
import Comments from '@legacy-js/views/comments';
import CommentsPagination from '@legacy-js/views/comments_pagination';
import ContactUs from '@legacy-js/views/contact_us';
import Coupons from '@legacy-js/views/coupons';
import DealGoLink from '@legacy-js/views/deal_go_link';
import DealTileWithBookmark from '@legacy-js/views/deal_tile_with_bookmark';
import DealTile from '@legacy-js/views/deal_tile';
import DealTilesWithBookmarks from '@legacy-js/views/deal_tiles_with_bookmarks';
import DealTiles from '@legacy-js/views/deal_tiles';
import DealsShowPage from '@legacy-js/views/deals_show_page';
import Deals from '@legacy-js/views/deals';
import EmailGate from '@legacy-js/views/email_gate';
import EngagementModule from '@legacy-js/views/engagement_module';
import ExpandableBlogPosts from '@legacy-js/views/expandable_blog_posts';
import ExpandableCoupons from '@legacy-js/views/expandable_coupons';
import ExpandableDeals from '@legacy-js/views/expandable_deals';
import InlineSubscriptionForm from '@legacy-js/views/inline_subscription_form';
import ItemEngagementModule from '@legacy-js/views/item_engagement_module';
import Merchants from '@legacy-js/views/merchants';
import MessagePreviewView from '@legacy-js/views/message_preview_view';
import MiniCoupons from '@legacy-js/views/mini_coupons';
import MiniCouponPageItem from '@legacy-js/views/mini_coupon_page_item';
import MiniDeals from '@legacy-js/views/mini_deals';
import MiniDealPageItem from '@legacy-js/views/mini_deal_page_item';
import MobileMenuView from '@legacy-js/views/mobile_menu_view';
import MobileSearchView from '@legacy-js/views/mobile_search_view';
import NavigationView from '@legacy-js/views/navigation';
import Pagination from '@legacy-js/views/pagination';
import SavedItems from '@legacy-js/views/saved_items';
import SearchCouponsPage from '@legacy-js/views/search_coupons_page';
import SearchDealsPage from '@legacy-js/views/search_deals_page';
import SearchMerchantsPage from '@legacy-js/views/search_merchants_page';
import SearchPage from '@legacy-js/views/search_page';
import SearchPagination from '@legacy-js/views/search_pagination';
import SearchPopularDeals from '@legacy-js/views/search_popular_deals';
import SearchTabView from '@legacy-js/views/search_tab_view';
import SearchTopCoupons from '@legacy-js/views/search_top_coupons';
import SingleUseCoupon from '@legacy-js/views/single_use_coupon';
import SocialMediaWidget from '@legacy-js/views/social_media_widget';
import StaleDealModule from '@legacy-js/views/stale_deal_module';
import SubscriptionModule from '@legacy-js/views/subscription_module';
import ToggleExpander from '@legacy-js/views/toggle_expander';
import ToggledNavigation from '@legacy-js/views/toggled_navigation';
import Tooltip from '@legacy-js/views/tooltip';

export default {
  AcquisitionMobileModal,
  AcquisitionModal,
  AcquisitionSignUpModal,
  AlexaAuthorization,
  AlexaPasswordSignIn,
  AlexaSignUp,
  BaseSearchView,
  BaseView,
  BlogPostTile,
  BlogPostTiles,
  CollectionItemView,
  CollectionView,
  CommentArea,
  CommentForm,
  CommentLinkModule,
  Comments,
  CommentsPageItem,
  CommentsPagination,
  ContactUs,
  CouponAuthorInfo,
  CouponGoLink,
  CouponModal,
  Coupons,
  CouponsPageItem,
  CouponTile,
  CouponTiles,
  CreateProfileModal,
  CreateSubscriptionForm,
  CreditCard,
  DealAuthorInfo,
  DealGoLink,
  Deals,
  DealsPageItem,
  DealsShowPage,
  DealTile,
  DealTiles,
  DealTilesWithBookmarks,
  DealTileWithBookmark,
  EmailGate,
  EmailPreferenceModal,
  EmailShareModal,
  EngagementModule,
  ErrorNotification,
  ExpandableBlogPosts,
  ExpandableCoupons,
  ExpandableDeals,
  HomePage,
  HomePageDealsWithAds,
  HomePageMyDeals,
  HomePageTab,
  InfoNotification,
  InlineSubscriptionForm,
  ItemEngagementModule,
  LoginModal,
  MerchantCouponsPageItem,
  Merchants,
  MerchantsPageItem,
  MessagePreviewView,
  MiniCouponPageItem,
  MiniCoupons,
  MiniDealPageItem,
  MiniDeals,
  MobileMenuView,
  MobileSearchView,
  Modal,
  NavigationView,
  Notification,
  Pagination,
  RemoveAlertModal,
  ResetPasswordModal,
  SavedItems,
  SearchCouponsPage,
  SearchDealsPage,
  SearchMerchantsPage,
  SearchPage,
  SearchPagination,
  SearchPopularDeals,
  SearchPopularMerchants,
  SearchTabView,
  SearchTopCoupons,
  SignUpModal,
  SingleUseCoupon,
  SocialMediaWidget,
  StaleDealModule,
  SubscriptionModule,
  SubscriptionPauseModal,
  SubscriptionUnsubscribeModal,
  SuccessNotification,
  TabBar,
  ToggledNavigation,
  ToggleExpander,
  Tooltip,
  Travel,
  TravelPills,
  UnsubscribeModal,
  User: {
    AccountSettings,
    AccountSetting: {
      AccountSettingsPassword,
      AccountSettingsProfile,
      AccountSettingsSocial,
    },
    EmailPreferences,
    FacebookConnect,
    Preferences: {
      EmailSubscriptions,
      NewsletterPreferences,
      PromotionalPreferences,
      RecommendedPreferences,
      Subscription,
      Subscriptions,
      SubscriptionStatus,
    },
    UserResetPassword,
    SavedItemsPage,
  },
  UserBadge,
};
