export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<form action="/comments" class="clearfix comment-form" data-remote="true" data-type="json" method="post">\n  <div class="errors-container">\n    <ul class="reset errors"><i class="material-icons">warning</i></ul>\n  </div>\n  <input name="topic_slug" type="hidden" value="' +
((__t = ( this.model.get("slug") )) == null ? '' : __t) +
'" />\n  <input name="topic_title" type="hidden" value="' +
((__t = ( this.model.get("title") )) == null ? '' : __t) +
'" />\n  <input name="topic_url" type="hidden" value="' +
((__t = ( this.model.get("url") )) == null ? '' : __t) +
'" />\n  ';
 if (this.model.get('author')) { ;
__p += '\n    <input name="editor_bd_user_id" type="hidden" value="' +
((__t = ( this.model.get('author').id )) == null ? '' : __t) +
'" />\n  ';
 } ;
__p += '\n\n  <textarea name="comment_body" id="comment-body" placeholder="' +
((__t = (this.placeholderText())) == null ? '' : __t) +
'"></textarea>\n\n  <div class="sign-up-warning">' +
((__t = (this.signUpWarningText())) == null ? '' : __t) +
'</div>\n  <input class="rounded-button" data-disable-with="Adding A Comment..." name="commit" type="submit" value="' +
((__t = ( this.submitButtonText() )) == null ? '' : __t) +
'" />\n  <p>\n    <a href="/comment-policy">Comment Policy</a>\n  </p>\n</form>\n';
return __p
}