import BaseView from './base_view';
import CreateProfileModal from './modals/create_profile';
import SignUpModal from './modals/sign_up';
import Subscription from '../models/subscription';
import template from '../templates/create_subscription_form.jst.ejs';
import successMessageTemplate from '../templates/create_subscription_form_success.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.CreateSubscriptionForm
export default BaseView.extend({
  template: template,
  successMessageTemplate: successMessageTemplate,

  events: {
    'submit form': 'handleFormSubmit'
  },

  initialize: function (options) {
    this.options = this.options || options || {};
    this.requireFullUser = this.options.requireFullUser || false;

    bindAll(this, 'saveSubscription');

    this.initializeModel();

    this.render();
  },

  registerEvents: function () {
    this.listenTo(this.model, 'error', this.handleSubscriptionSaveError);
    this.listenTo(this.model, 'sync', this.handleSubscriptionSaveSuccess);
  },

  initializeModel: function (options) {
    this.options = this.options || options || {};

    this.model = new Subscription({
      trigger: this.options.trigger || 'deal-alert',
      name: this.options.name || null,
      query: {
        categories: this.options.categories || null,
        flags: this.options.flags || null,
        genders: this.options.genders || null,
        merchants: this.options.merchants || null,
        search_string: this.options.searchString || null
      }
    });

    this.registerEvents();
  },

  render: function () {
    this.$el.html(this.template());

    this.$validator = this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      errorContainer: 'form .error',
      errorLabelContainer: 'form .error',
      rules: {
        keyword_search: {
          required: true,
          maxlength: 255,
        }
      },
      messages: {
        keyword_search: {
          required: 'You\'ll need some words to go with that. Please enter a term to create an alert for.'
        }
      }
    });
  },

  renderSuccessMessage: function (message) {
    this.$('#create_subscription_input').append(this.successMessageTemplate({
      success: message
    }));
  },

  handleFormSubmit: function (event) {
    event.preventDefault();

    if (!this.requireFullUser || this.options.user.isFullUser()) {
      this.saveSubscription();
      return;
    }

    this.options.user.queuedAction.enqueue(this.saveSubscription);

    if (this.options.user.isSubscriber()) {
      App.Dispatcher.trigger('showModal', {
        modalClass: CreateProfileModal
      });
    } else {
      App.Dispatcher.trigger('showModal', {
        modalClass: SignUpModal,
        to: 5
      });
    }
  },

  saveSubscription: function () {
    var message = 'Looks like this deal alert is already created';
    var searchString = this.$('#keyword_search').val();

    this.model.set({ user_id: this.options.user.get('id') });
    this.model.setSearchString(searchString);

    if (this.isSubscribed()) {
      this.$validator.showErrors({ keyword_search: message });
    } else {
      this.model.save();
    }
  },

  handleSubscriptionSaveSuccess: function () {
    var message = this.model.getName() + ' Alert Created';

    // add this.model (subscription) to user\'s subscriptions collection
    this.options.user.get('subscriptions').add(this.model);

    this.stopListening(this.model);
    this.initializeModel();
    this.render();
    this.renderSuccessMessage(message);
  },

  handleSubscriptionSaveError: function () {
    var message = 'Something went wrong. Try refreshing the page.';
    this.$validator.showErrors({ keyword_search: message });
  },

  isSubscribed: function () {
    var query = this.model.get('query');
    var trigger = this.model.get('trigger');
    var subscriptions = this.options.user.get('subscriptions');

    return !!subscriptions.findByQueryAndTrigger(query, trigger);
  }
});
