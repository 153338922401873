import Forms from '../../lib/forms/forms';
import Modal from '../modal';
import CreateProfileModal from './create_profile';
import LoginModal from './login';
import Tooltip from '../tooltip';
import template from '../../templates/modals/sign_up.jst.ejs';

// App.Views.SignUpModal
export default Modal.extend({
  template: template,

  events: {
    'ajax:success form': 'success',
    'ajax:error form': 'ajaxError',
    'ajax:before  form': 'validate'
  },

  modalName: 'sign_up',

  afterRender: function () {
    this.createAndRenderTooltip();
    this.controlGenderVisibility();
    this.$validator = this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      rules: {
        email: Forms.Validations.rules.email
      },
      messages: {
        email: Forms.Validations.messages.email
      },
      showErrors: Forms.Validations.showErrors.inline
    });
  },

  beforeSever: function () {
    if (this.tooltipView) {
      this.tooltipView.remove();
    }
  },

  createAndRenderTooltip: function () {
    this.tooltipView = new Tooltip({
      el: '#gender-tooltip',
      tooltipTrigger: '<span class="link">what\'s this?</span>',
      tooltipContent: 'This will help us customize the selection of deals we send you.'
    }).render();
  },

  controlGenderVisibility: function () {
    if (this.options.gender === false) {
      this.$('.gender-field').remove();
    }
  },

  validate: function () {
    return this.$('form').valid();
  },

  hideEmail: function () {
    // use == instead of === to get coercion to int
    return this.options.lo == 2;
  },

  hideFacebook: function () {
    // use == instead of === to get coercion to int
    return this.options.lo == 1;
  },

  hideGoogle: function () {
    return App.Data.currentUrlParameters().acq === "true";
  },

  modalTitle: function () {
    // coerce to integer just in case it's a string
    switch (+this.options.to) {
      case 1:
        return 'Subscribe to Brad\'s Picks';
      case 2:
        return 'Sign up to favorite a store';
      case 3:
        return 'Sign up to save a deal';
      case 4:
        return 'Sign up to comment';
      case 5:
        return 'Sign up to create a deal alert';
      default: case 0:
        return 'Sign up for Brad\'s Deals';
    }
  },

  success: function (event, data, status, xhr) {
    if (data.errors) {
      App.Dispatcher.trigger('showModal', {
        modalClass: LoginModal,
        to: '1'
      });
    } else {
      App.Dispatcher.trigger('initUserApplication', data.user);
      App.Dispatcher.trigger('userSubscribe', data.user);
      App.Dispatcher.trigger('showModal', {
        modalClass: CreateProfileModal,
        to: '1'
      });
    }
  }
});
