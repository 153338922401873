const appVersionMetaTagSelector = 'meta[property="bd-app-version"]';
let cachedAppVersion;

/**
 * Get the App Version as designated by the meta tag with property 'bd-app-version'
 */
const appVersion = {
  /**
   * Attempts to return a cached value for the app version as parsed from the
   * meta tag with property="bd-app-version". If there is no cached version
   * it will cache it
   *
   * @returns appVersion
   */
  getAppVersion: () => {
    if (!cachedAppVersion) {
      const versionMetaTag = document.querySelector(appVersionMetaTagSelector);

      cachedAppVersion = versionMetaTag?.getAttribute('content') || undefined;
    }

    return cachedAppVersion;
  },

  /**
   * Mostly for testing purposes but since the version is a meta tag and you
   * may attmept to parse it before it has rendered you may need to clear the
   * cache and try again.
   *
   * Sets the cached app version to undefined.
   */
  clearAppVersionCache: () => {
    cachedAppVersion = undefined;
  },
};

export default appVersion;
