import Cookies from 'js-cookie';

import BaseView from '../base_view';
import HomePageTab from './home_page_tab';
import HomePageMyDeals from './home_page_my_deals';
import HomePageDealsWithAds from './home_page_deals_with_ads';
import HomePageMyDealsGrid from './home_page_my_deals_grid';
import HomePageDealGridWithAds from './home_page_my_deals_grid';
import TabBar from './tab_bar';
import { include } from 'underscore';
import { getRoute, buildRouteFunc } from '../../../../javascript/src/utils/route-service'

// App.Views.HomePage
export default BaseView.extend({
  DEFAULT_TAB: 'popular',
  TABS: [{
    collectionView: HomePageMyDeals,
    pathHelper: buildRouteFunc('/my-deals'),
    slug: 'my-deals',
    title: 'My Deals',
    url: '/api/my-deals',
  }, {
    collectionView: HomePageDealsWithAds,
    pathHelper: buildRouteFunc('/deals'),
    slug: 'popular',
    title: 'Popular',
    url: '/deals.json?sort=popularity',
  }, {
    collectionView: HomePageDealsWithAds,
    pathHelper: buildRouteFunc('/newest-deals'),
    slug: 'newest',
    title: 'Newest',
    url: '/deals.json?sort=start'
  }, {
    collectionView: HomePageDealsWithAds,
    pathHelper: buildRouteFunc('/shop/feeds/exclusive-deals'),
    slug: 'collection',
    title: 'Exclusives',
    url: '/collections/exclusive-deals.json'
  }],

  GRID_VIEW_TABS: [{
    collectionView: HomePageMyDealsGrid,
    pathHelper: buildRouteFunc('/my-deals'),
    slug: 'my-deals',
    title: 'My Deals',
    url: '/api/my-deals',
  }, {
    collectionView: HomePageDealGridWithAds,
    pathHelper: buildRouteFunc('/deals'),
    slug: 'popular',
    title: 'Popular',
    url: '/deals.json?sort=popularity',
  }, {
    collectionView: HomePageDealGridWithAds,
    pathHelper: buildRouteFunc('/newest-deals'),
    slug: 'newest',
    title: 'Newest',
    url: '/deals.json?sort=start'
  }, {
    collectionView: HomePageDealGridWithAds,
    pathHelper: buildRouteFunc('/shop/feeds/exclusive-deals'),
    slug: 'collection',
    title: 'Exclusives',
    url: '/collections/exclusive-deals.json'
  }],

  initialize: function (options) {
    this.options = this.options || options || {};
    this.flags = this.options.flags;
    this.slugs = [];
    this.tabs = {};
    this.dealsLayout = this.flags.dealsLayout || 'default';
    this.generateTabs();

    this.tabBarView = new TabBar({
      el: '#tab-bar',
      tabs: this.TABS,
      defaultTab: this.DEFAULT_TAB
    });

    var hash = window.location.hash.substring(1);
    var tab = hash || this.getTabPreference() || this.DEFAULT_TAB;
    if (hash === 'my-deals') {
      this.handleMyDealsScroll();
    };

    this.handleTabChange(tab);
  },

  initializeTab: function (tab) {
    var slug = tab.slug;
    this.slugs.push(slug);

    App.Dispatcher.on('hashChange:' + slug, this.handleTabChange, this);

    this.tabs[slug] = new HomePageTab({
      collectionView: tab.collectionView,
      pathHelper: tab.pathHelper,
      slug: slug,
      url: tab.url,
      dealsLayout: this.dealsLayout,
    });
  },

  generateTabs: function () {
    if (this.dealsLayout === 'grid') {
      this.GRID_VIEW_TABS.forEach(this.initializeTab, this);
    } else {
      this.TABS.forEach(this.initializeTab, this);
    }
  },

  handleMyDealsScroll: function () {
    var target = document.querySelector('#tab-bar');

    target.scrollIntoView({ behavior: 'smooth' });
  },

  handleTabChange: function (newTab) {
    var activeTab = this.activeTab;

    if (!include(this.slugs, newTab) || newTab === activeTab) return;

    activeTab && this.tabs[activeTab].hide();
    this.tabBarView.setActiveTab(newTab);
    this.setTabPreference(newTab);
    this.tabs[newTab].show();
    this.activeTab = newTab;
  },

  getTabPreference: function () {
    var prefs = Cookies.get('user_preferences');
    if (prefs) return JSON.parse(prefs).hpsort;
  },

  setTabPreference: function (tab) {
    Cookies.set('user_preferences', JSON.stringify({ hpsort: tab }), { expires: 90 });
  },
});
