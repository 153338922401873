import BaseView from '../../base_view';
import Subscription from './subscription';
import template from '../../../templates/subscriptions/no_alerts.jst.ejs';
import { partition } from 'underscore';

import brokenBellSVG from '../../../templates/svgs/broken_bell.jst.ejs'

var _super = BaseView.prototype;

// App.Views.User.Preferences.Subscriptions
export default BaseView.extend({
  emptyTemplate: () => template({ brokenBellSVG: brokenBellSVG }),

  initialize: function (options) {
    this.options = this.options || options || {};

    _super.initialize.apply(this, arguments);

    // listenToOnce is not available in this version of Backbone
    this.collection.once('sync', this.render, this);

    this.listenTo(this.collection, 'remove', this.handleSubscriptionRemove);
    this.listenTo(this.collection, 'add', this.handleSubscriptionAdd);

    this.listenTo(this.options.preferences, 'change:subscribe', this.handleSubscribeChange);

    this.render();
  },

  render: function () {
    this.resetModelViews();
    this.createModelViews();

    if (this.modelViews.length === 0) {
      return this.$el.html(this.emptyTemplate());
    } else {
      this.collection.off('sync');

      // removes emptyTemplate
      this.$el.empty();

      this.renderModelViews();
      return this.$el;
    }
  },

  handleSubscribeChange: function () {
    if (this.options.preferences.get('subscribe')) {
      this.$el.removeClass('disabled');
    } else {
      this.$el.addClass('disabled');
    }
  },

  handleSubscriptionRemove: function (model) {
    var splitModelViews = partition(this.modelViews, function (view) {
      return view.model.get('id') === model.get('id');
    });

    // index 0: models that were removed, so remove the view
    splitModelViews[0].forEach((view) => view.remove());

    // index 1: all other models; use as the array of modelViews
    this.modelViews = splitModelViews[1];

    if (this.modelViews.length === 0) {
      return this.$el.html(this.emptyTemplate());
    }
  },

  handleSubscriptionAdd: function (model) {
    var newModelView;
    // required to clear empty template view when adding a deal alert to an empty collection
    if (this.modelViews.length === 0) {
      this.render();
    } else {
      newModelView = this.createModelView(model, this.modelViews.length);

      this.renderModelView(newModelView);
    }

  },

  renderModelViews: function () {
    this.modelViews.forEach(this.renderModelView, this);
  },

  renderModelView: function (modelView) {
    this.$el.append(modelView.render());
  },

  createModelViews: function () {
    this.collection.where({ trigger: 'deal-alert' }).forEach(this.createModelView, this);
  },

  createModelView: function (model, index) {
    var modelView = new Subscription({
      model: model,
      index: index
    });

    this.modelViews.push(modelView);

    return modelView;
  },

  resetModelViews: function () {
    if (this.modelViews) {
      this.modelViews.forEach((view) => view.remove());
    }

    this.modelViews = [];
  }
});
