import { formatDistanceToNow } from 'date-fns';
import { each } from 'underscore';

// App.FuzzyTime
export default {
  fuzzyTimeAttributes: "[data-created-at]",

  initialize: function(App){
    App.Dispatcher.on("bdFuzzyTime:initialize", this.initFuzzyTime, this);
  },

  initFuzzyTime: function() {
    each($(this.fuzzyTimeAttributes), this.setFuzzyTime);
  },

  setFuzzyTime: function(container) {
    let $container = $(container);
    let date = new Date($container.data("created-at"));

    // Allows the function to degrade gracefully like moment JS.
    if (isNaN(date)) date = new Date();

    $container.text(formatDistanceToNow(date, { addSuffix: true }));
  }
};
