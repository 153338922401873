import CouponsPageItem from '../coupons_page_item';
import CouponAuthorInfo from './coupon_author_info';
import template from '../../templates/coupons/coupon_tile.jst.ejs';

// App.Views.CouponTile
export default CouponsPageItem.extend({
  template: template,

  preinitialize: function (options) {
    this.index = options.index;
  },

  attributes: function () {
    return {
      class: 'coupon-flex-tile',
      'data-id': this.model.get('id'),
      'data-item-type': 'coupon',
      'data-position': this.index
    };
  },

  render: function () {
    this.$el.html(this.template());
    this.renderAuthorInfoView();
    this.showAdvertiserDisclosure(this.model.get('merchant').merchant_rules);
    return this.$el;
  },

  renderAuthorInfoView: function () {
    this.authorInfoView = new CouponAuthorInfo({
      el: this.$('.expiration-date'),
      model: this.model
    });
    this.authorInfoView.render();
  },

  exceedCharacterLimit: function (title, charLimit) {
    var str = title.split(' ');
    var length = str.reduce((prev, curr) => {
      if (curr.length > prev) prev = curr.length;
      return prev;
    }, 0);
    return length > charLimit;
  },

  headerClass: function () {
    return this.exceedCharacterLimit(this.model.get('title'), 24) ? 'exceeded-length' : '';
  }
});
