import { each, inject, pairs } from 'underscore';

// App.Forms.Validations
const Validations = {
  rules: {
    comment_body: {
      required: true,
      maxlength: 1000
    },
    password: {
      required: true,
      minlength: 8
    },
    password_confirmation: {
      equalTo: '#new_password'
    },
    current_password: {
      required: true
    },
    email: {
      required: true,
      email: true
    }
  },
  messages: {
    comment_body: {
      required: 'You\'ll need some words to go with that.',
      maxlength: 'We want you to share, just not so much. (1000 characters please)'
    },
    password: {
      required: 'You\'ll need tell us what you want your <em>password</em> to be.',
      minlength: 'Make sure your <em>password</em> is at least 8 characters.'
    },
    password_confirmation: {
      equalTo: 'Please make sure your passwords match.'
    },
    current_password: {
      required: 'Please enter your current <em>password</em>.'
    },
    email: {
      required: 'Please enter your email address.',
      email: 'Make sure your email address is entered correctly and try again.'
    }
  },
  errorPlacement: {
    before: function (error, element) {
      error.insertBefore(element);
    },

    inputListBefore: function (error, element) {
      error.addClass('error');
      element.closest('li').before(error);
    }
  },

  showErrors: {
    inline: function (errorMap, errorList) {
      var $formLabels = $(this.currentForm).find('label');
      $formLabels.removeClass('error');
      var groups = this.groups;

      each(errorMap, function (message, key) {
        var $label = $(Validations.__determineLabel(groups, key, $formLabels));
        $label.find('span.error').html(message);
        $label.addClass('error');
      });
    }
  },

  __determineLabel: function (groups, elementName, labels) {
    return (
      labels.filter(`[for=${elementName}]`)[0]
      || (function () {
        const possibleLabelNames = inject(pairs(groups), function (memo, pair) {
          if (pair[1] === groups[elementName]) {
            memo.push(pair[0]);
          }

          return memo;
        }, []);

        const possibleSelectors = possibleLabelNames
          .map(possibleName => `label[for=${possibleName}]`)
          .join(',')

        return labels.filter(possibleSelectors)[0];
      })()
    );
  }
};

export default Validations;
