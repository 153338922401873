<template src='./my_deals_complete_profile.html'/>
<style src="./my_deals_complete_profile.scss" lang="scss" scoped></style>

<script>
import { post } from '../../../utils/api-service';
import parseErrors from '../../../utils/error-handler';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules.umd.js';


import InfoLinks from '../../info_links/info_links.vue';

// Validation rules required for ValidationProvider
extend('required', {
  ...required,
  message: '{_field_} is required'
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('min', {
  ...min,
  message: '{_field_} must be eight or more characters'
});

export default {
  name: 'MyDealsCompleteProfile',
  components: {
    'info-links': InfoLinks,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      errors: [],
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
    }
  },
  methods: {
    advanceStep() {
      this.$emit('advanceStep', 3);
      window.Segment.track(
        'User Flow Step Completed',
        {
          flow_name: 'mydeals_web_signup',
          step_name: 'complete_credentials',
          step_number: 3
        }
      );
    },
    handleSubmitError(response) {
      const { body } = response;
      this.errors = this.parseErrors(body.errors);
      console.error(this.errors);
    },
    handleSubmitSuccess(response) {
      return new Promise((resolve) => {
        const { status, body } = response;

        if (status === 200) {
          resolve(body.user);
        } else if (body.errors) {
          throw response;
        }
      });
    },
    handleSaveUsersInterests() {
      this.$emit('saveUsersInterests');
    },
    triggerUserProfileEvents(userJSON) {
      this.$dispatcher.trigger('initUserApplication', userJSON);
     },
    onSubmit() {
      const { password, firstName, lastName } = this;

      post('/user_profiles', {
        body: {
          password,
          first_name: firstName,
          last_name: lastName,
        }
      })
        .then(this.handleSubmitSuccess)
        .then(this.triggerUserProfileEvents)
        .then(this.handleSaveUsersInterests)
        .then(this.advanceStep)
        .catch(this.handleSubmitError)
    },
  },
}
</script>
