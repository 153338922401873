import BaseSearchView from './base_search_view';
import CouponsView from '../views/coupons';
import Coupons from '../models/coupons';
import SearchTopCoupons from '../views/search_top_coupons';
import SearchPagination from '../views/search_pagination';
import emptyTemplate from '../templates/no_coupon_search_results.jst.ejs';

// App.Views.SearchCouponsPage
export default BaseSearchView.extend({
  createViews: function () {
    this.collectionView = new CouponsView({
      el: this.$el.find('.collection'),
      collection: this.model.coupons(),
      emptyTemplate: emptyTemplate,
      collectionName: 'coupons'
    });

    this.collectionView.render();

    if (!this.model.coupons() || !this.model.coupons().length) {
      this.searchTopCoupons = new SearchTopCoupons({
        el: '#coupons-feed',
        collection: new Coupons()
      });
    } else {
      this.searchPagination = new SearchPagination({
        model: this.model,
        el: '.coupons .pagination',
        mainModel: 'coupons'
      });
    }
  },

  removeViews: function () {
    if (this.collectionView) this.collectionView.remove();
    if (this.searchPagination) this.searchPagination.remove();
    if (this.searchTopCoupons) this.searchTopCoupons.remove();
  }
});
