import { bind } from 'underscore';


// App.Controllers.FacebookPixelController
export default {
  initialize: function () {
    App.Dispatcher.on('initUserApplication', this.trackSignUp, this);
    App.Dispatcher.on('goLinkClick', this.trackGoClick, this);
  },

  trackSignUp: function (userJSON) {
    var path = window.location.pathname;
    var params = App.Data.currentUrlParameters();

    if (this.isLanding(path)) return;

    var created = userJSON && userJSON.created || params.newsub === 'true';
    var reactivated = userJSON && userJSON.reactivated || params.reactivated === 'true';
    var conversionEvents = this.conversionEvents(params.ce);

    if (
      (created && conversionEvents.includes('newsub')) ||
      (reactivated && conversionEvents.includes('reactivated'))
    ) {
      this.sendSignUpEvent(window.fbq);
    }
  },

  conversionEvents: function (ce) {
    if (ce === 'newsub') return ['newsub'];
    if (ce === 'reactivated') return ['reactivated'];
    return ['newsub', 'reactivated'];
  },

  // isLanding returns true when we are on /landing or /landing/mobile
  isLanding: function (path) {
    return /^\/landing(\/mobile)?$/.test(path);
  },

  sendSignUpEvent: function (fbq) {
    if (fbq) {
      fbq('trackCustom', 'signUp');
    } else {
      this.tryLater(bind(this.sendSignUpEvent, this));
    }
  },

  trackGoClick: function (itemAttrs) {
    this.sendGoClickEvent({
      item_type: itemAttrs.itemType,
      item_id: itemAttrs.itemId
    }, window.fbq);
  },

  sendGoClickEvent: function (itemAttrs, fbq) {
    if (fbq) {
      fbq('trackCustom', 'goClick', itemAttrs);
    } else {
      // NOTE: Would prefer to use native Function.prototype.bind for partial
      // application here, but phantomjs does not currently support it :-(
      this.tryLater(bind(this.sendGoClickEvent, this, itemAttrs));
    }
  },

  tryLater: function (fn) {
    setTimeout(function () {
      fn(window.fbq);
    }, 200);
  }
};
