<template src='./icon_pill.html'/>
<style src="./icon_pill.scss" lang="scss" scoped></style>

<script>
export default {
  name: 'IconPill',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.target.value, e.target.checked);
    },
  }
};
</script>
