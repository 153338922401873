import user from '@legacy-js/user-data/user';
import { capitalize } from '@legacy-js/lib/view_helpers';
import Experiment from '../../../javascript/src/utils/segment/experiment';

// App.Controllers.SegmentController
const CLIENT_GO_CLICK = 'Client Go Click';

export default {
  initialize: function() {
    App.Dispatcher.on('applicationLoaded', this.handleAppLoaded, this);
    App.Dispatcher.on('engagement:saved', this.handleNewEngagement, this);
    App.Dispatcher.on('initUserApplication', this.handleIdentifyUser);
    App.Dispatcher.on('userSubscribe', this.handleTrackSubscribeEvent, this);
    App.Dispatcher.on('route', this.handleRouteChange);
    App.Dispatcher.on('segment:track', this.handleSegmentTrack);
    App.Dispatcher.on('goLinkClick', this.handleGoLinkClick, this);
    App.Dispatcher.on('userUnsubscribe', this.handleUserUnsubscribe, this);
    App.Dispatcher.on('experimentViewed', this.handleExperimentEvent)
    $(document).on('click ajax:before', '#log-out', this.handleResetSegment);
  },

  handleAppLoaded: function() {
    user.get('subscriptions').on('add', this.handleNewSubscription, this);
    this.handleRouteChange(window.location.pathname);
  },

  handleIdentifyUser: function(userJson) {
    if (userJson && userJson.id) return Segment.identify(userJson.id);

    var params = App.Data.currentUrlParameters();
    Segment.identify(params.u_id);
  },

  handleNewSubscription: function(subscription) {
    var subscriptionJSON = subscription.toJSON();
    var properties = Object.assign(
      {
        name: subscriptionJSON.name,
        query_id: subscriptionJSON.query_id,
        subscription_id: subscriptionJSON.id,
      },
      subscriptionJSON.query,
    );

    this.handleSegmentTrack('Deal Alert Created', properties);
  },

  handleNewEngagement: function(_engagement, model) {
    var properties = { item_id: model.get('id'), item_type: model.modelClass };

    this.handleSegmentTrack('Item Saved Raw', properties);
  },

  handleResetSegment: function() {
    Segment.track('Signed Out');
    Segment.reset();
  },

  handleRouteChange: function(path) {
    Segment.page({ path: path });
  },

  handleSegmentTrack: function(eventName, properties) {
    Segment.track(eventName, properties);
  },

  handleGoLinkClick: function({ itemId, itemType }) {
    // model can be Deal, Coupon, or Merchant, but App.Data.PageItems has a pluralized item key
    const model = App.Data.PageItems[`${capitalize(itemType)}s`].get(itemId);
    let properties = {
      item_id: itemId,
      item_type: capitalize(itemType),
    };

    if (!model) return this.handleSegmentTrack(CLIENT_GO_CLICK, properties);

    const { brand, merchant, primaryCat, merchandiseType } = {
      brand: model.get('brand'),
      merchant: model.get('merchant'),
      primaryCat: model.get('primary_category'),
      merchandiseType: model.get('merchandise_type')
    };

    properties = {
      ...properties,
      ...(merchant?.name && { merchant_name: merchant.name }),
      ...(merchant?.slug && { merchant_slug: merchant.slug }),
    };

    if (itemType === 'deal') {
      let dealProps = {
        deal_id: itemId,
        deal_brand_name: brand?.name,
        deal_brand_slug: brand?.slug,
        deal_flags: model.get('flag_slugs')?.replace(/\s+/g, ','),
        // Allows 0 through, but not NaN
        deal_msrp: model.valueToFloatOrNull('msrp'),
        deal_price: model.valueToFloatOrNull('price'),
        deal_shipping_cost: model.valueToFloatOrNull('shipping_cost'),
        deal_primary_category_name: primaryCat?.name,
        deal_primary_category_slug: primaryCat?.slug,
        deal_primary_category_parent_name: primaryCat?.parent?.name,
        deal_primary_category_parent_slug: primaryCat?.parent?.slug,
        merchandise_taxonomy_slug: merchandiseType?.slug,
        merchandise_type_full_name: merchandiseType?.full_name,
      };

      // Delete any entries that have null or undefined values
      for (const [key, value] of Object.entries(dealProps)) {
        if ((value ?? null) === null) delete dealProps[key];
      }

      properties = {
        ...properties,
        ...dealProps,
      };
    }

    this.handleSegmentTrack(CLIENT_GO_CLICK, properties);
  },

  handleUserUnsubscribe: function() {
    this.handleSegmentTrack('Unsubscribed');
  },

  handleTrackSubscribeEvent: function(userJson) {
    if (!userJson) {
      return;
    }

    const { created, reactivated, email } = userJson;
    Segment.track('Subscribed', {
      is_new_activating_subscription: created || reactivated,
      is_new_user: created === true,
      email,
      email_md5: CryptoJS.MD5(email.toLowerCase()).toString(),
      email_sha256: CryptoJS.SHA256(email.toLowerCase()).toString(),
    });
  },

  handleExperimentEvent: function(campaignName) {
    const properties = Experiment()

    if (properties && campaignName === properties.campaign_name) {
      Segment.track('Experiment Viewed', properties);
    }
  }
};
