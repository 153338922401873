/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'homepage-interests' %> (and
// <%= stylesheet_pack_tag 'homepage-interests' %> if you have styles in your component)
// to the appropriate view file

import Vue from 'vue';
import PortalVue from 'portal-vue';

import Well from '../src/components/homepage-interests/well/well.vue';

// loading directive for fixing the position of the content in the overlay
import '../src/directives/snap-to';
import '../src/directives/sticky';

const initHompageInterests = () => {
  Vue.use(PortalVue);
  return new Vue({
    el: '.homepage-interests',
    render: root => root(Well),
  });
};

export default initHompageInterests;
