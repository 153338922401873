export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="content-card tip">\n  <a href="/blog/';
this.model.get('slug') ;
__p += '"><img src="' +
((__t = ( this.model.imageUrl() )) == null ? '' : __t) +
'"></a>\n  <div class="card-details">\n    <a href="/blog/' +
((__t = (this.model.get('slug') )) == null ? '' : __t) +
'" class="title">' +
((__t = ( this.model.get('title') )) == null ? '' : __t) +
'</a>\n  </div>\n</div>\n';
return __p
}