var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-deals-page my-deals-complete-profile"},[_c('h2',[_vm._v("You're Almost There.")]),_vm._v(" "),_c('p',[_vm._v("Confirm your password and enter your name to complete your account")]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:8|password:@Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label-container"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_vm._v(" "),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"id":"my-deals-password","type":"password","name":"password","placeholder":"Password","autocomplete":"new-password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label-container"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Confirm Password")]),_vm._v(" "),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],attrs:{"id":"my-deals-confirm-password","type":"password","name":"confirm-password","placeholder":"Confirm Password","autocomplete":"new-password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label-container"},[_c('label',{attrs:{"for":"first-name"}},[_vm._v("First Name")]),_vm._v(" "),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],attrs:{"id":"my-deals-first-name","type":"text","name":"first-name","placeholder":"First Name","autocomplete":"given-name"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label-container"},[_c('label',{attrs:{"for":"last-name"}},[_vm._v("Last Name")]),_vm._v(" "),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],attrs:{"id":"my-deals-last-name","type":"text","name":"last-name","placeholder":"Last Name","autocomplete":"family-name"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}})]}}],null,true)}),_vm._v(" "),_c('input',{class:['rounded-button', 'secondary'],attrs:{"type":"submit","value":"Save"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }