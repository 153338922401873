<template src="./signup-panel.html" />
<style src="./signup-panel.scss" lang="scss" scoped></style>

<script>
import { post } from '../../../utils/api-service';
import parseErrors from '../../../utils/error-handler';

export default {
  name: 'SignupPanel',
  props: {
    close: {
      type: Function,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    loggedIn: {
      type: Boolean,
      required: true,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    setCurrentStep: {
      type: Function,
      required: true,
    },
    setSubscribed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      errors: '',
      privacyPath: '/privacy',
      termsPath: '/terms-of-use',
    };
  },
  methods: {
    handleSubmitSuccess(response) {
      return new Promise((resolve) => {
        const { status, body } = response;

        if (status === 201) {
          resolve(body.user);
        } else if (body.errors) {
          throw response;
        }
      });
    },
    handleSubmitError(response) {
      const { body } = response;
      this.errors = this.parseErrors(body.errors);
    },
    handleFacebookSignup() {
      this.$FB.handleFacebookLoginClick()
        .then(this.setSubscribed, this.handleSignupError)
        .then(this.triggerSubscribedEvents)
        .then(this.goToNextStep);
    },
    handleGoogleSignup() {
      this.$Google.handleGoogleLoginClick()
        .then(this.setSubscribed, this.handleSignupError)
        .then(this.triggerSubscribedEvents)
        .then(this.goToNextStep);
    },
    handleSignupError(body) {
      console.error(body.errors);
    },
    triggerSubscribedEvents(userJSON) {
      this.$dispatcher.trigger('initUserApplication', userJSON);
      this.$dispatcher.trigger('userSubscribe', userJSON);

      if (!this.$dataLayer) return;

      if (userJSON.created) this.$dataLayer.push({ event: 'onBoardNewSubscriber' });
      if (userJSON.reactivated) this.$dataLayer.push({ event: 'onBoardReactivatedSubscriber' });
    },
    goToNextStep() {
      this.setCurrentStep(this.currentStep + 1);
    },
    onSubmit() {
      const { email } = this;
      post('/users', { body: { email } })
        .then(this.handleSubmitSuccess)
        .then(this.setSubscribed)
        .then(this.triggerSubscribedEvents)
        .then(this.goToNextStep)
        .catch(this.handleSubmitError);
    },
    parseErrors,
  },
};
</script>
