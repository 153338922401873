export default function(data) {
var __t, __p = '';
__p += '<h2 class="modal-title">Share this ' +
((__t = ( this.title() )) == null ? '' : __t) +
' with a friend</h2>\n<form accept-charset="UTF-8" action="/email/share" class="email-share-form" data-remote="true" data-type="json" method="post" autocomplete="off">\n  <div style="margin:0;padding:0;display:inline">\n    <input name="utf8" type="hidden" value="✓">\n  </div>\n  <div class="row">\n    <div class="col large-7 medium-7 small-12">\n      <input id="share_item_description_" name="share[item_description]" type="hidden" value="' +
((__t = ( encodeURIComponent(this.description()) )) == null ? '' : __t) +
'"/>\n      <input id="share_item_headline_" name="share[item_headline]" type="hidden" value="' +
((__t = ( encodeURIComponent(this.headline()) )) == null ? '' : __t) +
'"/>\n      <input id="share_item_url_" name="share[item_url]" type="hidden" value="' +
((__t = ( this.shareUrl() )) == null ? '' : __t) +
'" />\n      <input id="share_item_image_" name="share[item_image]" type="hidden" value="' +
((__t = ( this.model.get('item').shareImage() )) == null ? '' : __t) +
'" />\n      <input id="share_item_type_" name="share[item_type]" type="hidden" value="' +
((__t = ( this.model.get('item').modelClass )) == null ? '' : __t) +
'" />\n\n      <label for="share_name">Your name</label>\n      <input id="share_name" name="share[name]" type="text" value="' +
((__t = ( this.model.get("share").name )) == null ? '' : __t) +
'" />\n\n      <label for="share_from">Your email</label>\n      <input id="share_from" name="share[from]" type="email" value="' +
((__t = ( this.model.get("share").from )) == null ? '' : __t) +
'" />\n\n      <label for="share_to">Your friend\'s email</label>\n      <input id="share_to" name="share[to]" type="email" value="' +
((__t = ( this.model.get("share").to )) == null ? '' : __t) +
'" />\n      <ul class="horizontal submit-list reset">\n        <li><input class="rounded-button" name="commit" type="submit" value="Send email"></li>\n      </ul>\n    </div>\n    <div class="col large-5 medium-5 small-12">\n      <label>Here\'s what you\'re sharing:</label>\n      <div class="message-preview-area"></div>\n    </div>\n  </div>\n</form>\n';
return __p
}