import { reduce, compact, isUndefined } from 'underscore';

// App.ViewHelpers
const scrollToTop = function () {
  window.scrollTo(0, 0);
};

const isCurrentPath = function (path, __location) {
  return (__location || window.location.pathname) === path;
};

const hashFromQueryString = function (queryString) {
  queryString = queryString || '';
  var queryStringParts = queryString.split('?');

  // Determine if argument was URL or just the search and return the search
  var search = queryStringParts[1] || queryStringParts[0];

  var queryObject = {};
  var keyValuePair;

  if (search !== undefined) {
    keyValuePair = search.split('&');

    reduce(keyValuePair, function (memo, pair) {
      // Split string on only the first `=` rather than all instances
      var p = decodeURIComponent(pair).replace(/\+/g, ' ').replace(/=/, '↔').split('↔');

      memo[p[0]] = p[1];
      return memo;
    }, queryObject);
  }

  return queryObject;
};

const numberWithCommas = function (string) {
  return string?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const queryStringFromHash = function (queryParams) {
  return compact(Object.keys(queryParams).map(function (k) {
    if (!isUndefined(queryParams[k])) return k + '=' + encodeURI(queryParams[k]);
  })).join('&');
};

const birthdayOptions = {
  months: function () {
    var html = '';
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
                  'September', 'October', 'November', 'December'];
    for (var i = 1; i <= 12; i++) {
      html += "<option value='" + i + "'>" + months[i - 1] + '</option>';
    }

    return html;
  },

  days: function () {
    var html = '';
    for (var i = 1; i <= 31; i++) {
      html += '<option>' + i + '</option>';
    }

    return html;
  },

  years: function () {
    var curYear = (new Date()).getFullYear();
    var html = '';
    for (var i = curYear; i >= curYear - 100; i--) {
      html += '<option>' + i + '</option>';
    }

    return html;
  },
};

// needed so that it can be mocked out in spec
const currentLocationHref =  function () {
  return window.location.href;
};

const detectMobileDevice = function () {
  return (/iPhone|iPod|iPad|Android|IEMobile|BlackBerry/).test(navigator.userAgent);
};

const detectMobilePhone =function () {
  return (/iPhone|iPod|Mobile|IEMobile|BlackBerry/).test(navigator.userAgent);
};

const isDealDetailAndExpired = function () {
  return ($('.deal-detail-page').length) && ($('.expired-deal').length);
};

const detectTransitionSupport = function (cssStyle) {
  var style =     cssStyle || document.createElement('p').style;
  var tag =       'transition';
  var prefixTag = 'Transition';
  var prefixes =  ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];

  if (typeof style[tag] == 'string') return true;

  // Tests for vendor specific prop
  for (var i = 0; i < prefixes.length; i++) {
    if (typeof style[prefixes[i] + prefixTag] == 'string') return true;
  }

  return false;
};

const capitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const truncate = function (str, length) {
  return (str.length > length) ? str.substring(0, length) + '...' : str;
};

export {
  capitalize,
  truncate,
  scrollToTop,
  isCurrentPath,
  hashFromQueryString,
  queryStringFromHash,
  birthdayOptions,
  currentLocationHref,
  detectMobileDevice,
  detectMobilePhone,
  isDealDetailAndExpired,
  detectTransitionSupport,
  numberWithCommas,
};
