<template src="./well.html" />

<script>
import user from '@legacy-js/user-data/user';
import { get } from '../../../utils/api-service';
import Overlay from '../overlay/overlay.vue';
import UserActions from '../user-actions/user-actions.vue';
import BlurryMaskOverlay from '../blurry-mask-overlay/blurry-mask-overlay.vue';
import Interests from '../interests/interests.vue';
import ShowInterests from '../show-interests/show-interests.vue';

const SOURCE = 'my-deals';

export default {
  components: {
    overlay: Overlay,
    'user-actions': UserActions,
    interests: Interests,
    'show-interests': ShowInterests,
  },
  data() {
    return {
      blurryMaskOverlay: BlurryMaskOverlay,
      editInterests: false,
      userInterests: [],
      interests: [],
      loading: true,
    };
  },
  computed: {
    hasInterests() {
      return this.userInterests.length !== 0;
    },
  },
  mounted() {
    const promises = [this.getInterests()];

    this.$dispatcher.on('initUserApplication', () => {
      this.loading = true;
      this.getUserInterests().finally(() => {
        if (this.userInterests.length > 0) this.broadCastUserInterestChanges(this.userInterests);
        this.handleFinishedRequest();
      });
    });

    this.$dispatcher.on('sync:userInterests', this.updateUserInterests);

    if (user.loggedIn()) promises.push(this.getUserInterests());

    Promise.all(promises).finally(this.handleFinishedRequest);
  },
  methods: {
    getAllInterests() {
      const promises = [this.getInterests()];
      if (user.loggedIn()) promises.push(this.getUserInterests());

      Promise.all(promises).finally(this.handleFinishedRequest);
    },
    getInterests() {
      return get('/api/interests').then(
        this.handleGetInterestsSuccess,
        this.handleGetUserInterestsError,
      ).catch((e) => { console.error(e); });
    },
    getUserInterests() {
      return get('/api/users/interests').then(
        this.handleGetUserInterestsSuccess,
        this.handleGetUserInterestsError,
      ).catch((e) => { console.error(e); });
    },
    updateUserInterests(obj) {
      this.userInterests = obj.userInterests;
    },
    handleGetUserInterestsSuccess(response) {
      const { body: { users_interests } } = response;
      this.userInterests = users_interests.map((userInterest) => userInterest.interest_slug);
    },
    handleGetUserInterestsError(response) {
      console.log(response);
    },
    handleGetInterestsSuccess(response) {
      const { body: { interests } } = response;

      this.interests = interests;
    },
    handleFinishedRequest() {
      this.loading = false;
    },
    handleAddInterests() {
      this.editInterests = true;
      const mainContent = document.getElementsByClassName('main-content');
      if (mainContent && mainContent[0]) {
        window.scrollTo({ top: (mainContent[0].offsetTop - 40), left: 0, behavior: 'smooth' });
      }
    },
    broadCastUserInterestChanges(userInterests) {
      this.$dispatcher.trigger('sync:userInterests', { userInterests, source: SOURCE });
    },
  },
};
</script>

<style src="./well.scss" lang="scss" scoped></style>
