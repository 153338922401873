export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 if (this.model.isExpired()) { ;
__p += '\n  <div class="flag expired">Expired</div>\n';
 } ;
__p += '\n<div class="row block deal mini">\n  <div class="content">\n    <a href="/deals/' +
((__t = ( this.model.get('param') )) == null ? '' : __t) +
'">\n      <div class="product col large-3 medium-3 tight">\n        ' +
((__t = ( this.model.get('image') )) == null ? '' : __t) +
'\n        ' +
((__t = ( this.model.get('third_party_tracking_pixel') )) == null ? '' : __t) +
'\n      </div>\n      <div class="information col large-9 medium-9">\n        <h5 class="merchant-name">' +
((__t = ( this.model.get('merchant').name )) == null ? '' : __t) +
'</h5>\n        <h3>' +
((__t = ( this.model.get('title') )) == null ? '' : __t) +
' <span class="advertiser-disclosure-link" hidden="true">*</span></h3>\n      </div>\n    </a>\n  </div>\n  <div class="engagement-module-container"></div>\n</div>\n';
return __p
}