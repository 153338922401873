import BaseView from '../../base_view';
import UnsubscribeModal from '../../modals/unsubscribe';
import template from '../../../templates/users/preferences/subscription_status.jst.ejs';

// App.Views.User.Preferences.SubscriptionStatus
export default BaseView.extend({
  events: {
    'click #unsub-btn': 'handleButtonClick'
  },

  template: template,

  initialize: function () {
    this.render();
    this.listenTo(this.model, 'change:subscribe', this.render);
  },

  render: function () {
    var subscribed = this.model.get('subscribe');
    if (!this.$el.html()) {
      this.$el.html(
        this.template({ subscribed: subscribed })
      );
    } else {
      this.setText(subscribed);
    }
  },

  getText: function (subscribed) {
    return !subscribed ? 'Subscribe to all' : 'Unsubscribe from all';
  },

  setText: function (subscribed) {
    this.$('#unsub-btn').text(this.getText(subscribed));
  },

  handleButtonClick: function (e) {
    var subscribed = this.model.get('subscribe');

    if (!subscribed) {
      this.model.set('subscribe', true);
      this.model.save()
        .catch(function (error) {
          App.Dispatcher.trigger('addError', 'Oops, something went wrong! Please refresh the page and try again.');
        });
    } else {
      App.Dispatcher.trigger('showModal', {
        modalClass: UnsubscribeModal,
        model: this.model
      });
    }
  }
});
