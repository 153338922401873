import AccountSettings from '../views/users/account_settings';
import SavedItemsPage from '../views/users/saved_items_page';
import CreateProfileModal from '../views/modals/create_profile';
import LoginModal from '../views/modals/login';
import { bindAll } from 'underscore';
import user from '@legacy-js/user-data/user';

// App.Controllers.UserProfileController
export default {
  initialize: function () {
    bindAll(this, 'showAccountSettingsPage', 'showSavedItemsPage');
    App.Dispatcher.on('initAccountSettingsPage', this.initAccountSettingsPage, this);
    App.Dispatcher.on('initSavedItemsPage', this.initSavedItemsPage, this);
  },

  initAccountSettingsPage: function () {
    this.loginGate().done(this.showAccountSettingsPage);
  },

  showAccountSettingsPage: function () {
    new AccountSettings({
      el: '#account-settings-page',
      model: user
    });
  },

  initSavedItemsPage: function () {
    this.loginGate().done(this.showSavedItemsPage);
  },

  showSavedItemsPage: function () {
    new SavedItemsPage({
      el: '#saved-items-page',
      model: user
    });
  },

  loginGate: function () {
    var promise = $.Deferred();

    if (user.isFullUser()) {
      promise.resolve();
    } else {
      App.Dispatcher.on('user:logUp', promise.resolve)
      this.startLoginFlow();
    }
    return promise;
  },

  startLoginFlow: function () {
    App.Dispatcher.once('bdModalCancel', function () {
      window.location = '/';
    });

    if (user.isSubscriber()) {
      App.Dispatcher.trigger('showModal', {
        modalClass: CreateProfileModal
      });
    } else {
      App.Dispatcher.trigger('showModal', {
        modalClass: LoginModal
      });
    }
  }
};
