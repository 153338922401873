import BaseView from './base_view';
import CreateProfileModal from './modals/create_profile';
import Email from '../models/email';
import EmailShareModal from './modals/email_share';
import SignUpModal from './modals/sign_up';
import template from '../templates/engagement_module.jst.ejs';
import { bindAll } from 'underscore';
import user from '@legacy-js/user-data/user';

// App.Views.EngagementModule
 export default BaseView.extend({
  template: template,
  signUpModalTitleOption: "2",
  inheritsFromEngagementModuleView: true,

  events: {
    'click .email-share': 'handleEmailShareClick',
    'click .facebook-share': 'handleFacebookShareClick',
    'click .save:not(".saved")': 'handleSaveClick',
    'click .save.saved': 'handleDestroyClick'
  },

  initialize: function (options) {
    this.options = this.options || options || {};
    bindAll(this, "handleEmailShareClick", "handleFacebookShareClick", "handleSaveClick", "saveItem", "handleDestroyClick");
    user.get("engagements").on("add remove sync", this.render, this);
    this.render();
  },

  render: function(){
    return this.$el.html(this.template({
      item: this.model,
      index: this.index,
      isFavorited: this.isFavorited()
    }));
  },

  isFavorited: function(){
    const targetedModel = user
    .get("engagements")
    .where({
      item_type: this.model.modelClass.toLowerCase(),
      engagement_type: "save",
      item_id: this.model.id
    })

    return !!targetedModel && !!targetedModel.length;
  },

  handleEmailShareClick: function(){
    App.Dispatcher.trigger('showModal', {
      modalClass: EmailShareModal,
      model: new Email({ item: this.model })
    });
  },

  handleFacebookShareClick: function(){
    App.Dispatcher.trigger("bdFacebookShare", { model: this.model } );
  },

  handleSaveClick: function() {
    if (user.isFullUser()) {
      this.saveItem();
    } else {
      user.queuedAction.enqueue(this.saveItem);
      if (user.loggedIn() && user.isSubscriber()) {
        App.Dispatcher.trigger('showModal', {
          modalClass: CreateProfileModal
        });
      } else {
        App.Dispatcher.trigger('showModal', {
          modalClass: SignUpModal,
          to: this.signUpModalTitleOption
        });
      }
    }
  },

  engagementForModel: function(){
    const targetedModel = user
    .get("engagements")
    .where({
      item_type: this.model.modelClass.toLowerCase(),
      item_id: this.model.get("id")
    });

    return !!targetedModel ? targetedModel[0] : undefined
  },

  saveItem: function(){
    App.Dispatcher.trigger("bdEngagement:save", { model: this.model } );
  },

  handleDestroyClick: function(){
    this.engagementForModel()
      .destroy({
        data: "item_type=" + this.model.modelClass.toLowerCase() + "&" +
              "item_id=" + this.model.get("id") + "&" +
              "engagement_type=save"
      });
  }
});

