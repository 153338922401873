<template src="./show-interests.html" />
<style src="./show-interests.scss" lang="scss" scoped></style>

<script>
  export default {
    props: {
      userInterests: {
        type: Array,
        required: true,
      },
      interests: {
        type: Array,
        required: true,
      },
    },
    computed: {
      userInterestNames() {
        return this.interests.reduce((names, interest) => {
          if (this.userInterests.includes(interest.slug)) names.push(interest.name);
          return names;
        }, []);
      },
    },
  };
</script>
