import CollectionView from './collection_view';
import MerchantsPageItem from './merchants_page_item';

var _super = CollectionView.prototype;

// App.Views.Merchants
export default CollectionView.extend({
  modelName: 'Merchant',
  modelView: MerchantsPageItem,

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.initializeViews();
  }
});
