// App.Forms.Profile
export default {
  setSavedMessage: function ($el) {
    var $submitList = $el.find('.submit-list');
    $submitList.find('.form-notice').remove();
    var $formNotice = $('<li class="form-notice success icon checkmark">Saved</li>');
    $formNotice.one('webkitAnimationEnd oanimationend oAnimationEnd msAnimationEnd animationend', function () {
      $(this).remove();
    });

    $submitList.append($formNotice);
  }
};
