import BaseView from '../base_view';
import Forms from '../../lib/forms/forms';
import template from '../../templates/oauth/alexa_password_sign_in.jst.ejs';

// App.Views.AlexaPasswordSignIn
export default BaseView.extend({
  template: template,

  events: {
    'ajax:success form.login-form': 'success',
    'ajax:before  form.login-form': 'validate'
  },

  initialize: function (options) {
    this.options = this.options || options || {};

    this.render();
    this.setValidation();
  },

  render: function () {
    return this.$el.html(this.template({
      email: this.options.user.email
    }));
  },

  validate: function () {
    return this.$('form').valid();
  },

  setValidation: function () {
    this.$validator = this.$('form').validate({
      rules: {
        password: { required: true }
      },
      onfocusout: false,
      onkeyup: false,
      messages: {
        password: 'Please enter your password.'
      },
      showErrors: Forms.Validations.showErrors.inline
    });
  },

  success: function (event, data, status, xhr) {
    if (data.flash.success) {
      App.Dispatcher.trigger('initUserApplication', data.user);
    } else {
      this.$validator.showErrors({
        password: 'Incorrect password. Please try again.'
      });
    }
  }
});
