const parseErrors = (errors) => {
  if (typeof errors === 'string') return [errors];
  if (typeof errors !== 'object') return 'Something went wrong';

  return Object.keys(errors).map((errKey) => {
    return `${errKey} ${errors[errKey][0]}`;
  });
};

export default parseErrors;
