import BaseView from './base_view';
import loadingTemplate from '../templates/loading.jst.ejs';
import template from '../templates/search_tabs.jst.ejs';
import { queryStringFromHash } from '../lib/view_helpers';

// App.Views.SearchTabView
export default BaseView.extend({
  DEFAULT_TAB: 'deals',

  loadingTemplate: loadingTemplate,
  template: template,

  events: {
    'click .tab a': 'handleTabClick'
  },

  initialize: function () {
    this.render();
    this.toggleActiveTab();

    this.model.on('sync', this.handleSearchSync, this);
    this.model.on('request', this.handleSearchRequest, this);

    App.Dispatcher.on('route', this.handleRouteChange, this);
  },

  render: function () {
    var html = this.$el.html(this.template());

    this.$tabs = this.$('.tab');

    return html;
  },

  handleRouteChange: function (_path, params) {
    this.toggleActiveTab(this.tabForParams(params));
  },

  handleSearchRequest: function () {
    if (!this.$('.loading-container').length) this.$el.append(this.loadingTemplate());
  },

  handleSearchSync: function () {
    this.render();
    this.toggleActiveTab();
  },

  handleTabClick: function (event) {
    event.preventDefault();

    App.Router.push(event.target.href);
  },

  searchPath: function (tabName) {
    var params = App.Router.currentParams();

    params.type = tabName;

    let baseRoute = '/search'
    let urlParams = queryStringFromHash(params)
    // Replace spaces with + signs for consistency
    return `${baseRoute}?${urlParams}`.replace(/%20/g, '+')
  },

  tabForParams: function (params) {
    params = params || App.Router.currentParams();

    return params.type || this.DEFAULT_TAB;
  },

  toggleActiveTab: function (tabName) {
    tabName = tabName || this.tabForParams();

    this.$tabs.removeClass('active');

    this.$tabs.siblings('.' + tabName).addClass('active');
  }
});
