<template src="./my_deals_interests.html" />
<style src="./my_deals_interests.scss" lang="scss" scoped></style>

<script>
import IconPill from '../../icon_pill/icon_pill.vue';
import InfoLinks from '../../info_links/info_links.vue';

export default {
  name: 'MyDealsInterests',
  components: {
    'icon-pill': IconPill,
    'info-links': InfoLinks,
  },
  props: {
    interests: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedInterests: [],
    };
  },
  computed: {
    buttonDisabled() {
      return this.selectedInterests.length === 0;
    },
  },
  mounted() {
    window.Segment.track('User Flow Started', { flow_name: 'mydeals_web_signup' });
  },
  methods: {
    checked(interestSlug) {
      return this.selectedInterests.includes(interestSlug);
    },
    interestsIconUrl(interest) {
      return `${interest.image_url}?width=36`;
    },
    updateSelectedInterests(interest, checked) {
      if (checked && !this.selectedInterests.includes(interest)) {
        this.selectedInterests.push(interest);
      } else {
        const foundInterestIndex = this.selectedInterests.indexOf(interest);

        this.selectedInterests.splice(foundInterestIndex, 1);
      }
    },
    onNextClick() {
      this.$emit('setSelectedInterests', this.selectedInterests);
      this.$emit('advanceStep', 2);
      window.Segment.track(
        'User Flow Step Completed',
        {
          flow_name: 'mydeals_web_signup',
          step_name: 'select_interests',
          step_number: 1,
        },
      );
    },
  },
};
</script>
