import CollectionView from '../collection_view';
import MerchantsPageItem from '../merchants_page_item';

var _super = CollectionView.prototype;

// App.Views.SearchPopularMerchants
export default CollectionView.extend({
  modelName: 'Merchant',
  modelView: MerchantsPageItem,

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.collection.off('add reset remove sync', this.render);
    this.collection.once('sync', this.render, this);
    this.getCollection();
  },

  getCollection: function () {
    this.collection.fetch({ data: { limit: 20 }});
  },

  renderListItemView: function (modelView) {
    this.$el.append(modelView.render());
  },
});
