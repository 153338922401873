import Forms from '../../lib/forms/forms';
import Modal from '../modal';
import template from '../../templates/modals/create_profile.jst.ejs';
import user from '@legacy-js/user-data/user';

// App.Views.CreateProfileModal
export default Modal.extend({
  template: template,

  events: {
    'ajax:success form': 'success',
    'ajax:error form': 'ajaxError',
    'ajax:before form': 'validate'
  },

  modalName: 'create_profile',

  afterRender: function () {
    this.$('form').validate({
      onfocusout: false,
      onkeyup: false,
      rules: {
        first_name: { required: true },
        last_name: { required: true },
        password: Forms.Validations.rules.password
      },
      groups: {
        full_name: 'first_name last_name'
      },
      messages: {
        first_name: 'Be sure to include your <em>first and last name</em>.',
        last_name: 'Be sure to include your <em>first and last name</em>.',
        password: Forms.Validations.messages.password
      },
      showErrors: Forms.Validations.showErrors.inline
    });
  },

  validate: function () {
    return this.$('form').valid();
  },

  success: function (event, data, status, xhr) {
    if (data.user) {
      App.Dispatcher.trigger('initUserApplication', data.user);
      this.hideModal();
    }
  },

  modalTitle: function () {
    switch (+this.options.to) {
      case 1:
        return 'Next Step: Complete Your Profile';
      case 2:
        return 'You don\'t have a password yet.';
      default: case 0:
        return 'You\'re So Close...';
    }
  },

  currentUserEmail: function () {
    return user.get('email');
  }
});
