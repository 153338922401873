export default function(data) {
var __t, __p = '';
__p += '<form action="#" class="coupon-code-form">\n  <code class="hide"></code>\n  <input type="submit" class="rounded-button secondary" value="' +
((__t = ( this.options.getCouponText )) == null ? '' : __t) +
'" data-disable-with="' +
((__t = ( this.options.disableWithText )) == null ? '' : __t) +
'"></input>\n  <a class="rounded-button secondary copy-and-go hide" href="" data-clipboard-target="form code" target="_blank">' +
((__t = ( this.options.goLinkText )) == null ? '' : __t) +
'</a>\n</form>\n';
return __p
}