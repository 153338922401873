import BaseModel from './base_model';
import Commentable from '../mixins/commentable';
import Topic from './topic';
import { extend } from 'underscore';

// App.Models.BlogPost
var BlogPost = BaseModel.extend({
  urlRoot: '/blog.json',
  wrappedAttributes: {
    topic: Topic
  },

  imageUrl: function () {
    if (this.has('featured_image_urls')) return this.get('featured_image_urls').full;
  }
});
extend(BlogPost.prototype, Commentable);

export default BlogPost;
