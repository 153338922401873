import BaseView from './base_view';
import SearchTabView from '../views/search_tab_view';
import SearchDealsPage from '../views/search_deals_page';
import SearchCouponsPage from '../views/search_coupons_page';
import SearchMerchantsPage from '../views/search_merchants_page';
import { searchTrimmer } from '../lib/search-trim';

// App.Views.SearchPage
export default BaseView.extend({
  DEFAULT_VIEW: 'deals',

  initialize: function (options) {
    this.options = this.options || options || {};
    this.createViews();
    this.toggleActiveView();

    App.Dispatcher.on('route', this.handleRouteChange, this);

    this.model.on('sync', this.handleSearchSync, this);

    document
      .querySelector('.clearfix.search-form')
      .addEventListener('submit', searchTrimmer('#search-specific-query')
      )
  },

  createViews: function () {
    this.views = [];

    this.searchTabView = new SearchTabView({
      el: '#js-search-tabs',
      model: this.model
    });

    this.views.push(
      this.dealsView = new SearchDealsPage({
        el: '#deals-page',
        model: this.model
      })
    );

    this.views.push(
      this.couponsView = new SearchCouponsPage({
        el: '#coupons-page',
        model: this.model
      })
    );

    this.views.push(
      this.storesView = new SearchMerchantsPage({
        el: '#stores-page',
        model: this.model
      })
    );
  },

  handleRouteChange: function (_path, params) {
    this.toggleActiveView(this.viewForParams(params));
  },

  handleSearchSync: function () {
    this.dealsView.recreateViews();
    this.couponsView.recreateViews();
    this.storesView.recreateViews();
  },

  toggleActiveView: function (viewName) {
    viewName = viewName || this.viewForParams();

    this.views.forEach(function (view) {
      view.hide();
    });

    this[viewName + 'View'].show();
  },

  viewForParams: function (params) {
    params = params || App.Router.currentParams();
    return params.type || this.DEFAULT_VIEW;
  }
});
