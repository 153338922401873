export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 if (this.model.isExpired()) { ;
__p += '\n  <div class="flag expired">Expired</div>\n';
 } ;
__p += '\n<div class="row block coupon mini">\n  <div class="content">\n    <a href="/coupons/' +
((__t = ( this.model.get("merchant").slug )) == null ? '' : __t) +
'">\n      <div class="product">\n        <img src="' +
((__t = ( this.model.get("merchant").logo.small )) == null ? '' : __t) +
'" alt="' +
((__t = ( this.model.get("merchant").name )) == null ? '' : __t) +
' coupons" />\n        ' +
((__t = ( this.model.get('third_party_tracking_pixel') )) == null ? '' : __t) +
'\n      </div>\n      <div class="information">\n        ' +
((__t = ( this.model.get("merchant").name + ": " )) == null ? '' : __t) +
'\n        ' +
((__t = ( this.model.get("title") )) == null ? '' : __t) +
'\n        <span class="advertiser-disclosure-link" hidden="true">*</span>\n      </div>\n    </a>\n  </div>\n  ';
 if(this.model.isExpiringSoon()) { ;
__p += '\n    <span class="alert">Expiring Soon</span>\n  ';
 } ;
__p += '\n  <div class="engagement-module-container"></div>\n</div>\n';
return __p
}