export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="row">\n  <div class="author-info clearfix col large-12 medium-12 small-12"></div>\n</div>\n<div class="comment-body col large-11 medium-10 small-12">\n  <p>\n    ';
 if(this.model.get("by_editor")) { ;
__p += '\n      ' +
((__t = ( this.model.get("body") )) == null ? '' : __t) +
'\n    ';
 } else { ;
__p += '\n      ' +
((__t = ( this.model.escape("body") )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n  </p>\n</div>\n';
 if(this.isDeleteable()) { ;
__p += '\n  <div class="col delete-container">\n    <span class="icon trash delete link small">delete</span>\n  </div>\n';
 } ;
__p += '\n';
return __p
}