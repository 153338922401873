import BaseModel from './base_model';
import { contains } from 'underscore';

// App.Models.Author
export default BaseModel.extend({
  sizes: {
    small:  '40x40',
    medium: '100x100',
    large:  '250x250'
  },

  avatarUrl: function (size) {
    size = this.sizes[size] ? size : 'small';
    var dimensions = this.sizes[size];
    var id = this.get('id');
    var initial = (this.get('name') || '')[0];
    var urlOptions = [window.App.Data.ImageRedirectUrl, dimensions];
    var userSpecificOptions = [initial, id];

    // Add known user options to url if both initial and id are defined
    if (!contains(userSpecificOptions, undefined)) {
      urlOptions = urlOptions.concat(userSpecificOptions);
    }

    return urlOptions.join('/');
  }
});
