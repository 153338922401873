import BaseModel from './base_model';

// App.Models.EmailPreference
export default BaseModel.extend({
  modelClass: 'EmailPreference',

  url: function () {
    return `/me/email/${this.get('md5')}/preferences`;
  },

  // FORCES ALL SAVE CALLS TO PREFERENCES TO USE PUT METHOD
  isNew: function () {
    return false;
  },

  isNewsletterSubscribed: function () {
    return this.get('subscribe') && !this.get('opt_out_newsletter');
  },

  isPromotionalSubscribed: function () {
    return this.get('subscribe') && !this.get('opt_out_promotional');
  },

  isRecommendedSubscribed: function () {
    return this.get('subscribe') && !this.get('opt_out_recommended');
  }
});
