<template src="./user-actions.html" />

<script>
import user from '@legacy-js/user-data/user';
import { currentParams } from '../../../utils/params';

export default {
  data() {
    return {
      loggedIn: false,
      onboardingOpen: false,
    };
  },

  mounted() {
    const { v } = currentParams();
    if (v === 'onboarding') this.onboardingOpen = true;
    this.$dispatcher.on('initUserApplication', this.setLoggedIn);
    this.$dispatcher.on('initOnboarding', this.setOnboardingOpen);
    this.setLoggedIn();
  },

  methods: {
    onSignupClick() {
      if (this.onboardingOpen === true) {
        this.$dispatcher.trigger('openOnboardingDrawer');
      } else {
        this.$dispatcher.trigger('initMyDeals', { el: '#vue-app-root' });
      }
    },

    setLoggedIn() {
      this.loggedIn = user.loggedIn();
    },

    setOnboardingOpen() {
      this.onboardingOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  span {
    margin: 0 20px;
  }
</style>
