import Notification from '../notification';
import template from '../../templates/notifications/error_notification.jst.ejs';

// App.Views.ErrorNotification
export default Notification.extend({

  el: '<li class="error-house"></li>',

  // Subclasses should override
  template: template

});
