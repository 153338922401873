export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 if ( !data.item.isExpired() && data.item.isShareable() ) { ;
__p += '\n  <li class="share-target share-button grey-button share-solo">\n    <span class="helper-text hide-for-small">Share</span>\n    <i class="material-icons">share</i>\n    <ul class="reset dropdown">\n      <li class="hover-target facebook-share">\n        <button class="rounded-facebook">\n          <img class="rounded-facebook-icon" src="' +
((__t = ( App.Assets.imagePath("svgs/official_facebook_color.svg") )) == null ? '' : __t) +
'" alt="Facebook logo">\n        </button>\n        <span class="invisible-helper-text">Share on Facebook</span>\n      </li>\n      <li class="hover-target email-share">\n        <i class="material-icons">email</i>\n      </li>\n    </ul>\n  </li>\n';
 } ;
__p += '\n';
return __p
}