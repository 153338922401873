export default function(data) {
var __t, __p = '';
__p += '<div class="form-block edit-user-profile">\n  <form class="clean-form">\n    <div class="errors"></div>\n    <div class="row">\n      <div class="large-12 medium-12 small-12">\n        <span class="user-email"><strong>Your email:</strong> ' +
((__t = ( this.model.email )) == null ? '' : __t) +
'</span>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col tight large-5 medium-5 small-5 field">\n        <input type="text" id="first_name" name="first_name" value="' +
((__t = ( this.model.firstName() )) == null ? '' : __t) +
'" tabindex="1" />\n        <label for="first_name">First Name</label>\n      </div>\n      <div class="col tight large-7 medium-7 small-7 field last-name-input">\n        <input type="text" id="last_name" name="last_name" value="' +
((__t = ( this.model.lastName() )) == null ? '' : __t) +
'" tabindex="2" />\n        <label for="last_name">Last Name</label>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col tight large-12 medium-12 small-12 field">\n        <input id="location" name="location" id="location" type="text" value="' +
((__t = ( this.model.location() )) == null ? '' : __t) +
'" tabindex="3" placeholder="City, State">\n        <label for="location">Location</label>\n      </div>\n    </div>\n    <div class="row">\n      <label class="col tight">Birthday</label>\n      <div class="col tight large-3 medium-4 small-12 field">\n        <select id="birth-month" name="month" tabindex="4">\n          <option selected disabled>month</option>\n          ' +
((__t = ( this.birthMonthOptions() )) == null ? '' : __t) +
'\n        </select>\n        <label for="month">Month</label>\n      </div>\n      <div class="col large-2 medium-3 small-6 field birth-day-wrapper">\n        <select name="day" id="day" tabindex="5">\n          <option selected disabled>day</option>\n          ' +
((__t = ( this.birthDateOptions() )) == null ? '' : __t) +
'\n        </select>\n        <label for="day">Day</label>\n      </div>\n      <div class="col tight large-2 medium-3 small-6 field">\n        <select name="year" id="year" tabindex="6">\n          <option selected disabled>year</option>\n          ' +
((__t = ( this.birthYearOptions() )) == null ? '' : __t) +
'\n        </select>\n        <label for="year">Year</label>\n      </div>\n    </div>\n    <div class="col tight large-3 medium-4 small-12 field">\n      <select name="gender" id="gender" tabindex="7">\n        <option selected value>No Selection</option>\n        ' +
((__t = ( this.genderOptions() )) == null ? '' : __t) +
'\n      </select>\n      <label for="gender">Gender</label>\n    </div>\n    <div class="row">\n      <div class="col tight submit-list">\n        <input type="submit" class="rounded-button disabled" disabled="disabled" value="Save Changes" tabindex="8">\n      </div>\n    </div>\n  </form>\n</div>\n';
return __p
}