import SignUpModal from './sign_up';

var _super = SignUpModal.prototype;

// App.Views.AcquisitionSignUpModal
export default SignUpModal.extend({
  afterRender: function () {
    _super.afterRender.apply(this, arguments);
    this.controlClickablity();
    this.controlGenderVisibility();
  },

  controlClickablity: function () {
    $(document).off('click', '#modal-overlay');

    if (this.options.btn === 'false') {
      $('.close-modal').hide();
    }
  },

  controlGenderVisibility: function () {
    if (this.options.g !== '1') {
      this.$('.gender-field').remove();
    }
  },

  success: function (event, data, status, xhr) {
    if (data.user && !data.user.is_full_user && !data.user.errors) {
      App.Dispatcher.trigger('initUserApplication', data.user);
      App.Dispatcher.trigger('userSubscribe', data.user);
    }

    this.hideModal();
  }
});
