import BaseView from '../../base_view';
import template from '../../../templates/users/preferences/newsletter_preferences.jst.ejs';

// App.Views.User.Preferences.NewsletterPreferences
export default BaseView.extend({
  events: {
    'change input[name=newsletter-toggle]': 'handleNewsletterToggleChange',
    'change input[name=frequency]': 'handleFrequencyChange'
  },

  frequencies: [
    {
      id: 'daily',
      val: 1,
      label: 'Daily'
    },
    {
      id: 'twice',
      val: 3,
      label: 'Two Days<br /> A Week',
    },
    {
      id: 'weekly',
      val: 7,
      label: 'Weekly'
    }
  ],

  template: template,

  initialize: function () {
    this.listenTo(this.model, 'change', this.render);
    this.render();
  },

  render: function () {
    this.$el.html(
      this.template()
    );
    this.setDisabled();
    this.setToggle();
    this.setRadioButton();
  },

  // Sets the toggle to checked if the user is
  // subscribed to our emails and opted into the newsletter
  setToggle: function () {
    this.$('input').prop('checked', this.model.isNewsletterSubscribed());
  },

  triggerError: function (error) {
    App.Dispatcher.trigger('addError', 'Oops, something went wrong! Please refresh the page and try again.');
  },

  // Allows the user to toggle their Newsletter subscription on and off
  handleNewsletterToggleChange: function (e) {
    e.preventDefault();
    var optedOut = this.model.get('opt_out_newsletter');

    this.model.set('opt_out_newsletter', !optedOut);
    this.model.save()
      .catch(this.triggerError);
  },

  // When a user clicks the Unsubscribe button then the
  // frequency radios are disabled for Newsletter Emails
  setDisabled: function () {
    this.$('input[name=frequency]').prop('disabled', (!this.model.isNewsletterSubscribed()));
  },

  // Sets the Frequency radio button
  setRadioButton: function () {
    var val = this.model.get('frequency');

    // Defaults to once a day
    if (typeof(val) === 'undefined') val = 1;

    this.$('input[name=frequency][value=' + val + ']').prop('checked', true);
  },

  // Saves the Frequency after a user changes it
  handleFrequencyChange: function (event) {
    var val = parseInt(event.target.value);
    this.model.set('frequency', val);
    this.model.save()
      .catch(this.triggerError);
  }
});
