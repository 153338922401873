import CollectionView from './collection_view';
import CommentsPageItem from './comments_page_item';
import _ from 'underscore';

var _super = CollectionView.prototype;

// App.Views.Comments
export default CollectionView.extend({
  modelName: "Comment",
  modelView: CommentsPageItem,

  initialize: function (options) {
    _super.initialize.apply(this, arguments);
    this.listenTo(App.Dispatcher, 'bdUserReady', this.render);
    this.render();
  },
});
