// App.Controllers.ConfirmationsController
export default {
  initialize: function () {
    App.Dispatcher.on('initUserApplication', this.initNewUser, this);
    App.Dispatcher.on('initConfirmation', this.renderNotifications, this);
  },

  renderCompleteSubscription: function () {
    App.Dispatcher.trigger('addInfo',
      'To complete your subscription, please check your email to confirm your account', 0);
  },

  renderConfirmation: function (confirm) {
    if (confirm === 'true') {
      App.Dispatcher.trigger('addSuccess', "Thank you for signing up to Brad's Deals");
    } else {
      App.Dispatcher.trigger('addError',
        'Unable to confirm email; please try again from your email.');
    }
  },

  initNewUser: function (user) {
    if (user && user.created && !user.confirmed) {
      this.renderCompleteSubscription();
    }
  },

  renderNotifications: function () {
    var params = App.Data.currentUrlParameters();

    if (params.confirm) this.renderConfirmation(params.confirm);
    if (params.acqconfirm) this.renderCompleteSubscription();
  }

};
