import Backbone from "backbone";

// App.Collections.BaseCollection
const BaseCollection = Backbone.Collection.extend({
  scope: function(scope) {
    if (!scope) {
      return new this.constructor([]);
    }
    else if (Object == scope.constructor) {
      return new this.constructor(
        this.where(scope)
          .map((model) => model['attributes'])
      );
    }
    else if (Function == scope.constructor) {
      var models = this.map.call(this, scope);
      return new this.constructor(
        models
        .filter((model) => !!model)
        .map((model) => model['attributes'])
      );
    }
  }
});

export default BaseCollection;
