/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'onboarding' %> (and
// <%= stylesheet_pack_tag 'onboarding' %> if you have styles in your component)
// to the appropriate view file

import Vue from 'vue';

import OnboardingWrapper from '../src/components/onboarding/wrapper/wrapper.vue';
import CrossCircle from '../src/components/cross-circle/cross-circle.vue';

// TODO: Implement event queue similar to google ads to handle boot order
const initOnboarding = (target) => {
  // If Vue already rendered Onboarding
  if (!document.querySelector(target.el)) {
    Vue.prototype.$dispatcher.trigger('openOnboardingDrawer');
    return;
  }

  // Globally registered components available from any component within the app without importing
  Vue.component('cross-circle', CrossCircle);

  // Define Prototype Methods that should be available to all Vue components
  Vue.prototype.$FB = window.App.FB;
  Vue.prototype.$Google = window.App.Google;

  const { el = '#vue-app-root', startOpened } = target;

  new Vue({
    el,
    render: (root) => root(OnboardingWrapper, { props: { startOpened } }),
  });
};

export default initOnboarding;
