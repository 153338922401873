export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<h2 class="underlined">Social Accounts</h2>\n';
 if ( this.model.isFacebookUser() ) { ;
__p += '\n  <span class="col large-4 medium-5 small-12 rounded-button connected-social facebook-login-button disabled">\n    <img src="' +
((__t = ( App.Assets.imagePath("svgs/official_facebook_color.svg") )) == null ? '' : __t) +
'" alt="Facebook logo">\n    Facebook Connected\n  </span>\n  ';
 } else { ;
__p += '\n    <a class="col large-4 medium-5 small-12 rounded-button facebook-login-button signup">\n      <img src="' +
((__t = ( App.Assets.imagePath("svgs/official_facebook_white.svg") )) == null ? '' : __t) +
'" alt="Facebook logo">\n      Connect Facebook\n    </a>\n';
 } ;
__p += '\n';
 if ( this.model.isGoogleUser() ) { ;
__p += '\n  <span class="col large-4 medium-5 small-12 rounded-button connected-social google-login-button disabled">\n    Google Connected\n  </span>\n';
 } else { ;
__p += '\n  <a class="col large-4 medium-5 small-12 rounded-button google-login-button signup">\n    Connect Google\n  </a>\n';
 } ;
__p += '\n';
return __p
}