// App.Controllers.Ads
export default {
  initialize() {
    App.Dispatcher.on('needsStickyAds', this.initFixedAd, this);
    App.Dispatcher.on('disableStickyAds', this.disableStickyAds, this);
  },

  setFixedAd() {
    // If sidebar is taller than the content, bail.
    const bodyContent = document.getElementById('body-content');
    if (!bodyContent) {
      return true;
    }
    const mainContent = bodyContent.getElementsByClassName('collapsible');
    const sidebar = bodyContent.getElementsByClassName('sidebar');
    if (mainContent.length && sidebar.length && mainContent[0].clientHeight <= sidebar[0].clientHeight) {
      return true;
    }
    const $stalkerAdContainer = $('.sticky');
    const stalkerAd = $stalkerAdContainer.find('> div');
    const footerInView = ($(window).height() + $(window).scrollTop())
      >= ($('.body-content').offset().top + $('.body-content').innerHeight());
    const windowPosition = $(window).scrollTop();
    const navClearance = 120;
    const topOffset = ($stalkerAdContainer.offset().top - navClearance);

    if (windowPosition > topOffset) {
      if (footerInView) {
        stalkerAd.removeClass('fixed')
          .css({ position: 'absolute', bottom: $(window).height() - stalkerAd.innerHeight() });
      } else {
        stalkerAd.css({ position: '', bottom: '' }).addClass('fixed');
      }
    } else {
      stalkerAd.removeClass('fixed').css({ position: '', bottom: '' });
    }
    return true;
  },

  initFixedAd() {
    $('#internal-ridealong').css({ display: 'block' });

    // Check to see if browser supports matchMedia. Bugfix for IE9
    if ('matchMedia' in window) {
      const mq = window.matchMedia('(min-device-width: 700px)');
      if (mq.matches) {
        App.Dispatcher.on('viewDidScroll', this.setFixedAd, this);
      }
    }
  },

  disableStickyAds() {
    App.Dispatcher.off('viewDidScroll');
  },
};
