import CommentLinkModule from '../views/engagements/comment_link_module';
import DealsPageItem from '../views/deals_page_item';
import SocialMediaWidget from '../views/social_media_widget';
import BlogPost from '../models/blog_post';
import Deal from '../models/deal';

// App.Controllers.BlogsController
export default {
  initialize: function () {
    App.Dispatcher.on('initializeBlogPostShow', this.initShow, this);
  },

  initShow: function (postInformation) {
    var blogPost = new BlogPost(postInformation);
    var $embeddedDeals = $('a[data-deal-id]');

    if ($embeddedDeals.length) {
      this.renderEmbeddedDeals($embeddedDeals);
    }

    this.socialMediaWidget = new SocialMediaWidget({
      el: $('.social-media-widget'),
      model: blogPost
    });

    this.commentLinkModule = new CommentLinkModule({
      el: $('footer.row .chat-pack'),
      model: blogPost
    });

    App.Dispatcher.trigger('initDiscussion', blogPost.get('topic'));
  },

  renderEmbeddedDeals: function ($embeddedDeals) {
    $embeddedDeals.each((index, el) => {
      var $el = $(el);
      var deal = new Deal({
        id: $el.data().dealId
      });
      var dealView = new DealsPageItem({
        model: deal,
        tagName: 'div',
        index: index
      });
      deal.fetch().done(function () {
        $el.replaceWith(dealView.render());
        dealView.render();
      });
    });
  }
};
