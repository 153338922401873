import DealsShowPage from '../views/deals_show_page';

// App.Controllers.DealsController
export default {
  initialize: function () {
    App.Dispatcher.on('initDealShow', this.initDealShow, this);
  },

  initDealShow: function (dealId, selector) {
    var hasPDSRef = window.location.search.match(/ref=pds_job_id_[0-9]+/);

    // Initialize the deal show page
    new DealsShowPage({
      el: selector,
      model: App.Data.PageItems.Deals.get(dealId)
    });

    if (hasPDSRef) this.mountBackButton();
  },

  mountBackButton: function () {
    var backButtonMount = document.getElementsByClassName('my-deals-home-button-mount')[0];
    var backButton = document.createElement('a');
    backButton.classList.add('rounded-button', 'secondary')
    backButton.setAttribute('href', '/shop');
    backButton.innerHTML = 'Back To My Deals';
    backButtonMount.appendChild(backButton);
  }
};
