// Methods designed to allow vue.js to submit requests to rails endpoints with additional restful methods.
// Works similar to rails UJS, but since UJS method are coupled to their DOM elements there is no
// good way to invoke the nessessary methods without causing invalid bindings
const getCsrfToken = () => {
  const meta = document.querySelector('meta[name=csrf-token]');
  return meta && meta.content;
};

const getCsrfParam = () => {
  const meta = document.querySelector('meta[name=csrf-param]');
  return meta && meta.content;
};

export { getCsrfToken };
