import MobileMenuView from '../views/mobile_menu_view';
import MobileSearchView from '../views/mobile_search_view';
import { detectMobileDevice } from '../lib/view_helpers';

// App.Controllers.MobileDevicesController
export default {
  initialize: function(){
    App.Dispatcher.on('initMobileCode', this.initMobileCode, this);
    App.Dispatcher.on('initMobileNav', this.initMobileNav, this);
    App.Dispatcher.on('toggleiPhoneViewport', this.toggleiPhoneViewport, this);
  },

  initMobileCode: function(){
    if (detectMobileDevice()) {
      this.setMobileTouchHelper();
    }
    this.detectResponsiveLayout();
    this.initFooterExpander();
    this.initCategoryDrawer();
  },

  initMobileNav: function(searchApiUrl) {
    this.mobileSearchView = new MobileSearchView({
      el: '.autocomplete-container',
      searchApiUrl: searchApiUrl
    });

    this.mobileMenuView = new MobileMenuView({
      el: 'nav.site-wide'
    });
  },

  toggleiPhoneViewport: function(visibility){
    if ((/iPhone|iPod/).test(navigator.userAgent)) {
      var pageElements = $('.leaderboard, header, .spanning, .body-content, footer');
      if (visibility === "hide") {
        pageElements.show();
        $('meta[name="viewport"]').attr("content", "initial-scale=-1.0, maximum-scale=-1.0");
      } else if (visibility === "show") {
        pageElements.hide();
        $('meta[name="viewport"]').attr("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=1");
      }
    }
  },

  detectResponsiveLayout: function(){
    if ($('body').hasClass('responsive')) {
      // responsive pages already have the necessary viewport and do not need it to change
      App.Dispatcher.off('toggleiPhoneViewport');
    }
  },

  setMobileTouchHelper: function(){
    // This needs to exist in order for mobile browsers to respect
    // the "tap-out" functionality to close drop downs
    $('body').addClass('mobile-touch-helper');
  },

  initFooterExpander: function() {
    $(document).on('click', '.footer-expander', function(e) {
      $('.text-container').toggleClass('fade-in');
      $(this).toggleClass('arrow-down arrow-up');
      document.getElementById('footer-navigation').scrollIntoView({behavior: "smooth"});
    });
  },

  initCategoryDrawer: function() {
    $(document).on('click', '.category-expander', function(e) {
      $('.category-drawer').toggleClass('fade-in');
      $(this).toggleClass('arrow-down arrow-up');
    });
  }
};
