import BaseModel from './base_model';
import { getRoute } from '../../../javascript/src/utils/route-service'
import { pick } from 'underscore';

// App.Models.UserProfile
export default BaseModel.extend({
  url: getRoute('user-profiles'),

  initialize: function () {
    this.on('change', this.setBirthday);
    if (this.get('birthday')) {
      var birthday = this.get('birthday').split('-').map((s) => parseInt(s));
      this.set({ year: birthday[0], month: birthday[1], day: birthday[2] });
    }
  },

  isNew: function () { return false; },

  toJSON: function () {
    return pick(this.attributes, 'birthday', 'first_name', 'gender', 'last_name', 'location');
  },

  setBirthday: function () {
    var attributes = pick(this.attributes, 'year', 'month', 'day');
    if (attributes.year && attributes.month && attributes.day) {
      var date = new Date(Date.UTC(attributes.year, attributes.month - 1, attributes.day))
      this.set({ birthday: date.toISOString().replace(/T.+/, '') });
    }
  }
});
