export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

if (this.maxPageNumber) { ;
__p += '\n<div class="pagination">\n  <ul class="reset clearfix">\n    <li class="previous">\n      <a href="' +
((__t = ( this.previousPageUrl() )) == null ? '' : __t) +
'" class="icon arrow-left ' +
((__t = ( this.isFirstPage() ? 'disabled' : '' )) == null ? '' : __t) +
'">prev</a>\n    </li>\n    <li class="next">\n      <a href="' +
((__t = ( this.nextPageUrl() )) == null ? '' : __t) +
'" class="icon arrow-right end-of-line ' +
((__t = ( this.isLastPage() ? 'disabled' : '' )) == null ? '' : __t) +
'">next</a>\n    </li>\n  </ul>\n  <span class="page-count">\n    Page ' +
((__t = ( this.currentPageNumber )) == null ? '' : __t) +
' of ' +
((__t = ( this.maxPageNumber )) == null ? '' : __t) +
'\n  </span>\n</div>\n';
 } ;
__p += '\n';
return __p
}