import CollectionItemView from '../collection_item_view';
import template from '../../templates/blog_post_tile.jst.ejs';

// App.Views.BlogPostTile
export default CollectionItemView.extend({
  template: template,
  className: 'col large-3 medium-4 small-6',
  tagName: 'li',

  render: function () {
    this.$el.html(this.template());
    return this.$el;
  }
});
