export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p +=
((__t = ( data.bdPrimaryReversedSVG() )) == null ? '' : __t) +
'\n<h1>' +
((__t = ( this.modalText().h1 )) == null ? '' : __t) +
'</h1>\n<h2 class=\'modal-subtitle\'>' +
((__t = ( this.modalText().h2 )) == null ? '' : __t) +
'</h2>\n<h3 class=\'modal-subtitle\'>' +
((__t = ( this.modalText().h3 )) == null ? '' : __t) +
'</h3>\n<form action=\'/users\' method=\'POST\' data-remote=\'true\' class=\'sign-up-form\' data-type=\'json\'>\n  ';
 if (!this.hideEmail()) { ;
__p += '\n    <ul class=\'errors reset\'></ul>\n    <input type=\'email\' id=\'email\' name=\'email\' placeholder=\'Enter Your Email Here\' />\n    <input type=\'submit\' value=\'Sign up to unlock the top deals\' class=\'rounded-button secondary\' style=\'' +
((__t = ( this.buttonStyling() )) == null ? '' : __t) +
'\'>\n  ';
 } ;
__p += '\n  ';
 if (!this.hideFacebook()) { ;
__p += '\n    <span class="rounded-button facebook-login-button signup">\n      <img src="' +
((__t = ( App.Assets.imagePath("svgs/official_facebook_white.svg") )) == null ? '' : __t) +
'" alt="Facebook logo">\n      Log In With Facebook\n    </span>\n  ';
 } ;
__p += '\n</form>\n<a href="' +
((__t = ( this.closeModalPath() )) == null ? '' : __t) +
'" class="cancel">No thanks, I\'m not interested in the best deals</a>\n';
return __p
}