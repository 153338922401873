import BaseModel from './base_model';
import user from '@legacy-js/user-data/user';

// App.Models.Email
export default BaseModel.extend({
  initialize: function () {
    var currentUser = user;
    var share = {};

    if (currentUser.loggedIn()) {
      share.from = currentUser.get('email');
    }

    if (currentUser.isFullUser()) {
      share.name = currentUser.get('userProfile').firstName;
    }

    this.set('share', share);
  }
});
