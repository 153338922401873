import BaseView from './base_view';
import DealTiles from '../views/deal_tiles';
import template from '../templates/expandable_deals.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.ExpandableDeals
export default BaseView.extend({
  template: template,

  events: {
    'click .more-deals': 'handleDealFetch'
  },

  initialize: function () {
    bindAll(this, 'enableButton');
    this.collection.on('sync', this.render, this);
    this.render();
  },

  render: function () {
    this.$el.html(this.template());
    if (this.collectionView) {
      this.collectionView.remove();
    }

    this.createViews();

    return this.$el;
  },

  createViews: function () {
    this.collectionView = new DealTiles({
      el: '#deal-collection',
      collection: this.collection,
      itemClassName: 'col large-3 medium-3 small-6'
    });
    this.collectionView.render();
  },

  handleDealFetch: function () {
    this.disableButton();
    this.collection.fetchNext().fail(this.enableButton);
  },

  enableButton: function () {
    this.$('.more-deals').removeClass('disabled');
  },

  disableButton: function () {
    this.$('.more-deals').addClass('disabled');
  }
});
