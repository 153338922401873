import BaseModel from './base_model';
import Commentable from '../mixins/commentable';
import Coupons from './coupons';
import Deals from './deals';
import Topic from './topic';
import { extend } from 'underscore';
import { capitalize } from '@legacy-js/lib/view_helpers';

var _super = BaseModel.prototype;
// App.Models.Merchant
var Merchant = BaseModel.extend({
  urlRoot: '/stores.json',

  modelClass: 'Merchant',

  wrappedAttributes: {
    online_coupons: Coupons,
    printable_coupons: Coupons,
    deals: Deals,
    sales: Coupons,
    topic: Topic
  },

  parse: function (data) {
    if (data.coupons) {
      data.online_coupons = data.coupons.online;
      data.printable_coupons = data.coupons.printable;
      data.sales = data.coupons.promotion;
    }

    return _super.parse.call(this, data);
  },

  url: function () {
    return this.urlRoot + '/' + this.get('slug');
  },

  shareURL: function () {
    return `${this.get('rails_env_base_url')}/stores/${this.get('param')}`;
  },

  shareImage: function () {
    return this.get('logo');
  },

  shareName: function () {
    return this.get('name');
  },

  shareDescription: function () {
    var seoContent = this.get('seo_content');
    return (seoContent && seoContent.attributes.store_coupons) || this.get('name');
  },

  isShareable: function () {
    var rules = this.get('merchant_rules');
    if (rules) {
      return !rules.disable_social_sharing;
    } else {
      return true;
    }
  },

  isExpired: function () {
    return false;
  },

  couponDisplayName: function () {
    return (this.get('merchant_rules').coupon_name || 'coupon');
  },

  pluralDecoratedCouponName: function () {
    return (capitalize(this.couponDisplayName()) + 's');
  }
});

extend(Merchant.prototype, Commentable);

export default Merchant;
