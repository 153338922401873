import BaseCollection from './base_collection';
import Engagement from './engagement';
import Merchants from './merchants';
import SavedItems from './saved_items';

// App.Collections.Engagements
export default BaseCollection.extend({
  url: '/users/engagements.json',
  model: Engagement,

  initialize: function () {
    this.on('remove', this.removeEngagedItem, this);
    this.merchants = new Merchants;
    this.savedItems = new SavedItems;
  },

  dealAndCouponSaveCounts: function () {
    // This currently only calls in the deals, but will bring in saved coupons again one day
    return this.where({ engagement_type: 'save', item_type: 'deal' }).length;
  },

  merchantSaveCounts: function () {
    return this.merchantEngagments().length;
  },

  savedMerchantIds: function () {
    return this.merchantEngagments().sort((engagementA, engagementB) => {
      const updatedAtA = Date.parse(engagementA.get('updated_at')) * -1;
      const updatedAtB = Date.parse(engagementB.get('updated_at')) * -1;

      if (updatedAtA < updatedAtB) {
        return -1
      } else if (updatedAtA > updatedAtB) {
        return 1
      }
      return 0
    }).map((merchant) => merchant.get('item_id'));
  },

  merchantEngagments: function () {
    return this.where({ engagement_type: 'save', item_type: 'merchant' });
  },

  fetchMerchants: function () {
    if (this.merchantSaveCounts() > 0) {
      return this.merchants.fetch({ data: { ids: this.savedMerchantIds().join() } });
    } else {
      this.merchants.trigger('sync');
    }
  },

  removeEngagedItem: function (engagement) {
    if (engagement.get('item_type') === 'merchant') {
      this.merchants.remove(engagement.get('item_id'));
    } else {
      this.savedItems.remove(engagement.get('item_id'));
    }
  },

  fetchSavedItems: function () {
    return this.savedItems.fetch(this.savedItemIds());
  },

  dealAndCouponEngagements: function () {
    // This currently only calls in the deals, but will bring in saved coupons again one day
    return this.where({ engagement_type: 'save', item_type: 'deal' });
  },

  savedItemIds: function () {
    return this.dealAndCouponEngagements().sort((engagementA, engagementB) => {
      const updatedAtA = Date.parse(engagementA.get('updated_at')) * -1;
      const updatedAtB = Date.parse(engagementB.get('updated_at')) * -1;

      if (updatedAtA < updatedAtB) {
        return -1
      } else if (updatedAtA > updatedAtB) {
        return 1
      }
      return 0
    }).map((item) => {
      return { item_id: item.get('item_id'), item_type: item.get('item_type') };
    });
  }
});
