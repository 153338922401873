/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import GeoLocation from '@legacy-js/lib/geo_location';
import { bindAll, clone } from 'underscore';
import Backbone from 'backbone';
// import $ from 'jquery';

import {
  google, facebook, PageItems,
} from '@legacy-js/lib/data_initializers';
import { hashFromQueryString } from '@legacy-js/lib/view_helpers';

const App = {
  GeoLocation: new GeoLocation(document.location.host),
  Mixins: {},
  Lib: {},
  Data: {
    currentUrlParameters() {
      if (App.Data.__currentUrlParameters) return App.Data.__currentUrlParameters;
      App.Data.__currentUrlParameters = hashFromQueryString(
        window.location.search,
      );

      return App.Data.__currentUrlParameters;
    },

    google,

    facebook,

    PageItems,
  },
  Dispatcher: clone(Backbone.Events),

  Router: {
    currentPath() {
      return window.location.pathname + window.location.search + window.location.hash;
    },

    currentParams(path) {
      const newPath = path || window.location.search;

      return hashFromQueryString(newPath);
    },

    push(path, data) {
      const newData = data || App.Router.currentParams(path);

      window.history.pushState(newData, '', path);

      App.Dispatcher.trigger('route', path, newData);
    },
  },

  initialize() {
    this.registerEvents();
    this.handleHashChange();
  },

  registerEvents() {
    $(window).on('hashchange', this.handleHashChange);
    window.addEventListener('popstate', this.handlePopState);
  },

  handleHashChange() {
    const hash = window.location.hash.replace(/^#/, '');
    if (hash.length < 1) return;
    this.Dispatcher.trigger('hashChange', hash);
    this.Dispatcher.trigger(`hashChange:${hash}`, hash);
  },

  handlePopState(event) {
    // Prefer to use the previous state, but fall back to current URL parameters
    const data = event.state || this.Router.currentParams();
    const path = this.Router.currentPath();

    this.Dispatcher.trigger('route', path, data);
  },
};

bindAll(App, 'handleHashChange', 'handlePopState', 'initialize');

export default App;
