import CollectionView from '../../collection_view';
import EmailSubscription from '../../../models/email_subscription';
import { bindAll, debounce } from 'underscore';

var _super = CollectionView.prototype;

// App.Views.User.Preferences.EmailSubscriptions
// @TODO: Remove since App.Views.User.Preferences.EmailSubscription is not a thing
export default CollectionView.extend({
  modelName: "Newslettertype",
  modelView: null,

  initialize: function(options) {
    this.options = this.options || options || {};
    bindAll(this, "success");
    _super.initialize.apply(this, arguments);

    this.renderLoading();
    // Initialize here so all tiles will inherit
    this.emailSubscription = new EmailSubscription(
        { md5: this.options.additionalOptions.emailPreferences.get('md5') }
    );
    // EXPOSE TO THE CHILD VIEWS
    this.options.additionalOptions.emailSubscription = this.emailSubscription;
    this.emailSubscription.fetch().success(this.success);
  },

  success: function() {
    this.render();
    this.listenTo(this.emailSubscription, "change", debounce(this.saveEmailSubscription, 1000));
  },

  saveEmailSubscription: function() {
    this.emailSubscription.save()
      .catch(function() {
        App.Dispatcher.trigger('addError', 'Oops, something went wrong! Please refresh the page and try again.');
      });
  }
});
