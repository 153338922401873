import user from '@legacy-js/user-data/user';
import AlexaAuthorization from '../views/oauth/alexa_authorization';
import AlexaPasswordSignIn from '../views/oauth/alexa_password_sign_in';
import AlexaSignUp from '../views/oauth/alexa_sign_up';

// App.Controllers.OauthController
export default {
  initialize: function () {
    App.Dispatcher.on('initAlexa', this.initAlexa, this);
    App.Dispatcher.on('initAlexaPasswordSignIn', this.initSignIn, this);
  },

  initAlexa: function () {
    if (this.signUpForm) this.signUpForm.sever();
    if (this.passwordSignInForm) this.passwordSignInForm.sever();

    if (user.get('id')) {
      this.initAuthorizationForm();
    } else {
      this.initSignUpForm();
    }
  },

  initSignUpForm: function () {
    this.signUpForm = new AlexaSignUp({
      el: '#alexa-form'
    });
  },

  initAuthorizationForm: function () {
    this.authorizationForm = new AlexaAuthorization({
      el: '#alexa-form'
    });
  },

  initSignIn: function (user) {
    if (this.signUpForm) this.signUpForm.sever();

    this.passwordSignInForm = new AlexaPasswordSignIn({
      el: '#alexa-form',
      user: user
    });
  }
};
