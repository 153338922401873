import BaseView from '../base_view';
import ExpandableBlogPosts from '../expandable_blog_posts';
import ExpandableCoupons from '../expandable_coupons';
import ExpandableDeals from '../expandable_deals';
import TravelPills from './travel_pills';

import BlogPosts from '../../models/blog_posts';
import Coupons from '../../models/coupons';
import Deals from '../../models/deals';

// App.Views.Travel
export default BaseView.extend({
  el: '#travel',

  initialize: function (options) {
    App.Dispatcher.on('travel:pillClick', this.handlePillClick, this);
    if (options.slug) {
      this.handlePillClick(options.slug);
    } else {
      this.createDealCollection(options);
      this.createDealView(this.dealCollection);
      this.createCouponCollection(options);
      this.createCouponView(this.couponCollection);
      this.createPostCollection(options);
      this.createBlogPostView(this.postCollection);
    }

    this.travelPills = new TravelPills(options);
  },

  handlePillClick: function (slug) {
    this.updateLocation(slug);
    this.updateTracking(slug);
    this.handleDealsChange(slug);
    this.handleCouponsChange(slug);
    this.handlePostsChange(slug);
  },

  updateLocation: function (slug) {
    var travelLoc = '/travel';
    if (slug !== 'everything') {
      travelLoc = travelLoc + '?slug=' + slug;
    }

    if (history.pushState) {
      history.pushState({ path: travelLoc }, '', travelLoc);
    }
  },

  updateTracking: function (slug) {
    if (slug === 'everything') slug = '';
    var dealAttr = 'Travel Category Page - Deal List ' + slug;
    $('#deals').data('module', dealAttr.trim());
  },

  handleDealsChange: function (slug) {
    var options = {};
    options.slug = slug;
    if (this.expandableDealsView) this.expandableDealsView.show();
    this.createDealCollection(options);
    this.createDealView(this.dealCollection);

    // When collection is empty don't show view
    this.dealCollection.on('sync', function () {
      if (!this.dealCollection || !this.dealCollection.length) {
        this.expandableDealsView.hide();
      }
    }, this);

    this.dealCollection.fetchNext();
  },

  handleCouponsChange: function (slug) {
    var options = {};
    options.slug = slug;
    if (this.expandableCouponsView) this.expandableCouponsView.hide();

    if (slug === 'everything') {
      this.expandableCouponsView.show();
      this.createCouponCollection(options);
      this.createCouponView(this.couponCollection);
      this.couponCollection.fetchNext();
    }
  },

  handlePostsChange: function (slug) {
    var options = {};
    options.slug = slug;
    if (this.expandableBlogPostsView) this.expandableBlogPostsView.show();
    this.createPostCollection(options);
    this.createBlogPostView(this.postCollection);

    // When collection is empty don't show view
    this.postCollection.on('sync', function () {
      if (!this.postCollection || !this.postCollection.length) {
        this.expandableBlogPostsView.hide();
      }
    }, this);

    this.postCollection.fetchNext();
  },

  createDealCollection: function (options) {
    options = this.configureDealOptions(options);
    if (this.dealCollection) this.dealCollection.remove();
    this.dealCollection = new Deals(
      options.records, {
        total: options.total,
        limit: 8,
        query: options.query,
      }
    );
  },

  createCouponCollection: function (options) {
    var records = [];
    var total;
    if (this.couponCollection) this.couponCollection.remove();
    if (options.couponsResponseJSON) {
      records = options.couponsResponseJSON.records;
      total = options.couponsResponseJSON.total;
    }

    this.couponCollection = new Coupons(
      records, {
        total: total,
        limit: 6,
        query: {
          category_slug: 'travel',
          sort: 'start',
          type: 'promotion',
          limit_per_merchant: 1
        }
      }
    );
  },

  createPostCollection: function (options) {
    options = this.configurePostOptions(options);
    if (this.postCollection) this.postCollection.remove();
    this.postCollection = new BlogPosts(
      options.records, {
        total: options.total,
        limit: 8,
        query: options.query
      }
    );
  },

  configureDealOptions: function (options) {
    var collectionOptions = {};
    collectionOptions.records = [];
    collectionOptions.query = { sort: 'start' };

    if (options.dealsResponseJSON) {
      collectionOptions.records = options.dealsResponseJSON.records;
      collectionOptions.total = options.dealsResponseJSON.total;
    }

    if (options.slug && options.slug !== 'everything') {
      collectionOptions.query.flag_slug = options.slug;
    } else {
      collectionOptions.query.category_slug = 'travel';
    }

    return collectionOptions;
  },

  configurePostOptions: function (options) {
    var collectionOptions = {};
    collectionOptions.records = [];
    collectionOptions.query = {
      include: 'id,slug,thumbnail,title'
    };

    if (options.blogPostsResponseJSON) {
      collectionOptions.records = options.blogPostsResponseJSON.records;
      collectionOptions.total = options.blogPostsResponseJSON.total;
    }

    if (options.slug && options.slug !== 'everything') {
      collectionOptions.query.tag_slug = options.slug;
    } else {
      collectionOptions.query.category_slug = 'travel';
    }

    return collectionOptions;
  },

  createDealView: function (dealCollection) {
    if (this.expandableDealsView) this.expandableDealsView.sever();
    this.expandableDealsView = new ExpandableDeals({
      el: '#deals',
      collection: dealCollection
    });
  },

  createCouponView: function (couponCollection) {
    if (this.expandableCouponsView) this.expandableCouponsView.sever();
    this.expandableCouponsView = new ExpandableCoupons({
      el: '#coupons',
      collection: couponCollection
    });
  },

  createBlogPostView: function (postCollection) {
    if (this.expandableBlogPostsView) this.expandableBlogPostsView.sever();
    this.expandableBlogPostsView = new ExpandableBlogPosts({
      el: '#blog-posts',
      collection: postCollection
    });
  }
});
