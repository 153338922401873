export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 if ( !data.item.isExpired() && data.isFavorited ) { ;
__p += '\n  <li class="save saved hover-target saved-bookmark">\n    <i class="material-icons icon-overlay done">done</i>\n    <i class="material-icons">bookmark</i>\n  </li>\n';
 } else if ( !data.item.isExpired() ) { ;
__p += '\n  <li class="save hover-target">\n    <i class="material-icons">bookmark</i>\n  </li>\n';
 } ;
__p += '\n';
return __p
}