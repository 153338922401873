export default function(data) {
var __t, __p = '';
__p += '<h2 class="headline">\nSave Every Day With Deal Alerts\n</h2>\n<p>\n  Connect to Brad’s Deals and get personalized Deal Alerts in your inbox.\n<p>\n<form action="/oauth/authorize" method="POST" class="sign-up-form grid-15-large-7 grid-15-medium-10 grid-15-small-12 clean-form" novalidate="novalidate">\n  <input type="hidden" value="' +
((__t = ( data.redirectUri )) == null ? '' : __t) +
'" name="redirect_uri" />\n  <input type="hidden" value="' +
((__t = ( data.responseType )) == null ? '' : __t) +
'" name="response_type" />\n  <input type="hidden" value="' +
((__t = ( data.scope )) == null ? '' : __t) +
'" name="scope" />\n  <input type="hidden" value="' +
((__t = ( data.state )) == null ? '' : __t) +
'" name="state" />\n  <input type="submit" value="Authorize" class="rounded-button" data-disable-with="Authorize">\n</form>\n';
return __p
}