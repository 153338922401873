import Cookies from 'js-cookie';
import platform from 'platform';
import Experiment from './experiment';

const DEFAULT_CHANNEL_ID = 25;
const DEFAULT_REFERRER_TYPE = 'Direct';
// const REFERRER_TYPES = ['Direct', 'Other', 'Search', 'Social'];

const compact = (obj, base) => {
  const newObj = { ...base };

  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) acc[key] = value;

    return acc;
  }, newObj);

  return newObj;
};

export default class {
  #payload = {
    channel: { // Object*
      category: null, // String
      channelId: null, // Integer*
      product: null, // String
    },
    referrer: {
      type: null, // [Direct, Other, Search, Social]*
    },
  };

  json() {
    const browser = this.#browser();
    const campaign = this.#campaign();
    const channel = this.#channel();
    const device = this.#device();
    const experiment = Experiment();
    const externalIds = this.#externalIds();
    const os = this.#os();
    // const referrer = this.#referrer();

    return {
      browser,
      ...campaign && { campaign },
      channel,
      device,
      experiment,
      externalIds,
      os,
      // referrer, TODO: Add logic for this
    };
  }

  get #clickId () {
    return this.#payload.click_id;
  }

  #browser () {
    const { name, version } = platform;
    return { name, version };
  }

  #campaign () {
    const {
      utm_content: content, utm_medium: medium, utm_campaign: name, utm_source: source, utm_term: term,
    } = Cookies.get();

    const campaign = compact({
      content, medium, name, source, term,
    });
    return Object.keys(campaign).length ? campaign : null;
  }

  #channel () {
    const { c_id: channel_id = DEFAULT_CHANNEL_ID } = Cookies.get();
    const base = { channel_id: Number(channel_id) };

    return compact(this.#payload.channel, base);
  }

  #device () {
    const { bd_device_cat: category } = Cookies.get();
    const { product: model } = platform;
    return { category, model };
  }

  #externalIds () {
    const eIds = [];
    const dId = Cookies.get('d_id');

    // If the client has a device id add it to the external_ids
    if (dId) {
      eIds.push({
        id: dId,
        type: 'bd_device_id',
        collection: 'users',
        encoding: 'none',
      });
    }

    return eIds;
  }

  #os () {
    const { version, family: name } = platform.os;
    return { version, name };
  }

  #referrer () {
    return {
      type: this.#payload.referrer.type || DEFAULT_REFERRER_TYPE,
    };
  }

  #experiement () {
    const bd_feature = Cookies.get('bd_feature');
    if (!bd_feature || typeof bd_feature !== 'string') {
      return undefined;
    }

    const [feature, bucket, split] = bd_feature.split(':');

    if (!feature || !bucket || !split) {
      return undefined;
    }

    return {
      campaign_name: feature,
      experiment_name: `${feature}-${split}`,
      variation_name: bucket,
    };
  }
}
