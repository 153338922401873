import BaseSearchView from './base_search_view';
import Merchants from '../views/merchants';
import PopularMerchants from '../models/popular_merchants';
import SearchPopularMerchants from '../views/search/search_popular_merchants';
import SearchPagination from '../views/search_pagination';
import emptyTemplate from '../templates/no_store_search_results.jst.ejs';

// App.Views.SearchMerchantsPage
export default BaseSearchView.extend({
  createViews: function () {
    this.collectionView = new Merchants({
      el: this.$el.find('.collection'),
      collection: this.model.merchants(),
      itemClassName: 'col large-6 medium-12 small-12 clearfix',
      emptyTemplate: emptyTemplate,
      collectionName: 'stores'
    });

    this.collectionView.render();

    if (!this.model.merchants() || !this.model.merchants().length) {
      this.searchPopularMerchants = new SearchPopularMerchants({
        el: '#stores-feed',
        collection: new PopularMerchants()
      });

    } else {
      this.searchPagination = new SearchPagination({
        model: this.model,
        el: '.merchants .pagination',
        mainModel: 'merchants'
      });
    }
  },

  removeViews: function () {
    if (this.collectionView) this.collectionView.remove();
    if (this.searchPopularMerchants) this.searchPopularMerchants.remove();
    if (this.searchPagination) this.searchPagination.remove();
  }
});
