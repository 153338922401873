export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 if ( !this.model.isExpired() ) { ;
__p += '\n  <a target="_blank" href="' +
((__t = ( this.model.goLink() )) == null ? '' : __t) +
'" class="rounded-button go-link ' +
((__t = ( this.wrapperClass() )) == null ? '' : __t) +
'">\n    ' +
((__t = ( this.model.linkText())) == null ? '' : __t) +
'\n  </a>\n  ';
 if(this.model.get("code")) { ;
__p += '\n    <span class="coupon-code" data-href="' +
((__t = ( this.model.goLink() )) == null ? '' : __t) +
'">' +
((__t = ( this.model.get("code") )) == null ? '' : __t) +
'</span>\n  ';
 } ;
__p += '\n';
 } else { ;
__p += '\n  <span class="rounded-button expired">\n    ' +
((__t = ( this.model.linkText())) == null ? '' : __t) +
'\n  </span>\n';
 } ;
__p += '\n';
return __p
}