import BaseCollection from './base_collection';
import Coupon from './coupon';
import { bindAll, clone } from 'underscore';

// App.Collections.Coupons
export default BaseCollection.extend({
  url: '/coupons.json',
  model: Coupon,

  initialize: function (models, options) {
    if (options) {
      this.limit = options.limit;
      this.total = options.total;
      this.query = options.query;
    }

    bindAll(this, 'parseAndMerge');
  },

  parse: function (data) {
    if (data.coupons) {
      this.pageCount = data.count;
      this.total = data.total;
      return data.coupons;
    }

    return data;
  },

  parseAndMerge: function (data) {
    if (data.coupons) {
      this.total = data.total;
      this.add(data.coupons);
    }

    this.trigger('sync');
  },

  fetchNext: function () {
    var params = clone(this.query);
    params.page = parseInt(this.length / this.limit) + 1;
    if (this.limit) params.limit = this.limit;
    this.trigger('request');

    return $.ajax({
      url: this.url,
      type: 'GET',
      data: params,
      dataType: 'json'
    }).done(this.parseAndMerge);
  }
});
