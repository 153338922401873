export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<a href="/deals/' +
((__t = (this.model.get('param'))) == null ? '' : __t) +
'?ref=deal-tile_image" class="deal-image">\n  ' +
((__t = ( this.model.get('image') )) == null ? '' : __t) +
'\n</a>\n<div class="flag-row">\n  <div class="deal-flag">' +
((__t = ( this.model.get('flags') )) == null ? '' : __t) +
'</div>\n</div>\n' +
((__t = ( this.model.get('third_party_tracking_pixel') )) == null ? '' : __t) +
'\n<div class="row-flex title-row">\n  <a href="/deals/' +
((__t = (this.model.get('param'))) == null ? '' : __t) +
'?ref=deal-tile_image" class="deal-title">\n    ' +
((__t = ( this.model.get('title') )) == null ? '' : __t) +
'\n  </a>\n  <ul class="reset item-engagement-module engagement-bookmark clearfix"></ul>\n</div>\n<div class="author-info clearfix"></div>\n';
 if (this.isPriceSectionShown()) { ;
__p += '\n  <div class="value-container hide">\n    <span class="discount-price">' +
((__t = ( this.priceWithCommas() )) == null ? '' : __t) +
'</span>\n    <div class="shipping-container">\n      <div class="shipping-info">' +
((__t = ( this.shippingInfo() )) == null ? '' : __t) +
'</div>\n  </div>\n';
 } ;
__p += '\n</div>\n<div class="row-flex engagement-row">\n  <span class="spacer"></span>\n  <ul class="reset item-engagement-module engagement-share-module"></ul>\n</div>\n';
return __p
}