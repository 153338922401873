<template src='./my_deals_email_confirm.html'/>
<style src="./my_deals_email_confirm.scss" lang="scss" scoped></style>

<script>
import InfoLinks from '../../info_links/info_links.vue';

export default {
  name: 'MyDealsEmailConfirm',
  components: {
    'info-links': InfoLinks,
  },
  mounted() {
    window.Segment.track(
      'User Flow Step Completed',
      {
        flow_name: 'mydeals_web_signup',
        step_name: 'email_sent',
        step_number: 5
      }
    );
  },
  methods: {
    handleCloseClick() {
      this.$emit('closeDrawer');
    }
  },
}
</script>
