import BaseView from '../base_view';
import Merchants from '../merchants';
import SavedItems from '../saved_items';
import template from '../../templates/users/saved_items.jst.ejs';
import merchantEmptyTemplate from '../../templates/users/merchants_empty.jst.ejs';
import itemEmptyTemplate from '../../templates/users/saved_items_empty.jst.ejs';

// App.Views.User.SavedItemsPage
export default BaseView.extend({
  template: template,

  initialize: function () {
    this.render();
    this.registerEvents();
    this.createSubViews();
  },

  render: function () {
    this.$el.html(this.template());
    return this.$el;
  },

  registerEvents: function () {
    var engagements = this.model.get('engagements');
    engagements.once('sync', engagements.fetchMerchants);
    engagements.once('sync', engagements.fetchSavedItems);
  },

  createSubViews: function () {
    this.merchantsCollectionView = new Merchants({
      collection: this.model.get('engagements').merchants,
      el: this.$('#saved-merchants-list'),
      itemClassName: 'col large-4 medium-6 small-12 clearfix',
      emptyTemplate: merchantEmptyTemplate
    });

    this.savedItemsCollectionView = new SavedItems({
      collection: this.model.get('engagements').savedItems,
      el: this.$('#saved-items'),
      emptyTemplate: itemEmptyTemplate
    });
  }
});
