import CollectionView from '../collection_view';
import BlogPostTile from './blog_post_tile';

var _super = CollectionView.prototype;

// App.Views.BlogPostTiles
export default CollectionView.extend({
  modelName: 'Post',
  modelView: BlogPostTile,

  initialize: function () {
    _super.initialize.apply(this, arguments);
    this.initializeViews();
  }
});
