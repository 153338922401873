import BaseView from './base_view';
import { bindAll, template } from 'underscore';

var _super = BaseView.prototype;

// App.Views.CollectionView
const CollectionView = BaseView.extend({
  inheritsFromCollectionView: true,
  initialize: function (options) {
    options = options || {};
    this.options = options
    this.additionalOptions = options.additionalOptions || {};
    _super.initialize.apply(this, arguments);
    if (CollectionView == this.constructor) throw new Error('App.Views.CollectionView should not be instantiated directly');
    bindAll(this, 'createListItemView', 'renderListItemView', 'initializeView');
    this.modelViews = [];
    this.modelName = this.modelName || options.modelName;
    this.modelView = this.modelView || options.modelView;
    this.emptyTemplate = options.emptyTemplate  || template('');
    this.itemClassName = options.itemClassName;
    this.collection.on('add reset remove sync', this.render, this);
    this.collection.on('request', this.renderLoading, this);
  },

  render: function () {
    this.$el.empty();
    if (!this.collection || !this.collection.length) {
      this.$el.html(this.emptyTemplate({ modelName: this.modelName }));
    } else {
      this.createListItemViews();
      this.renderListItemViews();
    }

    return this.$el;
  },

  renderLoading: function () {
    if (!this.collection || !this.collection.length) {
      return this.$el.html(this.loadingTemplate());
    } else {
      this.$el.append(this.loadingTemplate());
    }
  },

  modelViewName: function () {
    return this.modelName + 'ListItem';
  },

  createListItemView: function (model, index) {
    var attributes = {
      model: model,
      index: index,
      additionalOptions: this.additionalOptions,
    };

    if (this.itemClassName) attributes.className = this.itemClassName;

    this.modelViews.push(
      new this.modelView(attributes)
    );
  },

  renderListItemView: function (modelView) {
    this.$el.append(modelView.render());
  },

  createListItemViews: function () {
    this.modelViews.length = 0;
    if (this.options.limit) {
      this.collection.first(this.options.limit).forEach(this.createListItemView);
    } else {
      this.collection.forEach(this.createListItemView);
    }
  },

  renderListItemViews: function () {
    this.modelViews.forEach(this.renderListItemView);
  },

  initializeViews: function () {
    this.$el.find('li').toArray().forEach(this.initializeView);
  },

  initializeView: function (element, index) {
    var id = $(element).data('id');
    var model = this.collection.get(id);
    if (model) {
      this.modelViews.push(
        new this.modelView({
          el: element,
          model: model,
          index: index,
          additionalOptions: this.additionalOptions
        })
      );
    }
  }
});

export default CollectionView;
