import { bindAll } from 'underscore';

// App.Scrolling
export default {
  viewDidScroll: false,

  initialize: function(App){
    bindAll(this, "onViewScroll", "onScrollTimeOut");
    App.Dispatcher.on("trackScrolling", this.initScrollTracking, this);
  },

  initScrollTracking: function(){
    $(window).on("scroll", this.onViewScroll);
    this.scrollTimer = setInterval(this.onScrollTimeOut, 150);
  },

  onViewScroll: function(){
    this.viewDidScroll = true;
  },

  onScrollTimeOut: function(){
    if(this.viewDidScroll) {
      App.Dispatcher.trigger("viewDidScroll");
      this.viewDidScroll = false;
    }
  }
};
