import BaseView from './base_view';
import template from '../templates/mobile_search.jst.ejs';
import { bindAll } from 'underscore';
import { searchTrimmer } from '../lib/search-trim';

// App.Views.MobileSearchView
export default BaseView.extend({
  template: template,

  events: {
    'click .close-search': 'handleSearchClose',
    'submit .autocomplete-form': 'handleSearch'
  },

  initialize: function (options) {
    this.options = this.options || options || {};

    bindAll(this, 'handleSearchOpen');
    this.registerEvents();
    this.render();
    this.initAutocompleter();
  },

  render: function () {
    this.$el.html(this.template());
  },

  initAutocompleter: function () {
    Autocompleter(
      document.getElementById('mobilenav-search'), {
        url: this.options.searchApiUrl
      }
    );
  },

  registerEvents: function () {
    $(document).on('click', '.mobile-search-trigger', this.handleSearchOpen);
  },

  handleSearchOpen: function () {
    this.$el.css({ display: '' });
    $('body').addClass('overlay-active');
    this.$el.addClass('fade-in');
    this.$('.autocomplete-input').focus();
  },

  handleSearchClose: function () {
    this.$el.fadeOut();
    $('body').removeClass('overlay-active');
  },

  handleSearch: searchTrimmer('#mobilenav-search')
});
