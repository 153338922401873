import AcquisitionSignUpModal from '../views/modals/acquisition_sign_up'
import { bindAll, extend } from 'underscore';

// App.Controllers.AcquisitionsController
export default {
  initialize: function () {
    bindAll(this, 'escapeModal');
    App.Dispatcher.on('bdAcquisition:initialize', this.initFlow, this);
  },

  initFlow: function (params) {
    this.params = params;
    if (/dd_/.test(params.v)) {
      // nothing to see here, the view handles it
    } else if (params.v === 'onboarding') {
      App.Dispatcher.trigger('initOnboarding', { el: '#vue-app-root', startOpened: true });
    } else if (params.v === 'my-deals-sign-in') {
      App.Dispatcher.trigger('initMyDeals', { el: '#vue-app-root' });
    } else if (params.v === 'bdacq') {
      import('../../../javascript/packs/acq-modal').then(({ initAcqModal }) => {
        initAcqModal();
      }).catch((e) => console.error(e));
    } else {
      this.defaultFlow();
    }

    // Hide the Deal Click metrics on the deal details for acquisition users
    $('.view-metrics').hide();
  },

  defaultFlow: function () {
    if (this.params.user && !this.params.user.loggedIn()) {
      this.hackBackButton();
    }
  },

  hackBackButton: function () {
    // grab the current query string and strip out modal=y
    var prefix = window.location.search.replace('?modal=y&', '?');
    prefix = prefix.replace(/.modal=y/, '');

    // set up initial state
    window.history.replaceState({
      showModal: false
    }, document.title, prefix);

    // bind callback for statechange
    $(window).on('popstate', this.escapeModal);

    prefix += prefix.length ? '&' : '?';

    // push new state into history
    window.history.pushState({}, document.title, prefix + 'modal=y');

    this.showSignUpModal();
  },

  escapeModal: function () {
    const state = window.history.state;

    if (state.showModal === false) {
      this.hideSignUpModal();
      $(window).off('popstate', this.escapeModal);
    }
  },

  showSignUpModal: function () {
    var options = extend({
      modalClass: AcquisitionSignUpModal
    }, this.params);
    App.Dispatcher.trigger('showModal', options);
  },

  hideSignUpModal: function () {
    App.Dispatcher.trigger('hideModal', this.params);
  }
};
