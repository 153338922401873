import Deferred from './deferred';
import { bindAll, extend } from 'underscore';

// App.Google
export default {
  initialize: function (App) {
    App.Dispatcher.on('initializeGoogle', this.initializeApplication, this);
  },

  initializeApplication: function (initSettings) {
    bindAll(
      this,
      'handleGoogleLoginClick',
      'handleGoogleInitSuccess',
      'handleGoogleInitError',
      'initializeGoogle',
      'googleTokenSuccess',
      'handleGoogleLoginSuccess',
      'googleError'
    );

    extend(App.Data.google, initSettings);
    this.loadGoogleSDK();
  },

  loadGoogleSDK: function () {
    var _this = this;
    $(function () {
      window.$.ajax({
        dataType: 'script',
        cache: true,
        url: App.Data.google.sdk_uri,
        success: _this.initializeGoogle,
      });
    });
  },

  initializeGoogle: function () {
    window.gapi = gapi;
    var _this = this;

    gapi.load('auth2', function () {
      gapi.auth2
        .init({
          client_id: App.Data.google.app_id,
        })
        .then(_this.handleGoogleInitSuccess, _this.handleGoogleInitError);
    });
  },

  registerEvents: function () {
    $(document).on('click', '.google-login-button.signup', this.handleGoogleLoginClick);
  },

  disableEvents: function () {
    $(document).off('click', '.google-login-button.signup', this.handleGoogleLoginClick);
  },

  handleGoogleInitSuccess: function (auth2) {
    this.auth2 = auth2;
    this.registerEvents();

    // Show google login buttons
    $('body').removeClass('disable-google-auth');
  },

  handleGoogleInitError: function (e) {
    $('body').addClass('disable-google-auth');
    console.log(e);
  },

  handleGoogleLoginClick: function () {
    this.deferred = new Deferred();

    if (!this.auth2) {
      this.deferred.reject('Google Authentication is disabled');
    } else {
      this.auth2.grantOfflineAccess({ redirect_uri: 'postmessage' }).then(this.googleTokenSuccess, this.googleError);
    }

    return this.deferred.promise;
  },

  googleTokenSuccess: function (googleSuccess) {
    if (googleSuccess.code) {
      this.requestUser({ token: googleSuccess.code }).then(this.handleGoogleLoginSuccess).catch(this.googleError);
    }
  },

  googleError: function (error) {
    this.deferred.reject({ errors: error, created: false });
  },

  handleGoogleLoginSuccess: function (userJSON, status, xhr) {
    this.registerEvents();
    this.deferred.resolve(userJSON);
    this.deferred.promise.then(this.setCurrentUser);
  },

  setCurrentUser: function (userJSON) {
    App.Dispatcher.trigger('initUserApplication', userJSON);
    $(document).trigger('externalForm:success');
    App.Dispatcher.trigger('socialLoginSuccess');
    App.Dispatcher.trigger('userSubscribe', userJSON);
  },

  requestUser: function (response) {
    return $.ajax({
      type: 'POST',
      url: '/google',
      data: response,
    });
  },
};
