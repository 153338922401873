import BaseView from './base_view';
import template from '../templates/search_page.jst.ejs';
import { bindAll } from 'underscore';

// App.Views.BaseSearchView
var BaseSearchView = BaseView.extend({
  modelName: 'Search',
  template: template,
  inheritsFromBaseSearchView: true,

  initialize: function () {
    if (BaseSearchView == this.constructor) {
      throw new Error('App.Views.BaseSearchView should not be instantiated directly');
    } else {
      bindAll(this, 'handleShowMoreClick');
      this.registerEvents();
      this.render();
      this.createViews();
    }
  },

  createViews: function () {
    throw new Error('createViews not implemented');
  },

  recreateViews: function () {
    this.removeViews();
    this.render();
    this.createViews();
  },

  removeViews: function () {
    throw new Error('removeViews not implemented');
  },

  render: function () {
    return this.$el.html(this.template());
  },

  show: function () {
    this.$el.show();
    if (this.searchPagination) this.searchPagination.show();
  },

  hide: function () {
    this.$el.hide();
    if (this.searchPagination) this.searchPagination.hide();
  },

  registerEvents: function () {
    this.$el.on('click', '.pagination .link', this.handleShowMoreClick);
  },

  handleShowMoreClick: function () {
    this.model.fetchNext();
  }
});

export default BaseSearchView;
