import BaseModel from '@legacy-js/models/base_model';
import BaseCollection from '@legacy-js/models/base_collection';
import Author from '@legacy-js/models/author';
import BlogPost from '@legacy-js/models/blog_post';
import BlogPosts from '@legacy-js/models/blog_posts';
import Brand from '@legacy-js/models/brand';
import Brands from '@legacy-js/models/brands';
import Comment from '@legacy-js/models/comment';
import Comments from '@legacy-js/models/comments';
import Coupon from '@legacy-js/models/coupon';
import Coupons from '@legacy-js/models/coupons';
import DealCount from '@legacy-js/models/deal_count';
import Deal from '@legacy-js/models/deal';
import Deals from '@legacy-js/models/deals';
import DealsSearch from '@legacy-js/models/deals_search';
import EmailPreference from '@legacy-js/models/email_preference';
import EmailSubscription from '@legacy-js/models/email_subscription';
import Email from '@legacy-js/models/email';
import Engagement from '@legacy-js/models/engagement';
import Engagements from '@legacy-js/models/engagements';
import Merchant from '@legacy-js/models/merchant';
import Merchants from '@legacy-js/models/merchants';
import PopularMerchants from '@legacy-js/models/popular_merchants';
import NewsletterType from '@legacy-js/models/newsletter_type';
import NewsletterTypes from '@legacy-js/models/newsletter_types';
import SavedItems from '@legacy-js/models/saved_items';
import Search from '@legacy-js/models/search';
import SingleUseCoupon from '@legacy-js/models/single_use_coupon';
import Subscription from '@legacy-js/models/subscription';
import Subscriptions from '@legacy-js/models/subscriptions';
import Topic from '@legacy-js/models/topic';
import UserProfile from '@legacy-js/models/user_profile';
import User from '@legacy-js/models/user';

const Models = {
  Author,
  BaseCollection,
  BaseModel,
  BlogPost,
  Brand,
  Comment,
  Coupon,
  Deal,
  DealCount,
  Email,
  EmailPreference,
  EmailSubscription,
  Engagement,
  Merchant,
  NewsletterType,
  NewsletterTypes,
  Search,
  SingleUseCoupon,
  Subscription,
  Topic,
  User,
  UserProfile,
};

const Collections = {
  BlogPosts,
  Brands,
  Comments,
  Coupons,
  Deals,
  DealsSearch,
  Engagements,
  Merchants,
  PopularMerchants,
  SavedItems,
  Subscriptions,
};

export { Collections, Models };
