import CreateSubscriptionForm from '../views/create_subscription_form';
import user from '@legacy-js/user-data/user';

// App.Controllers.DealAlertsLandingController
export default {
  initialize: function () {
    App.Dispatcher.on('initDealAlertsShow', this.initDealAlertsShow, this);
  },

  initDealAlertsShow: function (options) {
    options = options || {};

    this.createSubscriptionForm = new CreateSubscriptionForm({
      el: '#create-deal-alert-form',
      user: user,
      requireFullUser: true
    });
  }
};
