<template>
  <div class="blurry-mask-overlay">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "blurry-mask-overlay",
  };
</script>
