import Author from './author';
import BaseModel from './base_model';
import Comments from './comments';
import { bindAll } from 'underscore';

var _super = BaseModel.prototype;

// App.Models.Topic
export default BaseModel.extend({
  wrappedAttributes: {
    comments: Comments,
    author: Author
  },

  initialize: function(){
    _super.initialize.apply(this, arguments);
    bindAll(this, "parseAndMerge");
    this.listenTo(this.get('comments'), 'remove', this.decrementCommentCount);
    this.listenTo(this.get('comments'), 'add', this.incrementCommentCount);
  },

  fetchNext: function(){
    $.ajax({
      url: "/topics",
      type: "GET",
      data: {
        topic_slug: this.get('slug'),
        offset: this.get("comments").length
      },
      dataType: "json"
    }).done(this.parseAndMerge);
  },

  parseAndMerge: function(data){
    this.get('comments').add(data.comments, { silent: true });
    this.get("comments").trigger("sync");
  },

  incrementCommentCount: function() {
    this.set('comments_count', this.get('comments_count') + 1);
  },

  decrementCommentCount: function() {
    this.set('comments_count', this.get('comments_count') - 1);
  }
});
