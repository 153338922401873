export default function(data) {
var __t, __p = '';
__p += '<img src="' +
((__t = ( this.model.get("item").shareImage() )) == null ? '' : __t) +
'" alt="' +
((__t = ( this.model.get("item").shareName() )) == null ? '' : __t) +
' logo" class="product-image" />\n' +
((__t = ( this.description() )) == null ? '' : __t) +
'\n\n';
return __p
}