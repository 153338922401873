import Forms from '../../lib/forms/forms';
import Modal from '../modal';
import CreateProfileModal from './create_profile';
import template from '../../templates/modals/login.jst.ejs';

// App.Views.LoginModal
export default Modal.extend({
  template: template,

  events: {
    'ajax:success form': 'success',
    'ajax:error form': 'ajaxError',
    'ajax:before form': 'validate'
  },

  modalName: 'login',

  afterRender: function () {
    this.$('form').validate({
      rules: {
        email: Forms.Validations.rules.email,
        password: { required: true }
      },
      onfocusout: false,
      onkeyup: false,
      messages: {
        email: Forms.Validations.messages.email,
        password: 'Please enter your password.'
      },
      showErrors: Forms.Validations.showErrors.inline
    });
  },

  validate: function () {
    return this.$('form').valid();
  },

  modalTitle: function () {
    // use == instead of === to get coercion to int
    if (this.options.to == 1) {
      return 'Looks like you already have an account.';
    } else {
      return 'Welcome Back!';
    }
  },

  success: function (event, data, status, xhr) {
    if (data.flash.success) {
      App.Dispatcher.trigger('initUserApplication', data.user);
      App.Dispatcher.trigger('segment:track', 'Signed In');

      if (data.user.is_full_user) {
        this.hideModal();
      } else {
        App.Dispatcher.trigger('showModal', {
          modalClass: CreateProfileModal,
          to: '2'
        });
      }
    } else {
      this.$('form').validate().showErrors({
        email: 'Incorrect email address or password. Please try again.'
      });
    }
  }
});
