import BaseView from './base_view';
import Forms from '../lib/forms/forms';
import { bindAll, defaults } from 'underscore';

// App.Views.Modal
export default BaseView.extend({
  baseDefaultOptions: {
    el: '#modal .content',
    closable: true,
    redirectUrl: null,
  },

  defaultOptions: {
    // Subclasses should override with defaults here
    // ex. closable: false
  },

  initialize: function (options) {
    // backbone views have options set automagically already
    this.options = defaults(options, this.defaultOptions, this.baseDefaultOptions);
    this._$overlay = $('#modal-overlay');
    bindAll(this, 'afterHide');
  },

  template: function () {
    if (this.options.html) {
      return this.options.html;
    }

    return '';
  },

  render: function () {
    App.Dispatcher.trigger('toggleiPhoneViewport', 'show');

    this.$el.html(this.template());
    this._$overlay.css({ display: 'block' });

    this.setClosable();
    this.setModalData();
    this.initFormEntry();

    this.afterRender();
  },

  afterRender: function () {
    // Override in inherited classes for additonal functionality
    // ex. validations, subviews, etc.
  },

  sever: function () {
    this.beforeSever();
    this.$el.unbind();
    this.stopListening();
  },

  beforeSever: function () {
    // Override in inherited classes for additional cleanup
    // ex. clean up subviews
  },

  hideModal: function () {
    $('body').removeClass('scroll-lock');
    
    this._$overlay.fadeOut({
      complete: this.afterHide
    });
  },

  afterHide: function () {
    this.sever();
    App.Dispatcher.trigger('toggleiPhoneViewport', 'hide');
  },

  isClosable: function () {
    return !!this.options.closable;
  },

  setClosable: function () {
    if (this.isClosable()) {
      this._$overlay.addClass('closable').removeClass('not-closable');
      $('body').addClass('scroll-lock');
    } else {
      this._$overlay.addClass('not-closable').removeClass('closable');
    }
  },

  setModalData: function () {
    this._$overlay.find('#modal').data({
      redirectUrl: this.options.redirectUrl,
      name: this.modalName,
    });
  },

  initFormEntry: function () {
    function setTabIndex(index, input) {
      $(input).attr('tabindex', index + 1);
    }

    this.$('input, textarea').filter(':visible').each(setTabIndex);
    this.$('input:visible:first').focus();
  },

  ajaxError: function (event, xhr, status, error) {
    status = status || '';
    error = error || '';
    var responseJSON = xhr.responseJSON || {};
    var responseText = xhr.responseText || '';
    var err = new Error(status + ': ' + error + ': ' + responseText);

    App.Dispatcher.trigger('errorOccurred', err);

    if (responseJSON.errors && responseJSON.errors.email) {
      this.showEmailValidationError();
    }
  },

  showEmailValidationError: function () {
    if (this.$validator) {
      this.$validator.showErrors({
        email: Forms.Validations.messages.email.email
      });
    }
  }
});
