import CollectionView from './collection_view';
import DealTileWithBookmark from './deal_tile_with_bookmark';

var _super = CollectionView.prototype;

// App.Views.DealTilesWithBookmarks
export default CollectionView.extend({
  modelName: 'Deal',
  modelView: DealTileWithBookmark,

  initialize: function () {
    _super.initialize.apply(this, arguments);

    this.render();
  }
});
