import Travel from '../views/travel/travel';

// App.Controllers.TravelController
export default {
  initialize: function () {
    App.Dispatcher.on('initTravelPage', this.initTravelPage, this);
  },

  initTravelPage: function (options) {
    this.travelView = new Travel(options);
  }
};
