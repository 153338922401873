import Cookies from 'js-cookie';

import Context from './segment/context';

export default class {
  #_pageProps

  #context

  #anonId

  #userId

  constructor() {
    const userId = localStorage.getItem('ajs_user_id');

    this.#context = new Context();
    this.#_pageProps = {};
    this.#userId = userId && Number(userId);
  }

  page(properties = {}, options = {}) {
    const send = (geo) => this.#sendPageEvent(geo, properties, options);

    this.#getGeoLocation()
      .then(send)
      .catch(send);
  }

  identify(newUserId, traits = {}) {
    const oldUserId = this.#userId;
    this.#userId = newUserId && Number(newUserId);

    if (!this.#userId || oldUserId === newUserId) return;

    const options = this.#buildOptions();
    this.#analytics.identify(this.#userId, traits, options);
  }

  reset() {
    this.#analytics.reset();
    this.#anonId = undefined;
    this.#userId = undefined;
  }

  track(eventName, properties) {
    const options = this.#buildOptions();

    if (!eventName) console.error(`'${typeof eventName}' is not a valid event name`);

    this.#analytics.track(eventName, properties, options);
  }

  setPageProps(key, value = null) {
    if (key instanceof Object) {
      this.#_pageProps = { ...key };
      return key;
    }

    if ([null, undefined].includes(value)) {
      delete this.#_pageProps[key];
    } else {
      this.#_pageProps[key] = value;
    }

    return this.#_pageProps[key];
  }

  get pageProps() {
    return this.#_pageProps;
  }

  get anonymousId() {
    try {
      this.#anonId = this.#anonId || window.analytics.user().anonymousId();
      return this.#anonId;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  get #analytics() {
    return window.analytics || {
      identify: () => {},
      page: () => {},
      reset: () => true,
      track: () => true,
    };
  }

  // For now fail gracefully when GeoLocation is not defined.
  // TODO: Move Geolocation out of Sprockets pipeline and into a place it can be shared by both pipelines
  get #getGeoLocation() {
    return window.App ? window.App.GeoLocation.get : () => Promise.resolve({});
  }

  #buildOptions(customOptions = {}) {
    return { context: this.#context.json(), ...customOptions };
  }

  #pageName() {
    return document.getElementsByTagName('html')[0].getAttribute('data-loc');
  }

  #sendPageEvent(geo, properties = {}, customOptions = {}) {
    const options = this.#buildOptions(customOptions);

    let eventProperties = { ...this.pageProps, ...properties };

    if (Object.keys(geo || {}).length) {
      eventProperties = {
        ...eventProperties,
        geo_city: geo.city,
        geo_metro_code: geo.metro_code,
        geo_region: `${geo.country_code}-${geo.region}`,
        geo_utc_offset: geo.utc_offset,
      };
    }

    this.#analytics.page(this.#pageName(), eventProperties, options);
  };
}
