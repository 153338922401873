/*
Wrapper for API requests to improve constistancy and reduce boiler platting.
An accepted or rejected promise is returned with an object
with `body` and `status` keys that can be chained to.

EX:
  Basic example with callback structure
  get('/users/123').then(successCallback({ body, status }), errorCallback({ body, status }));

  Customized headers and fetch options
  get('/interests', { cache: 'force-cache', headers: { token: 'abc123'} })

  Post a record
  post('/users', { body: { email: 'foo@bar.com' }, headers: { token: 'abc123' } });
*/

import { getCsrfToken } from './rails-adapter';
import appVersion from './app-version-helper';

const BASE_HEADERS = {
  'Content-Type': 'application/json',
  'X-App-Platform': 'www-vue',
};

const getBaseHeaders = () => {
  const version = appVersion.getAppVersion();
  return {
    ...BASE_HEADERS,
    ...(version ? { 'X-App-Version': version } : undefined),
  };
};

const BASE_OPTIONS = {
  cache: 'no-cache',
  credentials: 'same-origin',
  mode: 'cors',
  redirect: 'follow',
  referrer: 'no-referrer',
};

const STANDARD_ERROR = { errors: { server: ['says something went wrong, please try again.'] } };

const handleUnknownError = () => Promise.reject({ body: STANDARD_ERROR, status: 500 });

const handleResponse = (response) => {
  const { status } = response;

  if (status === 204) {
    return Promise.resolve({ status });
  }

  if (status >= 200 && status < 300) {
    return response.json().then((body) => ({ body, status }));
  }

  if (status >= 300 && status < 500) {
    return response.json().then((body) => Promise.reject({ body, status }));
  }

  return handleUnknownError();
};

const buildHeaders = (headers = {}) => ({ headers: { ...headers, ...getBaseHeaders(), 'X-CSRF-Token': getCsrfToken() } });

const buildRequestOptions = (options, method = 'GET') => {
  const requestOptions = {
    ...BASE_OPTIONS, ...options, ...buildHeaders(options.headers), method,
  };

  // Ensure body payload is a string
  if (requestOptions.body && typeof (requestOptions.body) !== 'string') {
    requestOptions.body = JSON.stringify(requestOptions.body);
  }

  return requestOptions;
};

// Converts object with objects to a properly formatted query string
// Supports nested objects 1 level deep
const queryObjectToString = (query) => Object.keys(query).reduce((arr, k) => {
  const val = query[k];

  if (val instanceof Object) {
    Object.keys(val).forEach((v) => {
      arr.push(`${encodeURIComponent(k)}[${encodeURIComponent(v)}]=${encodeURIComponent(val[v])}`);
    });
  } else {
    arr.push(`${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`);
  }

  return arr;
}, []).join('&');

const makeRequest = (requestUrl, requestOptions) => (
  fetch(requestUrl, requestOptions).then(handleResponse, handleUnknownError)
);

const get = (requestUrl, options = {}) => {
  const requestOptions = buildRequestOptions(options, 'GET');

  return makeRequest(requestUrl, requestOptions);
};

const post = (requestUrl, options = {}) => {
  const requestOptions = buildRequestOptions(options, 'POST');

  return makeRequest(requestUrl, requestOptions);
};

const patch = (requestUrl, options = {}) => {
  const requestOptions = buildRequestOptions(options, 'PATCH');

  return makeRequest(requestUrl, requestOptions);
};

const put = (requestUrl, options = {}) => {
  const requestOptions = buildRequestOptions(options, 'PUT');

  return makeRequest(requestUrl, requestOptions);
};

const destroy = (requestUrl, options = {}) => {
  const requestOptions = buildRequestOptions(options, 'DELETE');

  return makeRequest(requestUrl, requestOptions);
};

export {
  destroy, get, post, patch, put, queryObjectToString,
};
