export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<span class="details">Showing ' +
((__t = ( this.model.get("comments").length )) == null ? '' : __t) +
' of ' +
((__t = ( this.model.get("comments_count") )) == null ? '' : __t) +
'</span>\n';
 if(this.hasMorePages()) { ;
__p += '\n  <span class="link">Show More</span>\n';
 } ;

return __p
}