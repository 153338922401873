<template src="./my_deals_confirmation.html" />
<style src="./my_deals_confirmation.scss" lang="scss" scoped></style>

<script>
import InfoLinks from '../../info_links/info_links.vue';

export default {
  name: 'MyDealsConfirmation',
  components: {
    'info-links': InfoLinks,
  },
  mounted() {
    window.Segment.track(
      'User Flow Step Completed',
      {
        flow_name: 'mydeals_web_signup',
        step_name: 'my_deals_activated',
        step_number: 6,
      },
    );

    window.Segment.track('User Flow Completed', { flow_name: 'mydeals_web_signup' });
  },
  methods: {
    handleButtonClick() {
      if (!window.location.pathname.includes('/shop')) {
        window.location.href = '/shop';
      }

      this.$emit('closeDrawer');
    },
  },
};
</script>
