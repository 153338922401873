import BaseView from '../../base_view';
import template from '../../../templates/users/preferences/promotional_preferences.jst.ejs';

// App.Views.User.Preferences.PromotionalPreferences
export default BaseView.extend({
  events: {
    'change input[name=promotional-toggle]': 'handlePromotionalToggleChange',
  },

  template: template,

  initialize: function () {
    this.listenTo(this.model, 'change', this.render);
    this.render();
  },

  render: function () {
    this.$el.html(
      this.template()
    );
    this.setToggle();
  },

  // Sets the toggle to checked if the user is
  // subscribed to our emails and opted into the promotional emails
  setToggle: function () {
    this.$('input').prop('checked', this.model.isPromotionalSubscribed());
  },

  triggerError: function (error) {
    App.Dispatcher.trigger('addError', 'Oops, something went wrong! Please refresh the page and try again.');
  },

  // Allows the user to toggle their Promotional subscription on and off
  handlePromotionalToggleChange: function (e) {
    e.preventDefault();
    var optedOut = this.model.get('opt_out_promotional');

    this.model.set('opt_out_promotional', !optedOut);
    this.model.save()
      .catch(this.triggerError);
  }
});
