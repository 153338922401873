import {
  reduce,
  keys,
  find,
  isEmpty,
  difference,
  each
} from 'underscore';
import Cookies from 'js-cookie';

// App.Controllers.CookiesController
export default {
  TWO_YEARS: 730,

  // Expire timestamp session cookies
  // Value is 30 minutes in milliseconds
  SESSION_EXPIRE: 30 * 60 * 1000,

  CRITICAL_PARAMS: ['c_id', 'TID', 'utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'],

  initialize: function () {
    App.Dispatcher.on('initCookies', this.initCookies, this);
    App.Dispatcher.on('initUserApplication', this.setGenderCookie, this);
    App.Dispatcher.on('updateTracking', this.setSourceCookie, this);
    App.Dispatcher.on('updateTracking', this.setGenderCookie, this);
    App.Dispatcher.on('updateTracking', this.setCriticalCookies, this);
  },

  initCookies: function () {
    var params = App.Data.currentUrlParameters();

    this.setGenderCookie({ gender: params.u_g });
    this.setSourceCookie(params);
    this.setCriticalCookies(params);
    this.setTimestampCookies();
  },

  /*
   * Set a cookie from a json object containing a gender key
   *
   * @param {Object} userJSON - JSON representation of a user
   * @param {String} userJSON.gender - 'f' or 'm'
   */
  setGenderCookie: function (params) {
    if (!params || !params.gender) return;

    var genderValue = params.gender.toLowerCase();

    if (/[mf]/.test(genderValue)) {
      this.setCookie('u_g', genderValue, this.TWO_YEARS);
    }
  },

  /*
  * Set a cookie from a json object containing a source key
  *
  * @params {Object} params object
  * @param {String} params.s or params.S
  */
  setSourceCookie: function (params) {
    if (!params) return;

    var s = params.s || params.S;

    if (!s) return;

    this.setCookie('s', s);
  },

  /*
  * Set a cookie from a json object containing a all Critical keys
  *
  * @param {Object} Query
  */
  setCriticalCookies: function (cookies) {
    // Find All the Critical Cookie Params
    var params = reduce(keys(cookies), function (obj, key) {
      // Determine if key matches any crititical params but ignore case sensitivity
      var keyRegExp = new RegExp('^' + key + '$', 'i');
      var keyName = find(this.CRITICAL_PARAMS, function (p) { return keyRegExp.test(p); }, this);

      // Add The Critical Params Lowercase Key Value to New Object
      if (keyName) obj[keyName] = cookies[key];

      return obj;
    }, {}, this);

    // Short-circuit If no Critical Params are Passed
    if (isEmpty(params)) return;

    // Find Missing Params and Delete Them to Prevent Cross Contamination
    var missingParams = difference(this.CRITICAL_PARAMS, keys(params));
    missingParams.forEach(function (cookieName) {
      Cookies.remove(cookieName);
    });

    each(keys(params), function (cookieName) {
      this.setCookie(cookieName, params[cookieName]);
    }, this);
  },

  /*
   * lp_view: Last page view cookie value: current date set on every page view; permanent
   * ls_end: Last session end cookie value: set from lp_view on every new session; session
   * s_start: Session start cookie value: current time of every new session
   * Sessions will expire with SESSION_EXPIRE config
   */
  setTimestampCookies: function () {
    var currentTime = Date.now();
    var lastPageView = parseInt(Cookies.get('lp_view')) || undefined;

    this.setCookie('lp_view', currentTime, this.TWO_YEARS);

    if (!Cookies.get('ls_end') || this.isSessionExpired(lastPageView)) {
      this.setCookie('ls_end', lastPageView);
      this.setCookie('s_start', currentTime);
    }
  },

  /*
   * Is the provided timestamp more than SESSION_EXPIRE milliseconds ago
   *
   * @param {Int} time - Unix timestamp in milliseconds
   */
  isSessionExpired: function (time) {
    var timeElapsed = Date.now() - time;

    return timeElapsed >= this.SESSION_EXPIRE;
  },

  setCookie: function (cookieName, cookieValue, expiresInDays) {
    // Matches legacy behavior of nullifying cookie value
    if (cookieValue === undefined) {
      return Cookies.remove(cookieName);
    }

    Cookies.set(cookieName, cookieValue, {
        expires: expiresInDays,
        path: '/'
    });
  }
};
