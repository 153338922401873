import Backbone from "backbone";

// App.Models.BaseModel
var BaseModel = Backbone.Model.extend({
  constructor: function(attributes, options) {
    options = options || {};
    options.parse = true;
    this.inheritsFromBaseModel = true;
    Backbone.Model.call(this, attributes, options);
  },

  initialize: function() {
    if (BaseModel === this.constructor) throw new Error("App.Models.BaseModel should not be instantiated directly");
  },

  parse: function(data) {
    if (this.wrappedAttributes) {
      Object.keys(this.wrappedAttributes).forEach((key) => {
        const Class = this.wrappedAttributes[key];
        var value = data[key];
        if (data[key] && Class != value.constructor) {
          data[key] = new Class(value);
        }
      });
    }
    return data;
  }
});

export default BaseModel;
