import BaseView from './base_view';

// App.Views.MobileMenuView
export default BaseView.extend({
  events: {
    'click .ham-menu': 'handleMenuOpen',
    'click .close-menu': 'handleMenuClose',
    'click .active-subnav a': 'handleMenuClose',
    'click .nav-expander': 'handleExpandSubMenuClick',
    'click .top-navigation.transparentize': 'menuCloseEvent',
  },

  handleMenuOpen: function () {
    // Calculate banner height to better calc height of menu
    const navHeight = window.innerHeight - $('nav.site-wide')[0].getBoundingClientRect().top;

    this.$el.css('height', `${navHeight}px`).addClass('mobile-nav');
    this.$('.ham-menu').addClass('hide');
    this.$('.close-menu').removeClass('hide');
    this.$('.top-navigation').addClass('transparentize');
    this.$('.menu-nav-container').addClass('display-overlay');
    $('body').addClass('overlay-active');
    var $subNavEl = this.$('.top-level-nav.active .sub-nav');
    if ($subNavEl.is(':hidden')) {
      this.expandSubMenu($subNavEl.prev('.nav-expander'));
    }
    this.menuCloseDesktop();
  },

  handleExpandSubMenuClick: function (e) {
    var $currentTarget = $(e.currentTarget);
    this.expandSubMenu($currentTarget);
  },

  handleMenuClose: function () {
    // Reset height of nav when it closes
    this.$el.css('height', 'auto').removeClass('mobile-nav');
    this.$('.ham-menu').removeClass('hide');
    this.$('.close-menu').addClass('hide');
    this.$('.top-navigation').removeClass('transparentize');
    this.$('.menu-nav-container').removeClass('display-overlay');
    $('body').removeClass('overlay-active');
  },

  expandSubMenu: function ($subMenuEl) {
    $subMenuEl.parent().children('.sub-nav').toggleClass('shift-down');
    var $iconTarget = $subMenuEl.children('i');
    var iconText = $iconTarget.text();
    var arrowRight = 'keyboard_arrow_right';
    var arrowDown = 'keyboard_arrow_down';
    $iconTarget.text(iconText == arrowRight ? arrowDown : arrowRight);
  },

  menuCloseEvent: function () {
    this.handleMenuClose();
    if(this.$('.has-submenu').hasClass('open')){
      $('body').addClass('overlay-active');
    }
  },

  menuCloseDesktop: function () {
    $(window).on('resize', () => {
      if(window.innerWidth > 1100 ){
        $(window).off('resize')
        this.handleMenuClose()
      }
    })
  },

});
