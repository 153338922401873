import Cookies from 'js-cookie';

const getHeapSessionId = () => {
  const cookies = Cookies.get();
  const userId = window.heap?.userId;

  if (!userId) {
    return undefined;
  }

  return Object.keys(cookies)
    .filter((key) => key.match(/^_hp2_id./))
    .map((key) => JSON.parse(decodeURIComponent(cookies[key])))
    .find((config) => config.userId === userId)
    ?.sessionId;
};

export default getHeapSessionId;
