export default function(data) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="row">\n  ' +
((__t = ( data.deal.get('flags') )) == null ? '' : __t) +
'\n  <div class="product col large-5 medium-5 small-12">\n    ' +
((__t = ( data.deal.get('image_link') )) == null ? '' : __t) +
'\n    ';
 if(data.deal.isExpired()) { ;
__p += '\n      <span class="expired">\n        Expired\n      </span>\n    ';
 } ;
__p += '\n    <a href="/stores/' +
((__t = ( this.model.get('merchant').slug )) == null ? '' : __t) +
'" class="store-link">\n      <span class="pre-text">see all deals from</span>\n      <span>' +
((__t = ( this.model.get('merchant').name )) == null ? '' : __t) +
'</span>\n    </a>\n    ' +
((__t = ( data.deal.get('third_party_tracking_pixel') )) == null ? '' : __t) +
'\n  </div>\n  <div class="information col large-7 medium-7 small-12">\n    <h3>\n      <a href="' +
((__t = ( data.deal.get('go_link_href') )) == null ? '' : __t) +
'" class="go_link" target="_blank" >\n        ' +
((__t = ( data.deal.get('title') )) == null ? '' : __t) +
'\n      </a>\n    </h3>\n    ';
 if (data.deal.isFakespotVerified()) { ;
__p += '\n      <div class="fakespot-tag">\n        ' +
((__t = ( data.fakespotSVG() )) == null ? '' : __t) +
'\n        <span class="tag-text">Fakespot Verified</span>\n        <span class="fakespot-tooltip"></span>\n      </div>\n    ';
 } ;
__p += '\n    <p>\n      ' +
((__t = ( data.deal.get('merchant').merchant_rules.alternate_disclaimer )) == null ? '' : __t) +
'\n    </p>\n    ';
 if(data.deal.isExpired()) { ;
__p += '\n      <div class="deal-has-ended expired">\n        <p>\n          This deal has ended.\n        </p>\n      </div>\n    ';
 } ;
__p += '\n    <div class="advertiser-disclosure-link" hidden="true">\n      <a href="#disclosure">Advertiser Disclosure</a>\n    </div>\n    <p>' +
((__t = ( data.deal.get('description') )) == null ? '' : __t) +
'</p>\n    <div class="deal-go-link"></div>\n  </div>\n  <div class="col small-12 hide-for-large hide-for-medium">\n    <p class="description">' +
((__t = ( data.deal.get('description') )) == null ? '' : __t) +
'</p>\n    <span class="link more-details">More Details</span>\n  </div>\n</div>\n<footer class="row-flex mobile-columns justify-space-between">\n  <div class="author-info clearfix"></div>\n  <div class="row-flex tablet-columns">\n    <div class="engagement-module-container"></div>\n    ';
 if (data.deal.get('primary_category')) { ;
__p += '\n      <a href="' +
((__t = ( data.deal.primaryCategoryPath() )) == null ? '' : __t) +
'" class="grey-button view-similar-deals">View Similar Deals</a>\n    ';
 } ;
__p += '\n  </div>\n</footer>\n';
return __p
}