import BaseView from './base_view';
import template from '../templates/social_media_widget.jst.ejs';

// App.Views.SocialMediaWidget
export default BaseView.extend({
  template: template,

  initialize: function (options) {
    this.render();
    this.createTwitterBadge();
    this.createFacebookBadge();
  },

  render: function(){
    return this.$el.html(this.template);
  },

  createTwitterBadge: function(){
    this.$el.find(".twitter")
      .html('<a href="https://twitter.com/share" class="twitter-share-button" data-via="bradsdeals" data-lang="en" ' +
        'data-text="' + this.model.get("title") + '" ' +
        'data-url="/blogs/' + this.model.get("slug") + '"' +
        '>Tweet</a>');
  },

  createFacebookBadge: function(){
    this.$el.find(".facebook")
      .html('<div class="fb-share-button" data-href="/blogs/' + this.model.get("slug") + '" data-layout="button_count"></div>');

  }
});
