import Modal from '../views/modal';
import CreateProfileModal from '../views/modals/create_profile';
import EmailPreferenceModal from '../views/modals/email_preference';
import LoginModal from '../views/modals/login';
import ResetPasswordModal from '../views/modals/reset_password';
import SignUpModal from '../views/modals/sign_up';
import SubscriptionPauseModal from '../views/modals/subscription_pause';
import SubscriptionUnsubscribeModal from '../views/modals/subscription_unsubscribe';
import template from '../templates/modal.jst.ejs';
import { bindAll } from 'underscore';

// App.Controllers.Modals
export default {
  initialize: function () {
    App.Dispatcher.on('initModals', this.initModals, this);
  },

  initModals: function () {
    var params = App.Data.currentUrlParameters();

    this.createModalOverlay();

    bindAll(this, 'modalLinkClick', 'hideModalLinkClick', 'handleFormSuccess');

    $(document).on('click', '.modal-link',    this.modalLinkClick);
    $(document).on('click', '#modal-overlay', this.hideModalLinkClick);
    $(document).on('click', '.close-modal',   this.hideModalLinkClick);
    $(document).on('externalForm:success',    this.handleFormSuccess);

    App.Dispatcher.on('showModal', this.showModal, this);
    App.Dispatcher.on('hideModal', this.hideModal, this);

    if (params.m) {
      this.showModalByName(params.m);
    }
  },

  showModal: function (options) {
    if (!(options.modalClass || options.html)) {
      throw 'Modal Error: must provide modalClass or html to show a modal';
    } else if (options.html && !options.modalClass) {
      options.modalClass = Modal;
    }

    if (this.modalView) {
      this.modalView.sever();
    }

    options.el = '#modal .content';
    this.modalView = new options.modalClass(options);
    this.modalView.render();
  },

  showModalByName: function (modalName) {
    var modalClass = this.lookUpModalClass(modalName);

    if (modalClass) {
      this.showModal({ modalClass: modalClass });
    }
  },

  hideModal: function () {
    if (this.modalView) {
      this.modalView.hideModal();
    }
  },

  createModalOverlay: function () {
    if ($('#modal').length < 1) {
      return $(template()).appendTo('body');
    }
  },

  modalLinkClick: function (event) {
    event.preventDefault();
    var options = $(event.currentTarget).data();
    options.modalClass = this.lookUpModalClass(options.modal);

    if (!options.modalClass) {
      throw 'Modal Error: unknown modal name specified on modal link';
    }

    this.showModal(options);
  },

  hideModalLinkClick: function (event) {
    if (!this.modalView.isClosable()) {
      return;
    }

    if ((event.target.id === 'modal-overlay') || $(event.currentTarget).hasClass('close-modal')) {
      event.preventDefault();
      App.Dispatcher.trigger('bdResetUserAction');
      App.Dispatcher.trigger('bdModalCancel');
      this.hideModal();
    }
  },

  handleFormSuccess: function () {
    if (this.modalView && this.modalView.isClosable()) {
      this.hideModal();
    }
  },

  lookUpModalClass: function (modalName) {
    var modalClasses = {
      create_profile:  CreateProfileModal,
      email_pref:      EmailPreferenceModal,
      login:           LoginModal,
      reset_password:  ResetPasswordModal,
      sign_up:         SignUpModal,
      sub_pause:       SubscriptionPauseModal,
      sub_unsub:       SubscriptionUnsubscribeModal
    };
    return modalClasses[modalName];
  },
};
