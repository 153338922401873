/* eslint-disable no-param-reassign  */

import Vue from 'vue';

const buildOnClick = target => () => {
  target.scrollIntoView({ behavior: 'smooth' });
};

Vue.directive('snap-to', {
  bind(el, binding) {
    const selector = binding.value;
    const target = document.querySelector(selector);
    const handleOnClick = buildOnClick(target);

    el.addEventListener('click', handleOnClick);

    // Assign function to element so it can be turned off on unbind
    el.directiveFunction = handleOnClick;
  },

  componentUpdated(el, binding) {
    const selector = binding.value;
    const target = document.querySelector(selector);
    const handleOnClick = buildOnClick(target);

    el.removeEventListener('click', el.directiveFunction);
    el.addEventListener('click', handleOnClick);

    // Assign function to element so it can be turned off on unbind
    el.directiveFunction = handleOnClick;
  },
});
