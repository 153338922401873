import id from './sst/id';

/**
 * @fileoverview Client side code for interacting with the identity and
 *     event logging services.
 * @author ken@bradsdeals.com (Ken Walters)
 */

/**
 * Top level shopsmart tracker object
 */
const sst = {};

sst.id = id;

/**
 * Initialize tracker
 */
sst.init = function (options) {
  if (options && options.request_data) sst.config.request_data = _.defaults(options.request_data, sst.config.request_data);
  return sst.id.init();
};

/**
 * Config object
 */
sst.config = {};

/**
 * Request based data available to other methods
 */
sst.config.request_data = {
  http_referer: document.referrer === '' ? null : document.referrer,
  current_page: window.location.href,
  query_string: window.location.search,
  user_agent: navigator.userAgent,
  channel: 'web',
  location: $('html').attr('data-loc') || 'unknown',
};

// The default value of s_hash is -967143883,
// because we will always have client_id set to value of 25.
// sst.id.generateSessionHash({channel_id: sst.config.constants.default_www_channel_id})
sst.config.constants = {
  www_channel_id: 25,
  session_params_hash: -967143883,
};

/**
 * Config settings that control the functionality of id and event logger
 */
sst.config.settings = {
  urls: {
    // These urls are defined in content_api.yml
    // and set in initImpressionTracking
    id: '',
  },
  params: {
    session: {
      /**
             * The key in this hash represents the data label in the logging
             * data. The value is an array of query params. Query params match
             * in the priority order from left to right. There may be an
             * optional last item indicating whether the presence of the param
             * should trigger a new session. This optional last item defaults to
             * true, so the presence of any parameters without a final element
             * of false will force a new session. The presence of a parameter
             * with a final element of false (and no parameters without false)
             * will force an update of only the provided params.
             */
      campaign_name: ['utm_campaign'],
      campaign_medium: ['utm_medium'],
      campaign_source: ['utm_source'],
      campaign_term: ['utm_term'],
      campaign_content: ['utm_content'],
      channel_id: ['c_id'],
      affiliate_id: ['a_id'],
      affiliate_sid: ['a_sid'],
      x_tid: ['tid', 'TID', 'Tid', false],
      x_source: ['s', false],
    },
  },
  data: {
    session: {
      http_referer: sst.config.request_data.http_referer,
      landing_page: sst.config.request_data.current_page,
    },
    device: {
      user_agent: sst.config.request_data.user_agent,
    },
  },
};


/**
 * AJAX Functions
 */
sst.ajax = {};

/**
 * Wrapper for post ajax calls.
 * @param {Object} options Represents the options to be passed to the ajax
 *     request
 *     {
 *         url : 'url',
 *         async : true|false,
 *         data : {data},
 *         success : function(data){},
 *         error : function(jqXHR, status, error)
 *     }.
 */
sst.ajax.post = function (options) {
  // Set ajax request options
  const reqOptions = {
    url: options.url,
    cache: false,
    dataType: 'json',
    contentType: 'text/plain; charset=UTF-8',
    type: 'POST',
    data: JSON.stringify(options.data),
    success: options.success,
    error: options.error,
    complete: options.complete,
  };
    // Modify request options for browsers without cors support
  if (!jQuery.support.cors) {
    reqOptions.dataType = 'jsonp';
    reqOptions.type = 'GET';
    reqOptions.data = { _method: 'POST', data: reqOptions.data };
  }
  // Register session with identity service
  return jQuery.ajax(reqOptions);
};

/**
 * Utility Functions
 */
sst.util = {};

/**
 * Pads a string to the specified size with a leading filler chars
 * @param {string|number} input A string or integer.
 * @param {number} size The minimum length of the output string.
 * @param {string} filler A character to be used to fill in to the minimum
 *     length.
 * @return {string} A string with the input padded to the min length with the
 *     provided filler.
 */
sst.util.pad = function (input, size, filler) {
  let output = String(input);
  if (output.length < size) {
    const gap = (size - output.length);
    for (let i = 0; i < gap; i++) {
      output = filler.charAt(0) + output;
    }
  }
  return output;
};

/**
 * Handles time.toISOString() for browsers without native support
 * @param {time} t Time.
 * @return {string} ISO representation of the time.
 */
sst.util.dateToISOString = function (t) {
  if (t.toISOString) {
    return t.toISOString();
  }
  return sst.util.shivDateToISOString(t);
};

/**
 * Handles time.toISOString() for browsers without native support
 * @param {time} t Time.
 * @return {string} Time in the format of "YYYY-MM-DDTHH:mm:ss.sssZ".
 */
sst.util.shivDateToISOString = function (t) {
  const YYYY = String(t.getUTCFullYear());
  const MM = sst.util.pad(t.getUTCMonth() + 1, 2, '0');
  const DD = sst.util.pad(t.getUTCDate(), 2, '0');
  const HH = sst.util.pad(t.getUTCHours(), 2, '0');
  const mm = sst.util.pad(t.getUTCMinutes(), 2, '0');
  let ss = sst.util.pad(t.getUTCSeconds(), 2, '0');
  ss += `.${sst.util.pad(t.getUTCMilliseconds(), 3, '0')}`;
  return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}Z`;
};

/**
 * Get a specific cookie by name
 * @param {string} c_name Cookie name.
 * @return {string} Cookie value.
 */
sst.util.getCookie = function (c_name) {
  let i; let x; let y; const
    ARRcookies = document.cookie.split(';');
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, '');
    if (x == c_name) {
      return unescape(y);
    }
  }
};

/**
 * Set a cookie
 * @param {string} c_name Cookie name.
 * @param {string} value Cookie value.
 * @param {number} exdays Number of days until the cookie expires. A null value
 *     results in a cookie that will expire at the end of the current session.
 *     A negative value will result in the cookie being expired immediately.
 */
sst.util.setCookie = function (c_name, value, exdays) {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  let c_value = escape(value);
  c_value += ((exdays == null) ? '' : `; expires=${exdate.toUTCString()}`);
  document.cookie = `${c_name}=${c_value}; path=/`;
};

/**
 * Parses a provided querystring into url params
 * @param {string} queryString Querystring for parsing.
 * @return {Object} A hash of params and values.
 */
sst.util.parseQueryParams = function (queryString) {
  const params = {};
  queryString.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
    params[key] = decodeURI(value);
  });
  return params;
};

/**
 * Create a hashCode from a given string http://stackoverflow.com/a/7616484
 * @param {string} str Input string.
 * @return {number} A hashcode for the supplied input string.
 */
sst.util.stringToHashCode = function (str) {
  let hash = 0; let i; let l; let
    character;
  if (str.length == 0) return hash;
  for (i = 0, l = str.length; i < l; i++) {
    character = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + character;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Test to see if an object has no properties
 * @param {Object} obj Input object.
 * @return {bool} True if the object is empty, false if it has any members.
 */
sst.util.emptyObject = function (obj) {
  let empty = true;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      empty = false;
      break;
    }
  }
  return empty;
};

/**
 * Merges the non-null properties of multiple objects into a new combined object
 * @return {Object} A merged object comprised of all non-null members of the
 *     input objects with duplicate members in objects passed in to the right of
 *     other input objects taking priority.
 */
sst.util.mergeNonNullProperties = function () {
  const newObj = {};
  for (let i = 0; i < arguments.length; i++) {
    for (const key in arguments[i]) {
      if (arguments[i][key] != null) {
        newObj[key] = arguments[i][key];
      }
    }
  }
  return newObj;
};

/**
 * A bare bones set implementation
 * @constructor
 */
sst.util.Set = function () {
  const contents = {};

  this.add = function (item) {
    contents[item] = true;
  };

  this.each = function (eachFunction) {
    for (const item in contents) {
      eachFunction.call(item);
    }
  };
};

export default sst;
