import BaseView from './base_view';
import template from '../templates/pagination.jst.ejs';

// App.Views.Pagination
export default BaseView.extend({
  template: template,

  initialize: function (options) {
    this.options = this.options || options || {};
    this.pathHelper = this.options.pathHelper;
    this.currentPageNumber = this.options.currentPageNumber;
    this.maxPageNumber = this.options.maxPageNumber;
    this.render();
  },

  render: function () {
    return this.$el.html(this.template());
  },

  isFirstPage: function () {
    return this.currentPageNumber === 1;
  },

  isLastPage: function () {
    return this.currentPageNumber === this.maxPageNumber;
  },

  nextPageUrl: function () {
    return this.pathHelper({page: (this.currentPageNumber + 1)});
  },

  previousPageUrl: function () {
    return this.pathHelper({page: (this.currentPageNumber - 1)});
  }
});
